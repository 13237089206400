import { CheckConnectionError, HostedExportersApiErrorResult } from 'api/hosted-exporters-api/data-models';
import { isErrorResponse } from 'api/common/utils';
import { OnboardingPluginError } from 'api/common/data-model';
import { cloudwatchText } from 'utils/consts';

export function isHostedExportersApiErrorResult(thing: any): thing is HostedExportersApiErrorResult {
  if (!thing) {
    return false;
  }
  return (
    typeof thing === 'object' &&
    'error' in thing &&
    typeof thing.error === 'object' &&
    'code' in thing.error &&
    'message' in thing.error
  );
}

export function isCheckConnectionError(thing: any): thing is CheckConnectionError {
  return isErrorResponse(thing) && 'context' in thing;
}

export function handleDisabledAwsRegionError(error: CheckConnectionError): OnboardingPluginError | null {
  const innerError = error.data.error;

  if (error.status === 409 && innerError.code === 'aws_disabled_region') {
    return {
      code: '',
      message: cloudwatchText.errorFunctions.regionDisabled(innerError.message),
      errorObject: error,
    };
  } else {
    return null;
  }
}
