import { css } from '@emotion/css';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Icon, LinkButton, Spinner, useStyles2 } from '@grafana/ui';

import { OsBasedOptions, useCollectorSelector } from '@grafana-cloud/collector';
import { Alert } from 'components/Alert';
import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { CHECK_INTERVAL_MS, CHECK_TIMEOUT_MS, INSTALL_TROUBLESHOOTING_DOCS_LINK } from 'utils/consts';
import {
  IntegrationConnectionErrorCause,
  IntegrationConnectionResult,
  IntegrationConnectionStatus,
  RudderStackEvents,
} from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { AlloyIntegrationContext, AlloyIntegrationState } from 'app/contexts/alloyIntegration.context';

import { useGetIntegration, useTestIntegrationConnectionRequest } from 'api/int-api/queries';
import { isIntegrationUsingLogs, isIntegrationUsingMetrics } from 'api/int-api/utils';
import { QueryError } from 'components/QueryError/QueryError';
import { useQueryClient } from '@tanstack/react-query';

const getStyles = () => ({
  spinnerIcon: css`
    margin-right: 5px;
  `,
  button: css`
    margin-bottom: 16px;
  `,
});

type TestConnectionButtonProp = {
  testConnection: () => void;
};

const TestConnectionButton: FC<TestConnectionButtonProp> = ({ testConnection }) => {
  const { integrationConnectionResult } = useContext<AlloyIntegrationState>(AlloyIntegrationContext);
  const styles = useStyles2(getStyles);

  const pendingStatus =
    integrationConnectionResult.status === IntegrationConnectionStatus.Pending ||
    integrationConnectionResult.status === IntegrationConnectionStatus.NoData;

  return (
    <Button
      size="md"
      onClick={testConnection}
      className={styles.button}
      data-testid={Pages.Source.AlloyConfig.testConnectionButton}
    >
      {pendingStatus && <Icon className={styles.spinnerIcon} name="fa fa-spinner" />}
      Test connection
    </Button>
  );
};

const GiveUsFeedbackButton: FC = () => {
  return (
    <LinkButton
      variant="secondary"
      href="https://docs.google.com/forms/d/e/1FAIpQLScD0CkReyBWkr1byQnMWGk887S-mmvPc11AqvbYBiZcDqUQfA/viewform"
      target="_blank"
      rel="noreferrer"
    >
      Give us feedback
    </LinkButton>
  );
};

const Buttons: FC<TestConnectionButtonProp> = ({ testConnection }) => {
  const getStyles = (theme: GrafanaTheme2) => ({
    container: css({
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(1),
    }),
  });
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <TestConnectionButton testConnection={testConnection} />
      <GiveUsFeedbackButton />
    </div>
  );
};

const TestConnectionError: FC<{ sourceId: string; error: IntegrationConnectionErrorCause }> = ({ sourceId, error }) => {
  const getStyles = (theme: GrafanaTheme2) => ({
    alert: css`
      a {
        color: ${theme.colors.text.link};
      }
    `,
  });
  const styles = useStyles2(getStyles);
  const { osValue } = useCollectorSelector((state) => state.collector.selectedOs);
  const { trackRudderStackEvent } = useRudderStack();

  if (error === IntegrationConnectionErrorCause.Unexpected) {
    return <Alert status="error">An unexpected error occurred. Please try again.</Alert>;
  }

  let errorMessage: string;
  let docsLink = INSTALL_TROUBLESHOOTING_DOCS_LINK;

  switch (osValue) {
    case OsBasedOptions.Windows:
      docsLink += 'ing-windows/';
      break;
    case OsBasedOptions.MacOs:
      docsLink += '-mac/';
      break;
    default:
      docsLink += '-linux/';
      break;
  }

  switch (error) {
    case IntegrationConnectionErrorCause.AgentCannotScrapeMetrics:
      docsLink += '#the-agent-was-not-able-to-reach-your-application-prometheus-metrics-endpoint';
      errorMessage = 'The agent was not able to reach your application prometheus metrics endpoint.';
      break;
    case IntegrationConnectionErrorCause.NoMetricsFound:
      docsLink += '#no-metrics-were-found-for-this-integration';
      errorMessage = 'No metrics were found for this integration.';
      break;
    case IntegrationConnectionErrorCause.NoLogsFound:
      docsLink += '#the-agent-was-not-able-to-collect-your-application-logs';
      errorMessage = 'The agent was not able to collect your application logs.';
      break;
  }

  return (
    <Alert status="error" className={styles.alert}>
      {errorMessage} Please check{' '}
      <a
        href={docsLink}
        onClick={() =>
          trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionTroubleshootingDocsLinkClick, {
            integration_slug: sourceId,
            docs_link: docsLink,
          })
        }
      >
        the documentation
      </a>{' '}
      for troubleshooting.
    </Alert>
  );
};

const getTestConnectionStyles = (theme: GrafanaTheme2) => ({
  checkConnection: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};
    margin-right: ${theme.spacing(2)};
    width: 100%;
  `,
  alert: css`
    margin-top: 16px;
  `,
});

export const TestConnection: FC<{ sourceId: string }> = ({ sourceId }) => {
  const styles = useStyles2(getTestConnectionStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { integrationConnectionResult, setIntegrationConnectionError, setIntegrationConnectionStatus } =
    useContext<AlloyIntegrationState>(AlloyIntegrationContext);
  const [testConnectionData, setTestConnectionData] = useState<IntegrationConnectionResult>();
  const queryClient = useQueryClient();

  const {
    data: selectedIntegration,
    isFetching,
    isError,
  } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);

  const { refetch: testIntegrationConnectionRequest, isFetching: isFetchingIntegrationConnection } =
    useTestIntegrationConnectionRequest(pluginId, jsonData.grafana_instance_id, selectedIntegration);

  const [checkStartedAt, setCheckStarted] = useState(0);

  const testIntegrationConnection = useCallback(async () => {
    const { data } = await testIntegrationConnectionRequest();
    setTestConnectionData(data);
    setCheckStarted(new Date().getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (integrationConnectionResult.status !== IntegrationConnectionStatus.None) {
      setIntegrationConnectionStatus(IntegrationConnectionStatus.None);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingIntegrationConnection) {
      setIntegrationConnectionStatus(IntegrationConnectionStatus.Pending);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingIntegrationConnection]);

  const resetTestConnection = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['testIntegrationConnectionRequest', selectedIntegration],
    });
    setTestConnectionData(undefined);
  }, [queryClient, selectedIntegration]);

  useEffect(() => {
    if (!!testConnectionData) {
      if (testConnectionData.status === IntegrationConnectionStatus.Success) {
        setIntegrationConnectionStatus(IntegrationConnectionStatus.Success);
      }
      if (testConnectionData.status === IntegrationConnectionStatus.Error) {
        setIntegrationConnectionError(testConnectionData.error);
      }
      if (testConnectionData.status === IntegrationConnectionStatus.NoData && checkStartedAt) {
        if (new Date().getTime() - checkStartedAt > CHECK_TIMEOUT_MS) {
          setIntegrationConnectionError(testConnectionData.error);
        } else {
          resetTestConnection().then(() => {
            const timeout = setTimeout(async () => {
              const { data } = await testIntegrationConnectionRequest();
              setTestConnectionData(data);
            }, CHECK_INTERVAL_MS);
            return () => clearTimeout(timeout);
          });
        }
      }
    }
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testConnectionData, checkStartedAt]);

  if (isFetching) {
    return <Spinner />;
  }
  if (isError) {
    return <QueryError message="Error while retrieving the integration details." isFullPageError={false} />;
  }

  if (
    Boolean(selectedIntegration) &&
    (isIntegrationUsingMetrics(selectedIntegration) || isIntegrationUsingLogs(selectedIntegration))
  ) {
    switch (integrationConnectionResult?.status) {
      case IntegrationConnectionStatus.None:
        return <Buttons testConnection={testIntegrationConnection} />;
      case IntegrationConnectionStatus.Pending:
      case IntegrationConnectionStatus.NoData:
        return (
          <>
            <Buttons testConnection={testIntegrationConnection} />
            <p className={styles.checkConnection}>Checking connection. It might take up to a minute...</p>
          </>
        );

      case IntegrationConnectionStatus.Success:
        return (
          <>
            <Buttons testConnection={testIntegrationConnection} />
            <Alert status="success" className={styles.alert}>
              The agent is now collecting data from your machine.
            </Alert>
          </>
        );

      case IntegrationConnectionStatus.Error:
        return (
          <>
            <Buttons testConnection={testIntegrationConnection} />
            {integrationConnectionResult.error !== undefined && integrationConnectionResult.error !== null && (
              <TestConnectionError sourceId={sourceId} error={integrationConnectionResult.error} />
            )}
          </>
        );

      default:
        return <Buttons testConnection={testIntegrationConnection} />;
    }
  }
  return null;
};
