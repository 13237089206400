import React, { useContext, useState } from 'react';

import { Button, useStyles2, Spinner, ConfirmModal } from '@grafana/ui';

import { getInstallStyles } from './Install.styles';
import useRudderStack from 'hooks/useRudderstack';
import { useGetHostedDataDetails } from 'api/grafana-com/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { RudderStackEvents } from 'enums';
import {
  useGetIntegration,
  useInstallIntegration,
  useInstallIntegrationStatus,
  useUninstallIntegrations,
} from 'api/int-api/queries';
import { Alert } from 'components/Alert';
import { Pages } from 'e2eSelectors/pages';
import { Status } from 'api/int-api/data-models';
import { CloudProvider } from 'types/CloudProvider';
import { useGetIntegrationsToUninstall } from 'feature/common/hooks/useGetIntegrationsToUninstall';
import { getCloudProviderName } from 'feature/common/utils/utils';

type InstallButtonProps = {
  disabled: boolean;
  showSpinner: boolean;
  buttonText?: string;
  integrationId: CloudProvider;
};

const InstallButton = ({ disabled, showSpinner, buttonText, integrationId }: InstallButtonProps) => {
  const styles = useStyles2(getInstallStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { trackRudderStackEvent } = useRudderStack();
  const { data: hostedData } = useGetHostedDataDetails(pluginId);
  const { mutate: installSingleIntegration } = useInstallIntegration(
    integrationId,
    pluginId,
    jsonData.grafana_instance_id,
    hostedData?.orgSlug
  );
  const integrationsToUninstall = useGetIntegrationsToUninstall(integrationId);
  const { mutate: uninstallMutation } = useUninstallIntegrations(
    integrationsToUninstall ?? [],
    pluginId,
    jsonData.grafana_instance_id
  );
  const [showModal, setShowModal] = useState(false);
  const cloudProviderName = getCloudProviderName(integrationId);

  const handleInstallClick = () => {
    if (integrationsToUninstall && integrationsToUninstall?.length > 0) {
      setShowModal(true);
    } else {
      installSingleIntegration();
      trackRudderStackEvent(RudderStackEvents.InstallButton, { integration_slug: integrationId });
    }
  };

  return (
    <>
      <Button disabled={disabled} onClick={handleInstallClick} data-testid={Pages.Source.Config.install}>
        {showSpinner && <Spinner className={styles.spinner} />}
        {buttonText ?? 'Install'}
      </Button>
      <ConfirmModal
        isOpen={showModal}
        title="Install"
        body={`Installing the dashboards for ${cloudProviderName} will also remove the ${integrationsToUninstall?.join(', ')} deprecated dashboards.`}
        confirmText="Install"
        icon="exclamation-triangle"
        confirmButtonVariant="primary"
        onConfirm={() => {
          uninstallMutation();
          installSingleIntegration();
          trackRudderStackEvent(RudderStackEvents.InstallButton, { integration_slug: integrationId });
          setShowModal(false);
        }}
        onDismiss={() => setShowModal(false)}
      />
    </>
  );
};

type InstallProps = {
  integrationId: CloudProvider;
};

export const Install = ({ integrationId }: InstallProps) => {
  const styles = useStyles2(getInstallStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const integrationInstallationStatus = useInstallIntegrationStatus(integrationId);
  const { data: selectedIntegration } = useGetIntegration(integrationId, pluginId, jsonData.grafana_instance_id);
  const integrationsToUninstall = useGetIntegrationsToUninstall(integrationId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const areIntegrationsToUninstall = integrationsToUninstall && integrationsToUninstall.length > 0;

  const areAlertsAvailable = selectedIntegration?.alerts?.status === Status.Available;
  const areDashboardsAvailable = selectedIntegration?.dashboards?.status === Status.Available;
  const dashboardsAndAlertsText = areDashboardsAvailable
    ? areAlertsAvailable
      ? 'Dashboards and alerts'
      : 'Dashboards'
    : areAlertsAvailable
      ? 'Alerts'
      : '';
  const successMessage = areIntegrationsToUninstall
    ? 'New ' + dashboardsAndAlertsText.toLowerCase()
    : dashboardsAndAlertsText;

  const buttonText = 'Install ' + dashboardsAndAlertsText.toLowerCase();

  switch (integrationInstallationStatus) {
    case 'idle':
      return (
        <div className={styles.marginBottom}>
          <InstallButton
            disabled={selectedIntegration?.installation !== undefined}
            showSpinner={false}
            buttonText={buttonText}
            integrationId={integrationId}
          />
        </div>
      );

    case 'pending':
      return (
        <div className={styles.marginBottom}>
          <InstallButton disabled={true} showSpinner={true} buttonText={buttonText} integrationId={integrationId} />
        </div>
      );

    case 'success':
      return (
        <div className={styles.marginBottom}>
          <InstallButton disabled={true} showSpinner={false} buttonText={buttonText} integrationId={integrationId} />
          <Alert status="success" className={styles.alert} data-testid="install-success-message">
            {successMessage} have been installed.
          </Alert>
        </div>
      );

    case 'error':
      return (
        <div className={styles.marginBottom}>
          <InstallButton disabled={false} showSpinner={false} buttonText={buttonText} integrationId={integrationId} />
          <Alert status="error" className={styles.alert} data-testid="install-error-message">
            Something went wrong. Please try again.
          </Alert>
        </div>
      );
  }
};
