import {
  CustomVariable,
  EmbeddedScene,
  QueryVariable,
  SceneAppDrilldownView,
  SceneAppPage,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneVariableSet,
  TextBoxVariable,
  VizPanel,
} from '@grafana/scenes';
import { AWS_LOGS_URL } from 'scenes/AWS/routes';
import { LogsMethod } from 'scenes/types';
import { getLokiDatasourceVar, VAR_ACCOUNTS, VAR_LEVEL, VAR_LOG_TERM_SEARCH } from 'scenes/variables';
import { VariableHide } from '@grafana/schema';
import { decrypt, getAccountLabelValuesByMethod, PASS_PHRASE } from './utils';
import { AwsLogsQueryBehavior, AwsLogsVolumeQueryBehavior, LogsQueryState } from 'scenes/Logs/behaviours';
import { CloudProvider } from 'types/CloudProvider';
import { AWSLogsQueryState } from 'scenes/Logs/queries';
import { LogsSearchTextScene } from 'scenes/Logs/LogsSearchTextScene';
import { getLogsVolumePanel } from 'scenes/Logs/getLogsVolumePanel';
import { getLogsControls } from 'scenes/Logs/getLogsControls';
import { makeTimeRange } from 'scenes/misc';

export const getLogGroupDrilldown = (lokiUID: string, from?: string, to?: string) => {
  const sceneTimeRange = makeTimeRange(from, to, undefined, { from: 'now-1h', to: 'now' });

  return [
    {
      routePath: AWS_LOGS_URL + '/:logGroupId',
      getPage: (routeMatch, parent) => {
        const logGroupAndMethod = decrypt(PASS_PHRASE, routeMatch.params.logGroupId);
        const logGroup = logGroupAndMethod.split(' - ')[0];
        const logMethod: LogsMethod = logGroupAndMethod.split(' - ')[1];
        const datasource = { type: 'loki', uid: '$lokiDatasource' };

        const logsState: LogsQueryState<AWSLogsQueryState> = {
          platform: CloudProvider.AWS,
          params: { job: 'cloud/aws', logGroup, method: logMethod } as AWSLogsQueryState,
        };

        return new SceneAppPage({
          title: `${logGroup}`,
          url: `${AWS_LOGS_URL}/${routeMatch.params.logGroupId}`,
          getParentPage: () => parent,
          getScene: () =>
            new EmbeddedScene({
              $data: new SceneQueryRunner({
                datasource,
                queries: [],
                $behaviors: [new AwsLogsQueryBehavior(logsState)],
              }),
              $timeRange: sceneTimeRange,
              $variables: new SceneVariableSet({
                variables: [
                  getLokiDatasourceVar(lokiUID),
                  new QueryVariable({
                    name: VAR_ACCOUNTS,
                    label: 'AWS account',
                    datasource,
                    query: {
                      label: getAccountLabelValuesByMethod(logMethod),
                      refId: 'C',
                      stream: '',
                      type: 1,
                    },
                    includeAll: true,
                    defaultToAll: true,
                    isMulti: true,
                  }),
                  new CustomVariable({
                    name: VAR_LEVEL,
                    label: 'Level',
                    value: 'all',
                    query: ['all', 'debug', 'info', 'warn', 'error', 'fatal', 'critical', 'trace', 'unknown'].join(
                      ', '
                    ),
                  }),
                  new TextBoxVariable({
                    name: VAR_LOG_TERM_SEARCH,
                    hide: VariableHide.hideVariable,
                  }),
                ],
              }),
              controls: getLogsControls(),
              body: new SceneFlexLayout({
                direction: 'column',
                children: [
                  new SceneFlexItem({
                    key: 'search-text',
                    body: new LogsSearchTextScene<AWSLogsQueryState>(logsState),
                  }),
                  new SceneFlexItem({
                    key: 'logs-volume',
                    $data: new SceneQueryRunner({
                      datasource,
                      queries: [],
                      $behaviors: [new AwsLogsVolumeQueryBehavior(logsState)],
                    }),
                    body: getLogsVolumePanel(),
                    minHeight: 200,
                  }),
                  new VizPanel({ key: 'logs', title: 'Logs', pluginId: 'logs' }),
                ],
              }),
            }),
        });
      },
    },
  ] as SceneAppDrilldownView[];
};
