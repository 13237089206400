import { css } from '@emotion/css';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useReducer, useEffect, useState, useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Button, ConfirmModal, Spinner, Icon } from '@grafana/ui';
import { ScrapeJob } from 'api/hosted-exporters-api/data-models';
import { JobTableProps, JobTableView } from './JobTableView';
import {
  useCreateOrUpdateScrapeJobMutation,
  useDeleteScrapeJobsMutation,
  useFilteredJobs,
  useGetScrapeJobs,
} from 'api/hosted-exporters-api/queries';
import { SaasIntegrationWithJobType } from '../types';
import { useSelectedSource } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { SaasIntegrationContext } from 'app/contexts/saasIntegration.context';
import { Pages } from 'e2eSelectors/pages';
import { FilterJobStatus } from 'enums';
import { useCloudwatchParams } from 'hooks/useCloudwatchParams';
import { useHistory } from 'react-router-dom';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'utils/consts';
import { useClearMutationCache } from 'api/common/utils';
import { CloudProvider } from 'types/CloudProvider';

const getStyles = (theme: GrafanaTheme2) => ({
  backToJobsButton: css`
    &:last-child {
      margin-top: ${theme.spacing(2)};
    }

    margin-bottom: ${theme.spacing(2)};
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  step: css`
    position: relative;
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.components.input.borderColor};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    max-width: 780px;
  `,
});

enum JobManagementPage {
  JobList,
  JobForm,
  DeleteJob,
  EditJob,
}

type JobManagementState = {
  page: JobManagementPage;
  selectedJob: ScrapeJob | undefined;
  shouldShowInstallButton: boolean;
};

enum ActionType {
  CreateJob,
  EditJob,
  DeleteJob,
  ListJobs,
  ShowInstallButton,
}

type Action = {
  type: ActionType;
  job?: ScrapeJob;
};

function reducer(state: JobManagementState, action: Action): JobManagementState {
  switch (action.type) {
    case ActionType.CreateJob: {
      return {
        ...state,
        page: JobManagementPage.JobForm,
        selectedJob: undefined,
      };
    }
    case ActionType.EditJob: {
      return {
        ...state,
        page: JobManagementPage.EditJob,
        selectedJob: action?.job,
      };
    }
    case ActionType.DeleteJob: {
      return {
        ...state,
        page: JobManagementPage.DeleteJob,
        selectedJob: action?.job,
      };
    }
    case ActionType.ListJobs: {
      return {
        page: JobManagementPage.JobList,
        selectedJob: undefined,
        shouldShowInstallButton: false,
      };
    }
    case ActionType.ShowInstallButton:
      return {
        ...state,
        shouldShowInstallButton: true,
      };
    default: {
      return state;
    }
  }
}

export type CreateJobFormProps = {
  onSaveJob: (job: ScrapeJob) => void;
};

export type EditJobFormProps<T> = CreateJobFormProps & {
  job?: T;
  onCancel: () => void;
};

type JobManagerProps<T> = {
  saasIntegrationId: SaasIntegrationWithJobType;
  EditJobForm: FC<EditJobFormProps<T>>;
  CreateJobForm: FC<CreateJobFormProps>;
  JobTable?: FC<JobTableProps>;
};

export const JobManager = <T extends ScrapeJob>({
  saasIntegrationId,
  EditJobForm,
  CreateJobForm,
  JobTable,
}: JobManagerProps<T>) => {
  const styles = useStyles2(getStyles);
  const history = useHistory();
  const [jobStatusFilter, setJobStatusFilter] = useState<FilterJobStatus>(FilterJobStatus.All);
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);

  const sourceId = 'cloudwatch';
  const cloudwatchParams = useCloudwatchParams();
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: jobs, isLoading: isJobsLoading } = useGetScrapeJobs(
    saasIntegrationId,
    pluginId,
    jsonData.grafana_instance_id
  );
  const filteredJobs = useFilteredJobs(pluginId, jsonData.grafana_instance_id, jobStatusFilter);
  const { mutate: createOrUpdateJob } = useCreateOrUpdateScrapeJobMutation(
    sourceId,
    pluginId,
    jsonData.grafana_instance_id
  );
  const { mutate: deleteScrapeJobs, status: deleteJobStatus } = useDeleteScrapeJobsMutation(
    saasIntegrationId,
    pluginId,
    jsonData.grafana_instance_id
  );
  const resetState = useClearMutationCache();
  const selectedSource = useSelectedSource(pluginId, jsonData.grafana_instance_id);

  const areJobsPresent = !!jobs?.length;

  const handleBackToJobList = useCallback(() => {
    resetState();
    if (!!cloudwatchParams.action) {
      history.push(prefixRoute(CloudProvider.AWS, `${ROUTES.Configuration}/${sourceId}`));
    }
  }, [sourceId, history, resetState, cloudwatchParams.action]);

  useEffect(() => {
    return resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(selectedSource.installation)) {
      localDispatch({ type: ActionType.ShowInstallButton });
    }
    /* When the integration becomes installed, we still need to show
     * the InstallSaasIntegration component, because it then shows a
     * success message. Therefore we hide it only when we navigate away
     * from the JobForm. */
  }, [selectedSource.installation, selectedSource.id]);

  const [state, localDispatch] = useReducer(reducer, {
    page: JobManagementPage.JobList,
    selectedJob: undefined,
    shouldShowInstallButton: isEmpty(selectedSource.installation),
  });

  useEffect(() => {
    if (!isJobsLoading) {
      const { action, job } = cloudwatchParams;
      if (action === 'create') {
        localDispatch({ type: ActionType.CreateJob });
      } else if (action === 'edit' && !!job) {
        const scrapeJob = jobs?.find((j) => j.name === job);
        if (scrapeJob) {
          localDispatch({ type: ActionType.EditJob, job: scrapeJob });
        } else {
          localDispatch({ type: ActionType.ListJobs });
        }
      } else {
        localDispatch({ type: ActionType.ListJobs });
      }
    }
  }, [isJobsLoading, cloudwatchParams, jobs]);

  const onCreateJob = useCallback((job: ScrapeJob) => createOrUpdateJob(job), [createOrUpdateJob]);

  const onUpdateJob = useCallback(
    (job: ScrapeJob) => {
      createOrUpdateJob(job);

      // update state with updated job
      localDispatch({ type: ActionType.EditJob, job });
    },
    [createOrUpdateJob]
  );

  const handleAddScrapeJobClick = () => {
    history.push(prefixRoute(CloudProvider.AWS, `${ROUTES.Configuration}/${sourceId}/create`));
  };

  const handleJobEdit = (job: ScrapeJob) => {
    history.push(prefixRoute(CloudProvider.AWS, `${ROUTES.Configuration}/${sourceId}/edit/${job.name}`));
  };

  const handleJobDelete = (job: ScrapeJob) => {
    localDispatch({ type: ActionType.DeleteJob, job });
  };

  const confirmDeleteJob = async () => {
    if (!state.selectedJob) {
      return;
    }
    await deleteScrapeJobs([state.selectedJob.name]);

    localDispatch({ type: ActionType.ListJobs });
  };

  const renderMainContent = () => {
    if (state.page === JobManagementPage.EditJob) {
      return <EditJobForm onSaveJob={onUpdateJob} job={state.selectedJob as T} onCancel={handleBackToJobList} />;
    } else if (state.page === JobManagementPage.JobForm) {
      return <CreateJobForm onSaveJob={onCreateJob} />;
    } else {
      if (isJobsLoading) {
        return <Spinner className={styles.spinner} />;
      } else {
        if (JobTable) {
          return (
            <JobTableView
              jobs={filteredJobs}
              handleJobDelete={handleJobDelete}
              handleJobEdit={handleJobEdit}
              handleAddScrapeJob={handleAddScrapeJobClick}
              JobTable={JobTable}
              saasIntegrationId={saasIntegrationId}
            />
          );
        } else {
          return null;
        }
      }
    }
  };

  const showBackButton = (): boolean => {
    if (selectedSource.id === 'cloudwatch') {
      return state.page === JobManagementPage.JobForm;
    } else {
      return (
        !!selectedSource.installation &&
        areJobsPresent &&
        (state.page === JobManagementPage.JobForm || state.page === JobManagementPage.EditJob)
      );
    }
  };

  const backToListButton = showBackButton() && (
    <Button
      onClick={handleBackToJobList}
      variant="secondary"
      type="button"
      aria-label="Back to Job List"
      className={styles.backToJobsButton}
      data-testid={Pages.SaaSIntegration.backToJobList}
    >
      <Icon name={'arrow-left'} /> Back to Job List
    </Button>
  );

  function selectAllJobs(checked: boolean) {
    if (checked) {
      setSelectedJobs(filteredJobs.map((job) => job.name));
    } else {
      setSelectedJobs([]);
    }
  }

  function toggleSelectedJobs(job: string) {
    const elementIndex = selectedJobs.indexOf(job);
    if (elementIndex === -1) {
      setSelectedJobs([...selectedJobs, job]);
    } else {
      setSelectedJobs(selectedJobs.filter((j) => j !== job));
    }
  }

  function resetSelectedJobs() {
    setSelectedJobs([]);
  }

  function isJobSelected(job: string) {
    return selectedJobs.includes(job);
  }

  return (
    <>
      <ConfirmModal
        isOpen={state.page === JobManagementPage.DeleteJob}
        title={`Delete job '${state.selectedJob?.name}'`}
        body="Are you sure you want to delete this job?"
        confirmText={deleteJobStatus === 'pending' ? 'Deleting...' : 'Delete'}
        onConfirm={confirmDeleteJob}
        onDismiss={() => localDispatch({ type: ActionType.ListJobs })}
      />
      <SaasIntegrationContext.Provider
        value={{
          jobStatusFilter,
          setJobStatusFilter,
          selectedJobs,
          selectAllJobs,
          resetSelectedJobs,
          isJobSelected,
          toggleSelectedJobs,
        }}
      >
        {renderMainContent()}
      </SaasIntegrationContext.Provider>
      <div>{backToListButton}</div>
    </>
  );
};
