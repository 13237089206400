import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Field, Label, Select, useStyles2 } from '@grafana/ui';
import { INITIAL_SERVICE_OVERVIEW_SELECT_VALUE } from 'feature/common/utils/utils';

const getStyles = (theme: GrafanaTheme2) => ({
  field: css`
    margin-bottom: ${theme.spacing(3)};
    margin-top: ${theme.spacing(3)};

    @media (max-width: 640px) {
      margin-top: 0;
    }
  `,
  select: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: 300px;
  `,
  label: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: unset;
  `,
});

type ServiceOverviewSelectFilterProps = {
  id: string;
  label: string;
  currentValue: string;
  onChange: (value: string) => void;
  options: string[];
};

export const ServiceOverviewSelectFilter = ({
  id,
  label,
  currentValue,
  onChange,
  options,
}: ServiceOverviewSelectFilterProps) => {
  const styles = useStyles2(getStyles);

  function onChangeSelect(option: SelectableValue<string>) {
    onChange(option?.value ?? INITIAL_SERVICE_OVERVIEW_SELECT_VALUE);
  }

  return (
    <Field
      label={<Label className={styles.label}>{label}</Label>}
      data-testid={`${id}-field`}
      htmlFor={id}
      className={styles.field}
    >
      <Select<string>
        width={40}
        options={(options ?? []).map((option) => {
          return {
            label: option,
            value: option,
          };
        })}
        placeholder="Choose"
        className={styles.select}
        value={currentValue}
        inputId={id}
        onChange={onChangeSelect}
        isClearable={true}
      />
    </Field>
  );
};
