import { CustomVariable, QueryVariable, TextBoxVariable } from '@grafana/scenes';
import {
  getAccountVar,
  getPrometheusDatasourceVar,
  getRegionVar,
  getScrapeJobVar,
  VAR_ALL_TAGS,
  VAR_ALL_TAGS_MULTIPLE_OPTIONS,
  VAR_ALL_TAGS_VALUES,
  VAR_METRICS,
  VAR_TOP_INSTANCES,
  VAR_TOP_OR_BOTTOM,
} from 'scenes/variables';
import { NECESSARY_EC2_METRICS } from './queries';

export function getVariables(prometheusName: string, disableDataSourceFilter = false) {
  const datasource = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const allTags = new QueryVariable({
    name: VAR_ALL_TAGS,
    label: 'Tags',
    datasource,
    query: {
      query: 'label_names()',
      refId: 'B',
    },
    regex: `/tag_*/`,
  });

  const allTagsMultipleOptions = new QueryVariable({
    name: VAR_ALL_TAGS_MULTIPLE_OPTIONS,
    label: 'Tags',
    isMulti: true,
    datasource,
    query: {
      query: 'label_names()',
      refId: 'B',
    },
    regex: `/(?!tag_Name)tag_*/`,
    value: 'tag_aws_autoscaling_groupName',
  });

  const allTagsValues = new QueryVariable({
    name: VAR_ALL_TAGS_VALUES,
    label: 'Tags values',
    datasource,
    query: {
      query: 'label_values($allTags)',
      refId: 'D',
    },
  });

  const topInstances = new TextBoxVariable({
    name: VAR_TOP_INSTANCES,
    label: 'Instances#',
    description: 'Show number of instances',
    value: '40',
  });

  const topOrBottom = new CustomVariable({
    name: VAR_TOP_OR_BOTTOM,
    label: 'Top/Bottom',
    description: 'Top/Bottom instances',
    query: 'Top :    topk, Bottom :    bottomk',
  });

  const ec2metrics = new QueryVariable({
    name: VAR_METRICS,
    label: 'Metrics',
    datasource,
    query: {
      query: 'label_values(__name__)',
      refId: 'E',
    },
    isMulti: true,
    regex: '/(?!aws_ec2_info)aws_ec2_*/',
    value: [
      NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_AVERAGE,
      NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_MAXIMUM,
      NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_SUM,
      NECESSARY_EC2_METRICS.METRIC_EBSIOBALANCE_PERCENT_AVERAGE,
      NECESSARY_EC2_METRICS.METRIC_EBSBYTE_BALANCE_PERCENT_AVERAGE,
    ],
  });

  return {
    allTags,
    allTagsMultipleOptions,
    datasources: getPrometheusDatasourceVar(prometheusName, disableDataSourceFilter),
    region: getRegionVar(),
    allTagsValues,
    topInstances,
    topOrBottom,
    metrics: ec2metrics,
    account: getAccountVar(),
    scrapeJob: getScrapeJobVar(),
  };
}
