import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { SceneApp, sceneUtils } from '@grafana/scenes';
import { EventTrackByScenePageType, ScenePageType, ScenesCustomParams } from 'scenes/types';
import { getScenePageTypeFromPath } from 'scenes/misc';
import useRudderStack from 'hooks/useRudderstack';
import { Spinner, useStyles2 } from '@grafana/ui';
import { QueryError } from 'components/QueryError/QueryError';
import { getAwsScenePage } from 'scenes/AWS/getAwsScenePage';
import { LogGroupsDatasource } from 'scenes/AWS/Logs/LogGroupsDataSource';
import { LOG_GROUPS_DATASOURCE_UID } from 'scenes/AWS/Logs/consts';
import { useHistory } from 'react-router-dom';
import { getGcpScenePage } from 'scenes/GCP/getGcpScenePage';
import { getAzureScenePage } from 'scenes/Azure/getAzureScenePage';
import { useScenesParams } from 'scenes/useScenesParams';

const getStyles = () => ({
  rootContainer: css`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;

    [class$='-page-content'] {
      max-width: 100%;

      [class$='-canvas-content'] {
        [class$='-body'] {
          width: 100%;
          height: 100%;
        }
      }
    }
  `,
});

const getRootScene = (params?: ScenesCustomParams): SceneApp | null => {
  if (params) {
    return new SceneApp({
      pages: [getAwsScenePage(params), getGcpScenePage(params), getAzureScenePage(params)],
    });
  }
  return null;
};

export const PluginSceneApp = () => {
  const styles = useStyles2(getStyles);
  const [errorMsg, setErrorMsg] = useState<string>();
  const { trackRudderStackEvent } = useRudderStack();
  const history = useHistory();
  const { isLoading, data: scenesParams } = useScenesParams();

  const trackSceneApp = useCallback(() => {
    const type = getScenePageTypeFromPath();

    if (EventTrackByScenePageType[type]) {
      trackRudderStackEvent(EventTrackByScenePageType[type] as string, {});
    } else {
      setErrorMsg('No application found under this path');
    }
  }, [trackRudderStackEvent]);

  const scene = useMemo(() => {
    return !isLoading && !!scenesParams ? getRootScene(scenesParams) : null;
  }, [scenesParams, isLoading]);

  useEffect(() => {
    trackSceneApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listener = () => {
      if (getScenePageTypeFromPath() === ScenePageType.AWS_LOGS) {
        // register custom data sources
        try {
          sceneUtils.registerRuntimeDataSource({
            dataSource: new LogGroupsDatasource(`${LOG_GROUPS_DATASOURCE_UID}-ds`, LOG_GROUPS_DATASOURCE_UID),
          });
        } catch (e) {
          // Scenes datasource already registered
        }
      }
    };
    const unListen = history.listen(listener);
    listener();
    return () => unListen();
  }, [history, scenesParams]);

  if (errorMsg) {
    return <QueryError isFullPageError={false} message={errorMsg} />;
  }
  if (isLoading) {
    return <Spinner />;
  } else if (!!scene) {
    return (
      <div className={styles.rootContainer}>
        <scene.Component model={scene} />
      </div>
    );
  } else {
    return null;
  }
};
