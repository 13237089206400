import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { ALLOY_LINK } from './consts';
import { LinkText } from 'components/LinkText';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { useGetIntegration } from 'api/int-api/queries';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.body.fontSize,
  }),
});

export const AlloyDescription = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: sourceDetails } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);

  return (
    <div className={styles.container}>
      <p>
        Click <b>Run Grafana Alloy</b> to open steps to set up <LinkText href={ALLOY_LINK}>Grafana Alloy</LinkText> and
        create a configuration file.
      </p>
      <p>
        Skip this step if you have already installed Alloy{' '}
        {!!sourceDetails ? `where you intend to run ${sourceDetails.name}` : ''}.
      </p>
    </div>
  );
};
