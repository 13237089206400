import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { DisabledReasonType } from 'api/hosted-exporters-api/data-models';
import { DisabledReasonTooltip } from '../DisabledReasonTooltip';
import { LabelTypes } from 'enums';
import { Label } from 'components/Label';

const getStyles = (theme: GrafanaTheme2) => ({
  labelStyle: css`
    border: none;
    padding: ${theme.spacing(0.5)} 0;
    font-size: ${theme.typography.body.fontSize};
  `,
});

type JobStatusProps = {
  enabled: boolean;
  disabled_reason?: DisabledReasonType;
};

export const JobStatus: FC<JobStatusProps> = ({ enabled, disabled_reason }) => {
  const styles = useStyles2(getStyles);

  return (
    <DisabledReasonTooltip reason={disabled_reason}>
      <Label type={enabled ? LabelTypes.Enabled : LabelTypes.Disabled} customStyle={styles.labelStyle} />
    </DisabledReasonTooltip>
  );
};
