import { cloneDeep, isEqual } from 'lodash';
import {
  CloudWatchJob,
  CustomNamespaceApiResponse,
  ServiceConfigurationApi,
  ServiceInfoApiResponse,
  Services,
} from 'api/hosted-exporters-api/data-models';
import { AccountFormOutput, FormOutput, ServicesSummaryChanges, TagsFormOutput } from '../types';
import { transformServicesForApi } from '../utils';

export const INVALID_TAGS_MESSAGE = 'Select at least one tag option';

const createMetricsObjForForm = (service?: ServiceInfoApiResponse) => {
  return Object.fromEntries(
    service?.available_metrics?.map((metric) => [
      metric.metric.name,
      {
        isChecked: metric.isChecked ?? false,
        statistics: metric.default_statistics ?? [],
      },
    ]) ?? []
  );
};

export const initOutputMetricsArray = (serviceMetrics: FormOutput) => {
  if (!!serviceMetrics) {
    return Object.keys(serviceMetrics.metrics).map((name) => {
      return { ...serviceMetrics.metrics[name], name: name };
    });
  }
  return [];
};

export const searchServicesByNameAndMetrics = (searchText = '', services: FormOutput[] = []) => {
  if (searchText) {
    return services.filter(
      (service) =>
        service.service_id.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
        Object.keys(service.metrics).some((metric) => metric.indexOf(searchText) >= 0)
    );
  }
  return services;
};

export const reset = (serviceSettings: FormOutput, supportedServices?: Services) => {
  const currentServiceDefaultCloudwatchConfig = Object.values(supportedServices ?? {}).find(
    (s) => s.display_name === serviceSettings.display_name
  );
  if (!!currentServiceDefaultCloudwatchConfig) {
    currentServiceDefaultCloudwatchConfig?.available_metrics.forEach((metric) => {
      if (!serviceSettings.metrics[metric.metric.name]) {
        serviceSettings.metrics[metric.metric.name] = {
          isChecked: metric.is_default ?? false,
          statistics: metric.default_statistics ?? [],
        };
      } else {
        serviceSettings.metrics[metric.metric.name].isChecked = metric.is_default ?? false;
        serviceSettings.metrics[metric.metric.name].statistics = metric.default_statistics ?? [];
      }
    });

    serviceSettings.scrape_interval =
      currentServiceDefaultCloudwatchConfig?.available_scrape_intervals.find((interval) => interval.is_default)
        ?.interval_seconds ?? 0;

    serviceSettings.isNew = false;
    serviceSettings.tags = getTagsFormByService(currentServiceDefaultCloudwatchConfig);
  }
  return serviceSettings;
};

export function isValidStatistics(service: FormOutput, metricName: string) {
  if (!!service) {
    const isCurrentMetricCheckedNow = service.metrics[metricName]?.isChecked;
    const currentStatistics = service.metrics[metricName]?.statistics ?? [];

    return (isCurrentMetricCheckedNow && currentStatistics.length > 0) || !isCurrentMetricCheckedNow;
  }
  return true;
}

export const sortServices = (services: FormOutput[] = []) => {
  return services.sort((a, b) => {
    if (a.isNew === b.isNew) {
      return a.service_id.localeCompare(b.service_id);
    }
    return Number(b.isNew) - Number(a.isNew);
  });
};

export const getServicesName = (
  initialServiceConfigurations: ServiceConfigurationApi[] = [],
  defaultServices: ServiceInfoApiResponse[] = []
) => {
  return initialServiceConfigurations
    .map((s) => {
      const defaultService = defaultServices.find((defaultService) => defaultService.service_id === s.name);
      return defaultService?.display_name ?? s.name;
    })
    .sort((a, b) => a.localeCompare(b));
};

const getTagsFormByService = (service: ServiceInfoApiResponse | ServiceConfigurationApi): TagsFormOutput[] => {
  const tagMetrics = service.tags_to_add_to_metrics ?? [];
  if (service) {
    const result: TagsFormOutput[] = (service.resource_discovery_tag_filters ?? []).map(
      (filter) =>
        ({
          name: filter.key,
          value: filter.value,
          useAsFilter: true,
          addToMetrics: tagMetrics.indexOf(filter.key) >= 0,
        }) as TagsFormOutput
    );

    tagMetrics.forEach((tagMetric) => {
      if (result.findIndex((tag) => tag.name === tagMetric) < 0) {
        result.push({ name: tagMetric, value: '', addToMetrics: true, useAsFilter: false });
      }
    });
    return result.sort((a, b) => a.name.localeCompare(b.name));
  }
  return [];
};

export const getFormServiceFromId = (
  serviceId: string,
  defaultServices: ServiceInfoApiResponse[] = [],
  markAsNew = false,
  defaultCustomNamespace?: CustomNamespaceApiResponse
) => {
  const serviceFound = defaultServices.find((s) => s.service_id === serviceId);

  if (serviceFound) {
    return {
      display_name: serviceFound?.display_name,
      service_id: serviceFound?.service_id ?? serviceId,
      metrics: createMetricsObjForForm(serviceFound),
      scrape_interval: serviceFound?.available_scrape_intervals.find((el) => el.is_default)?.interval_seconds ?? 0,
      tags: getTagsFormByService(serviceFound),
      selected: false,
      isNew: markAsNew,
      isCustomNamespace: false,
    } as FormOutput;
  } else {
    return {
      display_name: serviceId ?? '',
      service_id: serviceId,
      metrics: {},
      scrape_interval:
        defaultCustomNamespace?.available_scrape_intervals?.find((el) => el.is_default)?.interval_seconds ?? 0,
      selected: false,
      isNew: markAsNew,
      isCustomNamespace: true,
    } as FormOutput;
  }
};

export const getFormServicesFromIds = (
  serviceIds: string[] = [],
  defaultServices: ServiceInfoApiResponse[] = [],
  markAsNew = false,
  defaultCustomNamespace?: CustomNamespaceApiResponse
) => {
  return sortServices(
    serviceIds.map((serviceId) => getFormServiceFromId(serviceId, defaultServices, markAsNew, defaultCustomNamespace))
  );
};

export const getFormServicesFromInitialConfiguration = (
  initialServiceConfigurations: ServiceConfigurationApi[] = [],
  defaultServices: ServiceInfoApiResponse[] = []
) => {
  const services = initialServiceConfigurations.map((service) => {
    const defaultService = defaultServices.find((defaultService) => defaultService.service_id === service.name);

    const metrics = Object.fromEntries(
      service?.metrics?.map((metric) => [
        metric.name,
        {
          isChecked: true,
          statistics: metric.statistics ?? [],
        },
      ]) ?? []
    );
    // fill not defined metrics from supported services
    defaultService?.available_metrics.forEach((metric) => {
      if (!metrics[metric.metric.name]) {
        metrics[metric.metric.name] = { isChecked: false, statistics: [] };
      }
    });

    return {
      display_name: defaultService?.display_name ?? service.name,
      service_id: service.name,
      metrics,
      scrape_interval: service.scrape_interval_seconds ?? 0,
      selected: false,
      isNew: false,
      isCustomNamespace: service?.is_custom_namespace ?? false,
      tags: getTagsFormByService(service),
    };
  });

  return sortServices(services);
};

export const jobsContainsEc2Service = (jobs: CloudWatchJob[] = []) => {
  return jobs.some((job) => job.service_configurations?.some((service) => service.name === 'ec2'));
};

export const hasDefaultValues = (service: FormOutput, supportedServices?: Services) => {
  const defaultFormService = reset(cloneDeep(service), supportedServices);
  return (
    isEqual(defaultFormService.metrics, service.metrics) &&
    isEqual(defaultFormService.scrape_interval, service.scrape_interval)
  );
};

export const getDefaultMetric = (
  serviceName: string,
  metricName: string,
  defaultServices: ServiceInfoApiResponse[] = []
) => {
  const service = defaultServices.find((s) => s.service_id === serviceName);
  if (!!service) {
    const metricFound = (service.available_metrics ?? []).find((metric) => metric.metric.name === metricName);
    if (metricFound) {
      return {
        isChecked: metricFound.isChecked ?? false,
        statistics: metricFound.default_statistics ?? [],
      };
    }
  }

  return {
    isChecked: false,
    statistics: [],
  };
};

export const getServicesSummaryChanges = (
  job: CloudWatchJob | undefined,
  currentServices: ServiceConfigurationApi[] = []
): ServicesSummaryChanges => {
  if (!!job) {
    const added = currentServices.filter(
      (s) => (job.service_configurations ?? []).findIndex((jobService) => s.name === jobService.name) < 0
    );
    const deleted = (job.service_configurations ?? []).filter(
      (jobService) => currentServices.findIndex((s) => s.name === jobService.name) < 0
    );
    const updated = currentServices.filter((s) => {
      if (job.service_configurations) {
        const index = job.service_configurations.findIndex((jobService) => s.name === jobService.name);
        if (index >= 0) {
          const jobService = job.service_configurations[index];
          return (
            !isEqual(s.metrics, jobService.metrics) ||
            !isEqual(s.scrape_interval_seconds, jobService.scrape_interval_seconds) ||
            !isEqual(s.tags_to_add_to_metrics, jobService.tags_to_add_to_metrics)
          );
        }
      }
      return false;
    });
    return { added, deleted, updated };
  }
  return { added: currentServices, deleted: [], updated: [] };
};

export const areServicesChanged = (job: CloudWatchJob, services: FormOutput[] = []): boolean => {
  if (!!job && services.length > 0) {
    const newServices: ServiceConfigurationApi[] = transformServicesForApi([...services]);
    const servicesChanges: ServicesSummaryChanges = getServicesSummaryChanges(job, newServices);
    return (
      servicesChanges.added.length !== 0 || servicesChanges.deleted.length !== 0 || servicesChanges.updated.length !== 0
    );
  }
  return false;
};

export function getFormErrorMessage(account: AccountFormOutput, hasServicesChanged: boolean, totalServices: number) {
  if (!account || (!account.name && !account.arn && account.regions.length === 0)) {
    return 'You need to configure an AWS account in step 2.';
  } else if (!account.isValid || totalServices === 0) {
    return 'Some errors require your attention';
  } else if (!account.isDirty && !hasServicesChanged) {
    return 'Nothing has changed';
  }
  return undefined;
}

export function areTagsValid(service: FormOutput) {
  return (service?.tags ?? []).every((tag) => tag.addToMetrics || tag.useAsFilter);
}

export function isServiceWithResources(supportedServices?: Services, service?: FormOutput) {
  if (supportedServices && service) {
    return (
      (supportedServices[service.display_name as string] ?? supportedServices[service.service_id])?.has_resources ??
      true
    );
  }
  return true;
}
