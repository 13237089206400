import { css, cx } from '@emotion/css';
import React, { ElementType } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { Source } from 'api/int-api/data-models';
import Markdown from 'react-markdown';
import { RenderCode } from 'components/Clipboard';
import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => ({
  flex: css`
    display: flex;
    min-width: 230px;
    padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)};
    background: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.medium};
    cursor: pointer;
    box-shadow: 0 2px 9px ${theme.isLight ? `rgba(208, 208, 208, 0.6)` : `rgba(0, 0, 0, 0.6)`};
    transition: background 0.2s ease-in-out;

    &:hover {
      background: ${theme.colors.background.secondary};
    }
  `,
  selectElement: css`
    display: flex;
    flex-direction: column;
  `,
  titleWrapper: css`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  `,
  icon: css`
    width: 24px;
    height: auto;
    margin-right: 12px;
  `,
  title: css`
    font-size: ${theme.typography.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
    margin-bottom: 0;
    line-height: 20px;
  `,
  description: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    margin-bottom: 0;
    line-height: 16px;
    color: ${theme.colors.text.secondary};
    a {
      color: ${theme.colors.text.link};
    }
  `,

  markdown: css`
    a,
    a > code {
      color: ${theme.colors.text.link};
    }
  `,
});

interface CardProps {
  item: Source;
  defaultRoute: string;
  onClick?: () => void;
  footer?: React.JSX.Element;
}

interface CustomMarkdownProps {
  markdownSnippet: string;
  className?: string;
  renderers?: { [nodeType: string]: ElementType };
}

export const CustomMarkdown: React.FC<CustomMarkdownProps> = ({ markdownSnippet, className, renderers }) => {
  const styles = useStyles2(getStyles);
  return (
    <Markdown
      components={{
        pre({ children }) {
          return <div>{children}</div>;
        },
        code: ({ children }) => {
          return <RenderCode value={String(children)} />;
        },
        // eslint-disable-next-line
        a: ({ node, ...props }) => <a {...props} onClick={(e) => e.stopPropagation()} target="_blank" />,
        ...renderers,
      }}
      className={cx(styles.markdown, className)}
    >
      {markdownSnippet}
    </Markdown>
  );
};

export const CardSource = ({ item, defaultRoute, onClick = () => {}, footer }: CardProps) => {
  const styles = useStyles2(getStyles);

  // For the inner AWS card pick the externalURL if defined, and if not fallback to a crafted url with the integration id
  const useExternalURL = item.externalUrl !== undefined;
  const linkHref = useExternalURL ? item.externalUrl : defaultRoute;

  return (
    // if useExternalURL, open target in new tab and keep other opened with Grafana to not kill engagement since
    // the use is likely to be directed to another site (Google Form for example)
    <a
      className={styles.flex}
      href={linkHref}
      target={useExternalURL ? '_blank' : ''}
      rel="noreferrer"
      onClick={onClick}
      data-testid={Pages.Configuration.getCard(item.id)}
    >
      <div className={styles.selectElement}>
        <div className={styles.titleWrapper}>
          {item.logo_url && <img src={item.logo_url} className={styles.icon} alt={`icon of ${item.name}`} />}
          <p className={styles.title} data-testid={Pages.Configuration.getTitleCard(item.id)}>
            {item.name}
          </p>
        </div>
        {item.overview && (
          <CustomMarkdown
            className={styles.description}
            renderers={{
              // eslint-disable-next-line
              link: ({ node, ...props }) => <a {...props} onClick={(e) => e.stopPropagation()} />,
              img: () => null,
            }}
            markdownSnippet={item.overview}
          />
        )}
        {footer}
      </div>
    </a>
  );
};
