import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Automatic as AutomaticAuth } from './Auth/Automatic';
import { colors } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  instructionItem: css`
    margin-bottom: 40px;

    h2 {
      line-height: 26px;
      font-size: 19px;
      color: ${theme.isLight ? colors.blue04 : theme.colors.text.maxContrast};
    }

    a {
      color: ${theme.isLight ? colors.blue07 : colors.blue03};
    }

    h3 {
      line-height: 22px;
      font-size: 16px;
    }

    p {
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
  stepText: css`
    padding-bottom: 7px;
  `,
});

export const ALBLogsInstructions = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.instructionItem}>
      <h2>Forward ALB access logs using a Lambda function</h2>
      <p className={styles.stepText}>
        To forward ALB access logs to Grafana Cloud Logs, you must create AWS resources in your account. Choose how you
        want to create them.
      </p>

      <AutomaticAuth />
    </div>
  );
};
