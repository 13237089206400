import { LinkButton, Spinner, useStyles2 } from '@grafana/ui';
import { useGetIntegration, useIntegrationsByCatalogQuery } from 'api/int-api/queries';
import React, { useContext, useEffect, useMemo } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { CardSource } from 'feature/common/components/Card/CardSource';
import { Source, Status } from 'api/int-api/data-models';
import { QueryError } from 'components/QueryError/QueryError';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { Pages } from 'e2eSelectors/pages';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'utils/consts';
import { CloudProvider } from 'types/CloudProvider';
import { InstallBackend } from 'feature/common/components/InstallBackend/InstallBackend';
import { VersionHistory } from 'feature/common/components/VersionHistory/VersionHistory';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: block;
    width: 100%;
  `,
  groupWrapper: (columns?: number) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    margin-top: ${theme.spacing(3)};
    margin-bottom: 60px;
    gap: ${theme.spacing(1)};

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 576px) and (max-width: 1050px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1050px) and (max-width: 1250px) {
      grid-template-columns: repeat(3, 1fr);
    }
  `,
  link: css`
    padding-left: ${theme.spacing(0.5)};
    padding-right: ${theme.spacing(0.5)};
  `,
});

export const Configuration = ({ showServices }: { showServices: boolean }) => {
  const provider = CloudProvider.AWS;
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const {
    isPending,
    data: awsSources,
    isError,
  } = useIntegrationsByCatalogQuery(pluginId, jsonData.grafana_instance_id, provider);
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { data } = useGetIntegration(provider, pluginId, jsonData.grafana_instance_id);
  const hasAnythingToInstall = useMemo(() => {
    return data?.dashboards?.status === Status.Available || data?.alerts?.status === Status.Available;
  }, [data?.dashboards?.status, data?.alerts?.status]);

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.AwsConfigurationViewPage, { page: 'aws-catalog' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isError ? (
        <QueryError message={'An unknown error occurred.'} />
      ) : (
        <div className={styles.container}>
          <p>
            Use AWS observability for Grafana Cloud to access all metrics, logs, and traces in one central place, then
            interact with your data using PromQL and other common query languages.
          </p>
          {isPending ? (
            <Spinner />
          ) : (
            awsSources && (
              <div className={styles.groupWrapper(awsSources.length)} data-testid={Pages.Configuration.cardsRow}>
                {awsSources.map((integration: Source) => (
                  <CardSource
                    item={integration}
                    key={integration.id}
                    defaultRoute={prefixRoute(provider, `${ROUTES.Configuration}/${integration.id}`)}
                  />
                ))}
              </div>
            )
          )}

          <h4>Looking to explore your CloudWatch data in Grafana using native CloudWatch query editors?</h4>
          <p>
            Check out the{' '}
            <LinkButton className={styles.link} fill="text" href={'plugins/cloudwatch'}>
              Grafana CloudWatch data source
            </LinkButton>{' '}
            instead.
          </p>

          {hasAnythingToInstall && (
            <>
              <InstallBackend cloudProvider={provider} showServices={showServices} />
              <VersionHistory provider={provider} />
            </>
          )}
        </div>
      )}
    </>
  );
};
