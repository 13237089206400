import React from 'react';
import { PluginMeta } from 'models/pluginMeta';

let pluginMeta = {} as PluginMeta;

export function setPluginMeta(value: PluginMeta) {
  pluginMeta = value;
}

export const PluginMetaContext = React.createContext(pluginMeta);
