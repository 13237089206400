import { useQuery } from '@tanstack/react-query';
import { apiGet } from 'api/baseApi';
import { DashboardDb, DashboardDTO } from 'api/dashboards/data-model';

export const useGetDashboards = (integrationFolder?: string[]) => {
  return useQuery({
    queryKey: ['getDashboards', integrationFolder],
    queryFn: () =>
      Promise.all<DashboardDb[]>(
        (integrationFolder ?? []).map((el) => apiGet<DashboardDb[]>(`api/search?type=dash-db&folderUIDs=${el}`))
      ).then((response) => response.flat()),
    enabled: !!integrationFolder && integrationFolder.length > 0,
  });
};

export const useGetDashboardDetails = (id: string, integrationFolder: string) => {
  return useQuery({
    queryKey: ['getDashboardDetails', integrationFolder, id],
    queryFn: async () => {
      const result = await apiGet<DashboardDb[]>(
        `api/search?type=dash-db&folderUIDs=${integrationFolder}&dashboardIds=${id}`
      );
      if (result.length > 0) {
        return result[0];
      }
      return null;
    },
    enabled: Boolean(id) && Boolean(integrationFolder),
  });
};

export const useGetDashboardByUid = (uid: string) => {
  return useQuery({
    queryKey: ['getDashboardByUid', uid],
    queryFn: async () => apiGet<DashboardDTO>(`api/dashboards/uid/${uid}`),
    enabled: Boolean(uid),
  });
};
