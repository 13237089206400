import { css, cx } from '@emotion/css';
import React from 'react';

import { useStyles2 } from '@grafana/ui';

import { AlloyStatus, GrafanaAlloyMessage, useCollectorSelector } from '@grafana-cloud/collector';
import { GrafanaTheme2 } from '@grafana/data';
import { colors } from 'utils/consts';
import { AlloyDescription } from './AlloyDescription';
import { AlloyConfigModal } from './AlloyConfigModal';

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),

    h5: {
      color: theme.isDark ? theme.colors.primary.contrastText : colors.dark03,
      marginBottom: theme.spacing(2),
    },
  }),
  blueBackground: css({
    backgroundColor: colors.blue12,
  }),
  greyBackground: css({
    backgroundColor: theme.colors.background.secondary,
  }),
  li: css({
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    paddingBottom: '8px',
    button: {
      display: 'flex',
      alignItems: 'center',
      '> div:nth-child(2)': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },
    },
    '> div:nth-child(2)': {
      paddingLeft: theme.spacing(3),
    },
  }),
  marginTop: css({
    marginTop: theme.spacing(2),
  }),
  yellowText: css({
    color: theme.colors.warning.text,
  }),
});

type Props = { sourceId: string; showOSSelection: boolean };

export const InstallAlloySection = ({ sourceId, showOSSelection }: Props) => {
  const styles = useStyles2(getStyles);
  const alloyStatus = useCollectorSelector((state) => state.collector.agentStatus);

  return (
    <li className={styles.li}>
      <h2>Install Grafana Alloy</h2>
      <div className={cx(styles.box, styles.blueBackground, styles.marginTop)}>
        <AlloyDescription sourceId={sourceId} />
        <AlloyConfigModal sourceId={sourceId} showOSSelection={showOSSelection} />
      </div>
      {alloyStatus === AlloyStatus.Success && <GrafanaAlloyMessage />}
    </li>
  );
};
