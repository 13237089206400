import React, { useMemo } from 'react';
import { useCollectorSelector } from '@grafana-cloud/collector';
import { CollectorMode } from 'enums';
import { getCollectorConfigFilePath } from 'feature/common/components/OSInstallConfig';

export const ConfigFileHelp = () => {
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);
  const configFilePath = useMemo(() => getCollectorConfigFilePath(selectedOs, CollectorMode.Alloy), [selectedOs]);

  return (
    <p>
      Navigate to the configuration file for your Alloy instance which you can find at <code>{configFilePath}</code>.
    </p>
  );
};
