import semver from 'semver';
import { config } from '@grafana/runtime';
import { ALLOY_HOSTNAME_RELABEL_KEY, HOSTNAME_RELABEL_KEY } from 'utils/consts';
import { CloudProvider } from 'types/CloudProvider';
import { SourceDetails } from 'api/int-api/data-models';
import { AWS_DASHBOARDS_URL } from 'scenes/AWS/routes';
import { GCP_DASHBOARDS_URL } from 'scenes/GCP/routes';
import { AZURE_DASHBOARDS_URL } from 'scenes/Azure/routes';

export function constructDashboardUrl(provider: CloudProvider | undefined, folderName: string) {
  if (isDashboardsSectionPresent() && provider) {
    switch (provider) {
      case CloudProvider.AWS:
        return AWS_DASHBOARDS_URL;

      case CloudProvider.GCP:
        return GCP_DASHBOARDS_URL;

      case CloudProvider.AZURE:
        return AZURE_DASHBOARDS_URL;
    }
  } else {
    folderName = folderName.toLowerCase().replace(/ /g, '-');
    const folder = folderName.replace(/integration---/, 'integration-');
    return `/dashboards/f/${folderName}/${folder}`;
  }
}

export function constructAlertUrl(sourceDetails: SourceDetails) {
  let alertURL = '';
  if (sourceDetails) {
    const { alerts, rules } = sourceDetails;
    if (alerts?.available_alerts && alerts.available_alerts?.length > 0) {
      alertURL = `/alerting/list?search=namespace:${alerts.available_alerts[0].namespace}`;
    } else if (rules?.available_rules && rules.available_rules?.length > 0) {
      alertURL = `/alerting/list?search=namespace:${rules.available_rules[0].namespace}`;
    }
  }
  return alertURL;
}

export function lokiCellURLToFirehose(lokiCellURL: string): string {
  // loki-prod2 should be mapped to aws-logs-prod2-us-central1: https://github.com/grafana/cloud-onboarding/issues/6298
  if (lokiCellURL.indexOf('loki-prod2') !== -1) {
    return `${lokiCellURL.replace('loki-prod2', 'aws-logs-prod2')}/aws-logs/api/v1/push`;
  }
  return `${lokiCellURL.replace('logs', 'aws-logs')}/aws-logs/api/v1/push`;
}

export const GRAFANA_EXAMPLE_API = '<grafana.com API Key>';
export const GRAFANA_EXAMPLE_USER = '<grafana.com username>';

export function jsonStringRepl<A>(obj: A, searchValue: RegExp, replaceValue: string): A {
  return JSON.parse(JSON.stringify(obj).replace(searchValue, replaceValue));
}

export function isGrafanaAtLeast10() {
  // 10.0.0-cloud1 is a prerelease of 10.0.0, and therefore smaller than 10.0.0,
  // but we want to return true for it.
  // The largest minor release in Grafana 9 was 9.5.
  return semver.gt(config.buildInfo.version, '9.9.0');
}

export function isDashboardsSectionPresent() {
  return semver.gte(config.buildInfo.version, '10.4.0') || config.buildInfo.version.includes('10.4.0-');
}

export function transformSecondsToMinutesFormat(seconds: number) {
  return Math.floor(seconds / 60);
}

export function getCommonElements(elements: string[][]) {
  return elements.reduce((a, b) => a.filter((c) => b.includes(c)));
}

export function getHighlightLinesForSnippet(snippet: string, keyword: string): number[] {
  let codeFirstLineIndex = -1;
  return snippet.split('\n').reduce((accumulated, element, index) => {
    if (element.includes('```')) {
      codeFirstLineIndex = index;
    }
    if (element.includes(keyword)) {
      accumulated.push(index - codeFirstLineIndex - 1);
    }
    return accumulated;
  }, [] as number[]);
}

export const replaceHostnameInConfig = (config: string, newValue: string) => {
  return config.replaceAll(HOSTNAME_RELABEL_KEY, newValue);
};

export const replaceAlloyHostnameInConfig = (config: string, newValue: string) => {
  return config.replaceAll(ALLOY_HOSTNAME_RELABEL_KEY, newValue);
};

export const isNullOrUndefined = (object: any) => {
  return object === null || object === undefined;
};
