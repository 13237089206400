import React, { FC, useCallback, useContext } from 'react';

import { Checkbox, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

import { JobTableProps } from '../JobManager/JobTableView';

import { JobRow } from './JobRow';
import { getJobTableStyles } from './JobTable.styles';
import { CloudWatchJob } from 'api/hosted-exporters-api/data-models';
import { JobEmptyList } from './JobEmptyList';
import { useFilteredJobs } from 'api/hosted-exporters-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { SaasIntegrationContext, SaasIntegrationState } from 'app/contexts/saasIntegration.context';

const JOB_HEADERS = ['', 'Scrape job name', 'ARN', 'Status', 'Regions', 'Services', ''];

export const JobTable: FC<JobTableProps> = ({ jobs, handleAddScrapeJob, handleJobDelete, handleJobEdit }) => {
  const styles = useStyles2(getJobTableStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { jobStatusFilter } = useContext(SaasIntegrationContext);
  const filteredJobs = useFilteredJobs(pluginId, jsonData.grafana_instance_id, jobStatusFilter);
  const { selectedJobs, selectAllJobs } = useContext<SaasIntegrationState>(SaasIntegrationContext);

  let allScrapeJobsSelected = filteredJobs.length > 0 && selectedJobs.length === filteredJobs.length;

  const onSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      selectAllJobs(e.target.checked);
    },
    [selectAllJobs]
  );

  if (jobs?.length > 0) {
    return (
      <table className={styles.table} data-testid={Pages.CloudWatch.scrapeJobsTable}>
        <thead>
          <tr>
            {JOB_HEADERS.map((header, i) => (
              <th key={header}>
                {i === 0 && filteredJobs.length > 0 && (
                  <div className={styles.selectedJobs}>
                    <Checkbox
                      data-testid={Pages.SaaSIntegration.Actions.selectAllScrapeJobs}
                      value={allScrapeJobsSelected}
                      className={styles.selectAllCheckbox}
                      onChange={onSelectAll}
                    />
                  </div>
                )}
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <JobRow
              key={job.name}
              handleJobDelete={handleJobDelete}
              handleJobEdit={handleJobEdit}
              job={job as CloudWatchJob}
            />
          ))}
        </tbody>
      </table>
    );
  } else {
    return <JobEmptyList onAddScrapeJob={handleAddScrapeJob} />;
  }
};
