import { isUserLightTheme } from 'utils/user';

export function getColorForTheme(dark: string, light: string): string {
  return isUserLightTheme() ? light : dark;
}

export const getVariableWithValues = (label: string, values: string[]): string => {
  if (!!label && !!values) {
    return `${label}=~"${values.join('|')}"`;
  }
  return '';
};
