import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';
import { ROUTES } from 'utils/consts';

export const AZURE_URL = prefixRoute(CloudProvider.AZURE);
export const AZURE_DASHBOARDS_URL = prefixRoute(CloudProvider.AZURE, ROUTES.Dashboards);
export const AZURE_SERVICES_URL = prefixRoute(CloudProvider.AZURE, ROUTES.SERVICES);
export const AZURE_OVERVIEW_URL = prefixRoute(CloudProvider.AZURE, `${ROUTES.OVERVIEW}`);
export const AZURE_LOGS_URL = prefixRoute(CloudProvider.AZURE, `${ROUTES.Logs}`);
export const AZURE_CONFIGURATION_URL = prefixRoute(CloudProvider.AZURE, `${ROUTES.Configuration}`);
