import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getEditServiceMetricsStyles = (theme: GrafanaTheme2) => ({
  searchInput: css`
    box-sizing: border-box;

    div[class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
    }
  `,
  table: css`
    width: 100%;
    margin-bottom: ${theme.spacing(2)};
    display: block;
    max-height: 30vh;
    overflow-y: scroll;

    thead {
      background: ${theme.colors.background.primary};
      position: sticky;
      top: 0;
      z-index: 3;
      height: 32px;
    }

    > th {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }

    th,
    td {
      padding-left: ${theme.spacing(1)};
      min-width: 300px;
      width: 100%;
    }

    th {
      vertical-align: baseline;
    }
  `,
  tr: css`
    background: ${theme.colors.background.secondary};
    border-bottom: 1px solid ${theme.colors.border.weak};
  `,
  statistic: css`
    max-width: 480px;
    margin: ${theme.spacing(1)} 0;
  `,
  statisticHead: css`
    font-size: ${theme.typography.body.fontSize};
    display: flex;
    gap: 10px;
    padding-right: ${theme.spacing(1)};
  `,
  dropdown: css`
    font-size: ${theme.typography.body.fontSize};
  `,
  hide: css`
    display: none;
  `,
  noResults: css`
    color: ${theme.colors.text.disabled};
    padding: ${theme.spacing(3)} 0px;
    font-style: italic;
  `,
});
