import React, { useState } from 'react';
import { Tab, TabContent, TabsBar, useStyles2, VerticalTab } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { TabItem } from 'feature/common/types/types';

const getStyles = (theme: GrafanaTheme2, isVertical: boolean) => {
  return {
    title: css`
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(1)};
      align-items: center;
    `,
    tabsContent: css`
      background: none;
      ${isVertical
        ? `
        display: flex;
        flex-direction: column;
      `
        : `display: flex;
          justify-content: center;
        `}
    `,
    background: css`
      box-shadow: ${theme.shadows.z3};
      ${isVertical
        ? `
        max-width: 860px;
      `
        : 'max-width: 860px;'}
    `,
    img: css`
      width: 100%;
    `,
    description: css`
      padding: ${theme.spacing(2)};
      border-top: 1px solid ${theme.colors.border.weak};
      margin: 0;
    `,
    tabsWrapper: css`
      ${isVertical
        ? `
        display: flex;
        flex-direction: row;
      `
        : ''}

      margin-top: 20px;
      margin-bottom: 10px;

      @media (max-width: 992px) {
        flex-direction: column;
      }
    `,
    tabsBar: css`
      display: flex;
      flex-direction: column;
      min-width: 200px;
      border-bottom: 0;
      ${isVertical
        ? `
        width: 200px;
      `
        : `
        align-items: center;
      `}

      [role='tablist'] {
        ${isVertical
          ? `
          flex-direction: column;
          align-items: flex-start;
        `
          : ''}
        height: auto;

        [role='tab'] {
          display: flex;
          align-items: center;
          overflow: hidden;
        }
      }
    `,
  };
};

const FeatureList = ({
  tabDirection = 'vertical',
  tabs,
}: {
  tabDirection?: 'vertical' | 'horizontal';
  tabs: TabItem[];
}) => {
  const styles = useStyles2((theme) => getStyles(theme, tabDirection === 'vertical'));
  const [active, setActive] = useState<TabItem>(tabs[0]);

  return (
    <div className={styles.tabsWrapper}>
      <TabsBar className={styles.tabsBar}>
        {tabs?.map((tab, index) => {
          return tabDirection === 'vertical' ? (
            <VerticalTab
              key={index}
              // @ts-ignore
              label={
                <div className={styles.title}>
                  {tab.icon ? tab.icon : ''}
                  {tab.name}
                </div>
              }
              active={tab === active}
              onChangeTab={() => setActive(tabs[index])}
            />
          ) : (
            <Tab
              key={index}
              // @ts-ignore
              label={
                <div className={styles.title}>
                  {tab.icon}
                  {tab.name}
                </div>
              }
              active={tab === active}
              onChangeTab={() => setActive(tabs[index])}
            />
          );
        })}
      </TabsBar>
      <TabContent className={styles.tabsContent}>
        {active.img && (
          <div className={styles.background}>
            <img src={active.img} className={styles.img} alt={active.name} />
          </div>
        )}
        {active.text && <p className={styles.description}>{active.text}</p>}
      </TabContent>
    </div>
  );
};

export default FeatureList;
