import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Input, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

type SelectServiceFilterProps = {
  onSearch: (searchTerm: string) => void;
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    margin-bottom: ${theme.spacing(3)};
  `,
});

export const SelectServiceFilter: FC<SelectServiceFilterProps> = ({ onSearch }) => {
  const styles = useStyles2(getStyles);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    onSearch(e.currentTarget.value.toLowerCase());
  };

  return (
    <div className={styles.container}>
      <Input
        prefix={<Icon name="search" />}
        placeholder="Search"
        onChange={handleChange}
        aria-label="Search aws service by name"
        data-testid={Pages.CloudWatch.SelectServices.serviceSearchInput}
      />
    </div>
  );
};
