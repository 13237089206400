import React, { useState } from 'react';
import { css } from '@emotion/css';
import { EmbeddedDashboard } from '@grafana/runtime';
import { useStyles2 } from '@grafana/ui';
import { DashboardDb } from 'api/dashboards/data-model';

const getStyles = () => ({
  dashboardContainer: css`
    display: flex;
    width: 100%;
    height: 100%;
  `,
});

export const CspEmbeddedDashboard = ({ dashboard }: { dashboard?: DashboardDb }) => {
  const styles = useStyles2(getStyles);
  const [state, setState] = useState('?orgId=1&refresh=30s&scenes');

  function onDashboardStateChange(state: string) {
    setState(`${state}&scenes`);
  }

  if (dashboard) {
    return (
      <div className={styles.dashboardContainer}>
        <EmbeddedDashboard uid={dashboard.uid} initialState={state} onStateChange={onDashboardStateChange} />
      </div>
    );
  }
  return null;
};
