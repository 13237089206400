import React, { useContext } from 'react';
import { PluginPage } from '@grafana/runtime';
import { prefixRoute } from 'utils/utils.routing';
import { Button, Card, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2, PageLayoutType } from '@grafana/data';
import { css } from '@emotion/css';
import { AWSIcon, AzureIcon, CloudIcon, GCPIcon } from 'img';
import { CloudProvider } from 'types/CloudProvider';
import { useHistory } from 'react-router-dom';
import { useIntegrationsByCatalogQuery } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { Catalogs } from 'types/Catalogs';
import { CspCard } from './common/types/types';
import { getCloudProviderName, getCloudProvidersCards } from './common/utils/utils';
import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    pageContent: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)};
      align-items: center;

      hr {
        border: 1px solid ${theme.colors.border.weak};
        width: 100%;
      }
    `,
    pageHeader: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.spacing(2)};
      width: 40%;
      text-align: center;
      padding: ${theme.spacing(3)} ${theme.spacing(3)} 0;

      p {
        color: ${theme.colors.text.secondary};
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        width: 60%;
        padding: 0;
      }
    `,
    pageIcon: css`
      width: 70px;
    `,
    cardsWrapper: css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: ${theme.spacing(2)};
      padding: 0 ${theme.spacing(3)} ${theme.spacing(3)};
    `,
    cardContent: css`
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing(4)};

      button {
        padding-left: 0;
      }
    `,
    cardHeader: css`
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(2)};
      align-items: center;
      margin-bottom: ${theme.spacing(2)};

      p {
        margin-bottom: 0;
      }

      img {
        height: 32px;
      }
    `,
    cardDescription: css`
      color: ${theme.colors.text.secondary};
      margin-bottom: ${theme.spacing(2)};
    `,
  };
};

const cards: CspCard[] = [
  {
    id: CloudProvider.AWS,
    title: 'Amazon Web Services',
    description: 'View and analyse the health and performance of your AWS infrastructure and large scale applications.',
    icon: AWSIcon,
  },
  {
    id: CloudProvider.AZURE,
    title: 'Azure',
    description: 'Easy set-up, granular configuration, and out-of-the-box monitoring for your Azure services',
    icon: AzureIcon,
  },
  {
    id: CloudProvider.GCP,
    title: 'Google Cloud',
    description: 'Easy set-up, granular configuration, and out-of-the-box monitoring for your GCP services',
    icon: GCPIcon,
  },
];

export const CspRootPage = () => {
  const styles = useStyles2(getStyles);
  const history = useHistory();
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data } = useIntegrationsByCatalogQuery(pluginId, jsonData.grafana_instance_id, Catalogs.CloudProvider);
  const cspCards = data ? getCloudProvidersCards(data) : cards;

  function onCardClick(card: CspCard) {
    history.push(prefixRoute(card.id));
  }

  return (
    <PluginPage layout={PageLayoutType.Custom}>
      <div className={styles.pageContent}>
        <div className={styles.pageHeader}>
          <img className={styles.pageIcon} src={CloudIcon} alt={'Cloud Icon'} />
          <h1>Instant observability for your cloud providers</h1>
          <p>Monitor and optimize your cloud&apos;s health and performance. Powered by Grafana Cloud and Alloy.</p>
        </div>
        <hr />
        <div className={styles.cardsWrapper}>
          {cspCards.map((card) => (
            <Card
              key={`card-${card.id}`}
              onClick={() => onCardClick(card)}
              data-testid={Pages.CspPage.getCard(card.id)}
              className={styles.cardContent}
            >
              <div className={styles.cardHeader}>
                <img src={card.icon} alt={`${card.id} logo`} />
                <p>{card.title}</p>
              </div>
              <p className={styles.cardDescription}>{card.description}</p>
              <Button fill={'text'} onClick={() => onCardClick(card)}>
                {`Go to ${getCloudProviderName(card.id as CloudProvider)}`}
              </Button>
            </Card>
          ))}
        </div>
      </div>
    </PluginPage>
  );
};
