import { ThresholdsMode } from '@grafana/data';
import {
  behaviors,
  EmbeddedScene,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { TableCellBackgroundDisplayMode } from '@grafana/schema';
import { TableCellDisplayMode } from '@grafana/ui';
import { EC2_URL } from 'scenes/AWS/routes';
import { getGenericQueryRunner, makeTimeRange, onChangeDatasourceBehavior } from 'scenes/misc';
import { allQueries } from './queries';
import { VAR_DATASOURCE } from 'scenes/variables';
import { getVariables } from './getVariables';
import { ExplorablePanel } from 'scenes/common/ExplorablePanel';

export const getProactiveTabScene = (prometheusName: string, from?: string, to?: string) => {
  const { datasources, topOrBottom, topInstances } = getVariables(prometheusName);

  type Row = Array<{
    query: string;
    title: string;
    description?: string;
    extraOverrides?: any;
  }>;

  const rows: Row = [
    {
      query: allQueries.topInstances.withDiskWriteBytesSum,
      title: '$topOrBottom $topInstances instances by disk write bytes',
      extraOverrides: [
        {
          matcher: {
            id: 'byName',
            options: 'Value',
          },
          properties: [
            {
              id: 'unit',
              value: 'bytes',
            },
          ],
        },
      ],
    },
    {
      query: allQueries.topInstances.withDiskReadBytesSum,
      title: '$topOrBottom $topInstances instances by disk read bytes',
      extraOverrides: [
        {
          matcher: {
            id: 'byName',
            options: 'Value',
          },
          properties: [
            {
              id: 'unit',
              value: 'bytes',
            },
          ],
        },
      ],
    },
    {
      query: allQueries.topInstances.withCpuUtilizationAvg,
      title: '$topOrBottom $topInstances instances by cpu utilization avg',
      extraOverrides: [
        {
          matcher: {
            id: 'byName',
            options: 'Value',
          },
          properties: [
            {
              id: 'unit',
              value: 'percent',
            },
            {
              id: 'custom.cellOptions',
              value: {
                mode: TableCellBackgroundDisplayMode.Basic,
                type: TableCellDisplayMode.Gauge,
              },
            },
            {
              id: 'thresholds',
              value: {
                mode: ThresholdsMode.Absolute,
                steps: [
                  {
                    color: '#5794F2',
                    value: 0,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ];

  const datasource = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const sceneTimeRange = makeTimeRange(from, to, undefined, { from: 'now-1h', to: 'now' });

  return new EmbeddedScene({
    $timeRange: sceneTimeRange,
    $variables: new SceneVariableSet({
      variables: [datasources, topOrBottom, topInstances],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ['5s', '1m', '1h'],
        isOnCanvas: true,
      }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      $behaviors: [
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_DATASOURCE,
          onChange: onChangeDatasourceBehavior,
        }),
      ],
      children: [
        [
          ...rows.map(
            (row) =>
              new SceneFlexItem({
                minHeight: 300,
                $data: getGenericQueryRunner(datasource, row.query, {
                  format: 'table',
                  instant: true,
                }),
                body: new ExplorablePanel({
                  title: row.title,
                  description: row?.description,
                  pluginId: 'table',
                  fieldConfig: {
                    defaults: {
                      custom: {
                        align: 'auto',
                        inspect: false,
                        filterable: true,
                      },
                      mappings: [],
                    },
                    overrides: [
                      {
                        matcher: {
                          id: 'byName',
                          options: 'Time',
                        },
                        properties: [
                          {
                            id: 'custom.hidden',
                            value: true,
                          },
                        ],
                      },
                      {
                        matcher: {
                          id: 'byName',
                          options: 'account_id',
                        },
                        properties: [
                          {
                            id: 'custom.width',
                            value: 110,
                          },
                          {
                            id: 'displayName',
                            value: 'Account ID',
                          },
                        ],
                      },
                      {
                        matcher: {
                          id: 'byName',
                          options: 'dimension_InstanceId',
                        },
                        properties: [
                          {
                            id: 'displayName',
                            value: 'Instance ID',
                          },
                          {
                            id: 'links',
                            value: [
                              {
                                title: 'Go to instance',
                                url:
                                  EC2_URL +
                                  '/overview/${__data.fields.dimension_InstanceId}?var-datasource=$datasource',
                              },
                            ],
                          },
                        ],
                      },
                      {
                        matcher: {
                          id: 'byName',
                          options: 'region',
                        },
                        properties: [
                          {
                            id: 'links',
                            value: [
                              {
                                title: 'Go to region',
                                url: EC2_URL + '/region?var-region=${__data.fields.region}',
                              },
                            ],
                          },
                          {
                            id: 'displayName',
                            value: 'Region',
                          },
                        ],
                      },
                      {
                        matcher: {
                          id: 'byName',
                          options: 'scrape_job',
                        },
                        properties: [
                          {
                            id: 'custom.width',
                            value: 110,
                          },
                          {
                            id: 'displayName',
                            value: 'Scrape job',
                          },
                        ],
                      },
                      {
                        matcher: {
                          id: 'byName',
                          options: 'tag_Name',
                        },
                        properties: [
                          {
                            id: 'displayName',
                            value: 'Tag name',
                          },
                        ],
                      },
                      {
                        matcher: {
                          id: 'byName',
                          options: 'tag_aws_autoscaling_groupName',
                        },
                        properties: [
                          {
                            id: 'displayName',
                            value: 'Autoscalling group name',
                          },
                        ],
                      },
                      [...(row?.extraOverrides ?? [])],
                    ].flat(),
                  },
                }),
              })
          ),
        ],
      ].flat(),
    }),
  });
};
