import { css } from '@emotion/css';
import React, { useContext } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { InstallAlloySection } from './InstallCollector/InstallAlloySection';
import { Status } from 'api/int-api/data-models';
import { MakeConfigurationSelectionsSection } from './MakeConfigurationSelections/MakeConfigurationSelectionsSection';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { AlloyIntegrationInitialState } from 'app/contexts/alloyIntegration.context';
import { useGetIntegration } from 'api/int-api/queries';
import { AlloyIntegrationContextProvider } from 'feature/common/components/AlloyIntegrationContextProvider';
import { ConfigureIntegrationSection } from './ConfigureIntegration/ConfigureIntegrationSection';
import { ViewYourDashboardsSection } from 'feature/common/components/ViewYourDashboardsSection';
import { PlatformSelectionSection } from 'feature/common/components/PlatformSelectionSection';
import { ViewYourLogsSection } from 'feature/common/components/ViewYourLogsSection';

const getStyles = (theme: GrafanaTheme2) => ({
  ol: css`
    > li {
      list-style-position: inside;
      margin-bottom: 70px;

      h2 {
        display: inline;
      }

      ::marker {
        font-weight: ${theme.typography.h2.fontWeight};
        font-size: ${theme.typography.h2.fontSize};
        line-height: 26px;
      }
    }
  `,
});

export function AlloyIntegrationInstructions({ sourceId }: { sourceId: string }): React.JSX.Element {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: sourceDetails } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const areDashboardsAvailable = sourceDetails?.dashboards?.status === Status.Available;
  const areMetricsAvailable = sourceDetails?.metrics?.status === Status.Available;
  const areLogsAvailable = sourceDetails?.logs?.status === Status.Available;

  return (
    <AlloyIntegrationContextProvider sourceId={sourceId} initialState={AlloyIntegrationInitialState}>
      <ol className={styles.ol}>
        <PlatformSelectionSection />
        <InstallAlloySection sourceId={sourceId} showOSSelection={false} />
        <MakeConfigurationSelectionsSection sourceId={sourceId} />
        <ConfigureIntegrationSection sourceId={sourceId} />
        {areDashboardsAvailable && areMetricsAvailable && <ViewYourDashboardsSection sourceId={sourceId} />}
        {areLogsAvailable && <ViewYourLogsSection sourceId={sourceId} />}
      </ol>
    </AlloyIntegrationContextProvider>
  );
}
