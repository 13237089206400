import {
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  SceneVariable,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { CloudProvider } from 'types/CloudProvider';
import { VAR_ACCOUNTS, VAR_LEVEL, VAR_LOG_TERM_SEARCH, VAR_LOKI_DATASOURCE } from 'scenes/variables';
import { getDataSourceSettings, PLUGIN_ID_STORAGE_KEY } from 'scenes/misc';
import { AWSLogsQueryState, buildLogsQuery, getLogsQueryState, getLogsVolumeQueryState } from 'scenes/Logs/queries';

export const onChangeLokiDatasourceBehavior = (variable: SceneVariable) => {
  let lokiUID = variable.getValue?.() as string;
  if (!lokiUID) {
    const lokiDataSource = getDataSourceSettings('loki');
    if (lokiDataSource) {
      lokiUID = lokiDataSource.uid;
    }
  }

  localStorage?.setItem(
    PLUGIN_ID_STORAGE_KEY,
    JSON.stringify({
      ...(JSON.parse(localStorage?.getItem(PLUGIN_ID_STORAGE_KEY) as string) || {}),
      lokiName: lokiUID,
    })
  );
};

export interface LogsQueryState<T> extends SceneObjectState {
  platform: CloudProvider;
  params: T;
}

abstract class LogsBaseQueryBehavior<T> extends SceneObjectBase<LogsQueryState<T>> {
  public getUpdatedParams(): T {
    const term = sceneGraph.lookupVariable(VAR_LOG_TERM_SEARCH, this)?.getValue() as string;
    if (this.state.platform === CloudProvider.AWS) {
      const accountId = sceneGraph.lookupVariable(VAR_ACCOUNTS, this)?.getValue() as string[];
      const level = sceneGraph.lookupVariable(VAR_LEVEL, this)?.getValue() as string;

      return { ...this.state.params, term, accountId, level } as T;
    }
    return { ...this.state.params, term } as T;
  }

  public getQuery() {
    const params: T = this.getUpdatedParams();
    // @ts-ignore
    return buildLogsQuery(this.state.platform, params);
  }
}

export class LogsQueryBehavior<T> extends LogsBaseQueryBehavior<T> {
  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [VAR_LOKI_DATASOURCE],
    onReferencedVariableValueChanged: this.onVariableValueChanged.bind(this),
  });

  public constructor(state: LogsQueryState<T>) {
    super(state);
    this.addActivationHandler(this._onActivate);
  }

  private _onActivate = () => {
    const parent = this.parent;

    if (!parent) {
      throw new Error('LogsQueryBehavior must be attached to a parent object');
    }
    const queryRunner = parent as SceneQueryRunner;
    if (queryRunner.state.queries?.length === 0) {
      // re-run the queries to update the view in case datasource change before scene activation (via url sync)
      const query = this.getQuery();
      queryRunner.setState(getLogsQueryState(query));
      queryRunner.runQueries();
    }
  };

  public onVariableValueChanged(variable: SceneVariable) {
    if (variable.state.name === VAR_LOKI_DATASOURCE) {
      onChangeLokiDatasourceBehavior(variable);
    }
    const query = this.getQuery();
    const queryRunner = sceneGraph.getAncestor(this, SceneQueryRunner);
    if (queryRunner) {
      queryRunner.setState(getLogsQueryState(query));
      queryRunner.runQueries();
    }
  }
}

export class AwsLogsQueryBehavior extends LogsQueryBehavior<AWSLogsQueryState> {
  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [VAR_LOKI_DATASOURCE, VAR_ACCOUNTS, VAR_LEVEL],
    onReferencedVariableValueChanged: this.onVariableValueChanged.bind(this),
  });
}

export class LogsVolumeQueryBehavior<T> extends LogsBaseQueryBehavior<T> {
  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [VAR_LOKI_DATASOURCE],
    onReferencedVariableValueChanged: this.onVariableValueChanged.bind(this),
  });

  public constructor(state: LogsQueryState<T>) {
    super(state);
    this.addActivationHandler(this._onActivate);
  }

  public onVariableValueChanged(variable: SceneVariable) {
    if (variable.state.name === VAR_LOKI_DATASOURCE) {
      onChangeLokiDatasourceBehavior(variable);
    }
    const query = this.getQuery();

    const queryRunner = sceneGraph.getAncestor(this, SceneQueryRunner);
    if (queryRunner) {
      queryRunner.setState(getLogsVolumeQueryState(query));
      queryRunner.runQueries();
    }
  }

  private _onActivate = () => {
    const parent = this.parent;

    if (!parent) {
      throw new Error('LogsVolumeQueryBehavior must be attached to a parent object');
    }
    const queryRunner = parent as SceneQueryRunner;
    if (queryRunner.state.queries?.length === 0) {
      // re-run the queries to update the view in case datasource change before scene activation (via url sync)
      const query = this.getQuery();
      queryRunner.setState(getLogsVolumeQueryState(query));
      queryRunner.runQueries();
    }
  };
}

export class AwsLogsVolumeQueryBehavior extends LogsVolumeQueryBehavior<AWSLogsQueryState> {
  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [VAR_LOKI_DATASOURCE, VAR_ACCOUNTS, VAR_LEVEL],
    onReferencedVariableValueChanged: this.onVariableValueChanged.bind(this),
  });
}
