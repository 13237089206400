import { MutationStatus, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NoDataSourceFoundError, requestIntegrations } from 'api/baseApi';
import {
  getAnyCatalogBySourceId,
  getCatalogBySourceId,
  getIntegrations,
  installDashboards,
  installIntegration,
  isAgentSendingLogs,
  isAgentSendingMetrics,
  isIntegrationUsingLogs,
  isIntegrationUsingMetrics,
  removeDashboards,
  uninstallIntegration,
} from './utils';
import { APIAgentDetails, ApiIntegrationDetails, Source, SourceDetails, VersionHistory } from './data-models';
import { useCurrentSourceId } from 'hooks/useCurrentSourceId';
import {
  handleError,
  invalidateSharedMutations,
  isErrorResponse,
  parseErrorMessage,
  useClearMutationByKey,
  useLastMutationState,
} from 'api/common/utils';
import { useMemo } from 'react';
import { ErrorApiResult, SuccessfulApiResult } from 'api/common/data-model';
import { sourcesWithLocalInstructions } from 'utils/localSources';
import { text } from 'utils/consts';
import { transformAgentDetails } from 'api/int-api/transform';
import {
  CollectorMode,
  IntegrationConnectionErrorCause,
  IntegrationConnectionResult,
  IntegrationConnectionStatus,
} from 'enums';
import { CloudProvider } from 'types/CloudProvider';
import { getDashboardFoldersByIntegration } from 'scenes/misc';
import { Catalogs } from 'types/Catalogs';

const handleIntegrationError = (error: any, message: string, orgSlug?: string) => {
  if (isErrorResponse<ErrorApiResult>(error)) {
    const errorObject: Error = { name: error.data.status, message: error.data.error };
    handleError(parseErrorMessage(error, message, orgSlug), errorObject);
    throw errorObject;
  } else if (error instanceof Error) {
    handleError(error.message, error);
    throw error;
  }
};

export const useIntegrationsByCatalogQuery = <T = Source[]>(
  pluginId: string,
  grafanaInstanceId: string,
  catalog?: CloudProvider | Catalogs,
  select?: (data: Source[]) => T,
  enabled = true
) =>
  useQuery<Source[], Error, T>({
    queryKey: ['integrationsByCatalog', catalog],
    queryFn: async () => getIntegrations(pluginId, grafanaInstanceId, catalog),
    select,
    enabled: !!pluginId && !!grafanaInstanceId && enabled,
  });

export const useCspIntegration = (id: string, pluginId: string, grafanaInstanceId: string) => {
  const catalog = getAnyCatalogBySourceId(id);
  return useIntegrationsByCatalogQuery(
    pluginId,
    grafanaInstanceId,
    catalog,
    (data: Source[]) => (data.find((int) => int.id === id) || {}) as Source,
    Boolean(catalog)
  );
};

export const useGetIntegration = (id: string, pluginId: string, grafanaInstanceId: string, enabled = true) =>
  useQuery({
    queryKey: ['getOneIntegration', id],
    queryFn: async () => {
      const response = await requestIntegrations<SuccessfulApiResult<ApiIntegrationDetails>>(
        pluginId,
        `/int-api-editor/v2/stacks/${grafanaInstanceId}/integrations/${id}`
      );
      return response.data.data as SourceDetails;
    },
    enabled: Boolean(id) && Boolean(pluginId) && Boolean(grafanaInstanceId) && enabled,
  });

export const useGetIntegrationChangelog = (id: string, pluginId: string, grafanaInstanceId: string) =>
  useQuery({
    queryKey: ['getIntegrationChangelog', id],
    queryFn: async () => {
      const response = await requestIntegrations<SuccessfulApiResult<VersionHistory[]>>(
        pluginId,
        `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${id}/changelog`
      );
      return response.data.data as VersionHistory[];
    },
    enabled: !!id && !!pluginId && !!grafanaInstanceId,
  });

const getDashboardsInvalidations = (id: CloudProvider) => {
  const folders = getDashboardFoldersByIntegration(id);
  return ['getDashboards', [folders]];
};

const getInstallInvalidations = (providerId: CloudProvider) => {
  const dashboardsInvalidations = getDashboardsInvalidations(providerId);
  return [
    ['getOneIntegration', providerId],
    ['integrationsByCatalog', providerId],
    ['getScrapeJobs', providerId],
    dashboardsInvalidations,
  ];
};

export const useInstallIntegration = (
  id: CloudProvider,
  pluginId: string,
  grafanaInstanceId: string,
  orgSlug?: string
) => {
  const queryClient = useQueryClient();
  const mutationKey = ['installIntegration', id];
  const installInvalidations = getInstallInvalidations(id);

  return useMutation({
    mutationKey,
    mutationFn: async () => {
      await installDashboards(id, pluginId, grafanaInstanceId);
      await installIntegration(id, pluginId, grafanaInstanceId);
    },
    onSuccess: () => invalidateSharedMutations(queryClient, installInvalidations),
    onError: async (error) => {
      handleIntegrationError(error, text.error.installIntegration, orgSlug);

      try {
        await removeDashboards(id, pluginId, grafanaInstanceId);
      } catch (e) {
        handleIntegrationError(e, text.errorFunctions.installRollbackFailed(id), orgSlug);
      }
    },
  });
};

export const useInstallIntegrationStatus = (id: string): MutationStatus => {
  const { status } = useLastMutationState(['installIntegration', id]);
  return status;
};

export const useUninstallIntegrations = (ids: CloudProvider[] = [], pluginId: string, grafanaInstanceId: string) => {
  const queryClient = useQueryClient();
  const installInvalidations = ids.map((id) => getInstallInvalidations(id)).flat();

  const mutationKeys = ids
    .map((id) => [
      ['installIntegration', id],
      ['checkCloudWatchConnection'],
      ['createOrUpdateScrapeJob'],
      ['getOneIntegration', id],
      getDashboardsInvalidations(id),
    ])
    .flat();
  const resetDependencies = useClearMutationByKey(mutationKeys);

  const mutation = useMutation({
    mutationKey: ids.map((id) => ['uninstallIntegration', id]),
    mutationFn: async () => {
      for (let i = 0; i < ids.length; i++) {
        await removeDashboards(ids[i], pluginId, grafanaInstanceId);
        await uninstallIntegration(ids[i], pluginId, grafanaInstanceId);
      }
    },
    onSuccess: () => invalidateSharedMutations(queryClient, installInvalidations),
    onError: (error) => handleIntegrationError(error, text.error.uninstallIntegration),
  });

  const resetFull = () => {
    mutation.reset();
    resetDependencies();
  };

  return { ...mutation, reset: resetFull };
};

export const useUpgradeIntegration = (id: string, pluginId: string, grafanaInstanceId: string) => {
  const queryClient = useQueryClient();
  const mutationKey = ['upgradeIntegration'];

  return useMutation({
    mutationKey,
    mutationFn: async () => {
      await removeDashboards(id, pluginId, grafanaInstanceId);
      await installDashboards(id, pluginId, grafanaInstanceId);
      await requestIntegrations(pluginId, `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${id}/upgrade`, {
        method: 'POST',
        showErrorAlert: false,
      });
    },
    onSuccess: () => {
      const provider = getCatalogBySourceId(id);
      return invalidateSharedMutations(queryClient, [
        ['getOneIntegration', id],
        ['integrationsByCatalog', provider],
      ]);
    },
    onError: (error) => handleIntegrationError(error, text.error.updateIntegration),
  });
};

export const useSelectedSource = (pluginId: string, grafanaInstanceId: string) => {
  const sourceId = useCurrentSourceId();
  const provider = getCatalogBySourceId(sourceId);
  const selectedLocalSource = sourcesWithLocalInstructions.find((source) => source.id === sourceId);

  const { data: sources } = useIntegrationsByCatalogQuery(pluginId, grafanaInstanceId, provider);
  return useMemo(
    () => sources?.filter((source) => source.id === sourceId)[0] ?? selectedLocalSource ?? ({} as Source),
    [sources, sourceId, selectedLocalSource]
  );
};

export const useGetAgentDetails = (
  integrationId: string,
  mode: CollectorMode,
  pluginId: string,
  grafanaInstanceId: string
) =>
  useQuery({
    queryKey: ['getAgentDetails', integrationId, mode],
    queryFn: async () => {
      const response = await requestIntegrations<SuccessfulApiResult<APIAgentDetails>>(
        pluginId,
        `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${integrationId}/agent?mode=${mode}`
      );
      return transformAgentDetails(response.data);
    },
    enabled: !!integrationId && !!mode && !!pluginId,
  });

export const useTestIntegrationConnectionRequest = <T = IntegrationConnectionResult>(
  pluginId: string,
  grafanaInstanceId: string,
  sourceDetails?: SourceDetails
) =>
  useQuery<IntegrationConnectionResult, Error, T>({
    queryKey: ['testIntegrationConnectionRequest', sourceDetails],
    queryFn: async () => {
      try {
        let isTestSuccessful: IntegrationConnectionResult = {
          status: IntegrationConnectionStatus.None,
          value: true,
        };
        if (sourceDetails) {
          if (isIntegrationUsingMetrics(sourceDetails)) {
            isTestSuccessful = await isAgentSendingMetrics(sourceDetails.metrics_check_query);
          }
          if (isTestSuccessful.value && isIntegrationUsingLogs(sourceDetails)) {
            isTestSuccessful = await isAgentSendingLogs(sourceDetails.logs_check_query);
          }
          if (isTestSuccessful.status === IntegrationConnectionStatus.None) {
            isTestSuccessful.value = false;
          }
        }
        return isTestSuccessful;
      } catch (error) {
        if (error instanceof NoDataSourceFoundError) {
          handleIntegrationError(error, text.errorFunctions.noDataSourceFound(error.message));
          return {
            status: IntegrationConnectionStatus.Error,
            error: IntegrationConnectionErrorCause.Unexpected,
            value: false,
          };
        } else {
          return {
            status: IntegrationConnectionStatus.Error,
            error: IntegrationConnectionErrorCause.Unexpected,
            value: false,
          };
        }
      }
    },
    enabled: false,
  });
