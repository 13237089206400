import React from 'react';
import { FilterJobStatus } from 'enums';

const SaasIntegrationInitialState: SaasIntegrationState = {
  jobStatusFilter: FilterJobStatus.All,
  setJobStatusFilter: () => {},
  selectedJobs: [],
  selectAllJobs: () => {},
  resetSelectedJobs: () => {},
  isJobSelected: () => false,
  toggleSelectedJobs: () => {},
};

export type SaasIntegrationState = {
  jobStatusFilter: FilterJobStatus;
  setJobStatusFilter: (value: FilterJobStatus) => void;
  selectedJobs: string[];
  selectAllJobs: (checked: boolean) => void;
  resetSelectedJobs: () => void;
  isJobSelected: (job: string) => boolean;
  toggleSelectedJobs: (job: string) => void;
};

export const SaasIntegrationContext = React.createContext<SaasIntegrationState>(SaasIntegrationInitialState);
