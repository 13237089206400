import { VAR_ACCOUNT, VAR_REGION, VAR_SCRAPE_JOB } from 'scenes/variables';

export const NECESSARY_RDS_METRICS = {
  METRIC_DATABASE_CONNECTIONS_SUM: `aws_rds_database_connections_sum`,
  METRIC_CPUUTILIZATION_MAXIMUM: `aws_rds_cpuutilization_maximum`,
  METRIC_CPUUTILIZATION_AVERAGE: `aws_rds_cpuutilization_average`,
  METRIC_REPLICA_LAG_AVERAGE: `aws_rds_replica_lag_average`,
  METRIC_SWAP_USAGE_AVERAGE: `aws_rds_swap_usage_average`,
  METRIC_WRITE_LATENCY_AVERAGE: `aws_rds_write_latency_average`,
  METRIC_WRITE_LATENCY_MAXIMUM: `aws_rds_write_latency_maximum`,
  METRIC_READ_LATENCY_AVERAGE: `aws_rds_read_latency_average`,
  METRIC_READ_LATENCY_MAXIMUM: `aws_rds_read_latency_maximum`,
  METRIC_EBSBYTE_BALANCE_PERCENT_AVERAGE: `aws_rds_ebsbyte_balance_percent_average`,
  METRIC_EBSIOBALANCE_PERCENT_AVERAGE: `aws_rds_ebsiobalance_percent_average`,
  METRIC_READ_IOPS_AVERAGE: `aws_rds_read_iops_average`,
  METRIC_WRITE_IOPS_AVERAGE: `aws_rds_write_iops_average`,
  METRIC_READ_THROUGHPUT_AVERAGE: `aws_rds_read_throughput_average`,
  METRIC_WRITE_THROUGHPUT_AVERAGE: `aws_rds_write_throughput_average`,
  METRIC_BURST_BALANCES_AVERAGE: `aws_rds_burst_balance_average`,
  METRIC_FREEABLE_MEMORY_AVERAGE: `aws_rds_freeable_memory_average`,
  METRIC_FREE_STORAGE_SPACE_AVERAGE: `aws_rds_free_storage_space_average`,
  METRIC_FREE_STORAGE_SPACE_LOG_VOLUME_AVERAGE: `aws_rds_free_storage_space_log_volume_average`,
  METRIC_CPU_CREDIT_USAGE_AVERAGE: `aws_rds_cpucredit_usage_average`,
  METRIC_CPUCREDIT_BALANCE_AVERAGE: `aws_rds_cpucredit_balance_average`,
  METRIC_DBLOAD_AVERAGE: `aws_rds_dbload_average`,
  METRIC_DBLOAD_CPU_AVERAGE: `aws_rds_dbload_cpu_average`,
  METRIC_DBLOAD_NON_CPU_AVERAGE: `aws_rds_dbload_non_cpu_average`,
};

export const rdsQueries = {
  dbInstancesCount: `count(label_replace(
        label_replace(
            aws_rds_info{name=~"arn:aws:rds:$${VAR_REGION}:.*", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}"},
            "region", "$1", "name", "arn:aws:rds:(.*?):.*"
        ), "account", "$1", "name", "arn:aws:rds:.*:(.*):.*"
      ))`,
  dbConnectionsSum: `sum by(__name__) (${NECESSARY_RDS_METRICS.METRIC_DATABASE_CONNECTIONS_SUM}{region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}"})`,
  instanceOverviewDrilldown: {
    dbConnectionsSum: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_DATABASE_CONNECTIONS_SUM}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    cpuUtilizationMaximum: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_MAXIMUM}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    connectionAttemptsAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_MAXIMUM}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    replicaLagAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_REPLICA_LAG_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    swapUsageAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_SWAP_USAGE_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    writeLatencyAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_WRITE_LATENCY_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    writeLatencyMaximum: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_MAXIMUM}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    readLatencyAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    readLatencyMaximum: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_MAXIMUM}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    ebsByteBalanceAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_EBSBYTE_BALANCE_PERCENT_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    ebsIOBalanceAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_EBSIOBALANCE_PERCENT_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    readIopsAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_IOPS_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    writeIopsAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_WRITE_IOPS_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    readThroughputAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_THROUGHPUT_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    writeThroughputAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_WRITE_THROUGHPUT_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
  },
  instanceAdminDrilldown: {
    writeLatencyAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_WRITE_LATENCY_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    readLatencyAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    burstBalanceAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_BURST_BALANCES_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    freeableMemoryAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_FREEABLE_MEMORY_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    freeStorageSpaceyAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_FREE_STORAGE_SPACE_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    writeThroughputAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_WRITE_THROUGHPUT_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    readThroughputAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_READ_THROUGHPUT_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    cpuCreditUsageAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_CPU_CREDIT_USAGE_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    cpuCreditBalanceAverage: (id: string) =>
      `${NECESSARY_RDS_METRICS.METRIC_CPUCREDIT_BALANCE_AVERAGE}{dimension_DBInstanceIdentifier=\"${id}\"}`,
    // TO DO: add from Trusted Advisor metrics - Amazon RDS Idle DB Instances
  },
  underutilizedInstances1: `count(${NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_AVERAGE}{region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}", name!='global'} < 60)`,
  underutilizedInstances2: `count(${NECESSARY_RDS_METRICS.METRIC_READ_IOPS_AVERAGE}{region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}", name!='global'} < 100)`,
  avgBurstBalance: `avg(${NECESSARY_RDS_METRICS.METRIC_BURST_BALANCES_AVERAGE}{region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}", name!='global'})`,
  filteredInstances: (metric: string) =>
    'sum by(account_id, region, dimension_InstanceId, dimension_DBInstanceIdentifier, dimension_DatabaseClass, scrape_job, tag_Name)' +
    `((${metric}{name!='global'}${metric === 'cpu' ? '< 60' : metric === 'readiops' ? '< 100' : ''}) + on(name, scrape_job) group_left(tag_Name) aws_rds_info)`,
  allTagsForAnInstance: (id: string) => `aws_rds_info{name=~".*${id}"}`,
};
