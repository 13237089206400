import {
  behaviors,
  EmbeddedScene,
  SceneAppDrilldownView,
  SceneAppPage,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
  VizPanel,
} from '@grafana/scenes';
import { RDS_URL } from 'scenes/AWS/routes';
import { makeTimeRange, onChangeDatasourceBehavior } from 'scenes/misc';
import { VAR_DATASOURCE } from 'scenes/variables';
import { getVariables } from './getVariables';
import { NECESSARY_RDS_METRICS, rdsQueries } from './queries';

export const getFilteredInstancesDrilldown = (
  prometheusName: string,
  from?: string,
  to?: string
): SceneAppDrilldownView => {
  const { datasources } = getVariables(prometheusName);

  return {
    routePath: RDS_URL + '/filtered-instances/:metric',
    getPage: (routeMatch, parent) => {
      const metric: string = routeMatch.params.metric;

      const metricName =
        metric === 'cpu'
          ? NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_AVERAGE
          : metric === 'readiops'
            ? NECESSARY_RDS_METRICS.METRIC_READ_IOPS_AVERAGE
            : NECESSARY_RDS_METRICS.METRIC_BURST_BALANCES_AVERAGE;

      const datasource = {
        type: 'prometheus',
        uid: '$datasource',
      };

      const queryRunner = new SceneQueryRunner({
        datasource: datasource,
        queries: [
          {
            refId: 'A',
            expr: rdsQueries.filteredInstances(metricName),
            format: 'table',
            instant: true,
            interval: '',
            legendFormat: '',
            excludeByName: {
              __name__: true,
            },
          },
        ],
      });

      const transformed = new SceneDataTransformer({
        $data: queryRunner,
        transformations: [
          {
            id: 'merge',
            options: {},
          },
          {
            id: 'organize',
            options: {
              indexByName: {
                account_id: 0,
                dimension_DBInstanceIdentifier: 1,
                region: 2,
                scrape_job: 3,
                tag_Name: 4,
                tag_aws_autoscaling_groupName: 5,
              },
            },
          },
        ],
      });

      const sceneTimeRange = makeTimeRange(from, to);

      return new SceneAppPage({
        title: 'Filtered instances by ' + metric,
        url: RDS_URL + `/filtered-instances/${metric}`,
        getParentPage: () => parent,
        getScene() {
          return new EmbeddedScene({
            $timeRange: sceneTimeRange,
            $variables: new SceneVariableSet({
              variables: [datasources],
            }),
            controls: [
              new VariableValueSelectors({}),
              new SceneControlsSpacer(),
              new SceneTimePicker({ isOnCanvas: true }),
              new SceneRefreshPicker({
                intervals: ['5s', '1m', '1h'],
                isOnCanvas: true,
              }),
            ],
            body: new SceneFlexLayout({
              direction: 'column',
              $behaviors: [
                new behaviors.ActWhenVariableChanged({
                  variableName: VAR_DATASOURCE,
                  onChange: onChangeDatasourceBehavior,
                }),
              ],
              children: [
                new SceneFlexItem({
                  minHeight: 400,
                  body: new VizPanel({
                    $data: transformed,
                    title: 'RDS instances',
                    pluginId: 'table',
                    options: {
                      sortBy: [{ desc: false, displayName: 'Value' }],
                    },
                    fieldConfig: {
                      defaults: {
                        custom: {
                          align: 'auto',
                          cellOptions: {
                            type: 'auto',
                          },
                          width: 200,
                          inspect: false,
                          filterable: true,
                        },
                        mappings: [],
                      },
                      overrides: [
                        {
                          matcher: {
                            id: 'byName',
                            options: 'Time',
                          },
                          properties: [
                            {
                              id: 'custom.hidden',
                              value: true,
                            },
                          ],
                        },
                        {
                          matcher: {
                            id: 'byName',
                            options: 'account_id',
                          },
                          properties: [
                            {
                              id: 'custom.width',
                              value: 110,
                            },
                            {
                              id: 'displayName',
                              value: 'Account ID',
                            },
                          ],
                        },
                        {
                          matcher: {
                            id: 'byName',
                            options: 'region',
                          },
                          properties: [
                            {
                              id: 'custom.width',
                              value: 110,
                            },
                            {
                              id: 'displayName',
                              value: 'Region',
                            },
                          ],
                        },
                        {
                          matcher: {
                            id: 'byName',
                            options: 'scrape_job',
                          },
                          properties: [
                            {
                              id: 'custom.width',
                              value: 110,
                            },
                            {
                              id: 'displayName',
                              value: 'Scrape job',
                            },
                          ],
                        },
                        {
                          matcher: {
                            id: 'byName',
                            options: 'tag_Name',
                          },
                          properties: [
                            {
                              id: 'displayName',
                              value: 'Tag name',
                            },
                          ],
                        },
                        {
                          matcher: {
                            id: 'byName',
                            options: 'dimension_DBInstanceIdentifier',
                          },
                          properties: [
                            {
                              id: 'displayName',
                              value: 'Instance ID',
                            },
                            {
                              id: 'links',
                              value: [
                                {
                                  title: 'Go to instance',
                                  url:
                                    RDS_URL +
                                    '/instance/${__data.fields.dimension_DBInstanceIdentifier}?var-region=${__data.fields.region}&${__url.params}&var-datasource=$datasource',
                                },
                              ],
                            },
                          ],
                        },
                      ].flat(),
                    },
                  }),
                }),
              ],
            }),
          });
        },
      });
    },
  };
};
