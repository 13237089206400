export const getError = (isInvalid: boolean, value: string) => {
  if (!isInvalid) {
    return '';
  }
  if (value.includes(' ')) {
    return 'The value should not include spaces.';
  } else {
    return 'A value with this name already exists.';
  }
};
