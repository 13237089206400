import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Checkbox, Toggletip, useStyles2 } from '@grafana/ui';

import { FieldValues, UseFormRegister } from 'react-hook-form';
import { AccountReactHookFormProps } from './AccountForm';
import { EditReactHookFormProps } from './EditJob';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  field: css`
    [class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
  tagsSubtitle: css`
    display: flex;
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};

    > span {
      margin-left: ${theme.spacing(3)};
    }

    button {
      color: ${theme.colors.text.link};
      height: ${theme.spacing(2.25)};
      font-size: ${theme.typography.bodySmall.fontSize};

      &:hover {
        background-color: transparent;
      }
    }
  `,
  tagsContainer: css`
    code {
      background-color: ${theme.visualization.getColorByName('dark1')} !important;
    }
  `,
  alert: css`
    margin: 20px 0;
    font-size: ${theme.typography.body.fontSize};
  `,
});

type TagsCheckboxProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  showDescription?: boolean;
};

export const TagsCheckbox = ({
  register,
  showDescription,
}: TagsCheckboxProps<AccountReactHookFormProps> | TagsCheckboxProps<EditReactHookFormProps>) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <Checkbox
        {...register('export_tags')}
        label={`Include your AWS resource tags on an aws_<service-name>_info metric (ex, aws_ec2_info) and enable the out of the box EC2 solution`}
        className={styles.field}
      />
      {showDescription && (
        <div className={styles.tagsSubtitle}>
          <span>Including tags will increase active series which can have an impact on your Grafana Cloud costs.</span>
          <Toggletip
            content={
              <div className={styles.tagsContainer}>
                Tags will appear as labels on the exported metric with a <code>tag_</code> prefix. Choosing to include
                tags will increase the total number of active series which can have an impact on your Grafana Cloud
                Costs. Additionally, please ensure your tags adhere to AWS best practices in that they do not contain
                personally identifiable information (PII) or other confidential or sensitive information.
              </div>
            }
            closeButton={true}
          >
            <Button fill={'text'} icon={'info-circle'} aria-label={'Learn more about including tags'}>
              Learn more about including tags
            </Button>
          </Toggletip>
        </div>
      )}
    </div>
  );
};
