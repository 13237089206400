import { PanelBuilders } from '@grafana/scenes';
import { GraphDrawStyle, LineInterpolation } from '@grafana/schema';
import { LogsVolumePanelBehavior } from 'scenes/Logs/LogsVolumePanelBehavior';

export const getLogsVolumePanel = () =>
  PanelBuilders.timeseries()
    .setTitle('Logs volume')
    .setOption('legend', { showLegend: true, calcs: ['sum'] })
    .setCustomFieldConfig('drawStyle', GraphDrawStyle.Bars)
    .setCustomFieldConfig('pointSize', 8)
    .setCustomFieldConfig('lineInterpolation', LineInterpolation.Linear)
    .setBehaviors([new LogsVolumePanelBehavior({})])
    .build();
