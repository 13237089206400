import React from 'react';
import { Tag, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { ServerIcon } from 'img';

const getStyles = (theme: GrafanaTheme2) => ({
  instanceBox: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.border.weak};
    padding: ${theme.spacing(1)};
    height: 100%;

    img {
      height: 32px;
    }
  `,
});

type Props = {
  title: string;
  tagName: string;
  tagValue: string;
  colorIndex: number;
};

export const ResourceContainer = ({ title, tagName, tagValue, colorIndex = 20 }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.instanceBox}>
      <span>{title}</span>
      <img src={ServerIcon} alt={title} />
      <Tag name={`${tagName}=${tagValue}`} colorIndex={colorIndex} />
    </div>
  );
};
