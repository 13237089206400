import React from 'react';
import { Source } from 'feature/common/components/Source/Source';
import { useCurrentSourceId } from 'hooks/useCurrentSourceId';
import { AlloyIntegrationInstructions } from 'feature/common/components/AlloyIntegrationInstructions';

export const ConfigurationDetail = () => {
  const sourceId = useCurrentSourceId();

  return (
    <Source sourceId={sourceId}>
      <AlloyIntegrationInstructions sourceId={sourceId} />
    </Source>
  );
};
