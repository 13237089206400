import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getJobTableStyles = (theme: GrafanaTheme2) => ({
  table: css`
    width: 100%;

    tr {
      border-bottom: 1px solid ${theme.colors.border.weak};
    }

    th,
    td {
      padding-right: ${theme.spacing(2)};
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
    }

    th:nth-child(3),
    td:nth-child(3) {
      @media (max-width: 768px) {
        display: none;
      }
    }

    th:nth-child(5),
    td:nth-child(5) {
      @media (max-width: 576px) {
        display: none;
      }
    }

    td:nth-child(6) {
      padding-right: 0;
    }

    td {
      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  `,
  selectedJobs: css`
    display: flex;
    align-items: center;

    span[class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
    }

    button {
      margin-right: ${theme.spacing(1)};
    }
  `,
  selectAllCheckbox: css`
    margin-right: ${theme.spacing(1)};
  `,
});
