import React from 'react';
import { TabItem } from 'feature/common/types/types';
import { AWSIcon } from 'feature/AWS/components/AWSIcon/AWSIcon';
import {
  ApplicationELBScreenshot,
  AzureBlobStorageScreenshot,
  AzureElasticPoolScreenshot,
  AzureEventHubScreenshot,
  BillingScreenshot,
  BlobStorageScreenshot,
  ClassicELBScreenshot,
  CloudFrontScreenshot,
  EBSScreenshot,
  EC2Screenshot,
  ECSScreenshot,
  GoogleCloudSqlScreenshot,
  LambdaScreenshot,
  NatGatewaysScreenshot,
  PubSubScreenshot,
  RdsScreenshot,
  S3Screenshot,
  SESScreenshot,
  SQSScreenshot,
  VpnScreenshot,
} from 'img/screenshots';

export const AWS_DASHBOARDS_TABS: TabItem[] = [
  {
    name: 'EC2 fleet overview',
    icon: <AWSIcon service={'AWS/EC2'} />,
    active: true,
    img: EC2Screenshot,
    text: 'Monitor how your EC2 instances scale up or down.',
    serviceId: 'AWS/EC2',
  },
  {
    name: 'RDS fleet overview',
    icon: <AWSIcon service={'AWS/RDS'} />,
    active: false,
    img: RdsScreenshot,
    text: 'Monitor your RDS instances.',
    serviceId: 'AWS/RDS',
  },
  {
    name: 'Billing overview',
    icon: <AWSIcon service={'AWS/Billing'} />,
    active: false,
    img: BillingScreenshot,
    text: 'Monitor estimated charges for AWS resources',
    serviceId: 'AWS/Billing',
  },
  {
    name: 'Cloud Front overview',
    icon: <AWSIcon service={'AWS/CloudFront'} />,
    active: false,
    img: CloudFrontScreenshot,
    text: 'Monitor CloudFront distributions and edge functions metrics to detect anomalous behavior',
    serviceId: 'AWS/CloudFront',
  },
  {
    name: 'ECS overview',
    icon: <AWSIcon service={'AWS/ECS'} />,
    active: false,
    img: ECSScreenshot,
    text: 'Monitor your Amazon ECS resources',
    serviceId: 'AWS/ECS',
  },
  {
    name: 'EBS overview',
    icon: <AWSIcon service={'AWS/EBS'} />,
    active: false,
    img: EBSScreenshot,
    text: 'Monitor your Amazon EBS volumes',
    serviceId: 'AWS/EBS',
  },
  {
    name: 'Application Load Balancer overview',
    icon: <AWSIcon service={'AWS/ApplicationELB'} />,
    active: false,
    img: ApplicationELBScreenshot,
    text: 'Monitor performance of your system and healthy targets for a load balancer',
    serviceId: 'AWS/ApplicationELB',
  },
  {
    name: 'Classic Load Balancer overview',
    icon: <AWSIcon service={'AWS/ELB'} />,
    active: false,
    img: ClassicELBScreenshot,
    text: 'Monitor your load balancers, analyze traffic patterns, and troubleshoot issues',
    serviceId: 'AWS/ELB',
  },
  {
    name: 'Lambda overview',
    icon: <AWSIcon service={'AWS/Lambda'} />,
    active: false,
    img: LambdaScreenshot,
    text: 'Monitor and troubleshooting Lambda functions',
    serviceId: 'AWS/Lambda',
  },
  {
    name: 'Nat Gateways overview',
    icon: <AWSIcon service={'AWS/NATGateway'} />,
    active: false,
    img: NatGatewaysScreenshot,
    text: 'Monitor your NAT gateway',
    serviceId: 'AWS/NATGateway',
  },
  {
    name: 'S3 overview',
    icon: <AWSIcon service={'AWS/S3'} />,
    active: false,
    img: S3Screenshot,
    text: 'Monitor your S3 buckets',
    serviceId: 'AWS/S3',
  },
  {
    name: 'SES overview',
    icon: <AWSIcon service={'AWS/SES'} />,
    active: false,
    img: SESScreenshot,
    text: 'Monitor your Amazon SES sending activity',
    serviceId: 'AWS/SES',
  },
  {
    name: 'SQS overview',
    icon: <AWSIcon service={'AWS/SQS'} />,
    active: false,
    img: SQSScreenshot,
    text: 'Monitor your Amazon SQS queues',
    serviceId: 'AWS/SQS',
  },
  {
    name: 'VPN overview',
    icon: <AWSIcon service={'AWS/VPN'} />,
    active: false,
    img: VpnScreenshot,
    text: 'Monitor your Amazon VPN tunnels',
    serviceId: 'AWS/VPN',
  },
];

export const AZURE_DASHBOARDS_TABS: TabItem[] = [
  {
    name: 'Azure Blob Storage',
    active: true,
    img: AzureBlobStorageScreenshot,
    serviceId: 'azure_microsoft_storage_storageaccounts_blobservices',
  },
  {
    name: 'Azure Elastic pool',
    active: false,
    img: AzureElasticPoolScreenshot,
    serviceId: 'azure_microsoft_sql_servers_elasticpools',
  },
  {
    name: 'Azure Event Hub',
    active: false,
    img: AzureEventHubScreenshot,
    serviceId: 'azure_microsoft_eventhub',
  },
];

export const GCP_DASHBOARDS_TABS: TabItem[] = [
  {
    name: 'Google Cloud SQL',
    active: true,
    img: GoogleCloudSqlScreenshot,
    serviceId: 'stackdriver_cloudsql_database_cloudsql',
  },
  {
    name: 'Google Cloud Pub/Sub',
    active: false,
    img: PubSubScreenshot,
    serviceId: 'stackdriver_pubsub_subscription_pubsub',
  },
  {
    name: 'GCP Blob storage',
    active: false,
    img: BlobStorageScreenshot,
    serviceId: 'stackdriver_gcs_bucket_storage',
  },
];
