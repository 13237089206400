import { css } from '@emotion/css';
import React, { FC, useCallback, useContext } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Field, Input, MultiSelect, useStyles2 } from '@grafana/ui';
import { AccountFormOutput } from '../../types';
import {
  useCheckCloudWatchConnection,
  useCheckCloudWatchConnectionStatus,
  useFilteredJobs,
} from 'api/hosted-exporters-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { SaasIntegrationContext } from 'app/contexts/saasIntegration.context';
import { FormErrors } from 'utils/consts';
import { TagsCheckbox } from './TagsCheckbox';
import { Pages } from 'e2eSelectors/pages';

import { OnboardingPluginError } from 'api/common/data-model';
import { ConnectionCheckButton } from 'feature/AWS/components/ConnectionCheckButton';
import { ScrapeJobNameField } from '../../ScrapeJobNameField';

type AccountFormProps = {
  regions: string[];
  onAccountChange: (accountForm: AccountFormOutput) => void;
};

const getStyles = (theme: GrafanaTheme2) => ({
  account: css`
    position: relative;
    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.components.input.borderColor};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  connectionStatus: css`
    margin-top: ${theme.spacing(2)};
    margin-bottom: 0;
    font-size: ${theme.typography.body.fontSize};
  `,
  dropdown: css`
    font-size: ${theme.typography.body.fontSize};
  `,
  tagsSubtitle: css`
    display: flex;
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text.secondary};

    > span {
      margin-left: ${theme.spacing(3)};
    }

    button {
      color: ${theme.colors.text.link};
      height: ${theme.spacing(2.25)};
      font-size: ${theme.typography.bodySmall.fontSize};

      &:hover {
        background-color: transparent;
      }
    }
  `,
  tagsContainer: css`
    code {
      background-color: ${theme.visualization.getColorByName('dark1')} !important;
    }
  `,
  testButtonContainer: css`
    display: block;
    width: 100%;
    margin: ${theme.spacing(2.5)} 0;
  `,
  field: css`
    [class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
});

export interface AccountReactHookFormProps extends FieldValues {
  name: string;
  arn: string;
  regions: Array<SelectableValue<string>>;
  export_tags: boolean;
}

export const AccountForm: FC<AccountFormProps> = ({ regions, onAccountChange }) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { jobStatusFilter } = useContext(SaasIntegrationContext);

  const { mutate: checkCloudwatchConnection, error: connectionError } = useCheckCloudWatchConnection(
    pluginId,
    jsonData.grafana_instance_id
  );
  // used shared status because modified else where
  const connectionStatus = useCheckCloudWatchConnectionStatus();
  const {
    control,
    register,
    watch,
    formState: { isDirty, isValid, errors },
  } = useForm<AccountReactHookFormProps>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      arn: '',
      regions: [],
      export_tags: true,
    },
  });

  const state = watch();

  const onCheckConnection = useCallback(() => {
    const { arn, regions } = state;
    checkCloudwatchConnection({ arn, regions: regions.map((r) => r.value!) });
  }, [state, checkCloudwatchConnection]);

  const handleAccountChange = useCallback(() => {
    onAccountChange({
      name: state.name,
      arn: state.arn,
      regions: state.regions.map((rs) => rs.value!),
      export_tags: state.export_tags,
      isValid,
      isDirty,
    });
  }, [onAccountChange, state.name, state.arn, state.regions, state.export_tags, isDirty, isValid]);

  const filteredJobs = useFilteredJobs(pluginId, jsonData.grafana_instance_id, jobStatusFilter);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className={styles.account}>
        <ScrapeJobNameField
          register={register}
          error={errors.name?.message}
          validate={{
            uniqueScrapeJobName: (v: any) =>
              filteredJobs.filter((job: any) => job.name === v).length === 0 || FormErrors.SCRAPE_JOB_NAME_EXISTS,
          }}
        />
        <Field
          label="ARN"
          description="Paste the ARN from your AWS IAM role."
          invalid={errors.arn !== undefined}
          error={errors.arn ? 'This field is required' : ''}
          className={styles.field}
        >
          <Input {...register('arn', { required: true })} id="arn" placeholder="ARN" />
        </Field>
        <Field
          data-testid={Pages.CloudWatch.CreateJob.regionsSelect}
          label="AWS Regions"
          invalid={errors.regions !== undefined}
          error={errors.regions ? 'At least one region is required' : ''}
          htmlFor="regions-selector"
          className={styles.field}
        >
          <Controller
            control={control}
            name="regions"
            rules={{
              validate: (s: Array<SelectableValue<string>>) => s.length > 0,
            }}
            render={({ field, fieldState }) => (
              <MultiSelect<string>
                options={(regions ?? []).map((region) => ({
                  label: region,
                  value: region,
                }))}
                placeholder="Select"
                inputId="regions-selector"
                noOptionsMessage="Unable to load regions."
                closeMenuOnSelect={false}
                className={styles.dropdown}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Field>
        <TagsCheckbox register={register} showDescription={true} />
        <div className={styles.testButtonContainer}>
          <ConnectionCheckButton
            connectionStatus={connectionStatus}
            connectionError={connectionError as unknown as OnboardingPluginError}
            onSuccess={handleAccountChange}
            onCheckConnection={onCheckConnection}
            disabled={!state.name || !state.arn || state.regions.length === 0}
          />
        </div>
      </div>
    </form>
  );
};
