import { css, cx } from '@emotion/css';
import React, { useCallback, useMemo } from 'react';

import { useStyles2 } from '@grafana/ui';

import { CardElement } from './CardElement';

const getStyles = () => ({
  groupWrapper: css`
    display: flex;
  `,
});

export interface OptionValue<T = any> {
  value: T;
  label: string;

  icon?: string;
  description?: string;
  [key: string]: any;
}

interface RadioButtonGroupProps {
  options: OptionValue[];

  value?: string;
  onChange?: (value?: any) => void;
  className?: string;
  size?: 'md' | 'base';
  CardElementOverride?: React.ElementType;
}

export const SelectGroup = ({
  options,
  value,
  onChange,
  className,
  size,
  CardElementOverride,
}: RadioButtonGroupProps) => {
  const styles = useStyles2(getStyles);

  const handleOnChange = useCallback(
    (option: OptionValue) => {
      return () => {
        if (onChange) {
          onChange(option.value);
        }
      };
    },
    [onChange]
  );

  const CardElementToRender = useMemo(() => CardElementOverride ?? CardElement, [CardElementOverride]);

  return (
    <div className={cx(styles.groupWrapper, className)}>
      {options.map((option) => (
        <CardElementToRender
          size={size}
          key={option.value}
          option={option}
          isSelected={value === option.value}
          onClick={handleOnChange(option)}
        />
      ))}
    </div>
  );
};
