import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { ServiceInfoApiResponse } from 'api/hosted-exporters-api/data-models';
import { SelectServiceItem } from './SelectServiceItem';
import { SelectServiceFilter } from './SelectServiceFilter';
import { SelectServiceResults } from './SelectServiceResults';
import { getServiceItems } from './SelectServiceUtils';

type SelectServiceProps = {
  defaultServices: ServiceInfoApiResponse[];
  selectedServices: string[];
  onSelected: (serviceIds: string[]) => void;
};

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    border-radius: 2px;
  `,
  title: css`
    font-size: ${theme.typography.h4.fontSize};
  `,
});

export const SelectService: FC<SelectServiceProps> = ({ defaultServices, selectedServices = [], onSelected }) => {
  const styles = useStyles2(getStyles);
  const [serviceItems, setServiceItems] = useState<SelectServiceItem[]>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    const result: SelectServiceItem[] = getServiceItems(searchTerm, defaultServices, selectedServices);
    setServiceItems(result);

    // select all if all elements in result are included in selectedServices
    const isSelectedAll = result.every((service) => selectedServices.includes(service.id));
    setSelectAll(isSelectedAll);
  }, [searchTerm, defaultServices, selectedServices]);

  function onServiceSelected(serviceId: string, selected: boolean) {
    let updatedSelection: string[];
    if (selected) {
      updatedSelection = [...selectedServices, serviceId];
    } else {
      updatedSelection = selectedServices.filter((s) => s !== serviceId);
    }
    onSelected(updatedSelection);
    setSelectAll(updatedSelection.length === defaultServices.length);
  }

  function onSelectAllServices(selected: boolean) {
    setSelectAll(selected);

    let updatedSelection: string[] = [];
    const currentSelection = defaultServices
      .filter((s) => !searchTerm || s.display_name.toLowerCase().indexOf(searchTerm) >= 0)
      .sort((a, b) => a.display_name.localeCompare(b.display_name))
      .map((s) => s.service_id);

    if (selected) {
      const allSelected = selectedServices.concat(currentSelection);
      updatedSelection = [...new Set(allSelected)];
    } else {
      updatedSelection = selectedServices.filter((serviceId) => !currentSelection.includes(serviceId));
    }
    onSelected(updatedSelection);
  }

  function onServiceSearch(searchTerm: string) {
    setSearchTerm(searchTerm);
  }

  return (
    <div className={styles.container} data-testid={Pages.CloudWatch.SelectServices.serviceMultiSelect}>
      <h4 className={styles.title}>Select AWS services</h4>
      <SelectServiceFilter onSearch={onServiceSearch} />
      <SelectServiceResults
        selectAll={selectAll}
        services={serviceItems ?? []}
        onSelected={onServiceSelected}
        onSelectAll={onSelectAllServices}
      />
    </div>
  );
};
