import { css, cx, keyframes } from '@emotion/css';
import React, { Fragment, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Icon, LoadingBar } from '@grafana/ui';

import { CopyToClipboard } from './CopyToClipboard';

const rotateAppearAnimation = keyframes`
  0% {
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform: rotate(0);
    opacity: 1;
  }
`;

const getStyles = (theme: GrafanaTheme2, { multipleLines }: { multipleLines: boolean }) => ({
  codeWrapper: css`
    position: relative;
    display: ${multipleLines ? 'flex' : 'inline-block'};
    flex-direction: column;
    align-items: baseline;
    min-height: 42px;
    margin-bottom: ${theme.spacing(1)};
    border: 1px solid ${theme.colors.border.strong};
    border-radius: 2px 2px 0 0;
    background: ${theme.components.input.background};
    padding: 6px;
  `,
  code: css`
    width: 100%;
    max-height: 104px;
    white-space: nowrap;
    margin-bottom: 0;
    padding-right: ${theme.spacing(5)};
    background: none;
    border-style: none;
  `,
  codeMultipleLines: css`
    white-space: break-spaces;
    padding-right: ${theme.spacing(5)};
  `,
  title: css`
    font-size: ${theme.typography.fontSize};
    color: ${theme.colors.text};
    font-weight: ${theme.typography.fontWeightLight};
    margin-bottom: 8px;
  `,
  description: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text};
    line-height: ${theme.spacing(2)};
    color: ${theme.colors.text};
    margin-bottom: ${theme.spacing(1)};
  `,
  link: css`
    display: flex;
    justify-content: center;
    font-weight: ${theme.typography.fontWeightRegular};
    border-radius: ${theme.shape.radius.default};
    padding: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(4)};
  `,
  expandHeight: css`
    max-height: 600px;
  `,
  check: css`
    animation: ${rotateAppearAnimation} 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    color: ${theme.colors.success.text};
    margin-right: ${theme.spacing(0.5)};
  `,
  loadingBarWrapper: css`
    overflow: hidden;
    min-height: 1px;
  `,
  barAndCodeWrapper: css`
    min-width: 200px;
    max-width: 700px;
  `,

  codeBackgroundLine: css`
    display: inline-block;
    width: 100%;
  `,
  codeHighlightedLine: css`
    color: ${theme.colors.info.contrastText};
    background: ${theme.colors.primary.main};
  `,
});

export function Clipboard({
  code,
  description,
  title,
  expandHeight = false,
  multipleLines = false,
  highlightLines,
  showLoadingBar = false,
  ...props
}: {
  code: string;
  description?: string;
  title?: string;
  expandHeight?: boolean;
  multipleLines?: boolean;
  highlightLines?: number[];
  showLoadingBar?: boolean;
  ['data-testid']?: string;
}) {
  const styles = useStyles2((theme: GrafanaTheme2) => getStyles(theme, { multipleLines }));
  const [isCopySuccessful, setIsCopySuccessful] = useState(false);
  const codeWithHighlights = code.split('\n').map((value, index) => (
    <Fragment key={index}>
      <span
        className={cx(styles.codeBackgroundLine, {
          [styles.codeHighlightedLine]: highlightLines?.includes(index),
        })}
      >
        {value}
      </span>
      {'\n'}
    </Fragment>
  ));

  return (
    <>
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.barAndCodeWrapper}>
        {/**
          *    LoadingBar component is not properly exported in grafana < 9.4
          *    Until the export is fixed we make sure the component is not undefined
          */}
        <div className={styles.loadingBarWrapper}>{showLoadingBar && LoadingBar && <LoadingBar width={500} />}</div>
        <div className={styles.codeWrapper} data-testid={props['data-testid']}>
          <pre
            className={cx(styles.code, multipleLines && styles.codeMultipleLines, expandHeight && styles.expandHeight)}
          >
            {highlightLines ? codeWithHighlights : code}
          </pre>
        </div>
        <CopyToClipboard
          className={styles.link}
          onClipboardCopy={() => {
            setIsCopySuccessful(true);
          }}
          clipboardText={code}
          fill="text"
        >
          {!isCopySuccessful ? (
            <>
              <Icon name="copy" />
              Copy to clipboard
            </>
          ) : (
            <>
              <Icon className={styles.check} name="check" />
              Copied to clipboard
            </>
          )}
        </CopyToClipboard>
      </div>
    </>
  );
}

export const RenderCode = ({ value }: { value: string }) => {
  return <Clipboard multipleLines code={value} expandHeight />;
};
