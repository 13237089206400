import { requestDatasource, requestIntegrations } from 'api/baseApi';
import { ApiIntegrationResult, Source, SourceDetails, Status } from './data-models';
import { createDashboard, createFolder, removeFolder } from 'api/dashboards/utils';
import { partition } from 'lodash';
import { ErrorApiResult, QueryDatasourceResult, SuccessfulApiResult } from 'api/common/data-model';
import { ScrapeJobApiResponse } from 'api/hosted-exporters-api/data-models';
import { handleError, isErrorResponse, parseErrorMessage } from 'api/common/utils';
import { text } from 'utils/consts';
import { CloudProvider } from 'types/CloudProvider';
import {
  AWS_INTEGRATION_IDS,
  AZURE_INTEGRATION_IDS,
  CLOUD_PROVIDERS_INTEGRATION_IDS,
  GCP_INTEGRATION_IDS,
} from 'api/int-api/consts';
import { IntegrationConnectionErrorCause, IntegrationConnectionResult, IntegrationConnectionStatus } from 'enums';
import { Catalogs } from 'types/Catalogs';

export const getIntegrations = async (
  pluginId: string,
  grafanaInstanceId: string,
  catalog?: CloudProvider | Catalogs
) => {
  let url = `/int-api-editor/v2/stacks/${grafanaInstanceId}/integrations`;
  if (catalog) {
    url += `?catalog=${catalog}`;
  }
  const response = await requestIntegrations(pluginId, url);
  const data = await response.data;
  const transformedData = mapIntegrationsToSources(data);
  if (catalog === CloudProvider.AWS) {
    return bumpCloudWatchMetricsIntegrationOrder(transformedData);
  } else {
    return transformedData;
  }
};

function mapIntegrationsToSources(response: SuccessfulApiResult<ApiIntegrationResult>): Source[] {
  const integrations = Object.entries(response.data);
  return integrations.map(([id, integration]) => ({
    id,
    slug: integration.slug,
    name: integration.name,
    installation: integration.installation,
    logo_url: integration.logo.dark_theme_url,
    light_logo_url: integration.logo.light_theme_url,
    overview: integration.overview,
    type: integration.type,
    version: integration.version,
    dashboard_folder: integration.dashboard_folder,
    search_keywords: integration.search_keywords,
    has_update: integration.has_update,
    rule_namespace: integration.rule_namespace,
  }));
}

interface DashboardInstallInfo {
  dashboard: { uid: string };
  folder_name: string;
  overwrite: boolean;
}

const getDashboardInstallInfos = async (
  integrationId: string,
  pluginId: string,
  grafanaInstanceId: string
): Promise<DashboardInstallInfo[]> => {
  const body = { configuration: { configurable_logs: { logs_disabled: false } } };
  const response = await requestIntegrations(
    pluginId,
    `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${integrationId}/dashboards`,
    {
      method: 'POST',
      showErrorAlert: false,
      data: JSON.stringify(body),
    }
  );
  return response.data.data;
};

export async function installDashboards(integrationId: string, pluginId: string, grafanaInstanceId: string) {
  const dashboardInstallInfos: DashboardInstallInfo[] = await getDashboardInstallInfos(
    integrationId,
    pluginId,
    grafanaInstanceId
  );

  const uniqueFolderNames = new Set(
    dashboardInstallInfos.map((dashboardInstallInfo) => dashboardInstallInfo.folder_name)
  );
  const createFolderPromises = Array.from(uniqueFolderNames.values()).map((folderName) => createFolder(folderName));
  await Promise.all(createFolderPromises);

  for (const dashboardInstallInfo of dashboardInstallInfos) {
    await createDashboard(
      dashboardInstallInfo.dashboard,
      dashboardInstallInfo.folder_name,
      dashboardInstallInfo.overwrite
    );
  }
}

export async function removeDashboards(
  integrationId: string,
  pluginId: string,
  grafanaInstanceId: string
): Promise<void[]> {
  const dashboardInstallInfos: DashboardInstallInfo[] = await getDashboardInstallInfos(
    integrationId,
    pluginId,
    grafanaInstanceId
  );

  const uniqueFolderNames = new Set(
    dashboardInstallInfos.map((dashboardInstallInfo) => dashboardInstallInfo.folder_name)
  );

  const removeFolderPromises = Array.from(uniqueFolderNames.values()).map((folderName) => removeFolder(folderName));

  return await Promise.all(removeFolderPromises);
}

export async function installIntegration(
  integrationId: string,
  pluginId: string,
  grafanaInstanceId: string
): Promise<void> {
  const body = { configuration: { configurable_logs: { logs_disabled: false } } };

  await requestIntegrations(pluginId, `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${integrationId}/install`, {
    method: 'POST',
    showErrorAlert: false,
    data: JSON.stringify(body),
  });
}

export async function uninstallIntegration(
  integrationId: string,
  pluginId: string,
  grafanaInstanceId: string
): Promise<void> {
  await requestIntegrations(
    pluginId,
    `/int-api/v2/stacks/${grafanaInstanceId}/integrations/${integrationId}/uninstall`,
    {
      method: 'POST',
      showErrorAlert: false,
    }
  );
}

export const removeAllScrapeJobs = async (
  integrationId: string,
  pluginId: string,
  grafanaInstanceId: string,
  jobNames: string[]
) => {
  try {
    await Promise.all(
      jobNames.map(async (jobName) =>
        requestIntegrations<SuccessfulApiResult<ScrapeJobApiResponse[]>>(
          pluginId,
          `/he-api/api/v1/stack/${grafanaInstanceId}/job/${jobName}`,
          {
            method: 'DELETE',
          }
        )
      )
    );
  } catch (error: any) {
    if (isErrorResponse<ErrorApiResult>(error)) {
      const errorObject: Error = { name: error.data.status, message: error.data.error };
      handleError(parseErrorMessage(error, text.error.uninstallIntegration), errorObject);
      throw errorObject;
    } else if (error instanceof Error) {
      handleError(error.message, error);
      throw error;
    }
  }
};

// bumpCloudWatchMetricsIntegrationOrder re-constructs the input Source list into one where the cloudwatch metrics integrations
// appears first, and the rest are lexicographically sorted in ascending order.
function bumpCloudWatchMetricsIntegrationOrder(input: Source[]): Source[] {
  const cloudWatchMetricsID = 'cloudwatch';
  const [cwMetricsSource, rest] = partition(input, (s) => s.id === cloudWatchMetricsID);
  return cwMetricsSource.concat(rest.sort((s1, s2) => lexCompare(s1.name, s2.name)));
}

function lexCompare(s1: string, s2: string): number {
  if (s1 < s2) {
    return -1;
  }
  if (s1 === s2) {
    return 0;
  }
  return 1;
}

async function queryDatasource<R = unknown>(datasourceName: string, query: string): Promise<R[]> {
  const queryStart = datasourceName === 'grafanacloud-logs' ? '/loki' : '';

  const response = await requestDatasource<{ data: { result: R[] } }>(
    datasourceName,
    `${queryStart}/api/v1/query?query=${encodeURIComponent(query)}`
  );

  if (response.status !== 200) {
    throw response;
  }
  return response.data.data.result;
}

export const isIntegrationUsingMetrics = (sourceDetails?: SourceDetails) =>
  sourceDetails?.metrics?.status === Status.Available && Boolean(sourceDetails?.metrics_check_query);

export const isIntegrationUsingLogs = (sourceDetails?: SourceDetails) =>
  sourceDetails?.logs?.status === Status.Available && Boolean(sourceDetails?.logs_check_query);

export async function isAgentSendingMetrics(query: string | undefined): Promise<IntegrationConnectionResult> {
  if (!query) {
    return {
      status: IntegrationConnectionStatus.Error,
      error: IntegrationConnectionErrorCause.Unexpected,
      value: false,
    };
  }

  const metricsResult = await queryDatasource<QueryDatasourceResult>('grafanacloud-prom', query);

  if (!metricsResult || metricsResult.length === 0) {
    return {
      status: IntegrationConnectionStatus.Error,
      error: IntegrationConnectionErrorCause.NoMetricsFound,
      value: false,
    };
  } else if (metricsResult.every((metric) => metric.value[1] === '0')) {
    return {
      status: IntegrationConnectionStatus.NoData,
      error: IntegrationConnectionErrorCause.AgentCannotScrapeMetrics,
      value: false,
    };
  } else {
    return { status: IntegrationConnectionStatus.Success, value: true };
  }
}

export async function isAgentSendingLogs(query: string | undefined): Promise<IntegrationConnectionResult> {
  if (!query) {
    return {
      status: IntegrationConnectionStatus.Error,
      error: IntegrationConnectionErrorCause.Unexpected,
      value: false,
    };
  }
  const logsResult = await queryDatasource<QueryDatasourceResult>('grafanacloud-logs', query);

  if (!logsResult || logsResult.length === 0 || logsResult.every((result) => result.value[1] === '0')) {
    return {
      status: IntegrationConnectionStatus.NoData,
      error: IntegrationConnectionErrorCause.NoLogsFound,
      value: false,
    };
  } else {
    return { status: IntegrationConnectionStatus.Success, value: true };
  }
}

export const getCatalogBySourceId = (sourceId: string): CloudProvider | undefined => {
  if (AWS_INTEGRATION_IDS.includes(sourceId)) {
    return CloudProvider.AWS;
  } else if (AZURE_INTEGRATION_IDS.includes(sourceId)) {
    return CloudProvider.AZURE;
  } else if (GCP_INTEGRATION_IDS.includes(sourceId)) {
    return CloudProvider.GCP;
  } else {
    return undefined;
  }
};

export const getAnyCatalogBySourceId = (sourceId: string): CloudProvider | Catalogs | undefined => {
  if (CLOUD_PROVIDERS_INTEGRATION_IDS.includes(sourceId)) {
    return Catalogs.CloudProvider;
  } else if (getCatalogBySourceId(sourceId) !== undefined) {
    return getCatalogBySourceId(sourceId);
  } else {
    return undefined;
  }
};
