import React, { useContext, useState } from 'react';
import { Button, Field, Icon, Modal, Switch, useStyles2, Label } from '@grafana/ui';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { AlloyIntegrationContext, AlloyIntegrationState } from 'app/contexts/alloyIntegration.context';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { debounce } from 'lodash';
import { getToggleStyles } from 'feature/common/components/Toggle.styles';
import { Status } from 'api/int-api/data-models';
import { useGetIntegration } from 'api/int-api/queries';
import { cx } from '@emotion/css';

export const FilteredMetricsToggle = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getToggleStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const {
    configuredParameters: { isFullMetricsSelected },
    removeLoadingBar,
    toggleMetricsSet,
  } = useContext<AlloyIntegrationState>(AlloyIntegrationContext);

  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: sourceDetails } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const areMetricsAvailable = sourceDetails?.metrics?.status === Status.Available;

  const debouncedRemoveLoadingBar = debounce(() => removeLoadingBar(), 1500);

  const FilteredMetricsLabel = (
    <Label
      className={styles.label}
      description="By default, we only include the metrics needed for the included pre-built dashboards and alerts."
    >
      Extended metrics
    </Label>
  );

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <Field label={FilteredMetricsLabel} className={styles.toggleGroup}>
        <Switch
          value={Boolean(isFullMetricsSelected)}
          onChange={() => {
            toggleMetricsSet();
            debouncedRemoveLoadingBar();
          }}
        ></Switch>
      </Field>
      <button
        className={styles.linkButton}
        onClick={() => {
          if (!showModal) {
            setShowModal(true);
            trackRudderStackEvent(RudderStackEvents.OpenExtendedMetricsModalButton, { integration_slug: sourceId });
          }
        }}
      >
        <Icon className={styles.infoIcon} name="info-circle"></Icon>
        How to choose either default or extended metrics
      </button>
      <Modal
        title="What do extended metrics provide me?"
        isOpen={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
      >
        <p>
          Extended metrics include additional metrics beyond what is needed for the out-of-the-box dashboards and
          alerts. These can be used to create custom dashboards or to run more complex queries.
        </p>
        {areMetricsAvailable && (
          <p>
            You can access the list of default metrics that come with this integration, used for dashboards and alerts
            by clicking on the button below.
          </p>
        )}
        <div className={cx(styles.buttonGroup, { [styles.alignRight]: !areMetricsAvailable })}>
          {areMetricsAvailable && (
            <Button
              onClick={() => {
                setShowModal(false);
                // TODO what to do here?
                // changeTab(Tabs.Metrics);
              }}
            >
              List of default metrics
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};
