import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getSelectServiceResultsStyles = (theme: GrafanaTheme2) => ({
  list: css`
    height: 195px;
    overflow-y: auto;
    background-color: ${theme.colors.background.secondary};
    list-style-type: none;

    li {
      margin-bottom: ${theme.spacing(1)};
      padding: ${theme.spacing(0.5)};
      position: relative;

      &.selected {
        background-color: #ccccdc14;
      }
    }
  `,
  item: css`
    display: flex;
    align-items: center;

    h4 {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
    }

    img {
      width: 32px;
      height: 32px;
      margin-right: ${theme.spacing(1)};
    }
  `,
  noResults: css`
    width: 100%;
    text-align: center;
  `,
  checkbox: css`
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1.5)};
  `,
  checkboxAll: css`
    padding: 0 ${theme.spacing(0.5)};
    margin-bottom: ${theme.spacing(1)};

    label {
      column-gap: ${theme.spacing(1.5)};
    }
  `,
});
