export enum PlanType {
  Gcloud = 'gcloud',
  Free = 'free',
}

export enum RudderStackEvents {
  // General
  AwsConfigurationViewPage = 'aws_configuration_page_opened',
  GcpConfigurationViewPage = 'gcp_configuration_page_opened',
  AzureConfigurationViewPage = 'azure_configuration_page_opened',

  // Integration page
  ViewDashboards = 'csp_view_dashboards_button_clicked',
  UninstallButton = 'csp_uninstall_integration_button_clicked',
  AbandonUninstall = 'csp_abandon_uninstall_integration_clicked',
  RemoveIntegration = 'csp_remove_integration_button_clicked',
  InstallButton = 'csp_install_integration_button_clicked',
  UpdateButton = 'csp_update_integration_button_clicked',
  ViewYourDashboardsButton = 'csp_view_your_dashboards_button_clicked',

  // Homepage
  GoToExplore = 'csp_explore_button_clicked',
  AddServices = 'aws_get_started_add_services_button_clicked',

  // ServiceOverview
  GoToEC2Dashboard = 'aws_ec2_link_clicked',
  GoToRDSDashboard = 'aws_rds_link_clicked',
  EditScrapeJob = 'aws_service_overview_scrape_job_link_clicked',

  // SaaS integrations
  CreateScrapeJobInitiated = `csp_scrape_job_creation_initiated`,
  UpdateScrapeJobInitiated = `csp_scrape_job_update_initiated`,
  CreateOrUpdateScrapeJobSuccessfully = `csp_scrape_job_create_or_update_success`,
  CreateOrUpdateScrapeJobFailed = `csp_scrape_job_create_or_update_failed`,
  CreateOrUpdateScrapeJobPending = `csp_scrape_job_create_or_update_pending`,
  DeleteScrapeJob = `csp_scrape_job_delete_initiated`,

  // Scenes
  AwsPageView = 'aws_scenes_main_page_viewed',
  AwsOverviewPageView = 'aws_scenes_overview_viewed',
  AwsDashboardsPageView = 'aws_scenes_dashboards_viewed',
  AwsDashboardEmbeddedPageView = 'aws_scenes_dashboard_embedded_viewed',
  AwsLogsPageView = 'aws_scenes_logs_viewed',
  AwsServicesPageView = 'aws_scenes_services_viewed',
  AwsConfigurationPageView = 'aws_scenes_configuration_viewed',
  EC2PageView = 'aws_scenes_viewed',
  RDSPageView = 'aws_scenes_rds_viewed',
  TipsDetailsOpened = 'aws_tips_details_opened',
  RDSAdminAwsDocsLinkClicked = 'aws_scenes_rds_admin_aws_docs_clicked',
  ScenesGoToExplore = 'aws_scenes_explore_button_clicked',
  LogsPageView = 'aws_logs_scenes_viewed',
  ChangeTimeRange = 'aws_change_time_range',

  AzurePageView = 'azure_scenes_main_page_viewed',
  AzureOverviewPageView = 'azure_scenes_overview_viewed',
  AzureConfigurationPageView = 'azure_scenes_configuration_viewed',
  AzureLogsPageView = 'azure_scenes_logs_viewed',
  AzureDashboardsPageView = 'azure_scenes_dashboards_viewed',
  AzureDashboardEmbeddedPageView = 'azure_scenes_dashboard_embedded_viewed',
  AzureServicesView = 'azure_scenes_services_viewed',

  GcpPageView = 'gcp_scenes_main_page_viewed',
  GcpOverviewPageView = 'gcp_scenes_overview_viewed',
  GcpConfigurationPageView = 'gcp_scenes_configuration_viewed',
  GcpLogsPageView = 'gcp_scenes_logs_viewed',
  GcpDashboardsPageView = 'gcp_scenes_dashboards_viewed',
  GcpDashboardEmbeddedPageView = 'gcp_scenes_dashboard_embedded_viewed',
  GcpServicesView = 'gcp_scenes_services_viewed',

  // Alloy
  OpenAlloyConfigModalButton = 'alloy_config_modal_opened',
  OpenExtendedMetricsModalButton = 'alloy_extended_metrics_modal_opened',
  SaveConfigSelections = 'alloy_save_config_selections_clicked',
  CopyAlloyInstructionToClipboard = 'alloy_copy_instructions_to_clipboard',
  TestIntegrationConnectionTroubleshootingDocsLinkClick = 'alloy_test_integration_connection_troubleshooting_docs_link_clicked',
  ViewAlloyDashboards = 'alloy_view_dashboards_button_clicked',
  ViewAlloyAlertsAndRecordingRules = 'alloy_view_alerts_and_rules_button_clicked',
}

export enum LabelTypes {
  Installed = 'installed',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum FilterJobStatus {
  All,
  Enabled,
  Disabled,
}

export enum ActionType {
  Edit,
  Add,
}

export enum CollectorMode {
  Alloy = 'alloy',
  AgentStatic = 'static',
}

export enum SetupMode {
  Simple = 'simple',
  Advanced = 'advanced',
}

export enum IntegrationConnectionStatus {
  None,
  Pending,
  Success,
  Error,
  NoData,
}

export type IntegrationConnectionWithoutErrorStatus =
  | IntegrationConnectionStatus.None
  | IntegrationConnectionStatus.Pending
  | IntegrationConnectionStatus.Success;

export type IntegrationConnectionResult =
  | { status: IntegrationConnectionStatus.Error; error: IntegrationConnectionErrorCause; value: boolean }
  | { status: IntegrationConnectionStatus.NoData; error: IntegrationConnectionErrorCause; value: boolean }
  | { status: IntegrationConnectionWithoutErrorStatus; value: boolean };

export enum IntegrationConnectionErrorCause {
  NoMetricsFound,
  AgentCannotScrapeMetrics,
  NoLogsFound,
  Unexpected,
}
