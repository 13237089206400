import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { ModalWithFooter } from '../../ModalWithFooter/ModalWithFooter';
import { ButtonProps, useStyles2 } from '@grafana/ui';
import { FormOutput, OutputMetrics } from 'feature/AWS/components/SaasIntegrations/types';
import { CustomNamespaceApiResponse, ServiceInfoApiResponse, Services } from 'api/hosted-exporters-api/data-models';
import {
  areTagsValid,
  hasDefaultValues,
  initOutputMetricsArray,
  isValidStatistics,
  reset,
} from '../../CloudWatchUtils';

import { EditServiceMetrics } from './EditServiceMetrics';
import { EditCustomNamespacesMetrics } from './EditCustomNamespacesMetrics';

const getStyles = () => ({
  modal: css`
    max-width: 1400px;
    max-height: 95%;
    top: 5%;
    width: 75vw;
  `,
});

type EditServiceModalProps = {
  isOpen: boolean;
  index: string;
  service: FormOutput;
  serviceConfiguration: FormOutput[];
  defaultServices: ServiceInfoApiResponse[];
  defaultCustomNamespace: CustomNamespaceApiResponse;
  supportedServices?: Services;
  onSave: (serviceSettings: FormOutput, id: string) => void;
  onDismiss: () => void;
};

const getButtons = (
  isSaveDisabled: boolean,
  invalidSaveMessage: string | undefined,
  enableReset: boolean,
  onSave: MouseEventHandler<HTMLButtonElement>,
  onReset: MouseEventHandler<HTMLButtonElement>,
  onDismiss: MouseEventHandler<HTMLButtonElement>
): ButtonProps[] => {
  const buttons: ButtonProps[] = [
    {
      disabled: isSaveDisabled,
      name: 'Save service settings',
      onClick: onSave,
      fill: 'solid',
      variant: 'primary',
      tooltip: invalidSaveMessage,
    },
  ];

  if (enableReset) {
    buttons.push({
      disabled: false,
      name: 'Reset to defaults',
      onClick: onReset,
      fill: 'solid',
      variant: 'secondary',
      tooltip: undefined,
    });
  }
  buttons.push({
    name: 'Cancel',
    variant: 'secondary',
    fill: 'solid',
    onClick: onDismiss,
    disabled: false,
    tooltip: undefined,
  });

  return buttons;
};

export const EditServiceModal = ({
  isOpen = false,
  index,
  service,
  serviceConfiguration,
  defaultServices,
  defaultCustomNamespace,
  supportedServices,
  onDismiss,
  onSave,
}: EditServiceModalProps) => {
  const styles = useStyles2(getStyles);
  const [serviceMetrics, setServiceMetrics] = useState<FormOutput>(service);
  const [hasMetricsChanged, setMetricsChanged] = useState<boolean>(false);
  const [outputMetricsArray, setOutputMetricsArray] = useState<OutputMetrics[]>(initOutputMetricsArray(service));

  const serviceInfoApi = defaultServices.find((defaultService) => defaultService.service_id === service.service_id);

  const currentServiceDefaultCloudwatchConfig = Object.values(supportedServices ?? {}).find(
    (service) => service.display_name === serviceInfoApi?.display_name
  );

  const tagsValid = areTagsValid(serviceMetrics);

  let areMetricsInvalid = undefined;
  if (!hasMetricsChanged) {
    areMetricsInvalid = 'No changes made';
  } else if (outputMetricsArray.every((metric) => !metric.isChecked)) {
    areMetricsInvalid = 'At least one metric is required';
  } else if (Object.keys(serviceMetrics.metrics).some((metric) => !isValidStatistics(serviceMetrics, metric))) {
    areMetricsInvalid = 'A selected metric is missing a statistic';
  }

  const hasServiceDefaultConfig = hasDefaultValues(serviceMetrics, supportedServices);

  const buttons = getButtons(
    Boolean(areMetricsInvalid) || !tagsValid,
    Boolean(areMetricsInvalid) ? areMetricsInvalid : !tagsValid ? 'Invalid tags' : '',
    currentServiceDefaultCloudwatchConfig !== undefined && !hasServiceDefaultConfig,
    () => onSave(serviceMetrics, index),
    () => onReset(serviceMetrics),
    onDismiss
  );

  const onReset = (serviceSettings: FormOutput) => {
    const updatedService = reset(cloneDeep(serviceSettings), supportedServices);

    if (updatedService.isNew) {
      updatedService.isNew = false;
    }
    setOutputMetricsArray(initOutputMetricsArray(updatedService));
    setServiceMetrics(updatedService);
    setMetricsChanged(true);
  };

  const onDataChanged = useCallback((data: FormOutput) => {
    if (data.isNew) {
      data.isNew = false;
    }
    setOutputMetricsArray(initOutputMetricsArray(data));
    setServiceMetrics(data);
    setMetricsChanged(true);
  }, []);

  return (
    <ModalWithFooter
      title={`Edit ${service.service_id} metrics and statistics`}
      isOpen={isOpen}
      onDismiss={onDismiss}
      buttons={buttons}
      className={styles.modal}
    >
      {!serviceMetrics.isCustomNamespace ? (
        <EditServiceMetrics
          index={index}
          service={serviceMetrics}
          serviceConfiguration={serviceConfiguration}
          defaultServices={defaultServices}
          onDataChanged={onDataChanged}
          supportedServices={supportedServices}
        />
      ) : (
        <EditCustomNamespacesMetrics
          index={index}
          service={serviceMetrics}
          defaultCustomNamespace={defaultCustomNamespace}
          onDataChanged={onDataChanged}
        />
      )}
    </ModalWithFooter>
  );
};
