import { DashboardDb } from 'api/dashboards/data-model';
import { RudderStackEvents } from 'enums';

export enum LogsMethod {
  FIREHOSE = 'firehose',
  LAMBDA = 'lambda',
  ALB = 'alb',
}

export type ScenesCustomParams = {
  prometheusName: string;
  lokiName: string;
  dashboard?: DashboardDb;
  showServices?: {
    aws: boolean;
    azure: boolean;
    gcp: boolean;
  };
  from?: string;
  to?: string;
};

export enum ScenePageType {
  AWS = 'aws',
  AWS_OVERVIEW = 'aws-overview',
  AWS_LOGS = 'aws-logs',
  AWS_DASHBOARDS = 'aws-dashboards',
  AWS_DASHBOARD_EMBEDDED = 'aws-dashboard-embedded',
  EC2 = 'ec2',
  RDS = 'rds',
  AWS_CONFIGURATION = 'aws-configuration',
  AWS_SERVICES = 'aws-services',
  UNKNOWN = 'unknown',
  GCP = 'gcp',
  GCP_OVERVIEW = 'gcp-overview',
  GCP_CONFIGURATION = 'gcp-configuration',
  GCP_LOGS = 'gcp-logs',
  GCP_DASHBOARDS = 'gcp-dashboards',
  GCP_SERVICES = 'gcp-services',
  GCP_DASHBOARD_EMBEDDED = 'gcp-dashboard-embedded',
  AZURE = 'azure',
  AZURE_OVERVIEW = 'azure-overview',
  AZURE_CONFIGURATION = 'azure-configuration',
  AZURE_LOGS = 'azure-logs',
  AZURE_DASHBOARDS = 'azure-dashboards',
  AZURE_DASHBOARD_EMBEDDED = 'azure-dashboard-embedded',
  AZURE_SERVICES = 'azure-services',
}

export const EventTrackByScenePageType = {
  [ScenePageType.AWS]: RudderStackEvents.AwsPageView,
  [ScenePageType.AWS_OVERVIEW]: RudderStackEvents.AwsOverviewPageView,
  [ScenePageType.AWS_DASHBOARDS]: RudderStackEvents.AwsDashboardsPageView,
  [ScenePageType.AWS_DASHBOARD_EMBEDDED]: RudderStackEvents.AwsDashboardEmbeddedPageView,
  [ScenePageType.AWS_SERVICES]: RudderStackEvents.AwsServicesPageView,
  [ScenePageType.AWS_CONFIGURATION]: RudderStackEvents.AwsConfigurationPageView,
  [ScenePageType.AWS_LOGS]: RudderStackEvents.AwsLogsPageView,
  [ScenePageType.EC2]: RudderStackEvents.EC2PageView,
  [ScenePageType.RDS]: RudderStackEvents.RDSPageView,
  [ScenePageType.GCP]: RudderStackEvents.GcpPageView,
  [ScenePageType.GCP_OVERVIEW]: RudderStackEvents.GcpOverviewPageView,
  [ScenePageType.GCP_CONFIGURATION]: RudderStackEvents.GcpConfigurationPageView,
  [ScenePageType.GCP_LOGS]: RudderStackEvents.GcpLogsPageView,
  [ScenePageType.GCP_DASHBOARDS]: RudderStackEvents.GcpDashboardsPageView,
  [ScenePageType.GCP_DASHBOARD_EMBEDDED]: RudderStackEvents.GcpDashboardEmbeddedPageView,
  [ScenePageType.GCP_SERVICES]: RudderStackEvents.GcpServicesView,
  [ScenePageType.AZURE]: RudderStackEvents.AzurePageView,
  [ScenePageType.AZURE_OVERVIEW]: RudderStackEvents.AzureOverviewPageView,
  [ScenePageType.AZURE_CONFIGURATION]: RudderStackEvents.AzureConfigurationPageView,
  [ScenePageType.AZURE_LOGS]: RudderStackEvents.AzureLogsPageView,
  [ScenePageType.AZURE_DASHBOARDS]: RudderStackEvents.AzureDashboardsPageView,
  [ScenePageType.AZURE_DASHBOARD_EMBEDDED]: RudderStackEvents.AzureDashboardEmbeddedPageView,
  [ScenePageType.AZURE_SERVICES]: RudderStackEvents.AzureServicesView,
  [ScenePageType.UNKNOWN]: '',
};
