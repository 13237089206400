import React, { ReactElement, useMemo } from 'react';
import { getDataSourceSettings, setPluginStorageProp } from 'scenes/misc';
import { ServiceDashboardExtensionProps } from 'components/Extensions/types';
import { getInstanceDrilldownScene } from 'scenes/AWS/Dashboards/RDS/getInstanceDrilldownScene.ts';
import { getOverviewDrilldownScene } from 'scenes/AWS/Dashboards/EC2/getOverviewDrilldownScene';

export function ServiceDashboardExtension({
  serviceId,
  metadata,
  promDatasource,
  lokiDatasource,
  initialTimeRange,
  onTimeRangeChange,
}: ServiceDashboardExtensionProps): ReactElement | null {
  if (promDatasource) {
    setPluginStorageProp('promName', promDatasource);
  }
  if (lokiDatasource) {
    setPluginStorageProp('lokiName', lokiDatasource);
  }

  const scene = useMemo(() => {
    const promName = !!promDatasource ? promDatasource : getDataSourceSettings('prometheus').uid ?? 'grafanacloud-prom';

    switch (serviceId) {
      case 'AWS/EC2':
        return !!metadata.instanceId
          ? getOverviewDrilldownScene(
              metadata.instanceId,
              promName,
              initialTimeRange?.raw?.from.toString(),
              initialTimeRange?.raw?.to.toString(),
              true,
              onTimeRangeChange
            )
          : null;

      case 'AWS/RDS':
        return !!metadata.instanceId
          ? getInstanceDrilldownScene(
              metadata.instanceId,
              promName,
              initialTimeRange?.raw?.from?.toString(),
              initialTimeRange?.raw?.to.toString(),
              true,
              onTimeRangeChange
            )
          : null;

      default:
        return null;
    }
    // excluding initialTimeRange since we use it only for initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, metadata, promDatasource, onTimeRangeChange]);

  if (scene) {
    return <scene.Component model={scene} />;
  }
  return null;
}
