import _ from 'lodash';

import { ServiceConfigurationApi, ServiceInfoApiResponse, Services } from 'api/hosted-exporters-api/data-models';

import { DEFAULT_SERVICES_SCRAPE_INTERVAL_SECONDS } from 'utils/consts';
import { ActionType } from 'enums';
import { FormOutput } from './types';

export const mapServices = (
  services: Services,
  actionType: ActionType,
  currentJobServiceConfiguration?: ServiceConfigurationApi[]
): ServiceInfoApiResponse[] => {
  return Object.entries(services).map(([id, service]) => {
    const currentServiceConfiguration = currentJobServiceConfiguration?.find((serv) => serv.name === id);
    const available_metrics = [
      ...service.available_metrics.map((metric) => {
        const currentMetric = currentServiceConfiguration?.metrics?.find((metr) => metr.name === metric.metric.name);

        const default_statistics =
          actionType === ActionType.Add
            ? metric.default_statistics ?? []
            : currentMetric
              ? currentMetric?.statistics
              : metric.default_statistics ?? [];

        const isChecked =
          actionType === ActionType.Add
            ? metric.is_default ?? false
            : currentServiceConfiguration
              ? currentMetric !== undefined
              : metric.is_default ?? false;

        return {
          metric: metric.metric,
          is_default: metric.is_default,
          default_statistics,
          isChecked,
        };
      }),
    ].sort((a, b) => a.metric.name.localeCompare(b.metric.name));

    const available_scrape_intervals =
      actionType === ActionType.Edit
        ? service.available_scrape_intervals.map((interval) => {
            if (_.isNil(currentServiceConfiguration)) {
              return interval;
            }
            return {
              interval_seconds: interval.interval_seconds,
              ...(interval.interval_seconds === currentServiceConfiguration?.scrape_interval_seconds && {
                is_default: true,
              }),
            };
          })
        : service.available_scrape_intervals;

    const tags_to_add_to_metrics = service.tags_to_add_to_metrics;

    return {
      service_id: id,
      display_name: service.display_name,
      available_scrape_intervals,
      available_metrics,
      tags_to_add_to_metrics,
      has_resources: service.has_resources,
    };
  });
};

export const transformServicesForApi = (jobServices: FormOutput[] = []): ServiceConfigurationApi[] => {
  return jobServices.map((job: FormOutput) => {
    return {
      name: job.service_id,
      scrape_interval_seconds: job.scrape_interval ?? DEFAULT_SERVICES_SCRAPE_INTERVAL_SECONDS,
      metrics: Object.entries(job?.metrics || {})
        .filter((metric: [string, { isChecked: boolean; statistics: string[] }]) => metric[1].isChecked)
        .map((metric: [string, { isChecked: boolean; statistics: string[] }]) => {
          return {
            name: metric[0],
            statistics: metric[1].statistics,
          };
        }),
      tags_to_add_to_metrics: (job.tags ?? []).filter((tag) => tag.addToMetrics).map((tag) => tag.name),
      resource_discovery_tag_filters: (job.tags ?? [])
        .filter((tag) => tag.useAsFilter)
        .map((tag) => ({ key: tag.name, value: tag.value })),
      is_custom_namespace: job.isCustomNamespace,
    };
  });
};
