import React, { useContext, useEffect } from 'react';

import { Alert, Button, Icon, Modal, useStyles2 } from '@grafana/ui';

import { getUpdateStyles } from './Update.styles';
import { Source } from 'api/int-api/data-models';
import { useUpgradeIntegration } from 'api/int-api/queries';
import { QueryError } from 'components/QueryError/QueryError';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

export const Update = ({
  selectedIntegration,
  displayModal,
  closeUninstallModal,
}: {
  selectedIntegration: Source;
  displayModal: boolean;
  closeUninstallModal: () => void;
}) => {
  const styles = useStyles2((theme) => getUpdateStyles(theme));
  const { pluginId, jsonData } = useContext(PluginMetaContext);

  const {
    mutate: updateIntegration,
    isPending,
    isError,
    isSuccess,
    error,
  } = useUpgradeIntegration(selectedIntegration.id, pluginId, jsonData.grafana_instance_id);

  const { trackRudderStackEvent } = useRudderStack();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isSuccess) {
        closeUninstallModal();
      }
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess, closeUninstallModal]);

  return (
    <>
      <Modal
        className={styles.overrideModal}
        title="Update"
        isOpen={displayModal}
        onDismiss={() => closeUninstallModal()}
      >
        <div className={styles.modalContent}>
          <p>
            We are constantly pushing updates to our integrations to add new default content and improve performance.
          </p>
          <div className={styles.alertText}>
            <Icon className={styles.icon} name="info-circle" size="lg" />
            <span>There may be downstream effects of updating this:</span>
          </div>
          <p className={styles.text}>
            Updating will overwrite any custom changes that have been made directly to the default dashboards and
            alerts. If you wish to keep any custom changes made to the included dashboards and alerts, make a copy of
            these before updating. Otherwise, if you used the data to create your own, separate custom dashboards and
            alerts, there will be no effect.
          </p>
          {isSuccess && (
            <Alert title={``} className={styles.alert} severity="success">
              Successfully updated!
            </Alert>
          )}
          <div className={styles.buttons}>
            <Button
              variant="primary"
              disabled={isPending || isSuccess}
              onClick={() => {
                updateIntegration();
                const trackingData = {
                  integration_slug: selectedIntegration.id,
                  integration_version_old: selectedIntegration.installation?.version,
                  integration_version_new: selectedIntegration.version,
                };
                trackRudderStackEvent(RudderStackEvents.UpdateButton, trackingData);
              }}
            >
              {isPending && <Icon className={styles.icon} name="fa fa-spinner" />}
              Update
            </Button>
            <Button
              variant="secondary"
              disabled={isPending}
              onClick={() => {
                closeUninstallModal();
              }}
            >
              Cancel
            </Button>
          </div>
          {isError !== undefined && error !== null && (
            <QueryError
              message={error.message ?? 'Error while attempting to perform the update.'}
              isFullPageError={false}
            />
          )}
        </div>
      </Modal>
    </>
  );
};
