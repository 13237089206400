import { css } from '@emotion/css';
import React, { FC, useContext, useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, LinkButton, useStyles2 } from '@grafana/ui';

import { Clipboard } from 'components/Clipboard';
import { SelectGroup } from 'components/SelectGroup';
import { TERRAFORM_DOCS_URL } from 'utils/consts';

import { CardElement } from '../../../CardElement';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { useCloudwatchConfig } from 'api/hosted-exporters-api/queries';

enum Methods {
  CloudFormation = 'cf',
  Terraform = 'tf',
}

const methodOptions = [
  {
    label: 'Use CloudFormation',
    value: Methods.CloudFormation,
  },
  {
    label: 'Use Terraform',
    value: Methods.Terraform,
  },
];

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    font-size: 18px;
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(3)};
  `,
  description: css`
    margin-top: ${theme.spacing(3)};
  `,
  link: css`
    * {
      color: white;
    }
  `,
  instructionItemField: css`
    margin-bottom: 0;
    margin-top: ${theme.spacing(3)};
  `,
});

export const Automatic: FC = ({}) => {
  const styles = useStyles2(getStyles);
  const [method, setMethod] = useState(Methods.CloudFormation);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: cloudWatchConfig } = useCloudwatchConfig(pluginId, jsonData.grafana_instance_id);

  const MethodComponent = useMemo(() => {
    switch (method) {
      case Methods.CloudFormation: {
        return (
          <>
            <p className={styles.description}>
              AWS CloudFormation guides you through setting up a new IAM role. Follow the steps and paste the output
              into the corresponding field below.
            </p>
            <LinkButton
              className={styles.link}
              variant="primary"
              target="_blank"
              icon="external-link-alt"
              href={cloudWatchConfig?.cloudformation_url}
            >
              Launch stack
            </LinkButton>
          </>
        );
      }
      case Methods.Terraform: {
        return (
          <>
            <p className={styles.description}>
              See our documentation and learn how to use Terraform to create an AWS role.
            </p>
            <LinkButton
              className={styles.link}
              variant="primary"
              target="_blank"
              icon="external-link-alt"
              href={TERRAFORM_DOCS_URL}
            >
              See docs
            </LinkButton>

            <Field
              label="External ID"
              description="This value is a required input variable when using terraform. An External ID is used by AWS to provide an extra layer of security when giving Grafana access to pull your CloudWatch metrics in to Grafana Cloud"
              className={styles.instructionItemField}
            >
              <Clipboard code={cloudWatchConfig?.external_id ?? ''} />
            </Field>
          </>
        );
      }
      default: {
        return null;
      }
    }
  }, [
    method,
    cloudWatchConfig?.cloudformation_url,
    styles.description,
    styles.link,
    styles.instructionItemField,
    cloudWatchConfig?.external_id,
  ]);

  return (
    <>
      <h2 className={styles.title}>Choose a method for creating the AWS role</h2>
      <div>
        <SelectGroup options={methodOptions} value={method} onChange={setMethod} CardElementOverride={CardElement} />
        {MethodComponent}
      </div>
    </>
  );
};
