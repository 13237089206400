import { PlanType } from 'enums';

export enum ROUTES {
  Configuration = 'configuration',
  Dashboards = 'dashboards',
  Logs = 'logs',
  OVERVIEW = 'overview',
  SERVICES = 'services',
}

export const colors = {
  black: '#000000',
  blue01: '#d5e5fF',
  blue02: '#d5e5fe',
  blue03: '#538ade',
  blue04: '#343b40',
  blue05: '#021e40',
  blue06: '#1f60c4',
  blue07: '#245baf',
  blue08: '#001a3e',
  blue09: '#6898e2',
  blue10: '#3274d9',
  blue11: '#6e9fff',
  blue12: 'rgba(50, 116, 217, .15)',
  blue13: '#538CF1',
  blueBg01: '#1D2739',
  gray01: '#e9f4ff',
  gray02: '#345c97',
  gray03: '#cccfd2',
  gray04: '#7f7f82',
  green01: '#99d98d',
  green02: '#5aa64b',
  green03: '#1a7f4b',
  red01: '#ff7389',
  red02: '#de314d',
  orange01: '#ffb375',
  orange02: '#ff780a',
  orange03: '#ffb375',
  orange04: '#8A6C00',
  dark01: '#26262a',
  dark02: '#111217',
  dark03: '#424345',
  labelBorderColor01: 'rgba(204, 204, 220, 0.15)',
  labelTextColor01: '#ccccdc',
  labelBorderColor02: 'rgba(108, 207, 142, 0.5)',
  labelTextColor02: '#6ccf8e',
  labelBorderColor03: 'rgba(198, 155, 6, 0.5)',
  labelTextColor03: '#c69b06',
  labelBorderColor04: 'rgba(209, 14, 92, 0.5);',
  labelTextColor04: '#ff5286',
  labelBorderColor05: 'rgb(121, 158, 248)',
  labelTextColor05: '#799df8',

  alertColor: '#ff5286',
  yellow01: '#bb9205',
  filterBorderColor: '#ff8833',
};

export const labelColors = {
  dark: {
    labelBorderColor01: colors.labelBorderColor01,
    labelTextColor01: colors.labelTextColor01,
    labelBorderColor02: colors.labelBorderColor02,
    labelTextColor02: colors.labelTextColor02,
    labelBorderColor03: colors.labelBorderColor03,
    labelTextColor03: colors.labelTextColor03,
    labelBorderColor04: colors.labelBorderColor04,
    labelTextColor04: colors.labelTextColor04,
    labelBorderColor05: colors.labelBorderColor05,
    labelTextColor05: colors.labelTextColor05,
  },
  light: {
    labelBorderColor01: colors.gray04,
    labelTextColor01: colors.gray04,
    labelBorderColor02: colors.green02,
    labelTextColor02: colors.green02,
    labelBorderColor03: colors.yellow01,
    labelTextColor03: colors.yellow01,
    labelBorderColor04: colors.labelBorderColor04,
    labelTextColor04: colors.labelTextColor04,
    labelBorderColor05: colors.labelBorderColor05,
    labelTextColor05: colors.labelTextColor05,
  },
};

const GRAFANA_SUPPORT_URL = 'https://grafana.com/profile/org#support';
const GRAFANA_PRICING_URL = 'https://grafana.com/products/cloud/pricing/';
export const errorIntegrationApiResponse = {
  dashboard: 'unable to import dashboards: dashboard quota reached',
  alert: 'unable to apply alerting rules: could not create rule group: rule groups per tenant limit reached',
  record: 'unable to apply recording rules: could not create rule group: rule groups per tenant limit reached',
};

const createLink = (href: string, innerText: string) =>
  `<a target="_blank" rel="noreferrer" href="${href}">${innerText}</a>`;

const CONTACT_US = `${createLink(GRAFANA_SUPPORT_URL, 'contact us')}`;
const CONTACT_US_IF_ERROR = `If the error persists, you can ${CONTACT_US}.`;
const TRY_AGAIN = `Please try again. ${CONTACT_US_IF_ERROR}`;

type Text = {
  error: {
    [key: string]: string;
  };
  errorFunctions: {
    [key: string]: (...args: string[]) => string;
  };
};

export const text: Text = {
  error: {
    installIntegration: `Error while attempting to install integrations. \n ${TRY_AGAIN}`,
    uninstallIntegration: `Error while attempting to uninstall an integration. \n ${TRY_AGAIN}`,
    updateIntegration: `Error while attempting to update an integration. \n ${TRY_AGAIN}`,
    saveConfigurationSelection: `Error while attempting to save your updated selections. \n ${TRY_AGAIN}`,
    queryDatasource: `Error querying datasource.\n ${TRY_AGAIN}`,
    generateAgentInstall: `Error while generating agent install instruction.\n ${TRY_AGAIN}`,
    generateAgentConfig: `Error while generating agent config.\n ${TRY_AGAIN}`,
    generateDatasource: `Error while attempting to generate a new datasource.\n ${TRY_AGAIN}`,
    getSourcesList: `Error while fetching integrations. \n ${TRY_AGAIN}`,
    genericError: `An unknown error occurred. \n ${TRY_AGAIN}`,
    unauthorizedError: `Unauthorized. An API key is probably missing. \n ${CONTACT_US_IF_ERROR}`,
    queryDetails: `Error querying integration details.\n ${TRY_AGAIN}`,
  },
  errorFunctions: {
    checkCloudWatchConnection: (region: string) =>
      `Authentication error on region ${region}. Please make sure you are using the correct AWS role with the appropriate permission policy. ${CONTACT_US_IF_ERROR}`,
    deleteJob: (jobName: string) => `Error while deleting scrape job ${jobName}. \n ${TRY_AGAIN}`,
    noDataSourceFound: (message: string) =>
      `Internal error while testing the connection. ${message} \n ${CONTACT_US_IF_ERROR}`,
    updateJobStatus: (jobName: string) => `Error while updating scrape job ${jobName}. \n ${TRY_AGAIN}`,
    installRollbackFailed: (id: string) =>
      `Error while attempting to install the integration. There could still be some dashboards left installed.<br/>If retrying to install the integration does not help, try removing the following dashboards: <a target="_blank" rel="noreferrer" href="/dashboards/f/integration---${id}/integration-${id}">${id}</a>.`,
  },
};

export const constructErrorMessage = (orgSlug?: string): Text => {
  const subscriptionUrl = orgSlug ? `https://grafana.com/orgs/${orgSlug}/subscription` : GRAFANA_PRICING_URL;

  return {
    error: {
      dashboard: `You've already reached your included dashboard limit of 10 on the free plan and cannot install this integration. \nYou must delete an existing dashboard or <a target="_blank" rel="noreferrer" href="${subscriptionUrl}">upgrade</a> to Grafana Cloud Pro in order to continue.`,
      alert: `You've already reached the alerting rule group limit and cannot install this integration. \nYou must delete existing rule groups in order to continue, or please <a target="_blank" rel="noreferrer" href="${GRAFANA_SUPPORT_URL}">create a support ticket</a> to increase your limit if you still need help.`,
      record: `You've already reached the recording rule group limit and cannot install this integration. \nYou must delete existing rule groups in order to continue, or please <a target="_blank" rel="noreferrer" href="${GRAFANA_SUPPORT_URL}">create a support ticket</a> to increase your limit if you still need help.`,
    },
    errorFunctions: {},
  };
};

export const AWS_IAM_URL = 'https://console.aws.amazon.com/iam/home';

const AWS_TAGGING_DOCS_URL = 'https://docs.aws.amazon.com/general/latest/gr/aws_tagging.html';
const AWS_MANAGE_REGIONS_URL = 'https://docs.aws.amazon.com/general/latest/gr/rande-manage.html';
const AWS_SCRAPE_JOB_RESOURCE_LIMIT_URL =
  'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/cloudwatch-metrics/job-resource-limit/';

export enum CloudWatchErrorCodes {
  NoTaggedResources = 'aws_no_tagged_resources',
  ResourcesPerJobLimitExceeded = 'aws_too_many_resources',
  JobLimitExceeded = 'job_limit_exceeded',
  UnknownError = 'unknown_error',
}

export const cloudwatchText = {
  errors: {
    [CloudWatchErrorCodes.NoTaggedResources]: `We found no tagged resources. Please make sure to ${createLink(
      AWS_TAGGING_DOCS_URL,
      'add tags to your AWS resources'
    )} and select the appropriate services and regions. If the issue persists, ${CONTACT_US}.`,
    [CloudWatchErrorCodes.ResourcesPerJobLimitExceeded]: `You reached the resource limit for a single scrape job. To continue, create multiple jobs for different regions. Read more about this in our ${createLink(
      AWS_SCRAPE_JOB_RESOURCE_LIMIT_URL,
      'CloudWatch metrics documentation.'
    )}`,
    [CloudWatchErrorCodes.JobLimitExceeded]: `You reached the limit for the number of scrape jobs. To increase the limit, ${CONTACT_US}.`,
    [CloudWatchErrorCodes.UnknownError]: `An unknown error occurred. If the issue persists, ${CONTACT_US}.`,
  },
  errorFunctions: {
    regionDisabled: (errorMessage: string) =>
      `Error accessing region: ${errorMessage}. Please make sure to ${createLink(
        AWS_MANAGE_REGIONS_URL,
        'enable regions'
      )}. If the issue persists, ${CONTACT_US}.`,
  },
};

export const TERRAFORM_DOCS_URL =
  'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/integration-reference/integration-cloudwatch/#setup-aws-connection-using-terraform';

export enum FormErrors {
  REQUIRED_FIELD = 'This field is required',
  JOB_NAME_CHARACTERS = 'Scrape job name can only contain alphanumeric characters, dashes, and underscores.',
  SCRAPE_JOB_NAME_EXISTS = 'A scrape job with this name already exists. Please choose a unique name.',
}

export const DISABLED_REASONS = {
  disabled_by_user: {
    title: 'User disabled access',
    description: 'This scrape job was disabled by the user.',
  },
  credentials_revoked: {
    title: 'Credentials revoked',
    description: 'This scrape job was disabled because credentials have been deleted.',
  },
  over_series_limit: {
    title: 'Over series limit',
    description: 'This scrape job was disabled because the series limit has been reached.',
  },
};

export const DEFAULT_SERVICES_SCRAPE_INTERVAL_SECONDS = 300;

export const HOSTED_DATA_DETAILS_FALLBACK = {
  orgSlug: '',
  orgId: 0,
  slug: '',
  id: 0,
  trial: 0,
  hmInstancePromId: 0,
  hlInstanceId: 0,
  hmInstanceGraphiteId: 0,
  htInstanceId: 0,
  hpInstanceId: 0,
  plan: PlanType.Gcloud,
  trialExpiresAt: '',
  hlInstanceUrl: '',
  hmInstanceGraphiteUrl: '',
  hmInstancePromUrl: '',
  htInstanceUrl: '',
  hpInstanceUrl: '',
  hmInstanceGraphiteType: '',
  regionSlug: '',
  url: 'https://example.grafana.net',
};

export const LOGS_DATA_SOURCE_UID = 'grafanacloud-logs';

export const ALLOY_HOSTNAME_RELABEL_KEY = 'constants.hostname';
export const HOSTNAME_RELABEL_KEY = '<your-instance-name>';

export const CHECK_TIMEOUT_MS = 60 * 1000;
export const CHECK_INTERVAL_MS = 5 * 1000;
export const INSTALL_TROUBLESHOOTING_DOCS_LINK =
  'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/integrations/install-troubleshoot';
