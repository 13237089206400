import React, { useContext, useEffect } from 'react';

import { Alert, Button, Icon, Modal, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Source } from 'api/int-api/data-models';
import { useUninstallIntegrations } from 'api/int-api/queries';
import { QueryError } from 'components/QueryError/QueryError';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { Pages } from 'e2eSelectors/pages';
import { CloudProvider } from 'types/CloudProvider';

const getUninstallStyles = (theme: GrafanaTheme2) => ({
  overrideModal: css`
    max-width: 600px;
    padding: 6px;
  `,
  modalContent: css`
    h3 {
      margin-bottom: ${theme.spacing(4)};
      padding-top: ${theme.spacing(2)};
    }
    button {
      margin-right: ${theme.spacing(1)};
    }
  `,
  icon: css`
    margin-right: 10px;
  `,
  text: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    color: #ccccdc;
    padding-left: 30px;
    margin-top: 15px;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  `,
  marginTop: css`
    margin-top: ${theme.spacing(2)};
    margin-bottom: 0;
  `,
  field: css`
    margin: ${theme.spacing(4)} ${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)};
  `,
});

export const UninstallModal = ({
  isOpen = false,
  selectedIntegration,
  onClose,
}: {
  isOpen: boolean;
  selectedIntegration: Source;
  onClose?: () => void;
}) => {
  const styles = useStyles2(getUninstallStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);

  const {
    mutate: uninstallFunction,
    isError,
    isSuccess,
    isPending,
    reset: resetUninstallation,
  } = useUninstallIntegrations(
    !!selectedIntegration ? [selectedIntegration.id as CloudProvider] : [],
    pluginId,
    jsonData.grafana_instance_id
  );

  const { trackRudderStackEvent } = useRudderStack();

  const onDisableModal = () => {
    onClose?.();
    trackRudderStackEvent(RudderStackEvents.AbandonUninstall, {
      integration_slug: selectedIntegration.id,
    });
  };

  useEffect(() => {
    if (!!isSuccess) {
      onClose?.();
      resetUninstallation();
    }
  }, [isSuccess, onClose, resetUninstallation]);

  return (
    <Modal
      className={styles.overrideModal}
      title={`Uninstall ${selectedIntegration.name}`}
      isOpen={isOpen}
      onDismiss={onDisableModal}
    >
      <div className={styles.modalContent}>
        <Alert severity="error" title="">
          Uninstalling this deletes its dashboard folder and alert rule namespace, as well as associated custom
          dashboards and alerts.
        </Alert>
        {isError && <QueryError message="Error while attempting to perform the uninstall." isFullPageError={false} />}
        <div className={styles.buttons}>
          <Button variant="secondary" disabled={isPending} onClick={onDisableModal}>
            Cancel
          </Button>
          <Button
            data-testid={Pages.Source.removeIntegrationModalButton}
            variant="destructive"
            disabled={isPending}
            onClick={() => {
              uninstallFunction();
              trackRudderStackEvent(RudderStackEvents.RemoveIntegration, {
                integration_slug: selectedIntegration.id,
              });
            }}
          >
            {isPending && <Icon className={styles.icon} name="fa fa-spinner" />}
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
};
