import React, { MouseEventHandler, useMemo, useState } from 'react';

import { ButtonProps, useStyles2 } from '@grafana/ui';
import { FormOutput } from 'feature/AWS/components/SaasIntegrations/types';
import { ServiceInfoApiResponse } from 'api/hosted-exporters-api/data-models';
import { ModalWithFooter } from '../../ModalWithFooter/ModalWithFooter';
import { SelectService } from '../SelectServices/SelectService';
import { css } from '@emotion/css';
import { AddCustomNamespaces } from '../CustomNamespaces/AddCustomNamespaces';

const getStyles = () => ({
  modal: css`
    max-height: 90vh;
    top: 5%;
  `,
});

const getButtons = (
  onSave: MouseEventHandler<HTMLButtonElement>,
  onDismiss: MouseEventHandler<HTMLButtonElement>
): ButtonProps[] => [
  {
    name: 'Save',
    onClick: onSave,
    fill: 'solid',
    variant: 'primary',
  },
  {
    name: 'Cancel',
    variant: 'secondary',
    fill: 'solid',
    onClick: onDismiss,
  },
];

type AddServicesModalProps = {
  isOpen: boolean;
  servicesSelected: FormOutput[];
  defaultServices: ServiceInfoApiResponse[];
  onSave: (services: string[]) => void;
  onDismiss: () => void;
};

const getServicesNotAdded = (
  servicesSelected: FormOutput[],
  defaultServices: ServiceInfoApiResponse[]
): ServiceInfoApiResponse[] => {
  return defaultServices.filter((defaultService) => {
    return (
      servicesSelected.findIndex((serviceSelected) => serviceSelected.service_id === defaultService.service_id) < 0
    );
  });
};

export const AddServicesModal = ({
  isOpen = false,
  servicesSelected,
  defaultServices,
  onDismiss,
  onSave,
}: AddServicesModalProps) => {
  const styles = useStyles2(getStyles);
  const [selected, setSelected] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);

  const servicesNotAdded = useMemo(
    () => getServicesNotAdded(servicesSelected, defaultServices),
    [servicesSelected, defaultServices]
  );

  const buttons = getButtons(() => onSave(selected.concat(namespaces)), onDismiss);

  const existingServices = defaultServices
    .map((el) => [el.service_id, el.display_name ?? ''])
    .flat()
    .filter((el) => el !== '')
    .concat(servicesSelected.map((el) => el.service_id));

  return (
    <ModalWithFooter
      title={`Add new services`}
      isOpen={isOpen}
      onDismiss={onDismiss}
      buttons={buttons}
      className={styles.modal}
    >
      <h3>Choose service(s)</h3>
      <p>Select which services you want to scrape. You will configure default settings for each selected service.</p>
      <SelectService defaultServices={servicesNotAdded} selectedServices={selected} onSelected={setSelected} />
      <AddCustomNamespaces customNamespaces={namespaces} onChange={setNamespaces} defaultServices={existingServices} />
    </ModalWithFooter>
  );
};
