import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  link: css`
    color: ${theme.colors.text.link};
  `,
});

export const getOverviewDrilldownSubtitle = (id: string, region: string | null) => {
  if (region === null || region === '') {
    return '';
  }

  return <DrilldownSubtitle id={id} region={region} />;
};

const DrilldownSubtitle = ({ id, region }: { id: string; region: string }) => {
  const styles = useStyles2(getStyles);
  const cloudwatchLink = `https://${region}.console.aws.amazon.com/ec2/home?region=${region}#InstanceDetails:instanceId=${id}`;

  return (
    <a
      className={styles.link}
      href={cloudwatchLink}
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        // TODO: add rudderstack
      }}
    >
      Visit {id} instance <Icon name="external-link-alt" />
    </a>
  );
};
