import { css, cx } from '@emotion/css';
import { GrafanaTheme2, RawTimeRange, SelectableValue } from '@grafana/data';
import { Button, Icon, LinkButton, Pagination, Select, Spinner, Tooltip, useStyles2 } from '@grafana/ui';
import useRudderStack from 'hooks/useRudderstack';
import React, { useContext, useEffect, useState } from 'react';
import { RudderStackEvents } from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import {
  createExploreLink,
  filterServiceOverviewOptions,
  getQuery,
  getServiceOverviewOptions,
  getTotalNumberOfPages,
  INITIAL_SERVICE_OVERVIEW_SELECT_VALUE,
  paginate,
  PAGINATION_PAGE_SIZES,
  useGetServicesForOverviewTable,
} from 'feature/common/utils/utils';
import { Pages } from 'e2eSelectors/pages';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'utils/consts';
import { EllipsisText } from 'components/EllipsisText';
import { ServiceOverviewSelectFilter } from './ServiceOverviewSelectFilter';
import { ServiceOverviewEmptyList } from './ServiceOverviewEmptyList';
import { QueryError } from 'components/QueryError/QueryError';
import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';
import { getDashboardsUrlByProvider, getDataSourceSettings, getPluginStorageProp, isAWSPage } from 'scenes/misc';
import { ScrapeJobLink } from 'feature/AWS/components/ScrapeJobLink/ScrapeJobLink';
import { HomepageTableRow } from 'feature/common/types/types';
import { isUserAdmin } from 'utils/user';
import { useGetDashboardByUid } from 'api/dashboards/queries';
import { Alert } from 'components/Alert';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: block;
    width: 100%;
  `,
  table: css`
    width: 100%;
    margin-bottom: ${theme.spacing(4)};

    li > ul {
      padding-left: ${theme.spacing(2)};
    }

    td,
    th {
      padding: ${theme.spacing(1.5)};
    }

    tr {
      border-bottom: 1px solid ${theme.colors.border.medium};
    }
  `,
  anchor: css`
    color: ${theme.colors.primary.text};
    text-decoration: underline;
  `,
  linkText: css`
    span {
      color: ${theme.colors.primary.text};
      text-decoration: underline;
    }

    &:hover {
      background-color: unset;
    }
  `,
  marginRight: css`
    margin-right: ${theme.spacing(0.5)};
  `,
  pageSize: css`
    margin-right: ${theme.spacing(1)};
  `,
  paginationWrapper: css`
    display: flex;
    margin: ${theme.spacing(2)} 0 ${theme.spacing(1)};
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
  `,
  actions: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: ${theme.spacing(2)};

    @media (max-width: 860px) {
      flex-direction: column-reverse;
    }
  `,
  filters: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(2)};

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 0;
    }
    @media (max-width: 860px) {
      justify-content: flex-start;
      width: 100%;
    }
  `,
  buttons: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (max-width: 860px) {
      margin-top: ${theme.spacing(3)};
      justify-content: flex-end;
      width: 100%;
    }
  `,
  servicesColumn: css`
    width: 30%;
  `,
  jobColumn: css`
    width: 20%;
  `,
  dashboardsColumn: css`
    width: 20%;
    display: inline-flex;
  `,
  dashboardWrapper: css`
    display: flex;
    flex-direction: column;

    [data-testid='alert'] {
      margin-bottom: 0;
    }
  `,
  iconColumn: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.success.text};
    gap: ${theme.spacing(1)};
  `,
});

export const ServiceOverview = ({ provider }: { provider: CloudProvider }) => {
  const styles = useStyles2(getStyles);
  const { push } = useHistory();
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const {
    data: dataForTable,
    areScrapeJobsDisabled,
    isLoading,
    error,
  } = useGetServicesForOverviewTable(pluginId, jsonData.grafana_instance_id, provider);

  const { trackRudderStackEvent } = useRudderStack();

  const [currentPage, setCurrentPage] = useState(1);
  const [servicesPerPage, setServicesPerPage] = useState(PAGINATION_PAGE_SIZES[0].value);

  const [sourceSelectValue, setSourceSelectvalue] = useState(INITIAL_SERVICE_OVERVIEW_SELECT_VALUE);
  const scrapeJobOptions = getServiceOverviewOptions(dataForTable, 'source');

  const [serviceSelectValue, setServiceSelectValue] = useState(INITIAL_SERVICE_OVERVIEW_SELECT_VALUE);
  const serviceOptions = getServiceOverviewOptions(dataForTable, 'service');

  const [filteredDataForTable, setFilteredDataForTable] = useState<HomepageTableRow[]>([]);

  const servicesToShow = paginate(filteredDataForTable, servicesPerPage, currentPage);
  const totalNumberOfPages = getTotalNumberOfPages(filteredDataForTable, servicesPerPage);
  const showPagination = filteredDataForTable.length > PAGINATION_PAGE_SIZES[0].value;

  const isAnyServiceSendingData = servicesToShow.some((row) => row.currently_sending_data);
  const doesAnyServiceHaveJobName = servicesToShow.some((row) => row.job_name);

  const [dashboardUID, setDashboardUID] = useState<string>('');
  const { data: dashboard, isLoading: isDashboardLoading, error: dashboardError } = useGetDashboardByUid(dashboardUID);

  useEffect(() => {
    setCurrentPage(1);
  }, [servicesPerPage]);

  useEffect(() => {
    if (!!dashboard) {
      const eventName = `${provider}_service_dashboard_link_clicked`;
      trackRudderStackEvent(eventName, { page: 'Service Overview', dashboard: dashboardUID });
      push(`${getDashboardsUrlByProvider(provider)}/${dashboard.meta.folderUid}/${dashboard.dashboard.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard]);

  useEffect(() => {
    const filteredData = filterServiceOverviewOptions(dataForTable, [
      { name: 'source', value: sourceSelectValue },
      { name: 'service', value: serviceSelectValue },
    ]);
    setFilteredDataForTable(filteredData);
    setCurrentPage(1);
  }, [sourceSelectValue, serviceSelectValue, dataForTable]);

  function openDashboard(row: HomepageTableRow) {
    if (row?.dashboard) {
      if (row.dashboard.target === '_self') {
        push(row.dashboard.url);
      }
      trackRudderStackEvent(row.dashboard.trackId ?? RudderStackEvents.ViewDashboards, {
        page: 'homepage',
        service: row.service,
      });
    } else if (!!row.dashboard_uid) {
      setDashboardUID(row.dashboard_uid);
    }
  }
  const onClearFilters = () => {
    setServiceSelectValue(INITIAL_SERVICE_OVERVIEW_SELECT_VALUE);
    setSourceSelectvalue(INITIAL_SERVICE_OVERVIEW_SELECT_VALUE);
  };

  return (
    <div className={styles.container}>
      <>
        <div className={styles.actions}>
          <div className={styles.filters}>
            <ServiceOverviewSelectFilter
              id={'service'}
              label={'Service'}
              currentValue={serviceSelectValue}
              onChange={setServiceSelectValue}
              options={serviceOptions}
            />
            <ServiceOverviewSelectFilter
              id={'source'}
              label={'Source'}
              currentValue={sourceSelectValue}
              onChange={setSourceSelectvalue}
              options={scrapeJobOptions}
            />
          </div>
          {isAWSPage() && isUserAdmin() && (
            <div className={styles.buttons}>
              <Button onClick={() => push(prefixRoute(CloudProvider.AWS, `${ROUTES.Configuration}/cloudwatch/create`))}>
                Add new AWS services
              </Button>
            </div>
          )}
        </div>
        <table className={styles.table} data-testid={Pages.ServiceOverview.table}>
          <thead>
            <tr>
              <th>Service</th>
              <th>Source</th>
              {doesAnyServiceHaveJobName && <th>Job name</th>}
              {isAnyServiceSendingData && <th>Status</th>}
              <th>View your data</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={3}>
                  <Spinner />
                </td>
              </tr>
            )}
            {!!error && (
              <tr>
                <td colSpan={3}>
                  <QueryError message={`There was an error while loading the services`} isFullPageError={false} />
                </td>
              </tr>
            )}
            {!isLoading &&
              !error &&
              servicesToShow?.length > 0 &&
              servicesToShow?.map((row, i) => (
                <tr key={i}>
                  <td className={styles.servicesColumn}>
                    <EllipsisText text={row?.service} />
                  </td>
                  <td className={styles.jobColumn}>
                    {isAWSPage() && isUserAdmin() ? <ScrapeJobLink service={row} /> : row.source}
                  </td>
                  {doesAnyServiceHaveJobName && <td>{row.job_name}</td>}
                  {isAnyServiceSendingData && (
                    <td>
                      {row.currently_sending_data && (
                        <Tooltip content={`Last from ${row.last_received_at}`}>
                          <div className={styles.iconColumn}>
                            <Icon name="check" color="green" /> Sending data
                          </div>
                        </Tooltip>
                      )}
                    </td>
                  )}
                  <td className={styles.dashboardsColumn}>
                    <div className={styles.dashboardWrapper}>
                      <div className={styles.buttons}>
                        {row.dashboard && (
                          <LinkButton
                            icon="apps"
                            fill="text"
                            size="sm"
                            variant="secondary"
                            target={row.dashboard.target}
                            className={cx(styles.marginRight, styles.linkText)}
                            href={row.dashboard.target === '_blank' ? row.dashboard.url : undefined}
                            onClick={() => openDashboard(row)}
                          >
                            {row.dashboard.title}
                          </LinkButton>
                        )}
                        {row.dashboard_uid && (
                          <LinkButton
                            icon="apps"
                            fill="text"
                            size="sm"
                            variant="secondary"
                            target={'_blank'}
                            className={cx(styles.marginRight, styles.linkText)}
                            onClick={() => openDashboard(row)}
                          >
                            {row.service}
                          </LinkButton>
                        )}
                        <LinkButton
                          icon="compass"
                          fill="outline"
                          size="sm"
                          variant="secondary"
                          target={'_blank'}
                          href={createExploreLink(
                            getDataSourceSettings('prometheus').uid,
                            getQuery(row),
                            getPluginStorageProp('timeRange') as RawTimeRange
                          )}
                          onClick={() =>
                            trackRudderStackEvent(RudderStackEvents.GoToExplore, {
                              page: 'homepage',
                              service: row.service,
                              provider,
                            })
                          }
                        >
                          Explore
                        </LinkButton>
                      </div>
                      {!!dashboardError && dashboardUID === row.dashboard_uid && (
                        <Alert status={'error'}>
                          <p>{(dashboardError as any)?.data.message ?? 'Error loading the dashboard'}</p>
                        </Alert>
                      )}
                      {isDashboardLoading && dashboardUID === row.dashboard_uid && <Spinner />}
                    </div>
                  </td>
                </tr>
              ))}
            {!isLoading && !error && servicesToShow?.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <ServiceOverviewEmptyList
                    areScrapeJobsDisabled={areScrapeJobsDisabled ?? false}
                    onClearFilters={onClearFilters}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {showPagination && (
          <div className={styles.paginationWrapper}>
            <Select
              width={10}
              options={PAGINATION_PAGE_SIZES as SelectableValue[]}
              value={servicesPerPage}
              onChange={(option: SelectableValue) => {
                setServicesPerPage(option.value);
              }}
              className={styles.pageSize}
            />
            <Pagination
              currentPage={currentPage}
              numberOfPages={totalNumberOfPages}
              onNavigate={(toPage: number) => setCurrentPage(toPage)}
            />
          </div>
        )}
      </>
    </div>
  );
};
