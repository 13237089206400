import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { SetupMode } from 'enums';
import { AlloyIntegrationContext, AlloyIntegrationState } from 'app/contexts/alloyIntegration.context';
import { SelectGroup } from 'components/SelectGroup';
import { useGetIntegration } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

const getStyles = (theme: GrafanaTheme2) => ({
  selectGroup: css({
    justifyContent: 'flex-start',
  }),
  paragraph: css({
    marginBottom: theme.spacing(1),
  }),
});

const setupModeOptions = [
  {
    value: SetupMode.Simple,
    label: 'Simple set-up',
    description: 'Choose this option if you are running this integration on a single host or machine.',
  },
  {
    value: SetupMode.Advanced,
    label: 'Advanced set-up',
    description: 'Choose this option if you are running Alloy on a remote host or scraping multiple nodes.',
  },
];

export const SetupModeSelector = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: selectedIntegration } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const {
    configuredParameters: { setupMode },
    selectSetupMode,
  } = useContext<AlloyIntegrationState>(AlloyIntegrationContext);

  return (
    <div>
      <p className={styles.paragraph}>
        Choose one of the following two options to configure your {selectedIntegration?.name ?? ''} integration:
      </p>
      <SelectGroup
        options={setupModeOptions}
        onChange={(value: SetupMode) => selectSetupMode(value)}
        value={setupMode}
        className={styles.selectGroup}
      />
    </div>
  );
};
