import { Spinner, useStyles2 } from '@grafana/ui';
import { useGetIntegration, useIntegrationsByCatalogQuery } from 'api/int-api/queries';
import React, { useContext, useEffect, useMemo } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { CardSource } from 'feature/common/components/Card/CardSource';
import { Source, Status } from 'api/int-api/data-models';
import { QueryError } from 'components/QueryError/QueryError';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { Pages } from 'e2eSelectors/pages';
import { CloudProvider } from 'types/CloudProvider';
import { AZURE_CONFIGURATION_URL } from 'scenes/Azure/routes';
import { InstallBackend } from 'feature/common/components/InstallBackend/InstallBackend';
import { VersionHistory } from 'feature/common/components/VersionHistory/VersionHistory';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: block;
    width: 100%;
  `,
  groupWrapper: (columns?: number) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    margin-top: ${theme.spacing(3)};
    margin-bottom: 60px;
    gap: ${theme.spacing(1)};

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 576px) and (max-width: 1050px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 1050px) and (max-width: 1250px) {
      grid-template-columns: repeat(3, 1fr);
    }
  `,
  link: css`
    padding-left: ${theme.spacing(0.5)};
    padding-right: ${theme.spacing(0.5)};
  `,
});

export const Configuration = ({ showServices }: { showServices: boolean }) => {
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const provider = CloudProvider.AZURE;
  const {
    isPending,
    data: sources,
    isError,
  } = useIntegrationsByCatalogQuery(pluginId, jsonData.grafana_instance_id, provider);
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { data } = useGetIntegration(provider, pluginId, jsonData.grafana_instance_id);
  const hasAnythingToInstall = useMemo(() => {
    return data?.dashboards?.status === Status.Available || data?.alerts?.status === Status.Available;
  }, [data?.dashboards?.status, data?.alerts?.status]);

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.AzureConfigurationViewPage, { page: 'azure-catalog' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      {isError ? (
        <QueryError message={'An unknown error occurred.'} />
      ) : (
        <>
          {isPending ? (
            <Spinner />
          ) : (
            sources && (
              <div className={styles.groupWrapper(sources.length)} data-testid={Pages.Configuration.cardsRow}>
                {sources.map((integration: Source) => (
                  <CardSource
                    item={integration}
                    key={integration.id}
                    defaultRoute={`${AZURE_CONFIGURATION_URL}/${integration.id}`}
                  />
                ))}
              </div>
            )
          )}
        </>
      )}
      {hasAnythingToInstall && (
        <>
          <InstallBackend cloudProvider={provider} showServices={showServices} />
          <VersionHistory provider={provider} />
        </>
      )}
    </div>
  );
};
