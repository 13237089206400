import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Icon } from '@grafana/ui';

import { Components } from 'e2eSelectors/components';
import { colors } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  error: css`
    color: ${theme.isLight ? colors.red02 : colors.red01};
  `,
  alert: css`
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    font-size: ${theme.typography.bodySmall.fontSize};
    align-items: center;
  `,
  success: css`
    color: ${theme.isLight ? colors.green02 : colors.green01};
  `,
  warning: css`
    color: ${theme.colors.warning.text};
  `,
  icon: css`
    margin-right: 6px;
  `,
  alertContent: css`
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }
  `,
  base: css`
    font-size: ${theme.typography.body.fontSize};
  `,
});

type Props = {
  status: 'error' | 'warning' | 'success';
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'sm';
  ariaLabel?: string;
  role?: string;
};

export function Alert(props: Props) {
  const styles = useStyles2(getStyles);
  const isSizeBase = props.size === 'base';

  const icon =
    props.status === 'error' || props.status === 'warning' ? (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="exclamation-triangle" className={styles.icon}/>
    ) : (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="check" className={styles.icon}/>
    );

  return (
    <>
      <span
        data-testid={Components.Alert.alert}
        className={cx(
          styles.alert,
          props.className,
          props.status === 'error' ? styles.error : props.status === 'warning' ? styles.warning : styles.success,
          isSizeBase && styles.base
        )}
      >
        {icon}
        <p
          role={props.role ?? 'alert'}
          aria-label={props.ariaLabel || `${props.status} message`}
          className={styles.alertContent}
        >
          {props.children}
        </p>
      </span>
    </>
  );
}
