import { Icon, useStyles2 } from '@grafana/ui';
import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    color: ${theme.colors.warning.text};
  `,
  warnIcon: css`
    margin-right: ${theme.spacing(0.5)};
  `,
});

type Props = {
  text: string;
};

export const WarningText = ({ text }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <span className={styles.wrapper}>
      <Icon className={styles.warnIcon} name="exclamation-triangle" />
      {text}
    </span>
  );
};
