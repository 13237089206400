import { css } from '@emotion/css';
import React, { useContext, useMemo } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import {
  getArchOptions,
  isK8sAlloyInstructionsEnabled,
  OSSelection,
  useCollectorSelector,
  useOsOptions,
} from '@grafana-cloud/collector';
import { KubernetesBadge } from 'components/KubernetesBadge';
import { useGetIntegration } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { getSourceText } from 'feature/common/components/utils';

type Props = {
  sourceId: string;
  showSmallHeader?: boolean;
  showStepBox?: boolean;
};

export const OSSelectionWrapper = ({ sourceId, showStepBox = true }: Props) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: source } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const { data: osOptions } = useOsOptions(sourceId);
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);
  const archOptions = useMemo(() => getArchOptions(sourceId ?? '', selectedOs.osValue), [selectedOs, sourceId]);

  if (!osOptions || osOptions.length === 0) {
    return null;
  }

  const content = (
    <>
      <OSSelection osOptions={osOptions} archOptions={archOptions} />
      {!isK8sAlloyInstructionsEnabled() && (
        <KubernetesBadge>
          <p>
            Looking to configure {getSourceText(source)} for your Kubernetes environment? Check out{' '}
            <a href="/a/grafana-k8s-app/configuration/integrations" target="_blank">
              Kubernetes Monitoring
            </a>{' '}
            with Grafana Cloud to enable this.
          </p>
        </KubernetesBadge>
      )}
    </>
  );

  return showStepBox ? <div className={styles.step}>{content}</div> : content;
};

const getStyles = (theme: GrafanaTheme2) => ({
  step: css({
    backgroundColor: theme.colors.background.secondary,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }),
});
