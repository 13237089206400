import { css } from '@emotion/css';
import React, { FC, useContext, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { CardElement } from '../../../CardElement';
import { GRAFANA_EXAMPLE_API, lokiCellURLToFirehose } from 'utils/misc';
import { GenerateAPI } from 'components/GenerateAPI/GenerateAPI';
import { SelectGroup } from 'components/SelectGroup';
import { useGetHostedDataDetails } from 'api/grafana-com/queries';
import { Clipboard } from 'components/Clipboard';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

const exploreQuery = '{job="cloud/aws"}';

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(3)};
  `,
  description: css`
    margin-top: ${theme.spacing(3)};
  `,
  link: css`
    * {
      color: white;
    }
  `,
  instructions: css`
    font-size: 20px;
    font-weight: ${theme.typography.fontWeightMedium};
    max-width: 780px;
    margin-bottom: 40px;
    margin-left: ${theme.spacing(3)};

    li {
      margin-bottom: 32px;
      font-size: 19px;

      h2 {
        margin: ${theme.spacing(2)} 0;
        line-height: 26px;
        font-size: 19px;
        color: ${theme.isLight ? '#343b40' : theme.colors.text.maxContrast};
      }

      p {
        font-size: ${theme.typography.body.fontSize};
        font-weight: ${theme.typography.fontWeightRegular};
      }
    }

    a {
      color: ${theme.isLight ? '#245baf' : '#538ade'};
      &:hover {
        text-decoration: underline;
      }
    }
  `,
});

enum Methods {
  CloudFormation = 'cf',
  Terraform = 'tf',
}

const CloudFormationComponent = () => {
  const styles = useStyles2(getStyles);
  const { pluginId } = useContext(PluginMetaContext);
  const { data: hostedDataDetails } = useGetHostedDataDetails(pluginId);

  const templateUrl = 'https://grafanalabs-cf-templates.s3.us-east-2.amazonaws.com/aws-logs/aws-logs-firehose.yaml';
  const templateName = 'GrafanaLabs-CloudWatch-Firehose-Logs';
  const paramWriteAddress = lokiCellURLToFirehose(hostedDataDetails?.hlInstanceUrl ?? '');
  const paramUsername = hostedDataDetails?.hlInstanceId;
  const cloudformationUrl = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${templateUrl}&stackName=${templateName}&param_TargetEndpoint=${paramWriteAddress}&param_LogsInstanceID=${paramUsername}`;

  const apiKeySuccessMessage =
    'Your API key has been generated below. Copy and paste this key into the "LogsWriteToken" parameter of the CloudFormation stack.';

  return (
    <>
      <p className={styles.description}>AWS CloudFormation guides you through setting up the necessary resources.</p>

      <p className={styles.description}>
        Follow these steps to configure a Kinesis Firehose delivery stream that sends AWS logs to Grafana Cloud Logs.
      </p>

      <ol className={styles.instructions}>
        <li>
          <h2>Create a Grafana.com token</h2>

          <p>
            Click below to generate a Grafana.com token with the <code>logs:write</code> permission. This token will be
            used as the <code>LogsWriteToken</code> parameter in the CloudFormation stack at the next step.
          </p>

          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={hostedDataDetails?.hlInstanceId ?? 1}
            successMessage={apiKeySuccessMessage}
          />
        </li>

        <li>
          <h2>Launch CloudFormation stack</h2>

          <p>
            Run the CloudFormation setup, and specify a <code>SubscriptionFilter</code> for the LogGroup you want to
            forward to Grafana Cloud.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            target="_blank"
            icon="external-link-alt"
            href={cloudformationUrl}
          >
            Launch CloudFormation stack
          </LinkButton>
        </li>

        <li>
          <h2>Explore logs</h2>

          <p>
            Head over to the Explore page and query all ingested log streams with <code>{exploreQuery}</code>.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            type="button"
            href={craftExploreURL(`grafanacloud-${hostedDataDetails?.slug}-logs`)}
            target={'_blank'}
          >
            Go to Explore
          </LinkButton>
        </li>
      </ol>
    </>
  );
};

const TerraformComponent = () => {
  const styles = useStyles2(getStyles);
  const { pluginId } = useContext(PluginMetaContext);
  const { data: hostedDataDetails } = useGetHostedDataDetails(pluginId);

  const writeAddress = lokiCellURLToFirehose(hostedDataDetails?.hlInstanceUrl ?? '');
  const username = hostedDataDetails?.hlInstanceId;

  // TODO(thepalbi): Update docs here, even though we don't have them live yet
  const terraformDocsUrl =
    'https://grafana.com/docs/grafana-cloud/integrations/integrations/integration-cloudwatch-logs/#automatically-configure-lambda-promtail-using-terraform';

  const apiKeySuccessMessage = 'Your API key has been generated below. Use it in the Terraform setup.';

  return (
    <>
      <p className={styles.description}>The Terraform configuration will create the necessary resources.</p>

      <p className={styles.description}>
        Follow these steps to configure a Kinesis Firehose delivery stream that sends AWS logs to Grafana Cloud Logs.
      </p>

      <ol className={styles.instructions}>
        <li>
          <h2>Create a Grafana.com token</h2>

          <p>
            Click below to generate a Grafana.com token with the <code>logs:write</code> permission. This token will be
            used in the Terraform setup at the next step, in the <code>logs_write_token</code> variable.
          </p>

          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={hostedDataDetails?.hlInstanceId ?? 1}
            successMessage={apiKeySuccessMessage}
          />
        </li>

        <li>
          <h2>Terraform setup</h2>

          <p>You’ll need to use the following parameters as input variables in the Terraform file.</p>

          <Clipboard title="Grafana Cloud target endpoint:" multipleLines code={writeAddress} />

          <Clipboard title="Grafana Cloud Loki instance ID:" multipleLines code={username?.toString() ?? ''} />

          <p>
            See our documentation and learn how to use Terraform to set up the necessary resources in your AWS account.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            target="_blank"
            icon="external-link-alt"
            href={terraformDocsUrl}
          >
            See Terraform docs
          </LinkButton>
        </li>

        <li>
          <h2>Explore logs</h2>

          <p>
            Head over to the Explore page and select query all ingested log streams with <code>{exploreQuery}</code>.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            type="button"
            href={craftExploreURL(`grafanacloud-${hostedDataDetails?.slug}-logs`)}
            target={'_blank'}
          >
            Go to Explore
          </LinkButton>
        </li>
      </ol>
    </>
  );
};

// Crafts the explore URL for firehose logs, following
// https://grafana.com/docs/grafana/latest/explore/#generating-explore-urls-from-external-tools,
// and querying loki with `{job="cloud/aws"}`.
function craftExploreURL(datasource: string) {
  const paneData = {
    datasource,
    queries: [
      {
        expr: exploreQuery,
        queryType: 'range',
        datasource: {
          type: 'loki',
          uid: datasource,
        },
      },
    ],
    range: {
      from: 'now-1h',
      to: 'now',
    },
  };
  return `/explore?left=${encodeURIComponent(JSON.stringify(paneData))}`;
}

const methodOptions = [
  {
    label: 'Use CloudFormation',
    value: Methods.CloudFormation,
    component: CloudFormationComponent,
  },
  {
    label: 'Use Terraform',
    value: Methods.Terraform,
    component: TerraformComponent,
  },
];

export const Automatic: FC = ({}) => {
  const styles = useStyles2(getStyles);
  const [method, setMethod] = useState(Methods.CloudFormation);
  const selectedOption = methodOptions.find((option) => option.value === method);
  const Component = selectedOption?.component;

  return (
    <>
      <h2 className={styles.title}>Choose a method for creating AWS resources</h2>
      <div>
        <SelectGroup options={methodOptions} value={method} onChange={setMethod} CardElementOverride={CardElement} />
        {selectedOption && Component && <Component />}
      </div>
    </>
  );
};
