import { Alert, Button, Card, Spinner, useStyles2 } from '@grafana/ui';
import React, { useContext } from 'react';
import { getStyles } from '../GetStarted.styles';
import { useHistory } from 'react-router-dom';
import { useIntegrationsByCatalogQuery } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { getStartedCards } from 'feature/AWS/utils/utils';
import { Pages } from 'e2eSelectors/pages';
import { useGetScrapeJobs } from 'api/hosted-exporters-api/queries';
import { GetStartedCard } from '../GetStartedCard';
import { GetStartedInsightsCard } from '../GetStartedInsightsCard';
import { GetStartedLearnMoreCard } from '../GetStartedLearnMoreCard';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';

export const GetStarted = () => {
  const styles = useStyles2(getStyles);
  const { push } = useHistory();
  const { trackRudderStackEvent } = useRudderStack();
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const {
    isSuccess,
    data: awsSources,
    isLoading,
    isError,
  } = useIntegrationsByCatalogQuery(pluginId, jsonData.grafana_instance_id, CloudProvider.AWS);
  const { data: jobs } = useGetScrapeJobs('cloudwatch', pluginId, jsonData.grafana_instance_id);
  const areJobsConfigured = jobs?.length !== 0;

  const cards = getStartedCards(awsSources, areJobsConfigured);

  function addAwsServices() {
    trackRudderStackEvent(RudderStackEvents.AddServices, { page: 'homepage' });
    push(prefixRoute(CloudProvider.AWS, `configuration`));
  }

  return (
    <>
      <div>
        <Card className={styles.cardCentered}>
          <h2>
            Easy set-up, granular configuration, and out-of-the-box monitoring for your services across all your AWS
            accounts.
          </h2>
          <div>
            <Button onClick={addAwsServices}>Add your AWS services</Button>
          </div>
        </Card>
        {isLoading && <Spinner />}
        {isError && (
          <Alert severity="error" title={''}>
            There was an error while loading the AWS integrations
          </Alert>
        )}
        {isSuccess && !!awsSources && (
          <div className={styles.integrationsFlex(cards?.length)} data-testid={Pages.GetStarted.cardsRow}>
            {cards.map((card) => (
              <GetStartedCard key={card.id} card={card} />
            ))}
          </div>
        )}
      </div>
      <div className={styles.insightsRow(cards?.length)}>
        <GetStartedInsightsCard cards={cards} />
        <GetStartedLearnMoreCard />
      </div>
    </>
  );
};
