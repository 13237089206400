import useRudderStack from 'hooks/useRudderstack';
import React, { useState } from 'react';
import { AlloyStatus } from '@grafana-cloud/collector';
import {
  AlloyIntegrationContext,
  AlloyIntegrationState,
  ConfigureParameters,
} from 'app/contexts/alloyIntegration.context';
import { AgentSendResult } from 'api/int-api/data-models';
import {
  IntegrationConnectionErrorCause,
  IntegrationConnectionStatus,
  IntegrationConnectionWithoutErrorStatus,
  RudderStackEvents,
  SetupMode,
} from 'enums';

export const AlloyIntegrationContextProvider = ({
  sourceId,
  initialState,
  children,
}: {
  sourceId: string;
  initialState: AlloyIntegrationState;
  children: any;
}) => {
  const { trackRudderStackEvent } = useRudderStack();
  const [alloyStatus, setAlloyStatus] = useState<AlloyStatus>(initialState.alloyStatus);
  const [showLoadingBar, setShowLoadingBar] = useState<boolean>(initialState.showLoadingBar);
  const [alloyCheckInstalled, setAlloyCheckInstalled] = useState<boolean>(initialState.alloyCheckInstalled);
  const [configuredParameters, setConfiguredParameters] = useState<ConfigureParameters>(
    initialState.configuredParameters
  );
  const [integrationConnectionResult, setIntegrationConnectionResult] = useState<AgentSendResult>(
    initialState.integrationConnectionResult
  );

  function removeLoadingBar() {
    setShowLoadingBar(false);
  }

  function toggleMetricsSet() {
    setShowLoadingBar(true);
    setConfiguredParameters({
      ...configuredParameters,
      isFullMetricsSelected: !configuredParameters.isFullMetricsSelected,
    });
  }

  function setHostName(hostName: string) {
    setShowLoadingBar(true);
    setConfiguredParameters({ ...configuredParameters, hostName });
  }

  function resetConfiguredParameters() {
    setConfiguredParameters({ isFullMetricsSelected: undefined, setupMode: SetupMode.Simple });
  }

  function openAlloyModal() {
    setAlloyCheckInstalled(false);
    setAlloyStatus(AlloyStatus.None);
  }

  function installedAlloyCheck() {
    setAlloyCheckInstalled(true);
  }

  function copyToClipboard() {
    trackRudderStackEvent(RudderStackEvents.CopyAlloyInstructionToClipboard, {
      integration_slug: sourceId,
    });
  }

  function setIntegrationConnectionError(error: IntegrationConnectionErrorCause) {
    setIntegrationConnectionResult({
      ...integrationConnectionResult,
      status: IntegrationConnectionStatus.Error,
      error,
    });
  }

  function setIntegrationConnectionStatus(status: IntegrationConnectionWithoutErrorStatus) {
    setIntegrationConnectionResult({ ...integrationConnectionResult, status });
  }

  function selectSetupMode(setupMode: SetupMode) {
    setConfiguredParameters({ ...configuredParameters, setupMode });
  }

  return (
    <AlloyIntegrationContext.Provider
      value={{
        alloyStatus,
        configuredParameters,
        showLoadingBar,
        alloyCheckInstalled,
        removeLoadingBar,
        toggleMetricsSet,
        setHostName,
        openAlloyModal,
        installedAlloyCheck,
        resetConfiguredParameters,
        setAlloyStatus,
        copyToClipboard,
        integrationConnectionResult,
        setIntegrationConnectionError,
        setIntegrationConnectionStatus,
        selectSetupMode,
      }}
    >
      {children}
    </AlloyIntegrationContext.Provider>
  );
};
