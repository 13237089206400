import { css } from '@emotion/css';
import React, { FC, useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, LinkButton, useStyles2 } from '@grafana/ui';

import { Clipboard } from 'components/Clipboard';
import { AWS_IAM_URL } from 'utils/consts';
import { useCloudwatchConfig } from 'api/hosted-exporters-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

const permissionsPolicy = `{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Action": [
        "tag:GetResources",
        "cloudwatch:GetMetricData",
        "cloudwatch:ListMetrics",
        "apigateway:GET",
        "aps:ListWorkspaces",
        "autoscaling:DescribeAutoScalingGroups",
        "dms:DescribeReplicationInstances",
        "dms:DescribeReplicationTasks",
        "ec2:DescribeTransitGatewayAttachments",
        "ec2:DescribeSpotFleetRequests",
        "shield:ListProtections",
        "storagegateway:ListGateways",
        "storagegateway:ListTagsForResource"
      ],
      "Effect": "Allow",
      "Resource": "*"
    }
  ]
}`;

const getStyles = (theme: GrafanaTheme2) => ({
  instructionItemField: css`
    margin-bottom: 0;
  `,
  launchButton: css`
    margin-bottom: ${theme.spacing(3)};

    * {
      color: white;
    }
  `,
});

export const Manual: FC = ({}) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: cloudWatchConfig } = useCloudwatchConfig(pluginId, jsonData.grafana_instance_id);

  return (
    <>
      <h2>Create a new role in the AWS IAM console</h2>
      <p>
        Use the information below to create a new role in your AWS IAM console and grant permission for Grafana Cloud to
        access your AWS data.
      </p>
      <LinkButton href={AWS_IAM_URL} target="_blank" icon="external-link-alt" className={styles.launchButton}>
        Open AWS IAM Console
      </LinkButton>
      <Field
        label="Account ID"
        description="Grafana’s AWS account ID allows Grafana Cloud to access your AWS metrics. Paste it into the Account ID field in AWS."
        className={styles.instructionItemField}
      >
        <Clipboard code={cloudWatchConfig?.grafana_account_id ?? ''} />
      </Field>

      <Field
        label="External ID"
        description="Your Grafana Cloud ID is used for security purposes. Select ‘Require External ID’ and paste it into the corresponding field."
        className={styles.instructionItemField}
      >
        <Clipboard code={cloudWatchConfig?.external_id ?? ''} />
      </Field>

      <h3>Grant permissions to Grafana Cloud</h3>
      <p>
        For your new AWS role, you need to create a permissions policy that allows Grafana Cloud to scrape your AWS
        metrics. When creating the new policy in AWS, select the JSON tab and paste the following code snippet to the
        policy textbox, replacing the existing code.
      </p>
      <Clipboard multipleLines code={permissionsPolicy} />
    </>
  );
};
