import { ThresholdsMode } from '@grafana/data';
import {
  behaviors,
  EmbeddedScene,
  SceneControlsSpacer,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariable,
  SceneVariableSet,
  SceneVariableState,
  VariableValueSelectors,
} from '@grafana/scenes';
import { TableCellBackgroundDisplayMode } from '@grafana/schema';
import { TableCellDisplayMode } from '@grafana/ui';
import { EC2_URL } from 'scenes/AWS/routes';
import {
  formatMetricName,
  getGenericQueryRunner,
  makeTimeRange,
  onChangeDatasourceBehavior,
  ServiceWithOotbViews,
} from 'scenes/misc';
import { allQueries, NECESSARY_EC2_METRICS } from './queries';
import {
  VAR_ACCOUNT,
  VAR_ALL_TAGS_MULTIPLE_OPTIONS,
  VAR_DATASOURCE,
  VAR_METRICS,
  VAR_REGION,
  VAR_SCRAPE_JOB,
} from 'scenes/variables';
import { getVariables } from './getVariables';
import { ExplorablePanel } from 'scenes/common/ExplorablePanel';

const metricsOverrides: Array<{
  name: string;
  otherProperties?: any[];
}> = [
  {
    name: NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_INSTANCE_SUM,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.ColorText,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 1,
            },
          ],
        },
      },
    ],
  },
  {
    name: NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_SUM,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.ColorText,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 1,
            },
          ],
        },
      },
    ],
  },
  {
    name: NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_SYSTEM_SUM,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.ColorText,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 1,
            },
          ],
        },
      },
    ],
  },
  {
    name: NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_MAXIMUM,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.Gauge,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: '#5794F2',
              value: 0,
            },
          ],
        },
      },
      {
        id: 'unit',
        value: 'percent',
      },
      {
        id: 'decimals',
        value: 2,
      },
    ],
  },
  {
    name: NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_AVERAGE,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.Gauge,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: '#5794F2',
              value: 0,
            },
          ],
        },
      },
      {
        id: 'unit',
        value: 'percent',
      },
      {
        id: 'decimals',
        value: 2,
      },
    ],
  },
  {
    name: NECESSARY_EC2_METRICS.METRIC_EBSIOBALANCE_PERCENT_AVERAGE,
    otherProperties: [
      {
        id: 'unit',
        value: 'percent',
      },
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.Gauge,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'red',
              value: 0,
            },
            {
              color: '#EAB839',
              value: 30,
            },
            {
              color: 'green',
              value: 50,
            },
          ],
        },
      },
    ],
  },
  {
    name: NECESSARY_EC2_METRICS.METRIC_EBSBYTE_BALANCE_PERCENT_AVERAGE,
    otherProperties: [
      {
        id: 'unit',
        value: 'percent',
      },
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.Gauge,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'red',
              value: 0,
            },
            {
              color: '#EAB839',
              value: 30,
            },
            {
              color: 'green',
              value: 50,
            },
          ],
        },
      },
    ],
  },
];

export const getOverviewScene = (prometheusName: string, from?: string, to?: string) => {
  const datasource = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const urlParams = new URLSearchParams(window.location.search);
  const metricsDefaults = urlParams.getAll('var-metrics') ?? [];

  const queryRunner = new SceneQueryRunner({
    datasource: datasource,
    queries: metricsDefaults.map((m) => {
      return {
        refId: m,
        expr:
          'sum by(account_id, region, dimension_InstanceId, scrape_job, tag_Name, ${' +
          `${VAR_ALL_TAGS_MULTIPLE_OPTIONS}:csv})(${m}{name!='global', region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}"} + on(name, scrape_job) group_left(tag_Name,` +
          ' ${' +
          `${VAR_ALL_TAGS_MULTIPLE_OPTIONS}:csv}) aws_ec2_info)`,
        format: 'table',
        instant: true,
        interval: '',
        legendFormat: '',
        excludeByName: {
          __name__: true,
        },
      };
    }),
  });

  const transformed = new SceneDataTransformer({
    $data: queryRunner,
    transformations: [
      {
        id: 'merge',
        options: {},
      },
      {
        id: 'organize',
        options: {
          indexByName: {
            account_id: 0,
            dimension_InstanceId: 1,
            region: 2,
            scrape_job: 3,
            tag_Name: 4,
            tag_aws_autoscaling_groupName: 5,
          },
        },
      },
    ],
  });

  const { datasources, allTagsMultipleOptions, metrics, region, account, scrapeJob } = getVariables(prometheusName);

  const sceneTimeRange = makeTimeRange(from, to);

  return new EmbeddedScene({
    $variables: new SceneVariableSet({
      variables: [datasources, allTagsMultipleOptions, metrics, region, account, scrapeJob],
    }),
    $timeRange: sceneTimeRange,
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ['5s', '1m', '1h'],
        isOnCanvas: true,
      }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      $behaviors: [
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_DATASOURCE,
          onChange: onChangeDatasourceBehavior,
        }),
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_METRICS,
          onChange: (variable: SceneVariable<SceneVariableState>) => {
            const metricsValues: string[] = variable.getValue?.() as string[];
            if (metricsValues) {
              const queries = metricsValues.map((m) => {
                return {
                  refId: m,
                  expr:
                    'sum by(account_id, region, dimension_InstanceId, scrape_job, tag_Name, ${' +
                    `${VAR_ALL_TAGS_MULTIPLE_OPTIONS}:csv})(${m}{name!='global', region=~'$${VAR_REGION}', account_id=~'$${VAR_ACCOUNT}', scrape_job=~'$${VAR_SCRAPE_JOB}'} + on(name, scrape_job) group_left(tag_Name,` +
                    ' ${' +
                    `${VAR_ALL_TAGS_MULTIPLE_OPTIONS}:csv}) aws_ec2_info)`,
                  format: 'table',
                  instant: true,
                  interval: '',
                  legendFormat: '',
                  excludeByName: {
                    __name__: true,
                  },
                };
              });
              queryRunner.setState({ queries });
              queryRunner.runQueries();
            }
          },
        }),
      ],
      children: [
        new SceneFlexLayout({
          direction: 'row',
          height: 200,
          children: [
            new SceneFlexItem({
              height: 200,
              $data: getGenericQueryRunner(datasource, allQueries.regionsQueries.countAll),
              body: new ExplorablePanel({
                title: 'Instance count',
              }),
            }),
          ],
        }),
        new SceneFlexItem({
          minHeight: 400,
          body: new ExplorablePanel({
            $data: transformed,
            title: 'EC2 instances',
            description: 'Balance Thresholds: 0 < red < 30 < yellow < 60 < green',
            pluginId: 'table',
            fieldConfig: {
              defaults: {
                custom: {
                  align: 'auto',
                  cellOptions: {
                    type: 'auto',
                  },
                  width: 200,
                  inspect: false,
                  filterable: true,
                },
                mappings: [],
              },
              overrides: [
                {
                  matcher: {
                    id: 'byName',
                    options: 'Time',
                  },
                  properties: [
                    {
                      id: 'custom.hidden',
                      value: true,
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'account_id',
                  },
                  properties: [
                    {
                      id: 'custom.width',
                      value: 110,
                    },
                    {
                      id: 'displayName',
                      value: 'Account ID',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'region',
                  },
                  properties: [
                    {
                      id: 'links',
                      value: [
                        {
                          title: 'Go to region',
                          url:
                            EC2_URL +
                            '/region?var-region=${__data.fields.region}&${__url.params}&var-datasource=$datasource',
                        },
                      ],
                    },
                    {
                      id: 'displayName',
                      value: 'Region',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'scrape_job',
                  },
                  properties: [
                    {
                      id: 'custom.width',
                      value: 110,
                    },
                    {
                      id: 'displayName',
                      value: 'Scrape job',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'tag_Name',
                  },
                  properties: [
                    {
                      id: 'displayName',
                      value: 'Tag name',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'dimension_InstanceId',
                  },
                  properties: [
                    {
                      id: 'displayName',
                      value: 'Instance ID',
                    },
                    {
                      id: 'links',
                      value: [
                        {
                          title: 'Go to instance',
                          url:
                            EC2_URL +
                            '/overview/${__data.fields.dimension_InstanceId}?var-region=${__data.fields.region}&${__url.params}&var-datasource=$datasource',
                        },
                      ],
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'tag_aws_autoscaling_groupName',
                  },
                  properties: [
                    {
                      id: 'displayName',
                      value: 'Autoscalling group name',
                    },
                  ],
                },
                ...(metricsDefaults.length === 1
                  ? [
                      {
                        matcher: {
                          id: 'byName',
                          options: 'Value',
                        },
                        properties: [
                          {
                            id: 'displayName',
                            value: formatMetricName(metricsDefaults[0], ServiceWithOotbViews.Ec2),
                          },
                          [
                            ...(metricsOverrides.find((metric) => metric.name === metricsDefaults[0])
                              ?.otherProperties ?? []),
                          ],
                        ].flat(),
                      },
                    ]
                  : []),
                ...(metricsDefaults.length > 1
                  ? [
                      ...metricsDefaults.map((metricName: string) => {
                        return {
                          matcher: {
                            id: 'byName',
                            options: `Value #${metricName}`,
                          },
                          properties: [
                            {
                              id: 'displayName',
                              value: formatMetricName(metricName, ServiceWithOotbViews.Ec2),
                            },
                            [...(metricsOverrides.find((metric) => metric.name === metricName)?.otherProperties ?? [])],
                          ].flat(),
                        };
                      }),
                    ]
                  : []),
                ...(metricsDefaults.length === 0
                  ? [
                      ...metricsOverrides.map((metric) => {
                        return {
                          matcher: {
                            id: 'byName',
                            options: `Value #${metric.name}`,
                          },
                          properties: [
                            {
                              id: 'displayName',
                              value: formatMetricName(metric.name, ServiceWithOotbViews.Ec2),
                            },
                            [...(metric?.otherProperties ?? [])],
                          ].flat(),
                        };
                      }),
                    ]
                  : []),
              ].flat(),
            },
          }),
        }),
      ],
    }),
  });
};
