import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Select, useStyles2 } from '@grafana/ui';
import { FilterJobStatus } from 'enums';
import { SaasIntegrationState, SaasIntegrationContext } from 'app/contexts/saasIntegration.context';

const JOB_STATUS_FILTER_OPTIONS = [
  {
    value: FilterJobStatus.All,
    label: 'All',
  },
  {
    value: FilterJobStatus.Enabled,
    label: 'Enabled jobs only',
  },
  {
    value: FilterJobStatus.Disabled,
    label: 'Disabled jobs only',
  },
];

const getStyles = (theme: GrafanaTheme2) => ({
  selectWrapper: css`
    max-width: 180px;
    margin-right: ${theme.spacing(1)};
  `,
});

type JobStatusFilterProps = {
  onFilterChanged: (value: FilterJobStatus) => void;
};

export const JobStatusFilter = ({ onFilterChanged }: JobStatusFilterProps) => {
  const styles = useStyles2(getStyles);
  const { jobStatusFilter, setJobStatusFilter } = useContext<SaasIntegrationState>(SaasIntegrationContext);

  return (
    <Select
      className={styles.selectWrapper}
      value={jobStatusFilter}
      options={JOB_STATUS_FILTER_OPTIONS}
      onChange={(selected: SelectableValue<FilterJobStatus>) => {
        setJobStatusFilter(selected.value as FilterJobStatus);
        onFilterChanged(selected.value as FilterJobStatus);
      }}
    />
  );
};
