import { EmbeddedScene, SceneAppPage, SceneAppPageLike, SceneAppPageState, SceneReactObject } from '@grafana/scenes';
import { AZURE_OVERVIEW_URL } from 'scenes/Azure/routes';
import { Homepage as AzureHomePage } from 'feature/AZURE/pages/Homepage/Homepage';

const getAzureOverviewScene = () => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: AzureHomePage,
    }),
  });
};

export const getOverviewSceneTab = (parent?: SceneAppPageLike) => {
  const state: SceneAppPageState = {
    title: 'Overview',
    url: AZURE_OVERVIEW_URL,
    preserveUrlKeys: [],
    getScene: () => getAzureOverviewScene(),
  };
  if (parent) {
    state.getParentPage = () => parent;
  }
  return new SceneAppPage(state);
};
