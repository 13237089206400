import { css } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, LinkButton, Tooltip } from '@grafana/ui';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { Status } from 'api/int-api/data-models';
import { useGetIntegration } from 'api/int-api/queries';
import { getCatalogBySourceId } from 'api/int-api/utils';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { constructAlertUrl } from 'utils/misc';
import { DashboardList } from '../pages/Dashboards/DashboardList';
import { useGetDashboards } from 'api/dashboards/queries';
import { getDashboardFoldersByIntegration } from 'scenes/misc';
import { getCloudProviderName } from 'feature/common/utils/utils';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    button: css`
      margin-right: 14px;
      margin-bottom: ${theme.spacing(2)};
    `,
    marginBottom: css`
      margin-bottom: ${theme.spacing(1)};
    `,
    p: css`
      margin-top: ${theme.spacing(1.25)};
    `,
  };
};

export const ViewYourDashboardsSection = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getStyles);
  const provider = getCatalogBySourceId(sourceId);

  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: source } = useGetIntegration(provider ?? '', pluginId, jsonData.grafana_instance_id);
  const { data: integration } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const { trackRudderStackEvent } = useRudderStack();

  const { data: dashboards } = useGetDashboards(
    source?.dashboard_folder ? [getDashboardFoldersByIntegration(provider)] : undefined
  );

  function clickAlertsAndRecordingButtons() {
    trackRudderStackEvent(RudderStackEvents.ViewAlloyAlertsAndRecordingRules, {
      integration_slug: sourceId,
    });
  }

  const dashboardsAndAlertsButtons = (
    <span>
      {!!dashboards && dashboards.length > 0 && !!provider && (
        <DashboardList provider={provider} dashboards={dashboards} />
      )}
      {source?.alerts?.status === Status.Available && (
        <LinkButton
          variant="primary"
          fill="outline"
          icon="bell"
          href={constructAlertUrl(source)}
          className={styles.button}
          onClick={clickAlertsAndRecordingButtons}
          disabled={!source?.installation}
        >
          View alerts and recording rules
        </LinkButton>
      )}
    </span>
  );

  return !!dashboards && dashboards.length > 0 ? (
    <li>
      <h2>View your dashboards {source?.alerts?.status === Status.Available && 'and alerts'}</h2>
      <p className={styles.p}>
        {integration?.name} comes with pre-built dashboards
        {source?.alerts?.status === Status.Available || source?.rules?.status === Status.Available
          ? ', alerts and recording rules'
          : ''}{' '}
        to get you up and running quickly.
      </p>
      {source?.installation ? (
        dashboardsAndAlertsButtons
      ) : (
        <Tooltip
          content={`Dashboards are not currently installed. Install them from the ${!!provider ? getCloudProviderName(provider) : ''} configuration page.`}
        >
          {dashboardsAndAlertsButtons}
        </Tooltip>
      )}
    </li>
  ) : (
    <></>
  );
};
