import { ALBLogsInstructions } from './ALBLogsInstructions';
import { CloudWatchLogsFirehoseInstructions } from './CloudWatchLogsFirehoseInstructions';
import { CloudWatchLogsInstructions } from './CloudWatchLogsInstructions';
import { CloudWatchInstructions } from './CloudWatchInstructions';

type ReactComponentType = {
  [key: string]: React.FC;
};

// Extend this mapping for other SaaS integrations
export const InstructionsMapping: ReactComponentType = {
  'cloudwatch-logs': CloudWatchLogsInstructions,
  'cloudwatch-logs-firehose': CloudWatchLogsFirehoseInstructions,
  'aws-alb-logs': ALBLogsInstructions,
  cloudwatch: CloudWatchInstructions,
};
