import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

const getStyles = (theme: GrafanaTheme2) => ({
  instanceContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(0.5)};
  `,
  instanceHeader: css`
    color: ${theme.colors.text.primary}
    font-size: ${theme.typography.body.fontSize};
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;
    text-align: left;
    width: 100%;
  `,
  snippets: css`
    padding: ${theme.spacing(0.5)};
    border: 1px solid ${theme.colors.border.weak};
    background: ${theme.colors.background.canvas};
    text-align: center;
    border-radius: 3px;
  `,
});

type Props = {
  title: string;
  snippet: string;
};

export const ResourceSnippet = ({ title, snippet }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.instanceContainer}>
      <div className={styles.instanceHeader}>{title}</div>
      <div className={styles.snippets}>{snippet}</div>
    </div>
  );
};
