import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { ResourceContainer } from './ResourceContainer';
import { ResourceSnippet } from './ResourceSnippet';

const getStyles = (theme: GrafanaTheme2) => ({
  content: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: ${theme.spacing(5)};
    margin-top: ${theme.spacing(2)};
  `,
  instanceContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(0.5)};
  `,
  metricsColumn: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  instanceHeader: css`
    color: ${theme.colors.text.primary}
    font-size: ${theme.typography.body.fontSize};
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;
    text-align: left;
    width: 100%;
  `,
});

export const TagsToAddToMetricsFlow = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.content}>
      <div className={styles.instanceContainer}>
        <div className={styles.instanceHeader}>EC2 Instance</div>
        <ResourceContainer title={'Instance A'} tagName={'env'} tagValue={'dev'} colorIndex={2} />
      </div>
      <div className={styles.metricsColumn}>
        <ResourceSnippet
          title={'Default CPU Utilization metric'}
          snippet={'aws_ec2_cpu_utilization \n {name=instance_a}'}
        />
        <ResourceSnippet
          title={'Apply label env=dev'}
          snippet={'aws_ec2_cpu_utilization \n {name=instance_a, env=dev}'}
        />
      </div>
    </div>
  );
};
