import React from 'react';

import { InstructionsMapping } from './InstructionsMapping';
import { Source } from 'api/int-api/data-models';

export const SaasIntegration = ({ selectedIntegration }: { selectedIntegration: Source }) => {
  const { id } = selectedIntegration;

  const SaasIntegrationComponent = InstructionsMapping[id];
  return <>{SaasIntegrationComponent && <SaasIntegrationComponent />}</>;
};
