import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppRootProps } from '@grafana/data';
import { PLUGIN_BASE_URL, PLUGIN_PROXY_URL } from '../../utils/utils.routing';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'app/contexts/queryClient';
import { CspRootPage } from 'feature/root';
import { ConfigurationDetail as AwsConfigurationDetail } from 'feature/AWS/pages/Configuration/ConfigurationDetail';
import { ConfigurationDetail as AzureConfigurationDetail } from 'feature/AZURE/pages/Configuration/ConfigurationDetail';
import { ConfigurationDetail as GcpConfigurationDetail } from 'feature/GCP/pages/Configuration/ConfigurationDetail';
import { Page404 } from 'feature/common/pages/404Page/404Page';
import { UpgradeGrafanaPageDisclaimer } from 'feature/AWS/pages/Homepage/UpgradeGrafanaPageDisclaimer';
import { PluginSceneApp } from 'scenes/PluginSceneApp';
import { AccessPoliciesProvider, RealmTypes } from '@grafana-cloud/access-policies';
import { CollectorProvider } from '@grafana-cloud/collector';
import { GrafanaApiProvider } from '@grafana-cloud/grafana-api';
import { GrafanaComApiProvider } from '@grafana-cloud/grafana-com-api';
import { IntegrationsApiProvider } from '@grafana-cloud/integrations-api';
import { useGetHostedDataDetails } from 'api/grafana-com/queries';
import { transformPluginMeta } from 'utils/configTransformation';
import { setPluginMeta, PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { isDashboardsSectionPresent, isGrafanaAtLeast10 } from 'utils/misc';
import { track } from 'utils/track';
import { OSSelectionWrapper } from 'feature/common/components/OSSelectionWrapper';
import {
  AWS_CONFIGURATION_URL,
  AWS_DASHBOARDS_URL,
  AWS_LOGS_URL,
  AWS_SERVICES_URL,
  AWS_URL,
  EC2_URL,
  RDS_URL,
} from 'scenes/AWS/routes';
import {
  AZURE_CONFIGURATION_URL,
  AZURE_DASHBOARDS_URL,
  AZURE_LOGS_URL,
  AZURE_SERVICES_URL,
  AZURE_URL,
} from 'scenes/Azure/routes';
import { GCP_CONFIGURATION_URL, GCP_DASHBOARDS_URL, GCP_LOGS_URL, GCP_SERVICES_URL, GCP_URL } from 'scenes/GCP/routes';

function Routes({ meta }: AppRootProps) {
  const pluginMeta = useMemo(() => transformPluginMeta(meta), [meta]);
  const { data: hostedDataDetails } = useGetHostedDataDetails(pluginMeta.pluginId);
  const stackId = String(pluginMeta.jsonData.grafana_instance_id);

  useEffect(() => {
    if (pluginMeta) {
      setPluginMeta(pluginMeta);
    }
  }, [pluginMeta]);

  const dashboardsEnabled = isDashboardsSectionPresent();
  return (
    <GrafanaComApiProvider config={{ baseApiUrl: `${PLUGIN_PROXY_URL}/grafanacom-api/auth` }}>
      <GrafanaApiProvider config={{ baseApiUrl: 'api' }}>
        <IntegrationsApiProvider
          config={{
            baseApiUrls: {
              admin: `${PLUGIN_PROXY_URL}/integrations-api-admin`,
              editor: `${PLUGIN_PROXY_URL}/integrations-api-editor`,
            },
          }}
        >
          <CollectorProvider
            config={{
              stackId,
              components: {
                OSSelection: OSSelectionWrapper,
              },
              track,
            }}
          >
            <AccessPoliciesProvider
              config={{
                realm: { realmType: RealmTypes.STACK, realmIdentifier: stackId },
                region: hostedDataDetails?.regionSlug!,
                track,
              }}
            >
              <PluginMetaContext.Provider value={pluginMeta}>
                <Switch>
                  <Route exact path={PLUGIN_BASE_URL} component={CspRootPage} />

                  {/* AWS Routes */}
                  <Route exact path={`${AWS_URL}/:tab?`} component={PluginSceneApp} />
                  <Route
                    exact
                    path={`${AWS_CONFIGURATION_URL}/:id/:action?/:job?`}
                    component={AwsConfigurationDetail}
                  />
                  <Route key={'solution/ec2'} path={EC2_URL} component={PluginSceneApp} />
                  <Route key={'solution/rds'} path={RDS_URL} component={PluginSceneApp} />
                  {dashboardsEnabled && (
                    <Route
                      exact
                      path={`${AWS_DASHBOARDS_URL}/:integrationFolder/:dashboardId`}
                      component={PluginSceneApp}
                    />
                  )}
                  <Route path={AWS_LOGS_URL} component={PluginSceneApp} />
                  <Route exact path={`${AWS_SERVICES_URL}`} component={PluginSceneApp} />

                  {/* AZURE Routes */}
                  <Route exact path={`${AZURE_URL}/:tab?`} component={PluginSceneApp} />
                  <Route exact path={AZURE_CONFIGURATION_URL} component={PluginSceneApp} />
                  <Route exact path={`${AZURE_CONFIGURATION_URL}/:id`} component={AzureConfigurationDetail} />
                  <Route path={AZURE_LOGS_URL} component={PluginSceneApp} />
                  {dashboardsEnabled && (
                    <Route
                      exact
                      path={`${AZURE_DASHBOARDS_URL}/:integrationFolder/:dashboardId`}
                      component={PluginSceneApp}
                    />
                  )}
                  <Route exact path={`${AZURE_SERVICES_URL}`} component={PluginSceneApp} />

                  {/* GCP Routes */}
                  <Route exact path={`${GCP_URL}/:tab?`} component={PluginSceneApp} />
                  <Route exact path={GCP_CONFIGURATION_URL} component={PluginSceneApp} />
                  <Route exact path={`${GCP_CONFIGURATION_URL}/:id`} component={GcpConfigurationDetail} />
                  <Route path={GCP_LOGS_URL} component={PluginSceneApp} />
                  {dashboardsEnabled && (
                    <Route
                      exact
                      path={`${GCP_DASHBOARDS_URL}/:integrationFolder/:dashboardId`}
                      component={PluginSceneApp}
                    />
                  )}
                  <Route exact path={`${GCP_SERVICES_URL}`} component={PluginSceneApp} />

                  {/* Default page */}
                  <Route component={Page404} />
                </Switch>
              </PluginMetaContext.Provider>
            </AccessPoliciesProvider>
          </CollectorProvider>
        </IntegrationsApiProvider>
      </GrafanaApiProvider>
    </GrafanaComApiProvider>
  );
}

export function App(props: AppRootProps) {
  if (!isGrafanaAtLeast10()) {
    return <UpgradeGrafanaPageDisclaimer />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Routes {...props} />
    </QueryClientProvider>
  );
}
