import { LogLevel } from '@grafana/data';
import { colors } from '@grafana/ui';
import { getColorForTheme } from 'scenes/Logs/utils';

export const LOG_LEVER_COLOR = {
  [LogLevel.critical]: colors[7],
  [LogLevel.warning]: colors[1],
  [LogLevel.error]: colors[4],
  [LogLevel.info]: colors[0],
  [LogLevel.debug]: colors[5],
  [LogLevel.trace]: colors[2],
  [LogLevel.unknown]: getColorForTheme('#8e8e8e', '#bdc4cd'),
} as const;
