import { css } from '@emotion/css';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { NECESSARY_EC2_METRICS } from './queries';
import { EC2_URL } from 'scenes/AWS/routes';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { ServiceAlert } from 'scenes/common/ServiceAlert';

const getStyles = (theme: GrafanaTheme2) => ({
  details: css`
    margin-left: ${theme.spacing(3)};
    margin-top: ${theme.spacing(2)};
  `,
  summary: css`
    margin-top: ${theme.spacing(1)};
    :hover {
      cursor: pointer;
    }
  `,
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
});

export const EC2Subtitle = () => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const { pathname } = useLocation();

  const isOverviewTabOpened = pathname === EC2_URL;

  return (
    <>
      {isOverviewTabOpened && (
        <details>
          <summary
            className={styles.summary}
            onClick={() => {
              trackRudderStackEvent(RudderStackEvents.TipsDetailsOpened, {});
            }}
          >
            Tips on how to make the most of this view
          </summary>
          <div className={styles.details}>
            <p>
              AWS EC2 instances scale up and down based on demand. This dashboard helps you to get a birds-eye view of
              all instances across all of your accounts and regions, and drill into the instances that require more
              attention. For a more customized view, filter the column headers by account, auth profile, tag, or
              autoscaling group. Choose which metrics to monitor by selecting the metrics you care about.
            </p>
            <h5>Troubleshooting use cases:</h5>
            <ul>
              <li>
                Monitor how your instances scale up or down in the Instance Count panel, and select a specific time
                range to filter the table by these instances.
              </li>
              <li>Sort the table by highest CPU to identify the instance that might be causing downstream problems.</li>
            </ul>

            <h5 className={styles.marginTop}>Optimization use cases:</h5>
            <ul>
              <li>
                Sort the table by lowest CPU to view instances that are not properly utilized and identify where you
                could repack or downsize.
              </li>
              <li>Sort the table by the IO balance to view instances that are about to be overdrawn.</li>
            </ul>
          </div>
        </details>
      )}

      <ServiceAlert necessaryMetrics={NECESSARY_EC2_METRICS} service="EC2" />
    </>
  );
};
