import React, { FC, useContext } from 'react';
import { css } from '@emotion/css';

import { GenerateToken, RealmTypes, ScopePermissions } from '@grafana-cloud/access-policies';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { GRAFANA_EXAMPLE_API, GRAFANA_EXAMPLE_USER, jsonStringRepl } from 'utils/misc';
import { Clipboard } from 'components/Clipboard';
import { useGetHostedDataDetails } from 'api/grafana-com/queries';
import { useApiTokenCacheKey } from 'hooks/useApiTokenCacheKey';
import useNavigation from 'hooks/useNavigation';
import { useApiToken } from 'hooks/useApiToken';
import { getAccessPolicyName } from 'api/access-policies/utils';
import { Scope } from 'api/access-policies/data-model';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    font-size: ${theme.typography.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
  `,
  generateToken: css`
    font-size: ${theme.typography.fontSize};
  `,
});

type Props = {
  config: string;
  instanceId: number;
  successMessage: string;
  shouldShowClipboard?: boolean;
  shouldShowInstruction?: boolean;
};

export const GenerateAPI: FC<Props> = ({
  config,
  instanceId,
  successMessage,
  shouldShowClipboard = true,
  shouldShowInstruction = true,
}) => {
  const styles = useStyles2(getStyles);
  const { pluginId } = useContext(PluginMetaContext);
  const { data } = useGetHostedDataDetails(pluginId);
  const stackId = data?.id;
  const { params } = useNavigation();
  const fixedCacheKey = useApiTokenCacheKey(params.id);

  const { token } = useApiToken(params.id, fixedCacheKey);

  let updatedConfig = jsonStringRepl(config, new RegExp(GRAFANA_EXAMPLE_USER, 'g'), instanceId.toString());

  if (token) {
    updatedConfig = jsonStringRepl(updatedConfig, new RegExp(GRAFANA_EXAMPLE_API, 'g'), token);
  }

  return (
    <>
      {shouldShowInstruction && (
        <p className={styles.title}>
          Click below to generate a Grafana.com API token. This API token will appear as the password in the
          configuration below.
        </p>
      )}

      <div className={styles.generateToken}>
        <GenerateToken
          onTokenGenerated={() => {}}
          fixedCacheKey={fixedCacheKey}
          successMessage={successMessage}
          showExpiration={false}
          showScopes={true}
          showToken={false}
          tokenNameLabel="API token name"
          tokenNamePlaceholder="API token name"
          tokenNameDescription="The name will help you view and revoke this token in Grafana.com later."
          accessPolicyName={getAccessPolicyName(stackId ?? 1)}
          scopes={[Scope.METRICS, Scope.LOGS, Scope.TRACES, Scope.PROFILES].map(
            (scope) => scope + ':' + ScopePermissions.WRITE
          )}
          realms={[
            {
              type: RealmTypes.STACK,
              identifier: String(stackId),
              labelPolicies: [],
            },
          ]}
        />
        {token && shouldShowClipboard && <Clipboard code={updatedConfig} multipleLines={true} expandHeight={true} />}
      </div>
    </>
  );
};
