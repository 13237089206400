export const Pages = {
  GetStarted: {
    cardsRow: 'get-started-cards-row',
    getCard: (id: string) => `get-started-card-${id}`,
    getExploreLink: (id: string) => `get-started-explore-link-${id}`,
    viewDashboards: 'get-started-view-dashboards',
  },
  ServiceOverview: {
    table: 'service-overview-table',
    scrapeJobAnchor: (name: string) => `scrape-job-anchor ${name}`,
    emptyContainer: 'service-overview-empty-container',
  },
  Configuration: {
    cardsRow: 'configuration-cards-row',
    getCard: (id: string) => `configuration-card-${id}`,
    getTitleCard: (id: string) => `configuration-card-title-${id}`,
  },
  Source: {
    uninstallIntegrationButton: 'uninstall-integration-button',
    viewDashboardsButton: 'view-dashboards-button',
    removeIntegrationModalButton: 'remove-integration-modal-button',
    actionsDropdownButton: 'actions-dropdown-button',
    Config: {
      osSelector: 'os-selector',
      install: 'install-button',
      viewDashboardsBottomButton: 'view-dashboards-button',
    },
    versionLabel: 'version-label',
    AlloyConfig: {
      modalButton: 'alloy-config-modal-button',
      agentConfigButton: 'agent-config-button',
      exampleConfigButton: 'example-config-button',
      preInstructions: 'pre-instructions',
      hostnameRelabelInput: 'hostname-relabel-input',
      integrationInstructionsHeader: 'integration-instructions-header',
      logsInstructionsHeader: 'logs-instructions-header',
      metricsInstructionsHeader: 'metrics-instructions-header',
      postInstructions: 'post-instructions',
      restartCommand: 'restart-command',
      testConnectionButton: 'test-connection-button',
      alloyAdvancedIntegrationsBlock: 'alloy-advanced-integrations-block',
      alloyAdvancedIntegrationsInstructions: 'alloy-advanced-integrations-instructions',
      alloyAdvancedLogsBlock: 'alloy-advanced-logs-block',
      alloyAdvancedLogsInstructions: 'alloy-advanced-logs-instructions',
      alloyAdvancedMetricsBlock: 'alloy-advanced-metrics-block',
      alloyAdvancedMetricsInstructions: 'alloy-advanced-metrics-instructions',
      alloySimpleBlock: 'alloy-simple-block',
      alloyGeneralGuidance: 'alloy-general-guidance',
    },
  },
  CloudWatch: {
    serviceIcon: `service-icon`,
    scrapeJobsTable: `scrape-jobs-table`,
    scrapeJobsEmpty: `scrape-jobs-empty`,
    scrapeJobTableRow: (name: string) => `scrape-job-table-row ${name}`,
    scrapeJobTableActionsButton: (name: string) => `scrape-job-table-actions-button ${name}`,
    scrapeJobRowCheckbox: (name: string) => `scrape-job-row-checkbox ${name}`,
    scrapeJobRowLink: (name: string) => `scrape-job-row-link ${name}`,
    addScrapeJobButton: `add-cloudwatch-job-button`,
    customNamespaceNameInput: 'custom-namespace-set-name',
    customNamespaceMetricInput: 'custom-namespace-add-metrics',
    CreateJob: {
      regionsSelect: `regions-select`,
      createScrapeJobButton: `create-scrape-job-button`,
    },
    EditJob: {
      saveScrapeJobButton: `save-scrape-job-button`,
      cancelScrapeJobButton: `cancel-scrape-job-button`,
      deleteScrapeJobButton: `delete-scrape-job-button`,
    },
    SelectServices: {
      serviceMultiSelect: `service-multi-select`,
      serviceSearchInput: 'service-search-input',
      serviceSelectEmpty: `service-select-empty`,
      serviceSelectList: `service-select-list`,
    },
    ConfigServiceMetrics: {
      searchByServiceOrMetricInput: 'search-by-service-or-metrics-input',
      addNewServicesButton: 'scrape-jobs-add-new-services',
      scrapeJobServicesTable: `scrape-job-services-table`,
      selectAllJobServices: 'select-all-services-checkbox',
      deleteSelectedServices: 'delete-selected-services',
      editServiceButton: (name: string) => `edit-scrape-job-service-button ${name}`,
      deleteServiceButton: (name: string) => `delete-scrape-job-service-button ${name}`,
      serviceRowCheckbox: (name: string) => `service-row-checkbox ${name}`,
      tagsContainer: (name: string) => `tags-container ${name}`,
      editTag: (name: string) => `edit-tag ${name}`,
      checkAddTagToMetrics: (service: string, tag: string) => `${service} add-tag-to-metrics ${tag}`,
      checkTagFilter: (service: string, tag: string) => `${service} add-tag-to-filter ${tag}`,
      setTagFilterValue: (service: string, tag: string) => `${service} add-tag-to-filter ${tag}`,
      configureServicesEmpty: `configure-services-empty`,
      selectAllMetricsCheckbox: `select-all-metrics-checkbox`,
      serviceMetricsTable: (name: string) => `service-metrics-table ${name}`,
      customNamespaceMetricsTable: (name: string) => `custom-namespace-metrics-table ${name}`,
      statisticsSelectField: 'statistics-select-field',
      statisticsApplyButton: 'statistics-apply-button',
    },
  },
  SaaSIntegration: {
    addScrapeJobButton: `add-scrape-job-button`,
    testConnectionButton: `test-connection-button`,
    alertParagraph: (type: string) => `alert ${type}`,
    modalFooter: `modal-footer`,
    viewEc2Button: 'view-ec2-button',
    backToJobList: `back-to-job-list`,

    Actions: {
      getButton: (name: string) => `scrape-jobs-action-button-${name}`,
      getModalButton: (name: string) => `scrape-jobs-action-modal-button-${name}`,
      selectAllScrapeJobs: `select-all-scrape-jobs`,
    },
  },
  Breadcrumbs: {
    configuration: 'data-testid Configuration breadcrumb',
  },
  Tabs: {
    Configuration: 'data-testid Tab Configuration',
    Logs: 'data-testid Tab Logs',
    Overview: 'data-testid Tab Overview',
  },
  Gcp: {
    OverviewButtonToConfiguration: 'gcp-overview-tab-configuration-button',
  },
  Azure: {
    OverviewButtonToConfiguration: 'azure-overview-tab-configuration-button',
  },
  CspPage: {
    getCard: (id: string) => `csp-card-${id}`,
  },
};
