import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  warning: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  anchor: css`
    color: ${theme.colors.text.link};
  `,
});

export const StatisticsAndMetricsWarning = () => {
  const styles = useStyles2(getStyles);
  return (
    <p className={styles.warning}>
      Not all metrics support all possible statistics. Refer to the{' '}
      <a className={styles.anchor} target="_blank" rel="noreferrer" href="https://docs.aws.amazon.com/index.html">
        AWS docs
      </a>{' '}
      to determine which statistics are recommended for each metric.
    </p>
  );
};
