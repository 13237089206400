import { PluginPage } from '@grafana/runtime';
import { testIds } from 'components/testIds';
import React from 'react';

export const Page404 = () => {
  return (
    <PluginPage>
      <p data-testid={testIds.page404.paragraph}>Sorry! This page does not exist...</p>
    </PluginPage>
  );
};
