import React from 'react';

import { ButtonProps, Modal, useStyles2 } from '@grafana/ui';
import { getModalWithFooterStyles } from './ModalWithFooter.styles';
import { ModalFooter } from './ModalFooter';

type ModalWithFooterProps = {
  isOpen: boolean;
  title: string;
  onDismiss: () => void;
  children: any;
  buttons?: ButtonProps[];
  className?: string;
};

export const ModalWithFooter = ({
  isOpen = false,
  title,
  onDismiss,
  children,
  buttons = [],
  className,
}: ModalWithFooterProps) => {
  const styles = useStyles2(getModalWithFooterStyles);

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onDismiss={onDismiss}
      contentClassName={styles.modalContent}
      className={className}
    >
      <div className={styles.modalBody}>{children}</div>
      {buttons?.length > 0 && <ModalFooter buttons={buttons} />}
    </Modal>
  );
};
