import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getConfigureServicesTableStyles = (theme: GrafanaTheme2) => ({
  searchOrAdd: css`
    display: flex;
    width: 100%;
    margin-bottom: ${theme.spacing(1)};
  `,
  search: css`
    display: flex;
    width: 60%;
  `,
  addButton: css`
    display: flex;
    width: 40%;
    justify-content: flex-end;
  `,
  table: css`
    width: 100%;
    margin-bottom: ${theme.spacing(3)};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};

    tr {
      border-bottom: 1px solid ${theme.colors.border.weak};
    }

    th,
    td {
      padding-right: ${theme.spacing(1)};
      padding-top: ${theme.spacing(1)};
      padding-bottom: ${theme.spacing(1)};
    }

    td {
      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  `,
  servicesColumn: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(4)};
    height: 32px;
  `,
  servicesSelection: css`
    display: flex;
    align-items: center;

    button {
      height: 28px;
    }
  `,
  selectAllCheckbox: css`
    margin-right: ${theme.spacing(1)};
  `,
  selectedServicesMessage: css`
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
    width: 230px;
  `,
  emptyServices: css`
    display: flex;
    justify-content: center;
    padding: ${theme.spacing(8)} 0 ${theme.spacing(8)} 0;
    width: 100%;
    border: 1px solid ${theme.colors.border.weak};
    border-radius: ${theme.spacing(0.5)};
    margin: ${theme.spacing(2)} 0 ${theme.spacing(1)} 0;
  `,
});
