import React, { useMemo } from 'react';

import { useCollectorSelector } from '@grafana-cloud/collector';
import { Clipboard } from '@grafana-cloud/ui';
import { Pages } from 'e2eSelectors/pages';
import { generateOsInstallConfig } from 'feature/common/components/OSInstallConfig';
import { CollectorMode } from 'enums';

export function RestartCollector() {
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);
  const collector = 'Grafana Alloy';

  const { restartCode, restartTitle } = useMemo(
    () => generateOsInstallConfig(selectedOs, CollectorMode.Alloy),
    [selectedOs]
  );

  return (
    <>
      <h3>Restart {collector}</h3>
      <p>Run the following command to restart {collector} so your changes can take effect.</p>
      {restartCode && (
        <Clipboard
          title={restartTitle}
          code={restartCode}
          multipleLines
          data-testid={Pages.Source.AlloyConfig.restartCommand}
          clipboardButtonType="below"
        />
      )}
    </>
  );
}
