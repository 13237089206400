import React, { FC, useEffect } from 'react';

import { textUtil } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Alert } from 'components/Alert';
import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { getJobStatusWidgetStyles } from './JobStatusWidget.styles';
import { isErrorResponse } from 'api/common/utils';
import { HostedExportersApiErrorResult } from 'api/hosted-exporters-api/data-models';
import { CloudWatchErrorCodes, cloudwatchText } from 'utils/consts';
import { RudderStackEvents } from 'enums';
import {
  handleDisabledAwsRegionError,
  isCheckConnectionError,
  isHostedExportersApiErrorResult,
} from 'api/hosted-exporters-api/utils';

export const JobFailedWidget: FC<{ sourceId: string | null; error: any }> = ({ sourceId, error }) => {
  const styles = useStyles2(getJobStatusWidgetStyles);
  const { trackRudderStackEvent } = useRudderStack();

  let message = '';
  if (isCheckConnectionError(error)) {
    message = handleDisabledAwsRegionError(error)?.message ?? '';

    if (!message) {
      const code = error.data.error.code;
      message = cloudwatchText.errors[code as CloudWatchErrorCodes] || error.data.error.message;
    }
  } else if (isErrorResponse<HostedExportersApiErrorResult>(error) && isHostedExportersApiErrorResult(error?.data)) {
    const code = error.data.error.code;
    message = cloudwatchText.errors[code as CloudWatchErrorCodes] || error.data.error.message;
  } else {
    message = cloudwatchText.errors[CloudWatchErrorCodes.UnknownError];
  }

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.CreateOrUpdateScrapeJobFailed, {
      integration_slug: sourceId,
      message,
    });
  }, [sourceId, message, trackRudderStackEvent]);

  return (
    <Alert className={styles.createScrapeJobStatus} status="error">
      <span
        role="alert"
        aria-label="Connection error"
        data-testid={Pages.SaaSIntegration.alertParagraph('creation-failed')}
        dangerouslySetInnerHTML={{
          __html: textUtil.sanitize(message),
        }}
        style={{ width: '100%' }}
      />
    </Alert>
  );
};
