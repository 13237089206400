import { css, cx } from '@emotion/css';
import _, { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Field, IconButton, MultiSelect, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

import { InputWithOptions } from '../CustomNamespaces/InputWithOptions/InputWithOptions';
import { StatisticsSelect } from '../StatisticsSelect';

import { ScrapeIntervalSelect } from './ScrapeIntervalSelect';
import { FormOutput, OutputMetrics } from '../../../types';
import { CustomNamespaceApiResponse } from 'api/hosted-exporters-api/data-models';
import { initOutputMetricsArray, isValidStatistics } from '../../CloudWatchUtils';

const getEditServiceMetricsStyles = (theme: GrafanaTheme2) => ({
  table: css`
    width: 100%;
    margin-bottom: ${theme.spacing(2)};
    display: block;
    max-height: 30vh;
    overflow-y: scroll;
    thead {
      background: ${theme.colors.background.primary};
      position: sticky;
      top: 0;
      z-index: 3;
      height: 32px;
    }
    > th {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightMedium};
    }
    th:not(:last-child),
    td:not(:last-child) {
      padding-left: ${theme.spacing(1)};
      min-width: 300px;
      width: 100%;
    }
    td:last-child {
      padding-right: ${theme.spacing(1)};
    }
    th {
      vertical-align: baseline;
    }
  `,
  tr: css`
    background: ${theme.colors.background.secondary};
    border-bottom: 1px solid ${theme.colors.border.weak};
  `,
  statistic: css`
    max-width: 480px;
    margin: ${theme.spacing(1)} 0;
  `,
  statisticHead: css`
    font-size: ${theme.typography.body.fontSize};
    display: flex;
    gap: 10px;
    padding-right: ${theme.spacing(1)};
  `,
  dropdown: css`
    font-size: ${theme.typography.body.fontSize};
  `,
  hide: css`
    display: none;
  `,
  border: css`
    border: 1px solid ${theme.colors.border.weak};
    padding: ${theme.spacing(2)};
    margin: ${theme.spacing(1)} 0 ${theme.spacing(3)} 0;
  `,
  metricsInput: css`
    max-width: 50%;
  `,
});

type EditCustomNamespacesMetricsProps = {
  index: string;
  service: FormOutput;
  defaultCustomNamespace: CustomNamespaceApiResponse;
  onDataChanged: (data: FormOutput) => void;
};

export const EditCustomNamespacesMetrics = ({
  index,
  service,
  defaultCustomNamespace,
  onDataChanged,
}: EditCustomNamespacesMetricsProps) => {
  const styles = useStyles2(getEditServiceMetricsStyles);
  const [namespacesMetrics, setNamespacesMetrics] = useState<FormOutput>(service);
  const [outputMetricsArray, setOutputMetricsArray] = useState<OutputMetrics[]>(initOutputMetricsArray(service));
  const [metricsArray, setMetricsArray] = useState<string[]>([]);

  const updateServiceMetrics = useCallback(
    (updatedData: FormOutput) => {
      setNamespacesMetrics(updatedData);
      setOutputMetricsArray(initOutputMetricsArray(updatedData));
      onDataChanged(updatedData);
    },
    [onDataChanged]
  );

  useEffect(() => {
    setMetricsArray(outputMetricsArray.map((el) => el.name));
  }, [outputMetricsArray]);

  useEffect(() => {
    if (!isEqual(service, namespacesMetrics)) {
      updateServiceMetrics(service);
    }
  }, [service, namespacesMetrics, updateServiceMetrics]);

  function onStatisticsChanged(metricName: string, statistics: string[]) {
    const updatedData = cloneDeep(namespacesMetrics);

    updatedData.metrics[metricName].statistics = statistics;

    updateServiceMetrics(updatedData);
  }

  function onApplyToAllSelectedStatistics(metrics: string[] = [], statisticBatchSelectValue: string[]) {
    if (metrics.length > 0) {
      const updatedData = cloneDeep(namespacesMetrics);
      metrics.forEach((metric) => {
        updatedData.metrics[metric].statistics = statisticBatchSelectValue;
      });

      updateServiceMetrics(updatedData);
    }
  }

  function onScrapeIntervalChanged(value: number) {
    updateServiceMetrics({ ...namespacesMetrics, scrape_interval: value });
  }

  function updateMetrics(values: string[]) {
    const newMetrics = Object.fromEntries(
      values.map((metric) => {
        if (namespacesMetrics.metrics[metric]) {
          return [
            metric,
            {
              isChecked: true,
              statistics: namespacesMetrics.metrics[metric].statistics,
            },
          ];
        }
        return [
          metric,
          {
            isChecked: true,
            statistics: [defaultCustomNamespace.available_statistics[0]],
          },
        ];
      }) ?? []
    );
    updateServiceMetrics({ ...namespacesMetrics, metrics: newMetrics });
  }

  const listElement = outputMetricsArray.map((metric, key) => (
    <tr key={key} className={styles.tr}>
      <td>{metric.name}</td>
      <td>
        <Field
          invalid={!isValidStatistics(namespacesMetrics, metric.name)}
          error={!isValidStatistics(namespacesMetrics, metric.name) ? 'At least one statistic is required' : ''}
          className={styles.statistic}
          htmlFor={`statistics-selector-${index}`}
        >
          <MultiSelect<string>
            options={(defaultCustomNamespace.available_statistics ?? []).map((statistic) => ({
              label: statistic,
              value: statistic,
            }))}
            inputId={`statistics-selector-${index}`}
            placeholder="Choose"
            noOptionsMessage="No options remaining."
            closeMenuOnSelect={false}
            className={styles.dropdown}
            value={metric.statistics ?? []}
            onChange={(options: Array<SelectableValue<string>>) => {
              onStatisticsChanged(
                metric.name,
                options.map((option) => option.value ?? '')
              );
            }}
            disabled={!namespacesMetrics.metrics[metric.name]?.isChecked}
          />
        </Field>
      </td>
      <td>
        <IconButton
          name="trash-alt"
          onClick={() => updateMetrics(metricsArray.filter((name) => name !== metric.name))}
          aria-label="delete"
        />
      </td>
    </tr>
  ));

  return (
    <>
      <p>
        If you would like Grafana to scrape metrics from your{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/cloudwatch_concepts.html#Namespace"
        >
          Amazon custom namespaces
        </a>
        , add the exact strings for the metric names associated with your custom namespace.
      </p>
      <div className={styles.border}>
        <div className={styles.metricsInput}>
          <InputWithOptions
            id={Pages.CloudWatch.customNamespaceMetricInput}
            values={metricsArray}
            onChange={updateMetrics}
            listElement={<></>}
            label={`Add ${service.display_name} metrics and statistics`}
          />
        </div>
        <table
          className={cx(styles.table, outputMetricsArray.length === 0 && styles.hide)}
          data-testid={Pages.CloudWatch.ConfigServiceMetrics.customNamespaceMetricsTable(service.service_id)}
        >
          <thead>
            <tr>
              <th>Metric</th>
              <th className={styles.statisticHead}>
                <StatisticsSelect
                  statistics={defaultCustomNamespace.available_statistics}
                  outputMetricsArray={outputMetricsArray}
                  index={index}
                  onApplyToAll={onApplyToAllSelectedStatistics}
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>{listElement}</tbody>
        </table>
      </div>
      {!!defaultCustomNamespace && defaultCustomNamespace.available_statistics?.length > 0 && (
        <ScrapeIntervalSelect
          index={index}
          serviceForm={service}
          statistics={defaultCustomNamespace.available_scrape_intervals}
          onScrapeIntervalChanged={onScrapeIntervalChanged}
        />
      )}
    </>
  );
};
