import { css } from '@emotion/css';
import React, { FC, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { AlloyInstallationInstructions, useCollectorSelector } from '@grafana-cloud/collector';
import { RudderStackEvents } from 'enums';
import useRudderStack from 'hooks/useRudderstack';
import { Pages } from 'e2eSelectors/pages';
import { AlloyModalFooter } from './AlloyModalFooter';

type Props = { sourceId: string; showOSSelection: boolean };

export const AlloyConfigModal: FC<Props> = ({ sourceId, showOSSelection }) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const existingToken = useCollectorSelector((state) => state.collector.existingToken);
  const token = useCollectorSelector((state) => state.collector.token);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        size="md"
        onClick={() => {
          setShowModal(true);
          trackRudderStackEvent(RudderStackEvents.OpenAlloyConfigModalButton, { integration_slug: sourceId });
        }}
        data-testid={Pages.Source.AlloyConfig.modalButton}
      >
        Run Grafana Alloy
      </Button>
      <Modal
        title="Alloy configuration"
        isOpen={showModal}
        onDismiss={() => {
          setShowModal(false);
        }}
        contentClassName={styles.modalContent}
      >
        <AlloyInstallationInstructions showTestConnection={false} showOSSelection={showOSSelection} />
        <AlloyModalFooter isDisabled={!(token || existingToken)} onClose={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  modalContent: css({
    display: 'flex',
    flexDirection: 'column',
    ol: {
      marginLeft: '10px',
      li: {
        marginBottom: '40px',
        '> p:first-child': {
          fontSize: theme.typography.h5.fontSize,
        },

        '> h2': {
          fontSize: theme.typography.h5.fontSize,
        },
      },
      overflow: 'scroll',
      padding: '0 4px 0 4px',
      flexGrow: 1,
    },
  }),
});
