export const testIds = {
  appConfig: {
    container: 'data-testid ac-container',
    apiKey: 'data-testid ac-api-key',
    apiUrl: 'data-testid ac-api-url',
    submit: 'data-testid ac-submit-form',
  },
  homepage: {
    paragraph: 'data-testid pg-homepage p',
  },
  page404: {
    paragraph: 'data-testid pg-homepage 404 p',
  },
};
