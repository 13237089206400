import { SceneAppPage } from '@grafana/scenes';
import { ScenesCustomParams } from 'scenes/types';
import { VAR_LOKI_DATASOURCE } from 'scenes/variables';
import { AWS_LOGS_URL } from 'scenes/AWS/routes';
import { getLogGroupsScene } from './getLogGroupsScene';
import { getLogGroupDrilldown } from './getLogGroupDrilldown';
import { LogsIcon } from 'img';

export const getLogsSceneTab = ({ lokiName, from, to }: ScenesCustomParams): SceneAppPage => {
  return new SceneAppPage({
    title: 'Logs',
    titleImg: LogsIcon,
    hideFromBreadcrumbs: false,
    url: AWS_LOGS_URL,
    getScene: () => getLogGroupsScene(lokiName, from, to),
    drilldowns: getLogGroupDrilldown(lokiName, from, to),
    preserveUrlKeys: ['from', 'to', `var-${VAR_LOKI_DATASOURCE}`],
  });
};
