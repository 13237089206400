import { LogsMethod } from 'scenes/types';
import {
  LokiQueryType,
  SupportingQueryType,
} from '@grafana/schema/dist/esm/raw/composable/loki/dataquery/x/LokiDataQuery_types.gen';
import { QueryRunnerState } from '@grafana/scenes';
import { CloudProvider } from 'types/CloudProvider';
import { getVariableWithValues } from './utils';

type LogsQueryState = {
  job: string;
  term?: string;
};

export type AWSLogsQueryState = LogsQueryState & {
  logGroup: string;
  method: LogsMethod;
  accountId?: string[];
  level?: string;
};

export type GCPLogsQueryState = LogsQueryState;
export type AzureLogsQueryState = LogsQueryState;

const buildAwsLogsQuery = (
  job: string,
  logGroup: string,
  method: LogsMethod,
  accountId?: string[],
  level?: string,
  term?: string
): string => {
  let query = [];
  switch (method) {
    case LogsMethod.FIREHOSE:
      query = [`job="${job}"`, `aws_log_group="${logGroup}"`];
      if (accountId && accountId.length > 0) {
        query.push(getVariableWithValues('account_id', accountId));
      }
      break;

    case LogsMethod.LAMBDA:
      query = [`__aws_cloudwatch_log_group="${logGroup}"`];
      if (accountId && accountId.length > 0) {
        query.push(getVariableWithValues('__aws_cloudwatch_owner', accountId));
      }
      break;

    case LogsMethod.ALB:
      query = [`__aws_s3_lb="${logGroup}"`];
      if (accountId && accountId.length > 0) {
        query.push(getVariableWithValues('__aws_s3_lb_owner', accountId));
      }
      break;
  }
  let result = `{${query.join(', ')}}`;
  result += ` | label_format level=detected_level`;
  if (level && level !== 'all') {
    result += ` | level="${level}"`;
  }
  if (term) {
    result += ` |= "${term}"`;
  }
  return result;
};

const buildLogsQueryByJob = (job: string, term?: string): string => {
  let result = `{job="${job}"}`;
  if (term) {
    result += ` |= "${term}"`;
  }
  return result;
};
const buildGcpLogsQuery = (term?: string): string => buildLogsQueryByJob('integrations/gcp', term);
const buildAzureLogsQuery = (term?: string): string => buildLogsQueryByJob('integrations/azure_event_hubs', term);

export const buildLogsQuery = (
  platform: CloudProvider,
  state: AWSLogsQueryState | GCPLogsQueryState | AzureLogsQueryState
) => {
  switch (platform) {
    case CloudProvider.AWS:
      const { logGroup, method, accountId, level, term: awsTerm } = state as AWSLogsQueryState;
      return buildAwsLogsQuery('cloud/aws', logGroup, method, accountId, level, awsTerm);

    case CloudProvider.GCP:
      const { term: gcpTerm } = state as GCPLogsQueryState;
      return buildGcpLogsQuery(gcpTerm);

    case CloudProvider.AZURE:
      const { term: azureTerm } = state as AzureLogsQueryState;
      return buildAzureLogsQuery(azureTerm);

    default:
      return '';
  }
};

export const getLogsQueryState = (query: string): QueryRunnerState => {
  if (query) {
    return { queries: [{ expr: query, refId: 'A', queryType: LokiQueryType.Range }] };
  }
  return { queries: [] };
};

export const getLogsVolumeQueryState = (query: string): QueryRunnerState => {
  if (query) {
    return {
      queries: [
        {
          expr: `sum by(level) (count_over_time(${query}[$__interval]))`,
          refId: 'B',
          queryType: LokiQueryType.Range,
          supportingQueryType: SupportingQueryType.LogsVolume,
          legendFormat: '{{ level }}',
        },
      ],
    };
  }
  return { queries: [] };
};
