import { EmbeddedScene, SceneReactObject } from '@grafana/scenes';
import { CspEmbeddedDashboard } from 'feature/common/components/CspEmbeddedDashboard/CspEmbeddedDashboard';
import { DashboardDb } from 'api/dashboards/data-model';

export const getDashboardEmbeddedScene = (dashboard?: DashboardDb) => {
  return new EmbeddedScene({
    key: 'dashboard-embedded',
    body: new SceneReactObject({
      component: CspEmbeddedDashboard,
      props: { dashboard },
    }),
  });
};
