import { useHistory, useParams, useLocation } from 'react-router-dom';

type NavigationParams = {
  id: string;
};

const useNavigation = () => {
  const history = useHistory();
  const params = useParams<NavigationParams>();
  const location = useLocation();

  const navigate = (path: string, replace?: boolean) => {
    if (replace) {
      history.replace(path);
    } else {
      history.push(path);
    }
  };

  const setParam = (params: string) => {
    history.replace({ search: new URLSearchParams(params).toString() });
  };

  return {
    goBack: history.goBack,
    history,
    params,
    navigate,
    location,
    setParam,
  };
};

export default useNavigation;
