import React from 'react';
import { css } from '@emotion/css';
import { getCatalogBySourceId } from 'api/int-api/utils';
import { LinkButton, useStyles2 } from '@grafana/ui';
import { getCloudProviderName } from 'feature/common/utils/utils';
import { getLogsUrlByProvider } from 'scenes/misc';

const getStyles = () => ({
  link: css({
    paddingLeft: 0,
  }),
});

export const ViewYourLogsSection = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getStyles);
  const provider = getCatalogBySourceId(sourceId);
  const providerName = !!provider ? getCloudProviderName(provider) : '';
  const logsUrl = !!provider ? getLogsUrlByProvider(provider) : '';

  return (
    <li>
      <h2>View your logs</h2>
      <p>
        Find your logs directly in the Cloud Provider app
        {!!logsUrl && (
          <>
            <span>, </span>
            <LinkButton className={styles.link} fill={'text'} href={logsUrl}>
              {providerName} Logs
            </LinkButton>
          </>
        )}
      </p>
    </li>
  );
};
