import { css } from '@emotion/css';
import React, { FC } from 'react';

import { useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

const getStyles = () => css`
  display: flex;
  width: 32px;
  height: 32px;
`;

export const AWSIcon: FC<{ service: string }> = ({ service }) => {
  const styles = useStyles2(getStyles);

  return !!service ? (
    <img
      data-testid={Pages.CloudWatch.serviceIcon}
      className={styles}
      src={`https://storage.googleapis.com/grafanalabs-integration-logos/aws/${service.replace('/', '-')}.svg`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = 'https://storage.googleapis.com/grafanalabs-integration-logos/aws/AWS-CloudWatch.svg';
      }}
      alt={`AWS Service Icon for ${service}`}
    />
  ) : null;
};
