import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export const getToggleStyles = (theme: GrafanaTheme2) => {
  return {
    container: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    hostnameInput: css`
      max-width: 480px;
    `,
    label: css`
      max-width: unset;
    `,
    toggleGroup: css`
      margin-bottom: 0;
    `,
    buttonGroup: css`
      display: flex;
      justify-content: space-between;
    `,
    alignRight: css`
      justify-content: flex-end;
    `,
    linkButton: css`
      background: none;
      border: none;
      color: ${theme.colors.text.link};
      cursor: pointer;
      padding: 0;
      font-size: ${theme.typography.bodySmall.fontSize};
      outline: inherit;
      margin-top: ${theme.spacing(1)};
      display: flex;
      align-items: center;
    `,
    infoIcon: css`
      margin-right: ${theme.spacing(0.5)};
    `,
    alert: css`
      margin-bottom: ${theme.spacing(1)};
    `,
  };
};
