import { css, cx } from '@emotion/css';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Dropdown, Menu, Spinner, useStyles2 } from '@grafana/ui';
import { useCspIntegration, useGetIntegration } from 'api/int-api/queries';
import { config, PluginPage } from '@grafana/runtime';
import { QueryError } from 'components/QueryError/QueryError';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'enums';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import useNavigation from 'hooks/useNavigation';
import { isCreateJobPage, isEditJobPage } from './utils';
import { getCatalogBySourceId } from 'api/int-api/utils';
import { prefixRoute } from 'utils/utils.routing';
import { ROUTES } from 'utils/consts';
import { CloudProvider } from 'types/CloudProvider';
import { Pages } from 'e2eSelectors/pages';
import { UninstallModal } from '../UninstallModal/UninstallModal';

const getStyles = (theme: GrafanaTheme2) => {
  const { colors, typography } = theme;

  return {
    container: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 0 auto;
    `,
    wrapper: css`
      display: flex;
      width: 100%;
      max-width: 1480px;
      margin: 0 auto;
      flex-direction: column;
    `,
    fullWidthPage: css`
      margin-left: unset;
      margin-right: unset;
      max-width: unset;

      > div,
      ul {
        margin-left: unset;
        margin-right: unset;
      }
    `,

    tabsContent: css`
      color: ${colors.text.primary};

      h4 {
        font-size: ${typography.pxToRem(19)};
      }

      section:not(:last-of-type) {
        margin-bottom: 70px;
      }
    `,

    tabsBar: css`
      padding: 0 20px;

      > div,
      ul {
        max-width: 1440px;
        margin: 0 auto;
      }
    `,
    icon: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
    `,
    successColor: css`
      color: ${theme.colors.success.text};
    `,
    tabWrapper: css`
      margin-top: ${theme.spacing(5)};
    `,
  };
};

type Props = {
  sourceId: string;
  children: any;
  createOrEditElement?: string;
};

export const Source = ({ sourceId, children, createOrEditElement = '' }: Props) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const {
    data: currentIntegration,
    isPending: isCurrentIntegrationPending,
    isError: isCurrentIntegrationError,
  } = useCspIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const {
    data,
    isPending: isIntegrationPending,
    isError: isIntegrationError,
  } = useGetIntegration(currentIntegration?.id ?? '', pluginId, jsonData.grafana_instance_id);
  const provider = getCatalogBySourceId(sourceId);

  const { trackRudderStackEvent } = useRudderStack();

  const { location } = useNavigation();
  const isCreatePage = isCreateJobPage(location.pathname);
  const isEditPage = isEditJobPage(location.pathname);
  const isCreateOrEditPage = isCreatePage || isEditPage;

  useEffect(() => {
    const catalog = getCatalogBySourceId(sourceId);
    if (catalog) {
      switch (catalog) {
        case CloudProvider.AWS:
          trackRudderStackEvent(RudderStackEvents.AwsConfigurationViewPage, {
            integration_slug: sourceId,
          });
          break;

        case CloudProvider.AZURE:
          trackRudderStackEvent(RudderStackEvents.AzureConfigurationViewPage, {
            integration_slug: sourceId,
          });
          break;

        case CloudProvider.GCP:
          trackRudderStackEvent(RudderStackEvents.GcpConfigurationViewPage, {
            integration_slug: sourceId,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, setShowModal] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item
        label="Uninstall"
        testId={Pages.Source.uninstallIntegrationButton}
        onClick={() => {
          setShowModal(true);
          trackRudderStackEvent(RudderStackEvents.UninstallButton, { integration_slug: currentIntegration?.id });
        }}
        destructive
      />
    </Menu>
  );

  const actionButtons = (
    <>
      <Dropdown overlay={menu}>
        <Button variant="secondary" data-testid={Pages.Source.actionsDropdownButton}>
          Actions
        </Button>
      </Dropdown>
    </>
  );

  const logo_url =
    config.theme2.isLight && data?.logo?.light_theme_url ? data?.logo?.light_theme_url : data?.logo?.dark_theme_url;

  const pageNav = useMemo(() => {
    const configParentItem = { text: 'Configuration', url: prefixRoute(provider, ROUTES.Configuration) };
    const cloudwatchParentItem = {
      text: 'CloudWatch metrics',
      url: prefixRoute(provider, `${ROUTES.Configuration}/cloudwatch`),
      parentItem: configParentItem,
    };

    return !isCreateOrEditPage
      ? {
          text: !!data && data.name ? data.name : '',
          img: !!data && data.name ? logo_url : undefined,
          parentItem: configParentItem,
        }
      : {
          text: isCreatePage ? `Create new ${createOrEditElement}` : `Edit ${createOrEditElement}`,
          img: logo_url,
          parentItem: cloudwatchParentItem,
        };
  }, [data, logo_url, isCreateOrEditPage, isCreatePage, createOrEditElement, provider]);

  const configurationOverviewContent = (
    <div className={styles.container}>
      <>
        {currentIntegration !== undefined && (
          <>
            <UninstallModal
              isOpen={showModal}
              selectedIntegration={currentIntegration}
              onClose={() => setShowModal(false)}
            />
          </>
        )}
      </>
      <div className={cx(styles.wrapper, styles.fullWidthPage)}>{currentIntegration && children}</div>
    </div>
  );

  return isCurrentIntegrationPending || isIntegrationPending ? (
    <PluginPage pageNav={pageNav}>
      <Spinner />
    </PluginPage>
  ) : isIntegrationError || isCurrentIntegrationError ? (
    <QueryError />
  ) : (
    <PluginPage
      pageNav={pageNav}
      actions={data?.installation !== undefined && !isCreateOrEditPage ? actionButtons : undefined}
    >
      {!isCreateOrEditPage ? (
        <div className={styles.tabWrapper}>{configurationOverviewContent}</div>
      ) : (
        configurationOverviewContent
      )}
    </PluginPage>
  );
};
