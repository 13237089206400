import { EmbeddedScene, SceneAppPage, SceneAppPageLike, SceneAppPageState, SceneReactObject } from '@grafana/scenes';
import { GCP_OVERVIEW_URL } from 'scenes/GCP/routes';
import { Homepage as GcpHomePage } from 'feature/GCP/pages/Homepage/Homepage';

const getGcpOverviewScene = () => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: GcpHomePage,
    }),
  });
};

export const getOverviewSceneTab = (parent?: SceneAppPageLike) => {
  const state: SceneAppPageState = {
    title: 'Overview',
    url: GCP_OVERVIEW_URL,
    preserveUrlKeys: [],
    getScene: () => getGcpOverviewScene(),
  };
  if (parent) {
    state.getParentPage = () => parent;
  }
  return new SceneAppPage(state);
};
