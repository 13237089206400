import { config } from '@grafana/runtime';
import { SceneObject, SceneQueryRunner, SceneTimeRange, SceneVariable, SceneVariableState } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { DataSourceInstanceSettings, RawTimeRange, TimeRange } from '@grafana/data';
import { RudderStackEvents } from 'enums';
import { ScenePageType } from 'scenes/types';
import {
  AWS_CONFIGURATION_URL,
  AWS_DASHBOARDS_URL,
  AWS_LOGS_URL,
  AWS_OVERVIEW_URL,
  AWS_SERVICES_URL,
  AWS_URL,
  EC2_URL,
  RDS_URL,
} from 'scenes/AWS/routes';
import {
  GCP_CONFIGURATION_URL,
  GCP_DASHBOARDS_URL,
  GCP_LOGS_URL,
  GCP_OVERVIEW_URL,
  GCP_SERVICES_URL,
  GCP_URL,
} from 'scenes/GCP/routes';
import {
  AZURE_CONFIGURATION_URL,
  AZURE_DASHBOARDS_URL,
  AZURE_LOGS_URL,
  AZURE_OVERVIEW_URL,
  AZURE_SERVICES_URL,
  AZURE_URL,
} from 'scenes/Azure/routes';
import { CloudProvider } from 'types/CloudProvider';

export const PLUGIN_ID_STORAGE_KEY = `grafana.grafana-csp-app.data`;

const getDataSourceByUID = (dataSourceUID: string): DataSourceInstanceSettings | undefined => {
  if (dataSourceUID) {
    const dsName = Object.keys(config.datasources).find((dsName) => config.datasources[dsName].uid === dataSourceUID);
    if (dsName) {
      return config.datasources[dsName];
    }
  }
  return undefined;
};

export const getDataSourceSettings = (type: 'prometheus' | 'loki'): DataSourceInstanceSettings => {
  let propName;
  let varName;
  switch (type) {
    case 'prometheus':
      varName = `var-datasource`;
      propName = 'promName';
      break;

    case 'loki':
      varName = `var-lokiDatasource`;
      propName = 'lokiName';
      break;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const dataSourceUIDParam = urlParams.get(varName);
  if (dataSourceUIDParam) {
    const dsFound = getDataSourceByUID(dataSourceUIDParam);
    if (dsFound) {
      return dsFound;
    }
  }

  const dataSourceUIDStored = getPluginStorageProp(propName);
  const dsFound = getDataSourceByUID(dataSourceUIDStored);
  if (dsFound) {
    return dsFound;
  }

  // Try and find a default datasource
  const defaultDataSourceName = Object.keys(config.datasources).find((dsName) => {
    return config.datasources[dsName].type === type && config.datasources[dsName].isDefault;
  });
  if (defaultDataSourceName) {
    return config.datasources[defaultDataSourceName];
  }

  // If there is no default just pick the first one of specified type
  const dsName = Object.keys(config.datasources).find((dsName) => config.datasources[dsName].type === type);
  if (dsName) {
    return config.datasources[dsName];
  }
  return { uid: '', name: '' } as DataSourceInstanceSettings;
};

export const onChangeDatasourceBehavior = (variable: SceneVariable<SceneVariableState>) => {
  let prometheusUID = variable.getValue?.() as string;
  if (!prometheusUID) {
    const promDataSource = getDataSourceSettings('prometheus');
    if (promDataSource) {
      prometheusUID = promDataSource.uid;
    }
  }
  setPluginStorageProp('promName', prometheusUID);
};

type Query = {
  expr: string;
  instant?: boolean;
  interval?: string;
  range?: boolean;
  refId: string;
  legendFormat?: string;
  format?: string;
};

/** Walks up the scene graph, returning the first non-undefined result of `extract` */
function getClosestScene<T>(sceneObject: SceneObject, extract: (s: SceneObject) => T | undefined): T | undefined {
  let curSceneObject: SceneObject | undefined = sceneObject;
  let extracted: T | undefined = undefined;

  while (curSceneObject && !extracted) {
    extracted = extract(curSceneObject);
    curSceneObject = curSceneObject.parent;
  }

  return extracted;
}

/**
 * Will walk up the scene object graph to the closest SceneQueryRunner
 */
export function getSceneQueryRunner(sceneObject: SceneObject): SceneQueryRunner | undefined {
  return getClosestScene(sceneObject, (obj) => {
    if (obj.state.$data instanceof SceneQueryRunner) {
      return obj.state.$data;
    }

    return undefined;
  });
}

export function getGenericQueryRunner(datasource: DataSourceRef, query: string, options?: any, extraQueries?: Query[]) {
  const queries: Query[] = [
    {
      expr: query,
      refId: 'A',
      ...options,
    },
  ];

  if (extraQueries) {
    queries.push(...extraQueries);
  }

  return new SceneQueryRunner({
    datasource: datasource,
    queries,
  });
}

const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export enum ServiceWithOotbViews {
  Ec2 = 'ec2',
  Rds = 'rds',
}

const formatName = (name: string) => {
  if (name === 'average') {
    return 'avg';
  } else if (name === 'maximum') {
    return 'max';
  } else if (name === 'minimum') {
    return 'min';
  } else if (name === 'percent') {
    return '%';
  } else if (name === 'cpuutilization') {
    return 'CPU util';
  } else if (name === 'ebsiobalance') {
    return 'EBS IO';
  } else if (name === 'ebsbyte') {
    return 'EBS byte';
  } else if (name === 'iops') {
    return 'IOPS';
  }
  return name;
};

export const formatMetricName = (name: string, service: ServiceWithOotbViews): string => {
  if (name.startsWith(`aws_${service}_`)) {
    return capitalizeFirstLetter(
      name
        .split(`aws_${service}_`)[1]
        .split('_')
        .map((el) => formatName(el))
        .join(' ')
    );
  }
  if (name.includes('_')) {
    return capitalizeFirstLetter(
      name
        .split('_')
        .map((el) => formatName(el))
        .join(' ')
    );
  }
  return name;
};

export const getPluginStorageProp = (propName: string) => {
  return JSON.parse(window?.localStorage?.getItem(PLUGIN_ID_STORAGE_KEY) || '{}')?.[propName];
};

export const setPluginStorageProp = (propName: string, value: any) => {
  localStorage?.setItem(
    PLUGIN_ID_STORAGE_KEY,
    JSON.stringify({
      ...(JSON.parse(localStorage?.getItem(PLUGIN_ID_STORAGE_KEY) as string) || {}),
      [propName]: value,
    })
  );
};

function addTimeRangeHandler(timeRange: SceneTimeRange, onTimeRangeChange?: (timeRange: TimeRange) => void) {
  timeRange.addActivationHandler(() => {
    const listener = timeRange.subscribeToState((newState) => {
      const { raw } = newState.value;
      const newTimeRange = { from: raw.from.toString(), to: raw.to.toString() };
      setPluginStorageProp('timeRange', newTimeRange);

      if (typeof onTimeRangeChange === 'function') {
        onTimeRangeChange(newState.value);
      }
      (window as any).rudderanalytics?.track(RudderStackEvents.ChangeTimeRange, newTimeRange);
    });

    return () => listener?.unsubscribe();
  });
}

export const makeTimeRange = (
  from?: string,
  to?: string,
  onTimeRangeChange?: (timeRange: TimeRange) => void,
  defaultValue: RawTimeRange = { from: 'now-1h', to: 'now' }
): SceneTimeRange => {
  const timeRangeStored = getPluginStorageProp('timeRange');

  const newFrom = from ?? (Boolean(timeRangeStored?.from) ? timeRangeStored?.from : defaultValue.from);
  const newTo = to ?? (Boolean(timeRangeStored?.to) ? timeRangeStored?.to : defaultValue.to);

  const sceneTimeRange = new SceneTimeRange({ from: newFrom, to: newTo });
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  return sceneTimeRange;
};

const getScenePageTypeFromAwsPath = () => {
  if (location.pathname === AWS_URL) {
    return ScenePageType.AWS;
  } else if (location.pathname.startsWith(EC2_URL)) {
    return ScenePageType.EC2;
  } else if (location.pathname.startsWith(RDS_URL)) {
    return ScenePageType.RDS;
  } else if (location.pathname.startsWith(AWS_LOGS_URL)) {
    return ScenePageType.AWS_LOGS;
  } else if (location.pathname.startsWith(AWS_DASHBOARDS_URL)) {
    if (/\/a\/grafana-csp-app\/aws\/dashboards\/\d+/g.test(location.pathname)) {
      return ScenePageType.AWS_DASHBOARD_EMBEDDED;
    }
    return ScenePageType.AWS_DASHBOARDS;
  } else if (location.pathname === AWS_CONFIGURATION_URL) {
    return ScenePageType.AWS_CONFIGURATION;
  } else if (location.pathname === AWS_OVERVIEW_URL) {
    return ScenePageType.AWS_OVERVIEW;
  } else if (location.pathname === AWS_SERVICES_URL) {
    return ScenePageType.AWS_SERVICES;
  } else {
    return ScenePageType.UNKNOWN;
  }
};

const getScenePageTypeFromGcpPath = () => {
  if (location.pathname === GCP_URL) {
    return ScenePageType.GCP;
  } else if (location.pathname === GCP_OVERVIEW_URL) {
    return ScenePageType.GCP_OVERVIEW;
  } else if (location.pathname === GCP_CONFIGURATION_URL) {
    return ScenePageType.GCP_CONFIGURATION;
  } else if (location.pathname.startsWith(GCP_LOGS_URL)) {
    return ScenePageType.GCP_LOGS;
  } else if (location.pathname.startsWith(GCP_DASHBOARDS_URL)) {
    if (/\/a\/grafana-csp-app\/gcp\/dashboards\/\d+/g.test(location.pathname)) {
      return ScenePageType.GCP_DASHBOARD_EMBEDDED;
    }
    return ScenePageType.GCP_DASHBOARDS;
  } else if (location.pathname.startsWith(GCP_SERVICES_URL)) {
    return ScenePageType.GCP_SERVICES;
  } else {
    return ScenePageType.UNKNOWN;
  }
};

const getScenePageTypeFromAzurePath = () => {
  if (location.pathname === AZURE_URL) {
    return ScenePageType.AZURE;
  } else if (location.pathname === AZURE_OVERVIEW_URL) {
    return ScenePageType.AZURE_OVERVIEW;
  } else if (location.pathname === AZURE_CONFIGURATION_URL) {
    return ScenePageType.AZURE_CONFIGURATION;
  } else if (location.pathname.startsWith(AZURE_LOGS_URL)) {
    return ScenePageType.AZURE_LOGS;
  } else if (location.pathname.startsWith(AZURE_DASHBOARDS_URL)) {
    if (/\/a\/grafana-csp-app\/azure\/dashboards\/\d+/g.test(location.pathname)) {
      return ScenePageType.AZURE_DASHBOARD_EMBEDDED;
    }
    return ScenePageType.AZURE_DASHBOARDS;
  } else if (location.pathname.startsWith(AZURE_SERVICES_URL)) {
    return ScenePageType.AZURE_SERVICES;
  } else {
    return ScenePageType.UNKNOWN;
  }
};

export const getScenePageTypeFromPath = () => {
  if (location.pathname.startsWith(AWS_URL)) {
    return getScenePageTypeFromAwsPath();
  } else if (location.pathname.startsWith(GCP_URL)) {
    return getScenePageTypeFromGcpPath();
  } else if (location.pathname.startsWith(AZURE_URL)) {
    return getScenePageTypeFromAzurePath();
  }
  return ScenePageType.UNKNOWN;
};

export const isAWSPage = () => location.pathname.startsWith(AWS_URL);
export const isGCPPage = () => location.pathname.startsWith(GCP_URL);
export const isAzurePage = () => location.pathname.startsWith(AZURE_URL);

export const getDashboardsUrlByProvider = (provider: CloudProvider) => {
  switch (provider) {
    case CloudProvider.AWS:
      return AWS_DASHBOARDS_URL;

    case CloudProvider.AZURE:
      return AZURE_DASHBOARDS_URL;

    case CloudProvider.GCP:
      return GCP_DASHBOARDS_URL;

    default:
      return '';
  }
};

export const getDashboardFoldersByIntegration = (integrationId: CloudProvider | undefined) => {
  switch (integrationId) {
    case CloudProvider.AWS:
      return 'cloud-provider---aws';
    case CloudProvider.AZURE:
      return 'cloud-provider---azure';
    case CloudProvider.GCP:
      return 'cloud-provider---gcp';

    default:
      return '';
  }
};

export const getServicesUrlByProvider = (provider: CloudProvider) => {
  switch (provider) {
    case CloudProvider.AWS:
      return AWS_SERVICES_URL;

    case CloudProvider.AZURE:
      return AZURE_SERVICES_URL;

    case CloudProvider.GCP:
      return GCP_SERVICES_URL;

    default:
      return '';
  }
};

export const getLogsUrlByProvider = (provider: CloudProvider) => {
  switch (provider) {
    case CloudProvider.AWS:
      return AWS_LOGS_URL;

    case CloudProvider.AZURE:
      return AZURE_LOGS_URL;

    case CloudProvider.GCP:
      return GCP_LOGS_URL;

    default:
      return '';
  }
};
