import { SceneAppPage } from '@grafana/scenes';
import { ScenesCustomParams } from 'scenes/types';
import { VAR_LOKI_DATASOURCE } from 'scenes/variables';
import { AZURE_LOGS_URL } from 'scenes/Azure/routes';
import { LogsIcon } from 'img';
import { getLogsScene } from 'scenes/Logs/getLogsScene';
import { CloudProvider } from 'types/CloudProvider';
import { AzureLogsQueryState } from 'scenes/Logs/queries';

export const getLogsSceneTab = ({ lokiName }: ScenesCustomParams): SceneAppPage => {
  return new SceneAppPage({
    title: 'Logs',
    titleImg: LogsIcon,
    hideFromBreadcrumbs: false,
    url: AZURE_LOGS_URL,
    getScene: () => getLogsScene<AzureLogsQueryState>(lokiName, CloudProvider.AZURE),
    preserveUrlKeys: ['from', 'to', `var-${VAR_LOKI_DATASOURCE}`],
  });
};
