import { ErrorApiResult, ErrorFetchResponse } from 'api/common/data-model';
import { MutationKey, QueryClient, useMutationState, useQueryClient } from '@tanstack/react-query';
import { faro, isObject } from '@grafana/faro-web-sdk';
import { constructErrorMessage, errorIntegrationApiResponse, text } from 'utils/consts';
import { findKey } from 'lodash';
import { MutationState } from '@tanstack/query-core';

export function isErrorResponse<D = unknown>(thing: any): thing is ErrorFetchResponse<D> {
  return (
    typeof thing === 'object' &&
    'status' in thing &&
    !isNaN(thing.status) &&
    (thing.status < 200 || thing.status >= 300) &&
    'data' in thing
  );
}

export const handleError = (message: string, error: Error) => {
  if (jest === undefined) {
    console.error(message, error);
    faro.api.pushError(isObject(error) ? new Error(JSON.stringify(error)) : error);
  }
};

export function parseErrorMessage(
  { status, data: { error } }: ErrorFetchResponse<ErrorApiResult>,
  customErrorMessage: string,
  orgSlug?: string
) {
  if (status === 401) {
    return text.error.unauthorizedError;
  }

  if (status !== 403) {
    return customErrorMessage;
  }

  const key = findKey(errorIntegrationApiResponse, (value) => value === error);

  if (key) {
    return constructErrorMessage(orgSlug).error[key];
  }

  return text.error.genericError;
}

export const useClearMutationCache = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.getMutationCache().clear();
  };
};

export const useClearMutationByKey = (mutationKeys: MutationKey[] = []) => {
  const queryClient = useQueryClient();

  return () => {
    mutationKeys.forEach((mutationKey) => {
      const currentMutations = queryClient.getMutationCache().findAll({ mutationKey });
      if (currentMutations?.length > 0) {
        currentMutations.forEach((mutation) => queryClient.getMutationCache().remove(mutation));
      }
    });
  };
};

export const useLastMutationState = <TResult = MutationState>(mutationKey: any[]): TResult => {
  const states = useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state,
  });

  if (states?.length > 0) {
    return states[states.length - 1] as TResult;
  }
  return { status: 'idle', isLoading: false, error: undefined } as TResult;
};

export const invalidateSharedMutations = (queryClient: QueryClient, mutationKeys: MutationKey[] = []) => {
  if (!!queryClient) {
    return Promise.all(
      mutationKeys.map((mutationKey) =>
        queryClient.invalidateQueries({
          queryKey: mutationKey,
          refetchType: 'all',
        })
      )
    );
  }
  return Promise.resolve();
};

interface ApiErrorResult {
  error: {
    message: string;
    code: string;
  };
}

// integrations-API and Hosted-Exporters-API returns this type of error
function isIntOrHeApiError(thing: any): thing is ApiErrorResult {
  return (
    typeof thing === 'object' &&
    'error' in thing &&
    typeof thing.error === 'object' &&
    'message' in thing.error &&
    'code' in thing.error
  );
}

export const getMessageFromApiError = (error: any) => {
  if (error) {
    let message: string;

    if (typeof error === 'object' && 'data' in error) {
      if (isIntOrHeApiError(error.data)) {
        message = error.data.error.message;
      } else {
        message = JSON.stringify(error.data);
      }
    } else {
      message = JSON.stringify(error);
    }
    return message;
  }
  return '';
};
