import React, { FC } from 'react';

import { JobManager } from '../JobManager/JobManager';

import { CreateJob } from './JobForm/CreateJob';
import { EditJob } from './JobForm/EditJob';
import { JobTable } from './JobTable';

export const CloudWatchInstructions: FC = () => {
  return (
    <JobManager saasIntegrationId="cloudwatch" EditJobForm={EditJob} CreateJobForm={CreateJob} JobTable={JobTable} />
  );
};
