import {
  behaviors,
  EmbeddedScene,
  SceneControlsSpacer,
  SceneGridItem,
  SceneGridLayout,
  SceneGridRow,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VAR_DATASOURCE } from 'scenes/variables';
import { getGenericQueryRunner, makeTimeRange, onChangeDatasourceBehavior } from 'scenes/misc';
import OpenInAwsButton from 'scenes/components/OpenInAwsButton';
import { TimeRange } from '@grafana/data';
import { getVariables } from 'scenes/AWS/Dashboards/EC2/getVariables';
import { allQueries } from 'scenes/AWS/Dashboards/EC2/queries';
import { ExplorablePanel } from 'scenes/common/ExplorablePanel';
import { PLUGIN_BASE_URL } from 'utils/utils.routing';

export const getOverviewDrilldownScene = (
  instanceId: string,
  prometheusName: string,
  from?: string,
  to?: string,
  loadFromExtension = false,
  onTimeRangeChange?: (timeRange: TimeRange) => void
) => {
  const datasourceObject = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const { datasources } = getVariables(prometheusName, loadFromExtension);

  const sceneTimeRange = makeTimeRange(from, to, onTimeRangeChange);

  const rows = [
    // 1
    {
      title: 'CPU utilization',
      firstColumn: {
        title: 'CPU utilization avg',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.cpuUtilizationAvg(instanceId)),
        unit: 'percent',
      },
      secondColumn: {
        title: 'CPU utilization max',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.cpuUtilizationMax(instanceId)),
        unit: 'percent',
      },
    },
    // 2
    {
      title: 'Network',
      firstColumn: {
        title: 'NetworkIn Instance avg',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.networkInAvg(instanceId)),
        unit: 'bytes',
      },
      secondColumn: {
        title: 'NetworkOut Instance avg',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.networkOutAvg(instanceId)),
        unit: 'bytes',
      },
    },
    // 3
    {
      title: 'Disk Ops',
      firstColumn: {
        title: 'DiskWriteOps Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.diskWriteSum(instanceId)),
      },
      secondColumn: {
        title: 'DiskReadOps Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.diskReadSum(instanceId)),
      },
    },
    // 4
    {
      title: 'StatusCheckFailed',
      firstColumn: {
        title: 'StatusCheckFailed Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.statusCheckFailedSum(instanceId)),
      },
      secondColumn: {
        title: 'StatusCheckFailed sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.statusCheckFailedSystemSum(instanceId)),
      },
    },
    // 5
    {
      title: 'ebs Bytes',
      firstColumn: {
        title: 'ebsReadBytes Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.ebsReadBytesSum(instanceId)),
        unit: 'bytes',
      },
      secondColumn: {
        title: 'ebsWriteBytes Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.ebsWriteBytesSum(instanceId)),
        unit: 'bytes',
      },
    },
    // 6
    {
      title: 'ebs Ops',
      firstColumn: {
        title: 'ebsReadOps Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.ebsReadOpsSum(instanceId)),
      },
      secondColumn: {
        title: 'ebsWriteOps Instance sum',
        data: getGenericQueryRunner(datasourceObject, allQueries.byInstance.ebsWriteOpsSum(instanceId)),
      },
    },
  ];

  return new EmbeddedScene({
    $timeRange: sceneTimeRange,
    $variables: new SceneVariableSet({
      variables: [datasources],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      ...(loadFromExtension
        ? [
            new OpenInAwsButton({
              href: `${PLUGIN_BASE_URL}/dashboard/ec2/overview/${instanceId}?var-datasource=${prometheusName}`,
            }),
          ]
        : []),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ['5s', '1m', '1h'],
        isOnCanvas: true,
      }),
    ],
    body: new SceneGridLayout({
      isDraggable: false,
      isLazy: true,
      $behaviors: [
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_DATASOURCE,
          onChange: onChangeDatasourceBehavior,
        }),
      ],
      children: [
        new SceneGridRow({
          x: 0,
          y: 0,
          title: 'Metadata',
          children: [
            new SceneGridItem({
              width: 24,
              x: 0,
              y: 0,
              isResizable: false,
              isDraggable: true,
              body: new ExplorablePanel({
                $data: getGenericQueryRunner(datasourceObject, allQueries.allTagsForAnInstance(instanceId), {
                  format: 'table',
                  instant: true,
                }),
                title: '',
                pluginId: 'table',
                fieldConfig: {
                  defaults: {
                    custom: {
                      align: 'auto',
                      cellOptions: {
                        type: 'auto',
                      },
                      inspect: false,
                    },
                    mappings: [],
                  },
                  overrides: [
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Time',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Value',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: '__name__',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                  ],
                },
              }),
            }),
          ],
        }),
        [
          ...rows.map(
            (row) =>
              new SceneGridRow({
                x: 0,
                y: 0,
                title: row.title,
                children: [
                  new SceneGridItem({
                    width: 12,
                    height: 10,
                    x: 0,
                    y: 0,
                    isResizable: false,
                    isDraggable: true,
                    body: new ExplorablePanel({
                      title: row.firstColumn.title,
                      $data: row.firstColumn.data,
                      fieldConfig: {
                        overrides: [],
                        defaults: {
                          unit: row.firstColumn.unit,
                        },
                      },
                    }),
                  }),
                  new SceneGridItem({
                    width: 12,
                    height: 10,
                    x: 12,
                    y: 0,
                    isResizable: false,
                    isDraggable: true,
                    body: new ExplorablePanel({
                      title: row.secondColumn.title,
                      $data: row.secondColumn.data,
                      fieldConfig: {
                        overrides: [],
                        defaults: {
                          unit: row.secondColumn.unit,
                        },
                      },
                    }),
                  }),
                ],
              })
          ),
        ],
      ].flat(),
    }),
  });
};
