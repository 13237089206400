import { SceneAppDrilldownView, SceneAppPage } from '@grafana/scenes';
import { VAR_DATASOURCE } from 'scenes/variables';
import { getInstanceDrilldownScene } from 'scenes/AWS/Dashboards/RDS/getInstanceDrilldownScene.ts';
import { RDS_URL } from 'scenes/AWS/routes';

export const getInstanceDrilldown = (prometheusName: string, from?: string, to?: string): SceneAppDrilldownView => {
  return {
    routePath: RDS_URL + '/instance/:dimension_DBInstanceIdentifier',
    getPage: (routeMatch, parent) => {
      const dbId: string = routeMatch.params.dimension_DBInstanceIdentifier;

      return new SceneAppPage({
        title: dbId,
        url: RDS_URL + `/instance/${dbId}`,
        preserveUrlKeys: ['from', 'to', `var-${VAR_DATASOURCE}`],
        getParentPage: () => parent,
        getScene: () => getInstanceDrilldownScene(dbId, prometheusName, from, to),
      });
    },
  };
};
