import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  link: css({
    color: theme.colors.text.link,
  }),
});

export const LinkText = ({ children, href }: { children: string; href: string }) => {
  const styles = useStyles2(getStyles);
  return (
    <a href={href} target="_blank" rel="noreferrer" className={styles.link}>
      {children}
    </a>
  );
};
