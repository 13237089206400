import { css } from '@emotion/css';
import React, { FC, useContext, useMemo, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Spinner, useStyles2 } from '@grafana/ui';

import { CreateJobFormProps } from '../../JobManager/JobManager';
import { Automatic as AutomaticAuth } from '../Auth/Automatic';
import { Manual as ManualAuth } from '../Auth/Manual';

import { AccountForm } from './AccountForm';
import { colors } from 'utils/consts';
import { AgentIcon, RemoteIcon } from 'img/hostedData';
import { AccountFormOutput } from '../../types';
import { CloudWatchConfig, CloudWatchJob } from 'api/hosted-exporters-api/data-models';
import { useCloudwatchConfig } from 'api/hosted-exporters-api/queries';
import { SelectGroup } from 'components/SelectGroup';
import { mapServices } from '../../utils';
import { ActionType } from 'enums';
import { SelectService } from '../services/SelectServices/SelectService';
import { CreateJobForm } from './CreateJobForm';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { AddCustomNamespaces } from '../services/CustomNamespaces/AddCustomNamespaces';

const getStyles = (theme: GrafanaTheme2) => ({
  instructions: css`
    font-size: 20px;
    max-width: 1000px;
    font-weight: ${theme.typography.fontWeightMedium};
    margin-bottom: 40px;
    margin-left: ${theme.spacing(3)};
  `,
  instructionItem: css`
    max-width: 1000px;
    margin-bottom: 40px;

    h2 {
      margin: ${theme.spacing(2)} 0;
      line-height: 26px;
      font-size: 19px;
      color: ${theme.isLight ? colors.blue04 : theme.colors.text.maxContrast};
    }

    a {
      color: ${theme.isLight ? colors.blue07 : colors.blue03};
    }

    h3 {
      line-height: 22px;
      font-size: 16px;
    }

    p {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.body.fontWeight};
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  authMethod: css`
    margin-top: ${theme.spacing(4)};
  `,
  stepText: css`
    padding-bottom: 7px;
  `,
});

enum AuthMethods {
  Manual = 'manual',
  Automatic = 'automatic',
}

const methodOptions = [
  {
    label: 'Automatically',
    value: AuthMethods.Automatic,
    icon: RemoteIcon,
    description: 'Use CloudFormation to create the new role in the AWS IAM console.',
  },
  {
    label: 'Manually',
    value: AuthMethods.Manual,
    icon: AgentIcon,
    description:
      "Use the AWS IAM console. You'll need to copy and paste Grafana Cloud account ID, External ID and permissions statement available below.",
  },
];

export const CreateJob: FC<CreateJobFormProps> = ({ onSaveJob }) => {
  const styles = useStyles2(getStyles);
  const [authMethod, setAuthMethod] = useState(AuthMethods.Automatic);
  const [account, setAccount] = useState<AccountFormOutput>({
    name: '',
    arn: '',
    regions: [],
    export_tags: true,
    isValid: true,
    isDirty: false,
  });
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [customNamespaces, setCustomNamespaces] = useState<string[]>([]);

  const { pluginId, jsonData } = useContext(PluginMetaContext);

  const { data, isSuccess } = useCloudwatchConfig(pluginId, jsonData.grafana_instance_id);
  const cloudWatchConfig = data as CloudWatchConfig;
  const defaultCustomNamespace = cloudWatchConfig?.custom_namespace;

  const onCreateJob = (job: CloudWatchJob) => {
    onSaveJob(job);
  };

  const onServiceSelected = (serviceIds: string[]) => setSelectedServices(serviceIds);
  const onAddCustomNamespace = (serviceIds: string[]) => setCustomNamespaces(serviceIds);

  const AuthMethodChoice = useMemo(() => {
    let AuthMethodComponent = null;
    switch (authMethod) {
      case AuthMethods.Manual: {
        AuthMethodComponent = (
          <div key={AuthMethods.Manual} className={styles.authMethod}>
            <ManualAuth />
          </div>
        );
        break;
      }
      case AuthMethods.Automatic: {
        AuthMethodComponent = (
          <div key={AuthMethods.Automatic} className={styles.authMethod}>
            <AutomaticAuth />
          </div>
        );
      }
    }

    return (
      <>
        <h2>Create a new AWS role</h2>
        <p className={styles.stepText}>
          To configure CloudWatch metrics, you must create an AWS role in the AWS IAM console. Choose how you want to
          create this role.
        </p>
        <SelectGroup options={methodOptions} value={authMethod} onChange={setAuthMethod} />
        {AuthMethodComponent}
      </>
    );
  }, [authMethod, styles.authMethod, styles.stepText]);

  const defaultServices = useMemo(
    () =>
      cloudWatchConfig?.supported_services ? mapServices(cloudWatchConfig?.supported_services, ActionType.Add) : [],
    [cloudWatchConfig?.supported_services]
  );

  const existingServices = defaultServices.map((el) => [el.service_id, el.display_name]).flat();

  if (!isSuccess) {
    return <Spinner />;
  }

  return (
    <>
      <ol className={styles.instructions}>
        <li className={styles.instructionItem}>{AuthMethodChoice}</li>
        <li className={styles.instructionItem}>
          <h2>Connect to AWS account</h2>
          <p>
            Once you have successfully created a new AWS IAM role, you can proceed with the installation by entering
            your account info below.
          </p>
          <AccountForm regions={cloudWatchConfig.regions} onAccountChange={setAccount} />
        </li>
        <>
          <li className={styles.instructionItem}>
            <h2>Choose service(s)</h2>
            <p>
              Select which services you want to scrape. You will configure optional settings for each selected service
              in next step.
            </p>
            <SelectService
              defaultServices={defaultServices}
              selectedServices={selectedServices}
              onSelected={onServiceSelected}
            />
            <AddCustomNamespaces
              customNamespaces={customNamespaces}
              onChange={onAddCustomNamespace}
              defaultServices={existingServices}
            />
          </li>
          <li className={styles.instructionItem}>
            <h2>Configure service settings</h2>
            <p>Select which metrics and statistics you want to scrape.</p>
            <CreateJobForm
              account={account}
              defaultServices={defaultServices}
              defaultCustomNamespace={defaultCustomNamespace}
              selectedServices={selectedServices}
              customNamespaces={customNamespaces}
              supportedServices={cloudWatchConfig.supported_services}
              onCreateJob={onCreateJob}
              onServicesChanged={onServiceSelected}
              onNamespacesChanged={onAddCustomNamespace}
            />
          </li>
        </>
      </ol>
    </>
  );
};
