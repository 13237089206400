import { config } from '@grafana/runtime';
import { useGetDashboards } from 'api/dashboards/queries';
import { AlloyService, CloudWatchJob } from 'api/hosted-exporters-api/data-models';
import { useCloudwatchConfig, useGetScrapeJobs, useGetServices } from 'api/hosted-exporters-api/queries';
import {
  AWS_DASHBOARDS_FOLDERS,
  getAwsServicesForHomepageOverviewTable,
  removeDuplicatesFromArray,
  SERVICES_WITH_OOTB_VIEWS,
} from 'feature/AWS/utils/utils';
import { useMemo } from 'react';
import { isAWSPage } from 'scenes/misc';
import { CloudProvider } from 'types/CloudProvider';
import { CspCard, HomepageTableRow, TabItem } from '../types/types';
import { Source, SourceDetails } from 'api/int-api/data-models';
import { RawTimeRange } from '@grafana/data';
import { useGetIntegration } from 'api/int-api/queries';

export function useGetServicesForOverviewTable(pluginId: string, instanceId: string, provider: CloudProvider) {
  const awsEnabled = provider === CloudProvider.AWS;

  const {
    data: integration,
    isLoading: isIntegrationLoading,
    error: integrationError,
  } = useGetIntegration(provider, pluginId, instanceId);

  // AWS queries
  const {
    data: cloudWatchConfig,
    isLoading: isConfigLoading,
    error: configError,
  } = useCloudwatchConfig(pluginId, instanceId, awsEnabled);

  const {
    data: dashboards,
    isLoading: isDashboardsLoading,
    error: dashboardError,
  } = useGetDashboards(awsEnabled && integration?.installation !== undefined ? AWS_DASHBOARDS_FOLDERS : undefined);

  const {
    data: jobs,
    isLoading: isJobsLoading,
    error: jobsError,
  } = useGetScrapeJobs('cloudwatch', pluginId, instanceId, awsEnabled);

  const isAwsLoading = awsEnabled ? isConfigLoading || isDashboardsLoading || isJobsLoading : false;
  const awsError = awsEnabled ? configError || dashboardError || jobsError : undefined;

  // gcp or azure query
  const {
    data: services,
    isLoading: isServicesLoading,
    error: serviceError,
  } = useGetServices(provider, pluginId, instanceId, !awsEnabled);

  const isLoading = isIntegrationLoading || (awsEnabled ? isAwsLoading : isServicesLoading);
  const error = integrationError || (awsEnabled ? awsError : serviceError);

  const data = useMemo(() => {
    if (!isLoading && !error) {
      return awsEnabled
        ? getAwsServicesForHomepageOverviewTable(jobs, cloudWatchConfig, dashboards)
        : getServicesForHomepageOverviewTable(services, integration);
    }
    return [];
  }, [awsEnabled, cloudWatchConfig, dashboards, error, integration, isLoading, jobs, services]);
  const areScrapeJobsDisabled = awsEnabled && jobs?.every((job) => !job.enabled);

  return {
    isLoading,
    error,
    data,
    areScrapeJobsDisabled,
  };
}

export const getServicesForHomepageOverviewTable = (
  services: AlloyService[] = [],
  integration?: SourceDetails
): HomepageTableRow[] => {
  return services.map((service) => {
    return {
      service: service.name,
      source: service.source,
      job_name: service.job_name,
      explore_query: service.explore_query,
      dashboard_uid: integration?.installation !== undefined ? service.dashboard_uid : '',
      currently_sending_data: service.currently_sending_data,
      last_received_at: getStringDateFromTimestamp(service.last_received_at),
    };
  });
};

export const getTotalNumberOfPages = (data: HomepageTableRow[], pageSize: number): number => {
  if (data?.length && pageSize > 0) {
    return Math.ceil(data.length / pageSize);
  }
  return 1;
};

export const paginate = (array: HomepageTableRow[], pageSize: number, pageNumber: number) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const PAGINATION_PAGE_SIZES = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export const getServicesLength = (jobs: CloudWatchJob[]): number => {
  if (jobs.length) {
    return (
      jobs?.reduce((accumulator, currentValue) => accumulator + currentValue.service_configurations.length, 0) ?? 0
    );
  }
  return 0;
};

export const INITIAL_SERVICE_OVERVIEW_SELECT_VALUE = 'All';

export const getServiceOverviewOptions = (
  tableData: HomepageTableRow[] = [],
  filterName: 'service' | 'source'
): string[] => {
  return [INITIAL_SERVICE_OVERVIEW_SELECT_VALUE].concat(
    removeDuplicatesFromArray(tableData.map((data) => data[filterName]))
  );
};

export const filterServiceOverviewOptions = (
  data: HomepageTableRow[],
  filters: Array<{ name: string; value: string }>
) => {
  return data.filter((el) => {
    const result = filters.map(
      (filter) =>
        filter.value === INITIAL_SERVICE_OVERVIEW_SELECT_VALUE ||
        el[filter.name as keyof HomepageTableRow] === filter.value
    );
    return result.every((item) => item === true);
  });
};

function createUrl(path: string, queryParams?: string[][] | Record<string, string> | string | URLSearchParams) {
  const searchParams = new URLSearchParams(queryParams);
  const searchParamsString = searchParams.toString();

  return `${config.appSubUrl}${path}${searchParamsString ? `?${searchParamsString}` : ''}`;
}

export function createExploreLink(datasource: string, query: string, range?: RawTimeRange) {
  if (query === '' || datasource === '') {
    return '/explore';
  }

  return createUrl(`/explore`, {
    schemaVersion: '1',
    panes: JSON.stringify({
      '4v3': {
        datasource,
        queries: [
          {
            refId: 'A',
            expr: query,
          },
        ],
        ...(!!range && { range: { from: range.from, to: range.to } }),
      },
    }),
    orgId: '1',
  });
}

export function getServiceMetricPrefix(service: string) {
  if (service === '') {
    return 'aws_';
  }

  let serviceName = service.toLowerCase();

  if (serviceName.includes('/')) {
    serviceName = serviceName.replaceAll('/', '_');
  }

  if (!serviceName.startsWith('aws_')) {
    serviceName = 'aws_' + serviceName;
  }

  return serviceName;
}

export const getStringDateFromTimestamp = (timestamp: number): string => {
  return new Date(timestamp).toDateString();
};

export const getQuery = (service: HomepageTableRow): string => {
  return isAWSPage()
    ? `{__name__=~"${getServiceMetricPrefix(service?.service)}.*", scrape_job="${service?.source}"}`
    : service?.explore_query ?? '';
};

export const getCloudProvidersCards = (data: Source[]): CspCard[] => {
  return data.map((cloudProvider) => ({
    id: cloudProvider.id as CloudProvider,
    title: getCloudProviderCardsName(cloudProvider.id as CloudProvider),
    description: getCloudProviderDescription(cloudProvider.overview ?? ''),
    icon: config.theme2.isDark ? cloudProvider.logo_url : cloudProvider.light_logo_url,
  }));
};

const getCloudProviderCardsName = (cloudProvider: CloudProvider) => {
  if (cloudProvider === CloudProvider.AWS) {
    return 'Amazon Web Services';
  } else if (cloudProvider === CloudProvider.AZURE) {
    return 'Azure';
  } else if (cloudProvider === CloudProvider.GCP) {
    return 'Google Cloud';
  }
  return '';
};

export const getCloudProviderDescription = (description: string) => {
  if (description.includes('**Links**')) {
    return description.split('**Links**')[0];
  }
  return description;
};

export const getCloudProviderName = (cloudProvider: CloudProvider) => {
  if (cloudProvider === CloudProvider.AWS) {
    return 'AWS';
  } else if (cloudProvider === CloudProvider.AZURE) {
    return 'Azure';
  } else if (cloudProvider === CloudProvider.GCP) {
    return 'GCP';
  }
  return '';
};

export function getDashboardsTabs(tabs: TabItem[] = []) {
  return tabs
    .filter((tab: TabItem) => Object.keys(SERVICES_WITH_OOTB_VIEWS).includes(tab.serviceId))
    .sort((a: TabItem, b: TabItem) => a.name.localeCompare(b.name))
    .concat(
      tabs
        .filter((tab: TabItem) => !Object.keys(SERVICES_WITH_OOTB_VIEWS).includes(tab.serviceId))
        .sort((a: TabItem, b: TabItem) => a.name.localeCompare(b.name))
    );
}
