import { css } from '@emotion/css';
import React, { ReactElement } from 'react';

import { Tooltip, useStyles2 } from '@grafana/ui';
import { DisabledReasonType } from 'api/hosted-exporters-api/data-models';
import { DISABLED_REASONS } from 'utils/consts';

const getStyles = () => ({
  wrapper: css`
    padding: 15px 20px;

    > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  `,
  title: css`
    margin-bottom: 20px;
  `,
  description: css`
    margin: 0;
  `,
  parentContent: css`
    cursor: pointer;
    max-width: 200px;
  `,
});

type DisabledReasonTooltipProps = {
  reason?: DisabledReasonType;
  children: ReactElement | string;
};

export const DisabledReasonTooltip = ({ reason, children }: DisabledReasonTooltipProps) => {
  const styles = useStyles2(getStyles);

  // Reason does not exist, so do not show tooltip
  if (!reason || typeof DISABLED_REASONS[reason] === 'undefined') {
    return <>{children}</>;
  }

  const tooltipContent = (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        <strong>{DISABLED_REASONS[reason].title}</strong>
      </p>
      <p className={styles.description}>{DISABLED_REASONS[reason].description}</p>
    </div>
  );

  return (
    <Tooltip placement="bottom" content={tooltipContent}>
      <div className={styles.parentContent}>{children}</div>
    </Tooltip>
  );
};
