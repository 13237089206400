import { EmbeddedScene, SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { CloudProvider } from 'types/CloudProvider';
import { getServicesUrlByProvider } from 'scenes/misc';
import { ServiceOverview } from 'feature/common/pages/Services/ServiceOverview';

const getServicesScene = (provider: CloudProvider) => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: ServiceOverview,
      props: { provider },
    }),
  });
};

export const getServicesSceneTab = (provider: CloudProvider) => {
  return new SceneAppPage({
    title: 'Services',
    url: getServicesUrlByProvider(provider),
    getScene: () => getServicesScene(provider),
    preserveUrlKeys: [],
  });
};
