import { CloudWatchConfig, CloudWatchJob } from 'api/hosted-exporters-api/data-models';
import { Source } from 'api/int-api/data-models';
import { ROUTES } from 'utils/consts';
import { prefixRoute } from 'utils/utils.routing';
import { GetStartedCardInfo } from 'feature/AWS/pages/Homepage/GetStartedCardInfo';
import { DashboardDb } from 'api/dashboards/data-model';
import { EC2_URL, RDS_URL } from 'scenes/AWS/routes';
import { RudderStackEvents } from 'enums';

import { isDashboardsSectionPresent } from 'utils/misc';
import { CloudProvider } from 'types/CloudProvider';
import { DashboardForServiceOverview, HomepageTableRow } from 'feature/common/types/types';
import { getDashboardsUrlByProvider } from 'scenes/misc';

export const getStartedCards = (awsSources: Source[] = [], areJobsConfigured: boolean): GetStartedCardInfo[] => {
  return awsSources.map((source) => {
    return {
      id: source.id,
      name: source.name,
      exploreLink:
        source.externalUrl ??
        prefixRoute(
          CloudProvider.AWS,
          `${ROUTES.Configuration}/${source.id}${source.id === 'cloudwatch' && !areJobsConfigured ? '/create' : ''}`
        ),
      description: source.overview ?? '',
      logo_url: source.logo_url,
      externalUrl: source.externalUrl ?? '',
    };
  });
};

export const SERVICE_BY_DASHBOARD = {
  'aws-billing': 'AWS/Billing',
  'aws-cloudfront': 'AWS/CloudFront',
  'aws-ebs': 'AWS/EBS',
  'aws-ec2': 'AWS/EC2',
  'aws-ecs': 'AWS/ECS',
  'aws-elb-application-load-balancer': 'AWS/ApplicationELB',
  'aws-elb-classic-load-balancer': 'AWS/ELB',
  'aws-lambda': 'AWS/Lambda',
  'aws-nat-gateways': 'AWS/NATGateway',
  'aws-rds': 'AWS/RDS',
  'aws-s3': 'AWS/S3',
  'aws-ses': 'AWS/SES',
  'aws-sqs': 'AWS/SQS',
  'aws-vpn': 'AWS/VPN',
  'aws-alb-access-logs-overview': 'AWS Application Load Balancer Logs',
};

export const DASHBOARD_ICON = {
  'aws-billing': 'AWS-Billing.svg',
  'aws-cloudfront': 'AWS-CloudFront.svg',
  'aws-ebs': 'AWS-EBS.svg',
  'aws-ec2': 'AWS-EC2.svg',
  'aws-ecs': 'AWS-ECS.svg',
  'aws-elb-application-load-balancer': 'AWS-ApplicationELB.svg',
  'aws-elb-classic-load-balancer': 'AWS-ELB.svg',
  'aws-lambda': 'AWS-Lambda.svg',
  'aws-nat-gateways': 'AWS-NATGateway.svg',
  'aws-api-gateway': 'AWS-ApiGateway.svg',
  'aws-rds': 'AWS-RDS.svg',
  'aws-s3': 'AWS-S3.svg',
  'aws-ses': 'AWS-SES.svg',
  'aws-sqs': 'AWS-SQS.svg',
  'aws-vpn': 'AWS-VPN.svg',
  'aws-alb-access-logs-overview': 'AWS-ELB-Logs.svg',
  'aws-network-loadbalancer': 'AWS-NetworkELB.svg',
};

export const AWS_DASHBOARDS_FOLDERS = ['cloud-provider---aws'];

export const SERVICES_WITH_OOTB_VIEWS: { [key: string]: DashboardForServiceOverview } = {
  'AWS/EC2': {
    title: 'AWS EC2',
    url: EC2_URL,
    type: 'OOTBView',
    trackId: RudderStackEvents.GoToEC2Dashboard,
    target: '_self',
  },
  'AWS/RDS': {
    title: 'AWS RDS',
    url: RDS_URL,
    type: 'OOTBView',
    trackId: RudderStackEvents.GoToRDSDashboard,
    target: '_self',
  },
};

const getDashboardByService = (
  service: string,
  dashboards: DashboardDb[] = []
): DashboardForServiceOverview | undefined => {
  if (!!SERVICES_WITH_OOTB_VIEWS[service]) {
    return SERVICES_WITH_OOTB_VIEWS[service];
  }
  if (dashboards?.length > 0) {
    const isDashboardsEnabled = isDashboardsSectionPresent();

    for (let i = 0; i < dashboards.length; i++) {
      const dashboard = dashboards[i];
      const dashboardId = dashboard.uri?.replace('db/', '');
      const serviceId = (SERVICE_BY_DASHBOARD as any)[dashboardId];

      if (serviceId === service) {
        return {
          title: dashboard.title,
          url: isDashboardsEnabled
            ? `${getDashboardsUrlByProvider(CloudProvider.AWS)}/${dashboard.folderUid}/${dashboard.id}`
            : dashboard.url,
          type: 'Dashboard',
          trackId: RudderStackEvents.ViewDashboards,
          target: isDashboardsEnabled ? '_self' : '_blank',
        };
      }
    }
  }
  return undefined;
};

export const getAwsServicesForHomepageOverviewTable = (
  jobs: CloudWatchJob[] = [],
  cloudWatchConfig: CloudWatchConfig | undefined,
  dashboards: DashboardDb[] = []
): HomepageTableRow[] => {
  return jobs
    .filter((job) => job.enabled)
    .map((job) => {
      return job.service_configurations.map((service) => {
        const serviceName = cloudWatchConfig?.supported_services[service.name]?.display_name ?? service.name;
        return {
          service: serviceName,
          source: job.name,
          dashboard: getDashboardByService(serviceName, dashboards),
        };
      });
    })
    .flat();
};

export const getScrapeJobLink = (scrapeJobName: string): string => {
  return prefixRoute(CloudProvider.AWS, `${ROUTES.Configuration}/cloudwatch/edit/${scrapeJobName}`);
};

export const removeDuplicatesFromArray = (array: string[]) => {
  return array.filter((tag, index) => array.indexOf(tag) === index);
};
