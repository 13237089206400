import React, { FC, useEffect } from 'react';

import { useStyles2 } from '@grafana/ui';

import { Alert } from 'components/Alert';
import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { getJobStatusWidgetStyles } from './JobStatusWidget.styles';
import { RudderStackEvents } from 'enums';

export const JobSuccessWidget: FC<{ sourceId: string | null; data: any }> = ({ sourceId, data }) => {
  const styles = useStyles2(getJobStatusWidgetStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const message = 'All good! Scrape job saved.';
  const services = !!data?.data
    ? Object.keys(data.data)
        .map((jobName) => data.data[jobName].services)
        .flat()
        .join(', ')
    : '';

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.CreateOrUpdateScrapeJobSuccessfully, {
      integration_slug: sourceId,
      message,
    });
  }, [sourceId, message, services, trackRudderStackEvent]);

  return (
    <Alert className={styles.createScrapeJobStatus} status="success">
      <span
        role="alert"
        aria-label="Connection success"
        data-testid={Pages.SaaSIntegration.alertParagraph('creation-successful')}
        style={{ width: '100%' }}
      >
        {message}
      </span>
    </Alert>
  );
};
