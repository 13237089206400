export const getTagsError = (isInvalid: boolean, value: string): string => {
  if (!isInvalid) {
    return '';
  }
  if (value.startsWith(' ')) {
    return 'The value should not start with spaces.';
  } else if (value.endsWith(' ')) {
    return 'The value should not end with spaces.';
  } else {
    return 'A value with this name already exists.';
  }
};

export const doesValueEndOrStartWithASpace = (value: string): boolean => {
  return value.startsWith(' ') || value.endsWith(' ');
};

export const removeStartOrEndSpaceFromArray = (tags: string): string => {
  if (tags.includes(',')) {
    return tags
      .split(',')
      .map((tag) => tag.trim())
      .join();
  }
  return tags;
};

export const isAnyTagFromArrayElementAlreadyDefined = (tags: string, existingTags: string[]): boolean => {
  return tags.split(',').some((newTag) => existingTags.includes(newTag.trim()));
};

export const areTagsInvalid = (newTags: string, existingTags: string[]): boolean => {
  return isAnyTagFromArrayElementAlreadyDefined(newTags, existingTags) || doesValueEndOrStartWithASpace(newTags);
};
