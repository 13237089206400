import {
  behaviors,
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneGridItem,
  SceneGridLayout,
  SceneGridRow,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { VAR_DATASOURCE } from 'scenes/variables';
import { getGenericQueryRunner, makeTimeRange, onChangeDatasourceBehavior } from 'scenes/misc';
import { ThresholdsMode } from '@grafana/schema';
import OpenInAwsButton from 'scenes/components/OpenInAwsButton';
import { TimeRange } from '@grafana/data';
import {
  CPUUTILIZATION_METRIC_TITLE,
  getInstanceDrilldownRows,
} from 'scenes/AWS/Dashboards/RDS/getInstanceDrilldownRows';
import { rdsQueries } from 'scenes/AWS/Dashboards/RDS/queries';
import { ExplorablePanel } from 'scenes/common/ExplorablePanel';
import { PLUGIN_BASE_URL } from 'utils/utils.routing';
import { getVariables } from 'scenes/AWS/Dashboards/RDS/getVariables';

export const getInstanceDrilldownScene = (
  instanceId: string,
  prometheusName: string,
  from?: string,
  to?: string,
  loadFromExtension = false,
  onTimeRangeChange?: (timeRange: TimeRange) => void
) => {
  const datasourceObject = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const { datasources } = getVariables(prometheusName, loadFromExtension);

  const sceneTimeRange = makeTimeRange(from, to, onTimeRangeChange);

  return new EmbeddedScene({
    $timeRange: sceneTimeRange,
    $variables: new SceneVariableSet({
      variables: [datasources],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      ...(loadFromExtension
        ? [
            new OpenInAwsButton({
              href: `${PLUGIN_BASE_URL}/dashboard/rds/instance/${instanceId}?var-datasource=${prometheusName}`,
            }),
          ]
        : []),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ['5s', '1m', '1h'],
        isOnCanvas: true,
      }),
    ],
    body: new SceneGridLayout({
      isDraggable: false,
      isLazy: true,
      $behaviors: [
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_DATASOURCE,
          onChange: onChangeDatasourceBehavior,
        }),
      ],
      children: [
        new SceneGridRow({
          x: 0,
          y: 0,
          title: 'Metadata',
          children: [
            new SceneGridItem({
              width: 24,
              x: 0,
              y: 0,
              isResizable: false,
              isDraggable: true,
              body: new ExplorablePanel({
                $data: getGenericQueryRunner(datasourceObject, rdsQueries.allTagsForAnInstance(instanceId), {
                  format: 'table',
                  instant: true,
                }),
                title: '',
                pluginId: 'table',
                fieldConfig: {
                  defaults: {
                    custom: {
                      align: 'auto',
                      cellOptions: {
                        type: 'auto',
                      },
                      inspect: false,
                    },
                    mappings: [],
                  },
                  overrides: [
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Time',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Value',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: '__name__',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                  ],
                },
              }),
            }),
          ],
        }),
        [
          ...getInstanceDrilldownRows(instanceId).map(
            (row: any) =>
              new SceneGridRow({
                x: 0,
                y: 0,
                children: [
                  new SceneGridItem({
                    width: 12,
                    height: 10,
                    x: 0,
                    y: 0,
                    isResizable: false,
                    isDraggable: true,
                    body: PanelBuilders.timeseries()
                      .setTitle(row.firstColumn.title)
                      .setData(row.firstColumn.data)
                      .setUnit(row.firstColumn.unit)
                      .setColor(
                        row.firstColumn.title === CPUUTILIZATION_METRIC_TITLE ? { mode: 'thresholds' } : undefined
                      )
                      .setCustomFieldConfig('fillOpacity', 15)
                      .setThresholds(
                        row.firstColumn.title === CPUUTILIZATION_METRIC_TITLE
                          ? {
                              mode: ThresholdsMode.Absolute,
                              steps: [
                                {
                                  color: '#5794F2',
                                  value: 0,
                                },
                                {
                                  color: 'red',
                                  value: 80,
                                },
                              ],
                            }
                          : undefined
                      )
                      .build(),
                  }),
                  row.secondColumn
                    ? new SceneGridItem({
                        width: 12,
                        height: 10,
                        x: 12,
                        y: 0,
                        isResizable: false,
                        isDraggable: true,
                        body: PanelBuilders.timeseries()
                          .setTitle(row.secondColumn.title)
                          .setData(row.secondColumn.data)
                          .setUnit(row.secondColumn.unit)
                          .setCustomFieldConfig('fillOpacity', 15)
                          .build(),
                      })
                    : [],
                ].flat(),
              })
          ),
        ],
      ].flat(),
    }),
  });
};
