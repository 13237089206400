import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, Icon, Toggletip, useStyles2 } from '@grafana/ui';

import { isUserAdmin } from 'utils/user';
import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';

const getStyles = (theme: GrafanaTheme2) => ({
  notification: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  alertWrapper: css`
    margin-top: ${theme.spacing(3)};
  `,
  alert: css`
    > div:first-child {
      align-items: center;
      > div:first-child {
        padding-top: 0;
      }
    }
  `,
  list: css`
    list-style: none;
  `,
});

export const ServiceAlert = ({
  necessaryMetrics,
  service,
}: {
  necessaryMetrics: { [key: string]: string };
  service: string;
}) => {
  const styles = useStyles2(getStyles);
  const cloudwatchLink = prefixRoute(CloudProvider.AWS, `configuration/cloudwatch`);

  return (
    <div className={styles.alertWrapper}>
      <Alert title="" severity="info" className={styles.alert}>
        {isUserAdmin() ? (
          <div className={styles.notification}>
            <div>
              If you&apos;re missing data here, make sure you configured{' '}
              <a href={cloudwatchLink} target="_blank" rel="noreferrer">
                <u>
                  Cloudwatch metrics with {service} <Icon name="external-link-alt" />
                </u>
              </a>{' '}
              and enabled tags.
            </div>
            <Toggletip
              content={
                <ul className={styles.list}>
                  {Object.values(necessaryMetrics).map((metric) => (
                    <li key={metric}>{metric}</li>
                  ))}
                </ul>
              }
              closeButton={true}
            >
              <Button fill={'text'} icon={'info-circle'}>
                Necessary metrics
              </Button>
            </Toggletip>
          </div>
        ) : (
          `If you're missing data here, contact your admin to configure Cloudwatch metrics with ${service} and enable tags.`
        )}
      </Alert>
    </div>
  );
};
