import { css } from '@emotion/css';
import React, { FC, useEffect } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import {
  AlloyStatus,
  ConnectionTestButton,
  ConnectionTestStatus,
  setAgentCheckInstalled,
  setAgentStatus,
  useCollectorDispatch,
} from '@grafana-cloud/collector';

export const AlloyModalFooter: FC<{ isDisabled: boolean; onClose: () => void }> = ({ isDisabled, onClose }) => {
  const getStyles = (theme: GrafanaTheme2) => ({
    footer: css`
      display: flex;
      flex-direction: column;

      flex-shrink: 0;
      margin-top: ${theme.spacing(2)};
      border-top: 1px solid ${theme.colors.border.medium};
      padding-top: ${theme.spacing(1)};
      gap: ${theme.spacing(1)};
    `,
    buttonRow: css`
      display: flex;
      justify-content: flex-end;
      gap: ${theme.spacing(2)};
      align-items: flex-end;
    `,
  });

  const styles = useStyles2(getStyles);
  const collectorDispatch = useCollectorDispatch();

  useEffect(() => {
    collectorDispatch(setAgentStatus(AlloyStatus.None));
    collectorDispatch(setAgentCheckInstalled(false));
  }, [collectorDispatch]);

  return (
    <div className={styles.footer}>
      <ConnectionTestStatus />

      <div className={styles.buttonRow}>
        <ConnectionTestButton isDisabled={isDisabled} />
        <Button variant="secondary" onClick={onClose}>
          Proceed to install integration
        </Button>
      </div>
    </div>
  );
};
