import { SceneDataTransformer, sceneGraph, SceneObjectBase, SceneQueryRunner, VizPanel } from '@grafana/scenes';
import { map } from 'rxjs';
import { FieldType } from '@grafana/data';
import { extractLevel, getLogVolumeFieldConfig } from 'scenes/Logs/logsVolume';

export class LogsVolumePanelBehavior extends SceneObjectBase {
  public constructor(state: any) {
    super(state);
    this.addActivationHandler(this._onActivate);
  }

  private _onActivate = () => {
    const queryRunner = sceneGraph.getData(this) as SceneQueryRunner;
    const parent = this.parent as VizPanel;

    const logsVolumeTransformed = new SceneDataTransformer({
      $data: queryRunner,
      transformations: [
        // Format logs volume levels
        () => (source) =>
          source.pipe(
            map((frames) =>
              frames?.map((frame) => {
                frame.fields = frame.fields.map((field) => {
                  if (field.type === FieldType.number) {
                    const oneLevelDetected = frame.length === 1;
                    field.config = {
                      ...field.config,
                      ...getLogVolumeFieldConfig(extractLevel(frame), oneLevelDetected),
                    };
                  }

                  return field;
                });

                return frame;
              })
            )
          ),
      ],
    });
    parent.setState({ $data: logsVolumeTransformed });
  };
}
