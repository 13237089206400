import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getJobRowStyles = (theme: GrafanaTheme2) => ({
  row: css`
    font-weight: ${theme.typography.fontWeightRegular};
  `,
  checkbox: css`
    margin-right: ${theme.spacing(1.25)};
    position: relative;
    top: ${theme.spacing(0.25)};

    > span {
      font-size: 19px;
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
  checkboxColumn: css`
    width: 20px;
  `,
  nameColumn: css`
    width: 160px;
  `,
  arnColumn: css`
    width: 200px;
  `,
  regionsColumn: css`
    min-width: 100px;
  `,
  servicesColumn: css`
    min-width: 100px;
  `,
  actionsColumn: css`
    width: 35px;
    color: ${theme.colors.text.secondary};
  `,
  ellipsisIcon: css`
    fill: ${theme.colors.secondary.text};
  `,
  nameLink: css`
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      opacity: 0.85;
    }
  `,
  actionsButton: css`
    padding: ${theme.spacing(1)};
  `,
});
