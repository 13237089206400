import { css, cx } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Spinner, useStyles2 } from '@grafana/ui';

import { useGetAgentDetails, useGetIntegration } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { CollectorMode } from 'enums';
import { Pages } from 'e2eSelectors/pages';
import { getMessageFromApiError } from 'api/common/utils';
import { TestConnection } from 'feature/common/components/AgentConnection/IntegrationConnection';
import { InstructionsAlloy } from './InstructionsAlloy';
import { RestartCollector } from './RestartCollector';
import { PreInstructions } from './PreInstructions';
import { isIntegrationUsingLogs, isIntegrationUsingMetrics } from 'api/int-api/utils';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    button: css`
      margin-bottom: 8px;
    `,
    p: css`
      margin-top: 10px;
    `,
    step: css`
      background-color: ${theme.colors.background.secondary};
      padding: ${theme.spacing(3)};
      margin-bottom: ${theme.spacing(3)};
    `,
    topMargin: css`
      margin-top: ${theme.spacing(3)};
    `,
    unsetPadding: css`
      padding-bottom: 1px;
    `,
  };
};

export const ConfigureIntegrationSection = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getStyles);

  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: selectedIntegration } = useGetIntegration(sourceId, pluginId, jsonData.grafana_instance_id);
  const {
    data: agentDetails,
    isError,
    isLoading,
    error,
    refetch,
  } = useGetAgentDetails(sourceId, CollectorMode.Alloy, pluginId, jsonData.grafana_instance_id);

  const collector = 'Grafana Alloy';
  const errorMessage = getMessageFromApiError(error);

  return (
    <li>
      <h2>Prepare your configuration file</h2>
      <div className={cx(styles.step, styles.topMargin)} data-testid={Pages.Source.AlloyConfig.preInstructions}>
        <h3>Check prerequisites specific to {selectedIntegration?.name}</h3>
        <PreInstructions sourceId={sourceId} />
      </div>

      {((selectedIntegration && agentDetails) || isLoading) && (
        <div className={styles.step}>
          {isLoading ? (
            <>
              Loading...
              <Spinner />
            </>
          ) : (
            agentDetails &&
            selectedIntegration && (
              <InstructionsAlloy selectedIntegration={selectedIntegration} agentDetails={agentDetails} />
            )
          )}
        </div>
      )}
      {isError && <Alert severity="error" title={errorMessage} buttonContent={'Retry'} onRemove={() => refetch()} />}

      <div className={cx(styles.step, styles.unsetPadding)}>
        <RestartCollector />
      </div>

      <div className={styles.step}>
        {selectedIntegration &&
          (isIntegrationUsingMetrics(selectedIntegration) || isIntegrationUsingLogs(selectedIntegration)) && (
            <>
              <h3>Test connection</h3>
              <p>Test that {collector} is collecting data and sending it to Grafana Cloud.</p>
              <TestConnection sourceId={sourceId} />
            </>
          )}
      </div>
    </li>
  );
};
