import { useCallback } from 'react';
import { useHostedDataDetailsWithFallback } from 'api/grafana-com/queries';
import { PLUGIN_ID } from 'utils/utils.routing';

const useRudderStack = () => {
  const { slug, orgId } = useHostedDataDetailsWithFallback(PLUGIN_ID);

  const trackRudderStackEvent = useCallback(
    (event: string, params: object) => {
      (window as any).rudderanalytics?.track(event, {
        ...params,
        org_id: orgId,
        instance_slug: slug,
      });
    },
    [orgId, slug]
  );

  return {
    trackRudderStackEvent,
  };
};

if (process.env.NODE_ENV === 'development') {
  (window as any).rudderanalytics = { track: console.log };
}

export default useRudderStack;
