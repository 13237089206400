import { getGenericQueryRunner } from 'scenes/misc';
import { rdsQueries } from './queries';

const datasourceObject = {
  type: 'prometheus',
  uid: '$datasource',
};

export const CPUUTILIZATION_METRIC_TITLE = 'Cpuutilization Maximum';

export const getInstanceDrilldownRows = (dbId: string) => [
  // 1
  {
    firstColumn: {
      title: CPUUTILIZATION_METRIC_TITLE,
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.cpuUtilizationMaximum(dbId)),
      unit: 'percent',
    },
    secondColumn: {
      title: 'Database Connections Sum',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.dbConnectionsSum(dbId)),
    },
  },
  // 2
  {
    firstColumn: {
      title: 'Read Latency Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.readLatencyAverage(dbId)),
      unit: 's',
    },
    secondColumn: {
      title: 'Read Latency Maximum',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.readLatencyMaximum(dbId)),
      unit: 's',
    },
  },
  // 3
  {
    firstColumn: {
      title: 'Write Latency Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.writeLatencyAverage(dbId)),
      unit: 's',
    },
    secondColumn: {
      title: 'Write Latency Maximum',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.writeLatencyMaximum(dbId)),
      unit: 's',
    },
  },
  // 4
  {
    firstColumn: {
      title: 'Replica Lag Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.replicaLagAverage(dbId)),
      unit: 's',
    },
    secondColumn: {
      title: 'Swap Usage Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.swapUsageAverage(dbId)),
      unit: 'bytes',
    },
  },
  // 5
  {
    firstColumn: {
      title: 'Read IOPs Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.readIopsAverage(dbId)),
      unit: 's',
    },
    secondColumn: {
      title: 'Write IOPs Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.writeIopsAverage(dbId)),
      unit: 's',
    },
  },
  // 6
  {
    firstColumn: {
      title: 'Read Throughput Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.readThroughputAverage(dbId)),
      unit: 'bytes',
    },
    secondColumn: {
      title: 'Write Throughput Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.writeThroughputAverage(dbId)),
      unit: 'bytes',
    },
  },
  // 7
  {
    firstColumn: {
      title: 'EBS Byte Balance Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.ebsByteBalanceAverage(dbId)),
      unit: 'percent',
    },
    secondColumn: {
      title: 'EBS IO Balance Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceOverviewDrilldown.ebsIOBalanceAverage(dbId)),
      unit: 'percent',
    },
  },
  // 8
  {
    firstColumn: {
      title: 'CPU Credit Usage Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceAdminDrilldown.cpuCreditUsageAverage(dbId)),
    },
    secondColumn: {
      title: 'CPU Credit Balance Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceAdminDrilldown.cpuCreditBalanceAverage(dbId)),
    },
  },
  // 9
  {
    firstColumn: {
      title: 'Burst Balance Average',
      data: getGenericQueryRunner(datasourceObject, rdsQueries.instanceAdminDrilldown.burstBalanceAverage(dbId)),
      unit: 'percent',
    },
  },
];
