import { DataSourceVariable, QueryVariable } from '@grafana/scenes';

export const VAR_DATASOURCE = 'datasource';
export const VAR_LOKI_DATASOURCE = 'lokiDatasource';
export const VAR_AWS_TAGS = 'awsTags';
export const VAR_ALL_TAGS = 'allTags';
export const VAR_ALL_TAGS_MULTIPLE_OPTIONS = 'allTagsMultipleOptions';
export const VAR_REGION = 'region';
export const VAR_ACCOUNT = 'account';
export const VAR_SCRAPE_JOB = 'scrape_job';
export const VAR_ALL_TAGS_VALUES = 'allTagsValues';
export const VAR_TOP_INSTANCES = 'topInstances';
export const VAR_TOP_OR_BOTTOM = 'topOrBottom';
export const VAR_METRICS = 'metrics';
export const VAR_ACCOUNTS = 'accounts';
export const VAR_LEVEL = 'level';
export const VAR_LOG_TERM_SEARCH = 'logTermSearch';

export const getPrometheusDatasourceVar = (prometheusName: string, disableDataSourceFilter = false) =>
  new DataSourceVariable({
    name: VAR_DATASOURCE,
    label: 'Datasource',
    pluginId: 'prometheus',
    value: prometheusName,
    isReadOnly: disableDataSourceFilter,
  });

export const getLokiDatasourceVar = (lokiUID: string) => {
  return new DataSourceVariable({
    name: VAR_LOKI_DATASOURCE,
    label: 'Datasource',
    pluginId: 'loki',
    value: lokiUID,
  });
};

export const getRegionVar = () =>
  new QueryVariable({
    name: VAR_REGION,
    label: 'AWS region',
    datasource: {
      type: 'prometheus',
      uid: '$datasource',
    },
    query: {
      query: 'label_values(region)',
      refId: 'C',
    },
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
  });

export const getAccountVar = () =>
  new QueryVariable({
    name: VAR_ACCOUNT,
    label: 'AWS account',
    datasource: {
      type: 'prometheus',
      uid: '$datasource',
    },
    query: {
      query: 'label_values(account_id)',
      refId: 'F',
    },
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
  });

export const getScrapeJobVar = () =>
  new QueryVariable({
    name: VAR_SCRAPE_JOB,
    label: 'Scrape job',
    datasource: {
      type: 'prometheus',
      uid: '$datasource',
    },
    query: {
      query: 'label_values(scrape_job)',
      refId: 'G',
    },
    includeAll: true,
    defaultToAll: true,
    isMulti: true,
    // TO DO: filter scrape jobs and remove those for confluent cloud or other scrape jobs. Possibly with Metric(dashboards variables).
  });
