import { ServiceInfoApiResponse } from 'api/hosted-exporters-api/data-models';
import { SelectServiceItem } from './SelectServiceItem';

export const getServiceItems = (
  searchTerm = '',
  services: ServiceInfoApiResponse[] = [],
  selectedItems: string[] = []
): SelectServiceItem[] => {
  return services
    .filter((s) => !searchTerm || s.display_name.toLowerCase().indexOf(searchTerm) >= 0)
    .sort((a, b) => a.display_name.localeCompare(b.display_name))
    .map((s) => ({
      id: s.service_id,
      name: s.display_name,
      selected: selectedItems.indexOf(s.service_id) >= 0,
    }));
};
