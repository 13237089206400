import { css } from '@emotion/css';
import React from 'react';
import { UseFormRegister, Validate } from 'react-hook-form';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Input, useStyles2 } from '@grafana/ui';

import { FormErrors } from 'utils/consts';
import { AccountReactHookFormProps } from './CloudWatchInstructions/JobForm/AccountForm';

const getStyles = (theme: GrafanaTheme2) => ({
  field: css`
    > div {
      max-width: none;
    }

    [class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
});

interface ScrapeJobNameFieldProps {
  title?: string;
  description?: string;
  placeholder?: string;
  error?: string;
  register: UseFormRegister<AccountReactHookFormProps>;
  validate?: Validate<string, AccountReactHookFormProps> | Record<string, Validate<string, AccountReactHookFormProps>>;
}

export const ScrapeJobNameField = ({
  title = 'Scrape job name',
  description,
  placeholder = 'my_scrape_job_name',
  register,
  error,
  validate,
}: ScrapeJobNameFieldProps) => {
  const styles = useStyles2(getStyles);
  return (
    <Field label={title} description={description} invalid={error !== undefined} error={error} className={styles.field}>
      <Input
        {...register('name', {
          required: {
            value: true,
            message: FormErrors.REQUIRED_FIELD,
          },
          pattern: {
            value: /^[\w-]*$/,
            message: FormErrors.JOB_NAME_CHARACTERS,
          },
          validate,
        })}
        type="text"
        aria-label={title}
        placeholder={placeholder}
        id="name"
      />
    </Field>
  );
};
