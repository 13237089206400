import React, { useCallback } from 'react';
import { SERVICE_BY_DASHBOARD, SERVICES_WITH_OOTB_VIEWS } from 'feature/AWS/utils/utils';
import useRudderStack from 'hooks/useRudderstack';
import { DashboardDb } from 'api/dashboards/data-model';
import { Button } from '@grafana/ui';
import { useHistory } from 'react-router-dom';
import { RudderStackEvents } from 'enums';
import { CloudProvider } from 'types/CloudProvider';
import { getDashboardsUrlByProvider } from 'scenes/misc';

export const DashboardList = ({ dashboards, provider }: { dashboards: DashboardDb[]; provider: CloudProvider }) => {
  const { trackRudderStackEvent } = useRudderStack();
  const { push } = useHistory();

  const getDashboardUrl = useCallback(
    (dashboard: DashboardDb) => {
      const dashboardId = dashboard.uri.replace('db/', '');
      const serviceId = (SERVICE_BY_DASHBOARD as any)[dashboardId];

      if (SERVICES_WITH_OOTB_VIEWS[serviceId]) {
        return SERVICES_WITH_OOTB_VIEWS[serviceId].url;
      }
      return `${getDashboardsUrlByProvider(provider)}/${dashboard.folderUid}/${dashboard.id}`;
    },
    [provider]
  );

  function onOpenDashboard(dashboard: DashboardDb) {
    trackRudderStackEvent(RudderStackEvents.ViewDashboards, {
      page: 'dashboards',
      dashboardId: dashboard.id,
      provider,
    });

    const url = getDashboardUrl(dashboard);
    push(url);
  }

  return (
    <ul style={{ listStyleType: 'none' }}>
      {dashboards.map((dashboard) => (
        <li key={dashboard.id}>
          <Button icon="apps" fill="text" size="sm" variant="secondary" onClick={() => onOpenDashboard(dashboard)}>
            {dashboard.title}
          </Button>
        </li>
      ))}
    </ul>
  );
};
