import React from 'react';

import { SceneAppPage, SceneAppPageLike } from '@grafana/scenes';

import { EC2Subtitle } from './EC2Subtitle';
import { getOverviewDrilldown } from './getOverviewDrilldown';
import { getOverviewScene } from './getOverviewScene';
import { getProactiveTabScene } from './getProactiveTabScene';
import { getRegionsTabScene } from './getRegionsTabScene';
import {
  VAR_ALL_TAGS,
  VAR_ALL_TAGS_MULTIPLE_OPTIONS,
  VAR_ALL_TAGS_VALUES,
  VAR_AWS_TAGS,
  VAR_DATASOURCE,
  VAR_METRICS,
  VAR_REGION,
  VAR_TOP_INSTANCES,
  VAR_TOP_OR_BOTTOM,
} from 'scenes/variables';
import { EC2_URL } from 'scenes/AWS/routes';
import { EC2Icon } from 'img';
import { ScenesCustomParams } from 'scenes/types';

const PRESERVE_URL_KEYS = [
  'from',
  'to',
  `var-${VAR_DATASOURCE}`,
  `var-${VAR_REGION}`,
  `var-${VAR_AWS_TAGS}`,
  `var-${VAR_ALL_TAGS}`,
  `var-${VAR_ALL_TAGS_VALUES}`,
  `var-${VAR_TOP_INSTANCES}`,
  `var-${VAR_TOP_OR_BOTTOM}`,
  `var-${VAR_METRICS}`,
  `var-${VAR_ALL_TAGS_MULTIPLE_OPTIONS}`,
];

export const getEC2ScenePage = (
  { prometheusName, from, to }: ScenesCustomParams,
  parent: SceneAppPageLike
): SceneAppPage => {
  const overviewTab = new SceneAppPage({
    title: 'Overview',
    url: EC2_URL,
    getScene: () => getOverviewScene(prometheusName, from, to),
    drilldowns: getOverviewDrilldown(prometheusName, from, to),
    preserveUrlKeys: PRESERVE_URL_KEYS,
  });

  const regionTab = new SceneAppPage({
    title: 'Regions',
    url: EC2_URL + '/region',
    getScene: () => getRegionsTabScene(prometheusName, from, to),
    preserveUrlKeys: PRESERVE_URL_KEYS,
  });

  const proactiveTab = new SceneAppPage({
    title: 'Rightsizing',
    url: EC2_URL + '/rightsizing',
    getScene: () => getProactiveTabScene(prometheusName, from, to),
    preserveUrlKeys: PRESERVE_URL_KEYS,
  });

  return new SceneAppPage({
    title: 'AWS/EC2',
    titleImg: EC2Icon,
    hideFromBreadcrumbs: false,
    preserveUrlKeys: PRESERVE_URL_KEYS,
    subTitle: <EC2Subtitle />,
    url: EC2_URL,
    tabs: [overviewTab, regionTab, proactiveTab],
    getParentPage: () => parent,
  });
};
