import React, { useContext } from 'react';
import { Source } from 'feature/common/components/Source/Source';
import { useCurrentSourceId } from 'hooks/useCurrentSourceId';
import { SaasIntegration } from 'feature/AWS/components/SaasIntegrations';
import { useCspIntegration } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

export const ConfigurationDetail = () => {
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const sourceId = useCurrentSourceId();

  const { data } = useCspIntegration(sourceId, pluginId, jsonData.grafana_instance_id);

  return (
    <Source sourceId={sourceId} createOrEditElement={'scrape job'}>
      {data && <SaasIntegration selectedIntegration={data} />}
    </Source>
  );
};
