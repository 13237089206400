import React, { FC } from 'react';
import { JobFailedWidget } from './JobFailedWidget';
import { JobSuccessWidget } from './JobSuccessWidget';
import { JobPendingWidget } from './JobPendingWidget';
import { useCreateOrUpdateScrapeJobState } from 'api/hosted-exporters-api/queries';
import { useCurrentSourceId } from 'hooks/useCurrentSourceId';

export const JobStatusWidget: FC = () => {
  const sourceId = useCurrentSourceId();
  const { status, error, data } = useCreateOrUpdateScrapeJobState();

  switch (status) {
    case 'error':
      return <JobFailedWidget sourceId={sourceId} error={error} />;

    case 'success':
      return <JobSuccessWidget sourceId={sourceId} data={data} />;

    case 'pending':
      return <JobPendingWidget sourceId={sourceId} />;

    default:
      return null;
  }
};
