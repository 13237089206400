import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => ({
  emptyList: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: ${theme.spacing(13)};

    p {
      color: ${theme.colors.text.secondary};
    }
  `,
});

type JobEmptyListProps = {
  onAddScrapeJob: () => void;
};

export const JobEmptyList = ({ onAddScrapeJob }: JobEmptyListProps) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.emptyList} data-testid={Pages.CloudWatch.scrapeJobsEmpty}>
      <h4>No scrape jobs detected</h4>
      <p>
        This view displays a list of scrape jobs. Click Add new scrape job to create new jobs for specific services in
        certain regions
      </p>
      <Button variant="primary" onClick={onAddScrapeJob} data-testid={Pages.CloudWatch.addScrapeJobButton}>
        Add new scrape job
      </Button>
    </div>
  );
};
