import {
  EmbeddedScene,
  PanelBuilders,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { AWS_LOGS_URL } from 'scenes/AWS/routes';
import { LOG_GROUPS_DATASOURCE_UID } from 'scenes/AWS/Logs/consts';
import { getLokiDatasourceVar } from 'scenes/variables';
import { LogGroupsBehaviour } from 'scenes/AWS/Logs/behaviours';
import { makeTimeRange } from 'scenes/misc';

export const getLogGroupsScene = (lokiUID: string, from?: string, to?: string) => {
  const sceneTimeRange = makeTimeRange(from, to, undefined, { from: 'now-1h', to: 'now' });

  return new EmbeddedScene({
    key: 'log-groups',
    $data: new SceneQueryRunner({
      datasource: { uid: LOG_GROUPS_DATASOURCE_UID },
      queries: [{ refId: 'A', expr: 'vector(1)' }],
      $behaviors: [new LogGroupsBehaviour({})],
    }),
    $timeRange: sceneTimeRange,
    $variables: new SceneVariableSet({ variables: [getLokiDatasourceVar(lokiUID)] }),
    controls: [
      new SceneControlsSpacer(),
      new VariableValueSelectors({}),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({ isOnCanvas: true }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        new SceneFlexItem({
          body: PanelBuilders.table()
            .setTitle('Log groups')
            .setDisplayMode('transparent')
            .setOption('showHeader', true)
            .setOverrides((b) => {
              b.matchFieldsWithName('value')
                .overrideLinks([
                  {
                    title: 'Go to Log Group overview',
                    url: AWS_LOGS_URL + '/${__data.fields.id}${__url.params}',
                  },
                ])
                .overrideDisplayName('Group')
                .matchFieldsWithName('origin')
                .overrideDisplayName('Type')
                .matchFieldsWithName('id')
                .overrideCustomFieldConfig('hidden', true)
                .matchFieldsWithName('method')
                .overrideCustomFieldConfig('hidden', true);
            })
            .build(),
        }),
      ],
    }),
  });
};
