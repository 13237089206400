import React from 'react';
import { testIds } from 'components/testIds';
import { EmbeddedScene, SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { AWS_URL } from 'scenes/AWS/routes';

const getFallbackScene = () => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: () => <p data-testid={testIds.page404.paragraph}>Sorry! This page does not exist...</p>,
    }),
  });
};

export const getFallbackPage = () => {
  return new SceneAppPage({
    title: 'Error',
    url: AWS_URL + '/404',
    hideFromBreadcrumbs: false,
    getScene: () => getFallbackScene(),
    preserveUrlKeys: [],
  });
};
