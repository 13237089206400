import { MutationKey, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { requestIntegrations } from 'api/baseApi';
import {
  AccountApiRequest,
  CloudWatchConfig,
  CloudWatchJob,
  SaasIntegrationConnection,
  ScrapeJob,
  ScrapeJobApiResponse,
  AlloyService,
} from './data-models';
import { FilterJobStatus } from 'enums';
import { OnboardingPluginError, SuccessfulApiResult } from 'api/common/data-model';
import { isCheckConnectionError } from 'api/hosted-exporters-api/utils';
import { handleDisabledAwsRegionError } from './utils';
import { text } from 'utils/consts';
import { invalidateSharedMutations, useLastMutationState } from 'api/common/utils';
import { getCatalogBySourceId } from 'api/int-api/utils';
import { SaasIntegrationWithJobType } from 'feature/AWS/components/SaasIntegrations/types';
import { CloudProvider } from 'types/CloudProvider';
import { AWS_DASHBOARDS_FOLDERS } from 'feature/AWS/utils/utils';

const getCloudWatchJobs = async (
  saasIntegrationId: string,
  pluginId: string,
  grafanaInstanceId: string
): Promise<CloudWatchJob[]> => {
  const response = await requestIntegrations(
    pluginId,
    `/he-api/api/v1/stack/${grafanaInstanceId}/jobs?job_type=${saasIntegrationId}`,
    { showErrorAlert: true }
  );
  const data: SuccessfulApiResult<ScrapeJobApiResponse[]> = response.data;
  return Object.entries(data.data).map(([name, job]) => ({
    name,
    ...job,
  })) as CloudWatchJob[];
};

export const useGetScrapeJobs = (
  saasIntegrationId: SaasIntegrationWithJobType,
  pluginId: string,
  grafanaInstanceId: string,
  enabled = true
) => {
  return useQuery({
    queryKey: ['getScrapeJobs', getCatalogBySourceId(saasIntegrationId)],
    queryFn: () => getCloudWatchJobs(saasIntegrationId, pluginId, grafanaInstanceId),
    enabled: saasIntegrationId === 'cloudwatch' && Boolean(pluginId) && Boolean(grafanaInstanceId) && enabled,
  });
};

export const useGetServices = (
  saasIntegrationId: CloudProvider,
  pluginId: string,
  grafanaInstanceId: string,
  enabled = true
) => {
  return useQuery({
    queryKey: ['getServices', saasIntegrationId],
    queryFn: async () => {
      const response = await requestIntegrations(
        pluginId,
        `/he-api/api/v2/stacks/${grafanaInstanceId}/${saasIntegrationId}/overview`
      );
      if (Object.keys(response.data.data).length > 0) {
        return (response.data.data.services ?? []) as AlloyService[];
      }
      return [];
    },
    enabled: Boolean(pluginId) && Boolean(grafanaInstanceId) && enabled,
  });
};

export const useFilteredJobs = (pluginId: string, grafanaInstanceId: string, filter: FilterJobStatus): ScrapeJob[] => {
  const { data: jobs } = useGetScrapeJobs('cloudwatch', pluginId, grafanaInstanceId);

  if (!jobs) {
    return [];
  }

  let filteredJobs = jobs;

  if (filter === FilterJobStatus.Enabled) {
    filteredJobs = filteredJobs.filter((job) => job.enabled);
  } else if (filter === FilterJobStatus.Disabled) {
    filteredJobs = filteredJobs.filter((job) => !job.enabled);
  }

  return filteredJobs;
};

const getQueriesToInvalidateAfterJobUpdate = (provider: CloudProvider | undefined): MutationKey[] => {
  const mutationKeys = [['getDashboards', AWS_DASHBOARDS_FOLDERS[0]]];

  if (!!provider) {
    mutationKeys.push(['getScrapeJobs', provider]);
  }
  return mutationKeys;
};

export const useCreateOrUpdateScrapeJobMutation = (
  saasIntegrationId: SaasIntegrationWithJobType,
  pluginId: string,
  grafanaInstanceId: string
) => {
  const queryClient = useQueryClient();
  const mutationKey = ['createOrUpdateScrapeJob'];

  const catalog = getCatalogBySourceId(saasIntegrationId);

  return useMutation({
    mutationKey,
    mutationFn: async (job: ScrapeJob): Promise<ScrapeJobApiResponse> => {
      const result = await requestIntegrations(pluginId, `/he-api/api/v1/stack/${grafanaInstanceId}/job/${job.name}`, {
        method: 'PUT',
        showErrorAlert: false,
        data: JSON.stringify({
          job_type: saasIntegrationId,
          job_data: {
            ...job,
          },
        }),
      });
      return result.data.data[job.name] as ScrapeJobApiResponse;
    },
    onSuccess: () => invalidateSharedMutations(queryClient, getQueriesToInvalidateAfterJobUpdate(catalog)),
  });
};

export const useCreateOrUpdateScrapeJobState = () => {
  return useLastMutationState(['createOrUpdateScrapeJob']);
};

export const useDeleteScrapeJobsMutation = (
  saasIntegrationId: SaasIntegrationWithJobType,
  pluginId: string,
  grafanaInstanceId: string
) => {
  const queryClient = useQueryClient();
  const mutationKey = ['deleteScrapeJobs'];
  const catalog = getCatalogBySourceId(saasIntegrationId);

  return useMutation({
    mutationKey,
    mutationFn: async (jobNames: string[]) => {
      for (let i = 0; i < jobNames.length; i++) {
        try {
          await requestIntegrations(pluginId, `/he-api/api/v1/stack/${grafanaInstanceId}/job/${jobNames[i]}`, {
            method: 'DELETE',
            showErrorAlert: true,
          });
        } catch (e: any) {
          throw e.data;
        }
      }
    },
    onSuccess: () => invalidateSharedMutations(queryClient, getQueriesToInvalidateAfterJobUpdate(catalog)),
  });
};

export const useEnableScrapeJobsMutation = (
  saasIntegrationId: SaasIntegrationWithJobType,
  pluginId: string,
  grafanaInstanceId: string
) => {
  const queryClient = useQueryClient();
  const mutationKey = ['enableScrapeJobs'];
  const catalog = getCatalogBySourceId(saasIntegrationId);

  return useMutation({
    mutationKey,
    mutationFn: async (jobNames: string[]) => {
      for (let i = 0; i < jobNames.length; i++) {
        try {
          await requestIntegrations(pluginId, `/he-api/api/v1/stack/${grafanaInstanceId}/job/${jobNames[i]}/enable`, {
            method: 'POST',
            showErrorAlert: true,
          });
        } catch (e: any) {
          throw e.data;
        }
      }
    },
    onSuccess: () => invalidateSharedMutations(queryClient, getQueriesToInvalidateAfterJobUpdate(catalog)),
  });
};

export const useDisableScrapeJobsMutation = (
  saasIntegrationId: SaasIntegrationWithJobType,
  pluginId: string,
  grafanaInstanceId: string
) => {
  const queryClient = useQueryClient();
  const mutationKey = ['disableScrapeJobs'];
  const catalog = getCatalogBySourceId(saasIntegrationId);

  return useMutation({
    mutationKey,
    mutationFn: async (jobNames: string[]) => {
      for (let i = 0; i < jobNames.length; i++) {
        try {
          await requestIntegrations(pluginId, `/he-api/api/v1/stack/${grafanaInstanceId}/job/${jobNames[i]}/disable`, {
            method: 'POST',
            showErrorAlert: true,
          });
        } catch (e: any) {
          throw e.data;
        }
      }
    },
    onSuccess: () => invalidateSharedMutations(queryClient, getQueriesToInvalidateAfterJobUpdate(catalog)),
  });
};

export const useCloudwatchConfig = (pluginId: string, grafanaInstanceId: string, enabled = true) => {
  return useQuery({
    queryKey: ['cloudwatchConfig'],
    queryFn: async (): Promise<CloudWatchConfig> => {
      const response = await requestIntegrations(
        pluginId,
        `/he-api/api/v1/stack/${grafanaInstanceId}/cloudwatch/config`,
        {
          method: 'GET',
          showErrorAlert: false,
        }
      );
      return response.data.data;
    },
    enabled: !!pluginId && !!grafanaInstanceId && enabled,
  });
};

export const useCheckCloudWatchConnection = (pluginId: string, grafanaInstanceId: string) => {
  const mutationKey = ['checkCloudWatchConnection'];

  return useMutation({
    mutationKey,
    mutationFn: async ({ arn, regions }: AccountApiRequest) => {
      for (let i = 0; i < regions.length; i++) {
        try {
          await requestIntegrations<{ status: string }>(
            pluginId,
            `/he-api/api/v1/stack/${grafanaInstanceId}/cloudwatch/verify-auth`,
            {
              method: 'POST',
              showErrorAlert: false,
              data: JSON.stringify({ role_arn: arn, region: regions[i] } as SaasIntegrationConnection),
            }
          );
        } catch (e: any) {
          throw handleConnectionError(e);
        }
      }
    },
  });
};

export const useCheckCloudWatchConnectionStatus = () => {
  const { status } = useLastMutationState(['checkCloudWatchConnection']);
  return status;
};

function handleConnectionError(error: any): OnboardingPluginError {
  if (isCheckConnectionError(error)) {
    const onboardingError = handleDisabledAwsRegionError(error) ?? {
      code: '',
      message: text.errorFunctions.checkCloudWatchConnection(error.context.mainErrorField),
      errorObject: error,
    };
    return onboardingError;
  }
  return {
    code: '',
    message: '',
    errorObject: error,
  };
}

// TODO manage error when call useCloudwatchConfig
// function getCloudwatchError() {
//   const obError: OnboardingPluginError = {
//     code: '',
//     message: text.error.genericError,
//     errorObject: error,
//   };
//   thunkAPI.dispatch(handleError({ obError, saasIntegrationId }));
// }
