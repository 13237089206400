import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import {
  SceneComponentProps,
  SceneFlexLayout,
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  TextBoxVariable,
} from '@grafana/scenes';
import { Icon, Input, useStyles2 } from '@grafana/ui';
import { VAR_ACCOUNTS, VAR_LEVEL, VAR_LOG_TERM_SEARCH } from 'scenes/variables';
import { getSceneQueryRunner } from 'scenes/misc';
import { buildLogsQuery, getLogsQueryState, getLogsVolumeQueryState } from 'scenes/Logs/queries';
import { CloudProvider } from 'types/CloudProvider';

const getStyles = (theme: GrafanaTheme2) => ({
  content: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
  `,
});

interface LogsSearchTextState<T> extends SceneObjectState {
  platform: CloudProvider;
  params: T;
}

function LogsSearchTextRenderer({ model }: SceneComponentProps<LogsSearchTextScene<any>>) {
  const styles = useStyles2(getStyles);
  const { params } = model.useState();

  return (
    <div className={styles.content}>
      <Input
        width={60}
        name="textSearch"
        value={params.term}
        placeholder={'Type text to filter the logs below'}
        onChange={(e) => model.onTextChanged(e.currentTarget.value)}
        prefix={<Icon name="search" />}
      />
    </div>
  );
}

export class LogsSearchTextScene<T> extends SceneObjectBase<LogsSearchTextState<T>> {
  static Component = LogsSearchTextRenderer;

  public constructor(state: LogsSearchTextState<T>) {
    super(state);
    this.addActivationHandler(this._onActivate);
  }

  private _onActivate = () => {
    let changed = false;
    let params = this.state.params;
    const term = sceneGraph.lookupVariable(VAR_LOG_TERM_SEARCH, this)?.getValue() as string;
    if (this.state.platform === CloudProvider.AWS) {
      const accountId = sceneGraph.lookupVariable(VAR_ACCOUNTS, this)?.getValue() as string[];
      const level = sceneGraph.lookupVariable(VAR_LEVEL, this)?.getValue() as string;
      params = { ...params, accountId, level };
      changed = true;
    }
    if (term) {
      params = { ...params, term };
      changed = true;
    }
    if (changed) {
      this.setState({ params });
    }
    this.onRunQueries();
  };

  getQueryRunners(): { logs: SceneQueryRunner | undefined; logsVolume: SceneQueryRunner | undefined } {
    const logsQueryRunner: SceneQueryRunner | undefined = getSceneQueryRunner(this);

    let logsVolumeQueryRunner: SceneQueryRunner | undefined = undefined;
    const parent = sceneGraph.getAncestor(this, SceneFlexLayout);
    if (parent) {
      const logVolumeObj = sceneGraph.findObject(parent, (s) => s.state.key === 'logs-volume');
      if (logVolumeObj) {
        logsVolumeQueryRunner = logVolumeObj.state.$data as SceneQueryRunner;
      }
    }
    return { logs: logsQueryRunner, logsVolume: logsVolumeQueryRunner };
  }

  onRunQueries(): void {
    const { platform, params } = this.state;
    const { logs: logsQueryRunner, logsVolume: logsVolumeQueryRunner } = this.getQueryRunners();
    // @ts-ignore
    const query = buildLogsQuery(platform, params);
    if (logsQueryRunner) {
      logsQueryRunner.cancelQuery();
      logsQueryRunner.setState(getLogsQueryState(query));
      logsQueryRunner.runQueries();
    }
    if (logsVolumeQueryRunner) {
      logsVolumeQueryRunner.cancelQuery();
      logsVolumeQueryRunner.setState(getLogsVolumeQueryState(query));
      logsVolumeQueryRunner.runQueries();
    }
  }

  public onTextChanged = (term: string) => {
    const termVar = sceneGraph.lookupVariable(VAR_LOG_TERM_SEARCH, this) as TextBoxVariable;
    termVar.setState({ value: term });

    const params = { ...this.state.params, term };
    this.setState({ params });
    this.onRunQueries();
  };
}
