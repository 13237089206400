import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getUpdateStyles = (theme: GrafanaTheme2) => ({
  alertWrapper: css`
    max-width: 1400px;

    width: 100%;
  `,
  alert: css`
    > div:first-child {
      align-items: center;
      > div:first-child {
        padding-top: 0;
      }
    }
  `,
  alertContent: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  updateButton: css`
    margin-left: ${theme.spacing(0.5)};
  `,
  overrideModal: css`
    max-width: 600px;
    padding: 6px;
  `,
  modalContent: css`
    h3 {
      margin-bottom: ${theme.spacing(4)};
      padding-top: ${theme.spacing(2)};
    }
    button {
      margin-right: ${theme.spacing(1)};
    }
  `,
  icon: css`
    margin-right: 10px;
  `,
  alertText: css`
    color: #f5b73d;
  `,
  text: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ccccdc;
    padding-left: 30px;
    margin-top: 15px;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  `,
});
