import { useGetDashboardDetails } from 'api/dashboards/queries';
import { useGetScrapeJobs, useGetServices } from 'api/hosted-exporters-api/queries';
import { isAWSPage, isAzurePage, isGCPPage } from 'scenes/misc';
import { useParams } from 'react-router-dom';
import { useContext, useMemo, useState } from 'react';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { ScenesCustomParams } from 'scenes/types';
import { CloudProvider } from 'types/CloudProvider';
import { isEqual } from 'lodash';
import { isNullOrUndefined } from 'utils/misc';

type ScenesParamsState = { isLoading: boolean; isError: boolean; data: ScenesCustomParams | undefined };

const useDashboardInfoFromUrl = () => {
  const { dashboardId, integrationFolder } = useParams<{ dashboardId: string; integrationFolder: string }>();

  return useMemo(() => {
    const regex = /\/a\/grafana-csp-app\/(aws|gcp|azure)\/dashboards\/.*\/[0-9]+/g;
    if (regex.test(window.location.pathname)) {
      return { dashboardId, integrationFolder };
    }
    return { dashboardId: '', integrationFolder: '' };
  }, [dashboardId, integrationFolder]);
};

export const useScenesParams = (): ScenesParamsState => {
  const [scenesParamsState, setScenesParamsState] = useState<ScenesParamsState>({
    isLoading: false,
    isError: false,
    data: undefined,
  });

  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { dashboardId, integrationFolder } = useDashboardInfoFromUrl();

  const enabledAws = useMemo(
    () => isNullOrUndefined(scenesParamsState?.data?.showServices?.aws) || isAWSPage(),
    [scenesParamsState?.data?.showServices?.aws]
  );
  const enabledGcp = useMemo(
    () => isNullOrUndefined(scenesParamsState?.data?.showServices?.gcp) || isGCPPage(),
    [scenesParamsState?.data?.showServices?.gcp]
  );
  const enabledAzure = useMemo(
    () => isNullOrUndefined(scenesParamsState?.data?.showServices?.azure) || isAzurePage(),
    [scenesParamsState?.data?.showServices?.azure]
  );

  const {
    data: dashboard,
    isLoading: isDashboardLoading,
    isPending: isDashboardPending,
    isError: isDashboardError,
  } = useGetDashboardDetails(dashboardId, integrationFolder);
  const {
    data: jobs,
    isLoading: isJobLoading,
    isPending: isJobPending,
    isError: isJobError,
  } = useGetScrapeJobs('cloudwatch', pluginId, jsonData.grafana_instance_id, enabledAws);
  const {
    data: gcpServices,
    isLoading: isGcpLoading,
    isPending: isGcpPending,
    isError: isGcpError,
  } = useGetServices(CloudProvider.GCP, pluginId, jsonData?.grafana_instance_id, enabledGcp);
  const {
    data: azureServices,
    isLoading: isAzureLoading,
    isPending: isAzurePending,
    isError: isAzureError,
  } = useGetServices(CloudProvider.AZURE, pluginId, jsonData?.grafana_instance_id, enabledAzure);

  const dashboardLoading = Boolean(dashboardId) ? isDashboardLoading : false;
  const dashboardError = Boolean(dashboardId) ? isDashboardError : false;
  const dashboardPending = Boolean(dashboardId) ? isDashboardPending : false;
  const jobsLoading = enabledAws ? isJobLoading : false;
  const jobsPending = enabledAws ? isJobPending : false;
  const jobError = enabledAws ? isJobError : false;
  const gcpLoading = enabledGcp ? isGcpLoading : false;
  const gcpPending = enabledGcp ? isGcpPending : false;
  const gcpError = enabledGcp ? isGcpError : false;
  const azureLoading = enabledAzure ? isAzureLoading : false;
  const azurePending = enabledAzure ? isAzurePending : false;
  const azureError = enabledAzure ? isAzureError : false;

  const newScenesParamsState = { ...scenesParamsState } as ScenesParamsState;
  const isLoading = dashboardLoading || jobsLoading || gcpLoading || azureLoading;
  if (scenesParamsState.isLoading !== isLoading) {
    newScenesParamsState.isLoading = isLoading;
  }

  const isError = dashboardError || jobError || gcpError || azureError;
  if (scenesParamsState.isError !== isError) {
    newScenesParamsState.isError = isError;
  }

  const isPending = dashboardPending || jobsPending || gcpPending || azurePending;
  const data = {
    dashboard: !!dashboard ? dashboard : undefined,
    showServices: {
      aws: !!jobs && jobs.length > 0,
      azure: !!azureServices && azureServices?.length > 0,
      gcp: !!gcpServices && gcpServices?.length > 0,
    },
  } as ScenesCustomParams;
  if (!isLoading && !isPending && !isEqual(scenesParamsState.data, data)) {
    newScenesParamsState.data = data;
  }
  if (isScenesParamsChanged(scenesParamsState, newScenesParamsState)) {
    setScenesParamsState(newScenesParamsState);
  }
  return scenesParamsState;
};

const isScenesParamsChanged = (a: ScenesParamsState, b: ScenesParamsState) => {
  return (
    a?.isLoading !== b?.isLoading ||
    a?.isError !== b?.isError ||
    !isEqual(a?.data?.showServices, b?.data?.showServices) ||
    !isEqual(a?.data?.dashboard, b?.data?.dashboard)
  );
};
