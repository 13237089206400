import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { colors } from 'utils/consts';

interface Props {
  children: React.ReactNode;
}

const getStyles = (theme: GrafanaTheme2) => ({
  box: css({
    backgroundColor: 'rgba(50, 116, 217, 0.25)',
    color: theme.isDark ? theme.colors.primary.contrastText : colors.dark03,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.radius?.default ?? '2px',
    marginTop: theme.spacing(0.5),
    p: {
      margin: 0,
    },
    a: {
      color: colors.blue11,
    },
  }),
});

export const KubernetesBadge: React.FC<Props> = (props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.box}>
      <Icon name="info-circle" />
      {props.children}
    </div>
  );
};
