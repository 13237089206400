import { cx } from '@emotion/css';
import _, { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { SelectableValue } from '@grafana/data';
import { Checkbox, Field, Input, MultiSelect, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { FormOutput, OutputMetrics, TagsFormOutput } from 'feature/AWS/components/SaasIntegrations/types';
import { ServiceInfoApiResponse, Services } from 'api/hosted-exporters-api/data-models';
import {
  getDefaultMetric,
  initOutputMetricsArray,
  isServiceWithResources,
  isValidStatistics,
} from '../../CloudWatchUtils';
import { StatisticsSelect } from '../StatisticsSelect';
import { StatisticsAndMetricsWarning } from '../StatisticsAndMetricsWarning';
import { ScrapeIntervalSelect } from './ScrapeIntervalSelect';
import { getEditServiceMetricsStyles } from './EditServiceMetrics.styles';
import { getCommonElements } from 'utils/misc';
import { TagsManager } from './Tags/TagsManager';

type EditServiceMetricsProps = {
  index: string;
  service: FormOutput;
  serviceConfiguration: FormOutput[];
  defaultServices: ServiceInfoApiResponse[];
  onDataChanged: (data: FormOutput) => void;
  supportedServices?: Services;
};

export const EditServiceMetrics = ({
  index,
  service,
  defaultServices,
  onDataChanged,
  serviceConfiguration,
  supportedServices,
}: EditServiceMetricsProps) => {
  const styles = useStyles2(getEditServiceMetricsStyles);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [serviceMetrics, setServiceMetrics] = useState<FormOutput>(service);
  const [outputMetricsArray, setOutputMetricsArray] = useState<OutputMetrics[]>(initOutputMetricsArray(service));

  const hasResources = isServiceWithResources(supportedServices, service);
  const serviceInfoApi = defaultServices.find((defaultService) => defaultService.service_id === service.service_id);

  const currentServiceName = serviceInfoApi?.display_name;
  const currentServiceMetrics = serviceInfoApi?.available_metrics ?? [];
  const currentServiceMetricsLength = serviceInfoApi?.available_metrics.length;
  const searchResultLength = outputMetricsArray.filter((metr) => metr.name.includes(searchInputValue)).length;
  const isSearchResultEmpty = searchResultLength === 0;

  const selectedMetricsLength = outputMetricsArray.filter((metric) => metric.isChecked).length;
  const areAllMetricsChecked =
    !_.isEmpty(serviceMetrics.metrics) &&
    outputMetricsArray.filter((metric) => metric.name.includes(searchInputValue)).every((metric) => metric.isChecked);

  const updateServiceMetrics = useCallback(
    (updatedData: FormOutput) => {
      setServiceMetrics(updatedData);
      setOutputMetricsArray(initOutputMetricsArray(updatedData));
      onDataChanged(updatedData);
    },
    [onDataChanged]
  );

  useEffect(() => {
    if (!isEqual(service, serviceMetrics)) {
      updateServiceMetrics(service);
    }
  }, [service, serviceMetrics, updateServiceMetrics]);

  function onStatisticsChanged(metricName: string, statistics: string[]) {
    const updatedData = cloneDeep(serviceMetrics);

    if (!updatedData.metrics[metricName]) {
      updatedData.metrics[metricName] = getDefaultMetric(serviceMetrics.service_id, metricName, defaultServices);
    }
    updatedData.metrics[metricName].statistics = statistics;

    updateServiceMetrics(updatedData);
  }

  function onMetricChanged(metricName: string, isChecked: boolean) {
    const updatedData = cloneDeep(serviceMetrics);
    if (!updatedData.metrics[metricName]) {
      updatedData.metrics[metricName] = getDefaultMetric(serviceMetrics.service_id, metricName, defaultServices);
    }
    updatedData.metrics[metricName].isChecked = isChecked;

    updateServiceMetrics(updatedData);
  }

  function onSelectAllMetrics(isChecked: boolean) {
    const updatedData = cloneDeep(serviceMetrics);

    outputMetricsArray
      .filter((metric) => metric.name.includes(searchInputValue))
      .forEach((metric) => {
        if (!updatedData.metrics[metric.name]) {
          updatedData.metrics[metric.name] = getDefaultMetric(serviceMetrics.service_id, metric.name, defaultServices);
        }
        updatedData.metrics[metric.name].isChecked = isChecked;
      });

    updateServiceMetrics(updatedData);
  }

  function onApplyToAllSelectedStatistics(metrics: string[] = [], statisticBatchSelectValue: string[]) {
    if (metrics.length > 0) {
      const updatedData = cloneDeep(serviceMetrics);
      metrics.forEach((metric) => {
        if (!updatedData.metrics[metric]) {
          updatedData.metrics[metric] = getDefaultMetric(serviceMetrics.service_id, metric, defaultServices);
        }
        updatedData.metrics[metric].statistics = statisticBatchSelectValue;
      });

      updateServiceMetrics(updatedData);
    }
  }

  function onScrapeIntervalChanged(value: number) {
    updateServiceMetrics({ ...serviceMetrics, scrape_interval: value });
  }

  function onTagsChanged(values: TagsFormOutput[]) {
    updateServiceMetrics({ ...serviceMetrics, tags: values });
  }

  function onClearAllTags() {
    updateServiceMetrics({ ...serviceMetrics, tags: [] });
  }

  function onApplyAllTags() {
    const allSavedTagNames = Array.from(
      new Set(
        serviceConfiguration
          .map((service) => service.tags ?? [])
          .flat()
          .map((tag) => tag.name)
      )
    );
    allSavedTagNames.forEach((tagName) => {
      if ((serviceMetrics.tags ?? [])?.findIndex((tag) => tag.name === tagName) < 0) {
        const newValue = { name: tagName, addToMetrics: true, useAsFilter: false };
        if (!serviceMetrics.tags) {
          serviceMetrics.tags = [newValue];
        } else {
          serviceMetrics.tags.push(newValue);
        }
      }
    });
    updateServiceMetrics({ ...serviceMetrics });
  }

  const statisticsDefaultOptions = getCommonElements(
    (serviceInfoApi ?? ({} as ServiceInfoApiResponse))?.available_metrics.map((metric) => metric.metric.statistics)
  );

  return (
    <>
      <Field
        className={styles.searchInput}
        label={`Select ${currentServiceName} metrics`}
        description={`${selectedMetricsLength} out of ${currentServiceMetricsLength} metrics selected`}
      >
        <Input
          type="text"
          name="metrics-search"
          placeholder={`Search ${currentServiceName} metrics`}
          value={searchInputValue}
          autoComplete="off"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value)}
        />
      </Field>
      <table
        className={styles.table}
        data-testid={Pages.CloudWatch.ConfigServiceMetrics.serviceMetricsTable(service.service_id)}
      >
        <thead>
          <tr>
            <th>
              <Checkbox
                data-testid={Pages.CloudWatch.ConfigServiceMetrics.selectAllMetricsCheckbox}
                value={areAllMetricsChecked}
                defaultChecked={areAllMetricsChecked}
                label="Metric"
                disabled={isSearchResultEmpty}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onSelectAllMetrics(e.currentTarget.checked);
                }}
              />
            </th>
            <th className={styles.statisticHead}>
              <StatisticsSelect
                statistics={statisticsDefaultOptions}
                outputMetricsArray={outputMetricsArray}
                index={index}
                searchInputValue={searchInputValue}
                onApplyToAll={onApplyToAllSelectedStatistics}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentServiceMetrics.map((metrics, key) => (
            <tr key={key} className={cx(styles.tr, !metrics.metric.name.includes(searchInputValue) && styles.hide)}>
              <td>
                <Checkbox
                  checked={serviceMetrics.metrics[metrics.metric.name]?.isChecked ?? false}
                  label={metrics.metric.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onMetricChanged(metrics.metric.name, e.currentTarget.checked)
                  }
                />
              </td>
              <td>
                <Field
                  invalid={!isValidStatistics(serviceMetrics, metrics.metric.name)}
                  error={
                    !isValidStatistics(serviceMetrics, metrics.metric.name) ? 'At least one statistic is required' : ''
                  }
                  className={styles.statistic}
                  htmlFor={`statistics-selector-${index}`}
                >
                  <MultiSelect<string>
                    options={(metrics.metric.statistics ?? []).map((statistic) => ({
                      label: statistic,
                      value: statistic,
                    }))}
                    inputId={`statistics-selector-${index}`}
                    placeholder="Choose"
                    noOptionsMessage="No options remaining."
                    closeMenuOnSelect={false}
                    className={styles.dropdown}
                    value={serviceMetrics.metrics[metrics.metric.name]?.statistics ?? []}
                    onChange={(options: Array<SelectableValue<string>>) => {
                      onStatisticsChanged(
                        metrics.metric.name,
                        options.map((option) => option.value ?? '')
                      );
                    }}
                    disabled={!serviceMetrics.metrics[metrics.metric.name]?.isChecked}
                  />
                </Field>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isSearchResultEmpty && <p className={styles.noResults}>No results matching your query were found.</p>}
      <StatisticsAndMetricsWarning />
      {!!serviceInfoApi && serviceInfoApi.available_scrape_intervals?.length > 0 && (
        <ScrapeIntervalSelect
          index={index}
          serviceForm={service}
          statistics={serviceInfoApi.available_scrape_intervals}
          onScrapeIntervalChanged={onScrapeIntervalChanged}
        />
      )}
      {!!serviceInfoApi && hasResources && (
        <TagsManager
          serviceForm={service}
          serviceConfiguration={serviceConfiguration}
          onTagsChanged={onTagsChanged}
          onClearAllTags={onClearAllTags}
          onApplyAllTags={onApplyAllTags}
        />
      )}
    </>
  );
};
