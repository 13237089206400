import React, { FC } from 'react';

import { Checkbox, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

import { getSelectServiceResultsStyles } from './SelectServiceResults.styles';
import { SelectServiceItem } from './SelectServiceItem';

import { handleKeyDown } from 'utils/aria';
import { AWSIcon } from 'feature/AWS/components/AWSIcon/AWSIcon';

type SelectServiceResultsProps = {
  selectAll: boolean;
  services: SelectServiceItem[];
  onSelected: (serviceId: string, selected: boolean) => void;
  onSelectAll: (selected: boolean) => void;
};

export const SelectServiceResults: FC<SelectServiceResultsProps> = ({
  selectAll,
  services,
  onSelected,
  onSelectAll,
}) => {
  const styles = useStyles2(getSelectServiceResultsStyles);

  function onServiceSelected(e: any, id: string) {
    e.preventDefault();
    const currentService = services.find((s) => s.id === id);
    if (!!currentService) {
      onSelected(currentService.id, !currentService.selected);
    }
  }

  return services?.length > 0 ? (
    <>
      <div className={styles.checkboxAll}>
        <Checkbox
          name={'select-all'}
          label={`${selectAll ? 'Unselect' : 'Select'} all services`}
          className={styles.checkbox}
          value={selectAll}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSelectAll(e.currentTarget.checked)}
        />
      </div>
      <ul className={styles.list} data-testid={Pages.CloudWatch.SelectServices.serviceSelectList}>
        {services.map((service) => (
          <li key={'service-' + service.id} className={service.selected ? 'selected' : ''}>
            <div
              className={styles.item}
              onClick={(ev) => onServiceSelected(ev, service.id)}
              onKeyDown={handleKeyDown((ev) => onServiceSelected(ev, service.id))}
              role="button"
              tabIndex={0}
            >
              <Checkbox name={service.id + '-checkbox'} className={styles.checkbox} value={service.selected} />
              <AWSIcon service={service.name} />
              <h4>{service.name}</h4>
            </div>
          </li>
        ))}
      </ul>
    </>
  ) : (
    <div className={styles.noResults} data-testid={Pages.CloudWatch.SelectServices.serviceSelectEmpty}>
      <h2>No service found</h2>
    </div>
  );
};
