import React, { useMemo } from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';
import { VAR_LOKI_DATASOURCE } from 'scenes/variables';

interface OpenInExploreButtonState extends SceneObjectState {}

export class OpenInExploreButton extends SceneObjectBase<OpenInExploreButtonState> {
  static Component = OpenInExploreButtonRenderer;

  constructor(state: OpenInExploreButtonState) {
    super(state);
  }
}

function OpenInExploreButtonRenderer({ model }: SceneComponentProps<OpenInExploreButton>) {
  const { from, to } = sceneGraph.getTimeRange(model).useState();
  const data = sceneGraph.getData(model).useState();
  const datasource = sceneGraph.lookupVariable(VAR_LOKI_DATASOURCE, model)?.getValue() as string;

  const left = useMemo(
    () => [
      encodeURIComponent(
        JSON.stringify({
          datasource,
          queries: (data as any).queries,
          range: {
            from,
            to,
          },
        })
      ),
    ],
    [from, to, data, datasource]
  );

  return (
    <LinkButton icon="compass" data-cy="openInExploreButton" variant="secondary" href={`/explore?left=${left}`}>
      Open in Explore
    </LinkButton>
  );
}
