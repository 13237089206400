import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { CardElementProps } from 'components/SelectGroup/CardElement';
import { colors } from 'utils/consts';
import { handleKeyDown } from 'utils/aria';

const getStyles = (theme: GrafanaTheme2) => ({
  selectElement: css`
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    width: 240px;
    background: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.medium};
    margin-right: ${theme.spacing(3)};
    cursor: pointer;
    box-shadow: 0px 2px 9px ${theme.isLight ? `rgba(208, 208, 208, 0.6)` : `rgba(0, 0, 0, 0.6)`};
    transition: background 0.2s ease-in-out;

    &:hover {
      background: ${theme.colors.background.secondary};
      box-shadow: inset 0 0 1px ${colors.black};
    }
  `,
  selected: css`
    padding: 23px 30px 23px 23px;
    border: 2px solid ${theme.isLight ? `#5794F2` : `#2f5ca1`};
    box-shadow: 0px 2px 9px ${theme.isLight ? colors.blue09 : colors.blue10};
    background: ${theme.colors.background.secondary};

    &:hover {
      box-shadow: 0px 2px 9px ${theme.isLight ? colors.blue09 : colors.blue10};
    }
  `,
  title: css`
    font-size: 16px;
    font-weight: ${theme.typography.fontWeightRegular};
    color: ${theme.isLight ? colors.blue04 : theme.colors.text.maxContrast};
    margin: 0;
  `,
  checkbox: css`
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-left: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
  `,
  checked: css`
    color: ${colors.black};
    position: relative;
    color: ${colors.blue03};
  `,
});

export const CardElement: FC<CardElementProps> = ({ option, isSelected, onClick }) => {
  const styles = useStyles2(getStyles);
  return (
    <div
      onClick={onClick}
      onKeyDown={handleKeyDown(onClick)}
      role="button"
      tabIndex={0}
      className={cx(styles.selectElement, isSelected && styles.selected)}
    >
      <div className={styles.title}>{option.label}</div>
      <span className={styles.checkbox}>
        {isSelected && <Icon className={styles.checked} name="check" size="xl" />}
      </span>
    </div>
  );
};
