import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getModalWithFooterStyles = (theme: GrafanaTheme2) => ({
  modalContent: css`
    display: flex;
    flex-direction: column;
  `,
  modalBody: css`
    overflow: auto;
    padding: 0 ${theme.spacing(0.5)} 0 ${theme.spacing(0.5)};
    flex-grow: 1;
  `,
});
