import { IntegrationConnectionErrorCause, IntegrationConnectionStatus } from 'enums';

interface Installation {
  stack_id?: string;
  slug?: string;
  version: string;
  installed_on: string;
  configuration?: {
    configurable_logs?: {
      logs_disabled: boolean;
    };
  };
}

export enum SourceType {
  SaasIntegration = 'saas',
  HostedData = 'hosted-data',
  AgentIntegration = 'agent',
  CloudApp = 'cloud-app',
  DataSource = 'data-source',
  Alpha = 'alpha',
}

interface ApiIntegration {
  name: string;
  installation?: Installation;
  overview?: string;
  logo: {
    dark_theme_url: string;
    light_theme_url: string;
  };
  slug: string;
  version: string;
  type: SourceType.AgentIntegration | SourceType.SaasIntegration;
  dashboard_folder?: string;
  search_keywords: string[];
  has_update: boolean;
  rule_namespace?: string;
}

export type ApiIntegrationResult = Record<string, ApiIntegration>;

enum SourcePlan {
  Cloud = 'cloud',
}

export type Source = {
  id: string;
  name: string;
  logo_url: string;
  light_logo_url?: string;
  type: SourceType;

  plan?: SourcePlan;
  installation?: Installation;
  overview?: string;
  externalUrl?: string;
  slug?: string;
  version?: string;
  dashboard_folder?: string;
  search_keywords?: string[];
  has_update?: boolean;
  rule_namespace?: string;
};

type Screenshot = {
  description: string;
  sort_order: number;
  url: string;
};

type Metrics = {
  name: string;
  description?: string;
  type?: string;
};

type Alerts = {
  name: string;
  summary?: string;
  namespace?: string;
  group?: string;
};

type Rules = {
  name?: string;
  namespace?: string;
  group?: string;
};

export type VersionHistory = {
  Date: string;
  SortOrder: number;
  Text: string;
  Version: string;
};

export enum Status {
  Available = 'available',
  NotSupported = 'not_supported',
}

export type SourceDetails = {
  installation?: Installation;
  dashboards?: {
    screenshots: Screenshot[];
    status: Status;
  };

  agent_configuration?: {
    supported_platforms?: string[];
  };

  metrics?: {
    status: Status;
    available_metrics?: Metrics[];
  };

  alerts?: {
    status: Status;
    available_alerts?: Alerts[];
  };
  rules?: {
    status: Status;
    available_rules?: Rules[];
  };
  logs?: {
    status: Status;
  };

  version_history?: VersionHistory[];

  metrics_check_query?: string;
  logs_check_query?: string;

  configuration_defaults?: {
    enable_logs_toggle: boolean;
    configurable_logs_defaults: {
      logs_disabled: boolean;
    };
  };
  name: string;
  logo: {
    dark_theme_url: string;
    light_theme_url: string;
  };
  dashboard_folder?: string;
  has_update: boolean;
};

export interface ApiIntegrationDetails extends ApiIntegration {
  agent_configuration?: {
    supported_platforms?: string[];
  };
  dashboards: {
    status: Status;
    screenshots: Screenshot[];
  };
  metrics?: {
    status: Status;
    available_metrics?: Metrics[];
  };
  alerts?: {
    status: Status;
    available_alerts?: Alerts[];
  };
  rules?: {
    status: Status;
    available_rules?: Rules[];
  };
  logs?: {
    status: Status;
  };
  version_history?: VersionHistory[];
  metrics_check_query?: string;
  logs_check_query?: string;
  configuration_defaults: {
    enable_logs_toggle: boolean;
    configurable_logs_defaults: {
      logs_disabled: boolean;
    };
  };
}

/******************** Alloy ***********************/
export enum MetricsSetType {
  Filtered = 'filtered',
  Unfiltered = 'unfiltered',
}

interface APIAgentDetailsStatic {
  default_type: MetricsSetType;
  enable_hostname_relabel_and_filter: boolean;
  pre_instructions: string;
  post_instructions: string;
  integrations_snippets: string;
  integrations_snippets_filtered: string;
  metrics_snippets: string;
  metrics_snippets_filtered: string;
  logs_snippets: string;
  agent_example_configuration: string;
  agent_example_configuration_filtered: string;
  agent_example_configuration_filtered_logs_disabled: string;
  agent_example_configuration_logs_disabled: string;
}

export type SimpleBlocks = {
  blocks: string;
  blocks_filtered?: string;
};

type SimpleLogBlocks = {
  blocks: string;
};

type AdvancedBlocks = {
  blocks: string;
  blocks_filtered?: string;
  instructions: string;
};

type AdvancedLogBlocks = {
  blocks: string;
  instructions: string;
};

export type APIAlloyPlatforms<Blocks> =
  | {
      all: Blocks;
    }
  | {
      darwin: Blocks;
      linux: Blocks;
      windows: Blocks;
    };

export interface APIAgentDetailsAlloySimple {
  general_guidance: string;

  metrics?: APIAlloyPlatforms<SimpleBlocks>;
  integrations?: APIAlloyPlatforms<SimpleBlocks>;
  logs?: APIAlloyPlatforms<SimpleLogBlocks>;
}

export interface APIAgentDetailsAlloyAdvanced {
  general_guidance: string;

  metrics?: APIAlloyPlatforms<AdvancedBlocks>;
  integrations?: APIAlloyPlatforms<AdvancedBlocks>;
  logs?: APIAlloyPlatforms<AdvancedLogBlocks>;
}

export type APIAgentDetailsAlloy = {
  pre_instructions: string;
} & (
  | { simple: APIAgentDetailsAlloySimple }
  | { advanced: APIAgentDetailsAlloyAdvanced }
  | {
      simple: APIAgentDetailsAlloySimple;
      advanced: APIAgentDetailsAlloyAdvanced;
    }
);

export type APIAgentDetails =
  | {
      static: APIAgentDetailsStatic;
    }
  | {
      alloy: APIAgentDetailsAlloy;
    };

export interface StaticContent {
  defaultType: MetricsSetType;
  enableHostnameRelabelAndFilter: boolean;
  preInstructions: string;
  postInstructions: string;
  integrationsSnippets: string;
  integrationsSnippetsFiltered: string;
  metricsSnippets: string;
  metricsSnippetsFiltered: string;
  logsSnippets: string;
  agentExampleConfiguration: string;
  agentExampleConfigurationFiltered: string;
  agentExampleConfigurationFilteredLogsDisabled: string;
  agentExampleConfigurationLogsDisabled: string;
}

export type AlloyPlatforms<Blocks> =
  | {
      type: 'unified';
      all: Blocks;
    }
  | {
      type: 'specific';
      darwin: Blocks;
      linux: Blocks;
      windows: Blocks;
    };

export interface AlloySimpleContent {
  preInstructions: string;
  generalGuidance: string;

  metrics?: AlloyPlatforms<SimpleBlocks>;
  integrations?: AlloyPlatforms<SimpleBlocks>;
  logs?: AlloyPlatforms<SimpleLogBlocks>;
}

export interface AlloyAdvancedContent {
  preInstructions: string;
  generalGuidance: string;

  metrics?: AlloyPlatforms<AdvancedBlocks>;
  integrations?: AlloyPlatforms<AdvancedBlocks>;
  logs?: AlloyPlatforms<AdvancedLogBlocks>;
}

export type AgentDetails =
  | {
      contentType: 'static';
      static: StaticContent;
    }
  | {
      contentType: 'alloy-advanced';
      advanced: AlloyAdvancedContent;
    }
  | {
      contentType: 'alloy-simple';
      simple: AlloySimpleContent;
    }
  | {
      contentType: 'alloy-both-modes';
      simple: AlloySimpleContent;
      advanced: AlloyAdvancedContent;
    };

export type AgentDetailsStatic = AgentDetails & { contentType: 'static' };
export type AgentDetailsAlloyAdvanced = AgentDetails & { contentType: 'alloy-advanced' };
export type AgentDetailsAlloySimple = AgentDetails & { contentType: 'alloy-simple' };
export type AgentDetailsAlloyBothModes = AgentDetails & { contentType: 'alloy-both-modes' };

export type AgentSendResult = {
  status: IntegrationConnectionStatus;
  error?: IntegrationConnectionErrorCause;
  value: boolean;
};
