import { LogsMethod } from 'scenes/types';

export type LogGroupInfo = {
  id: string;
  value: string;
  origin: LogsOrigin;
  method: LogsMethod;
};

export enum LogsOrigin {
  CLOUDWATCH = 'CloudWatch',
  S3_BUCKET = 'ALB',
}
