import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';

export const getStartedCards = [
  {
    id: 'cloudwatch',
    name: 'CloudWatch metrics',
    exploreLink: prefixRoute(CloudProvider.AWS, 'configuration/cloudwatch/create'),
    description:
      'The CloudWatch integration allows you to scrape AWS CloudWatch metrics without installing the Grafana agent. You can create multiple configurations called "scrape jobs" to separate concerns. Please note that we can only discover metrics for AWS resources that have tags applied to them.\nFor more information, see the [AWS docs](https://docs.aws.amazon.com/general/latest/gr/aws_tagging.html).\n\nThe CloudWatch integration packages together many dashboards, a few of which reference [Monitoring Artist](https://github.com/monitoringartist/grafana-aws-cloudwatch-dashboards).\n\n**Links**  \n[Cloudwatch docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-cloudwatch/)\n\n',
    logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws.svg',
    externalUrl: '',
  },
  {
    id: 'aws-alb-logs',
    name: 'Application Load Balancer Logs',
    exploreLink: prefixRoute(CloudProvider.AWS, 'configuration/aws-alb-logs'),
    description:
      'Send [Application Load Balancer access logs](https://docs.aws.amazon.com/elasticloadbalancing/latest/application/load-balancer-access-logs.html) into Grafana Cloud Loki.\n\nThis integration will guide you in configuring the Grafana Lambda Forwarder, to read load balancer access logs from AWS S3, and send them to Grafana Cloud Loki.\n\n**Links**  \n[AWS Application Load Balancer](https://docs.aws.amazon.com/elasticloadbalancing/latest/application/introduction.html)  \n[ALB Access Logs](https://docs.aws.amazon.com/elasticloadbalancing/latest/application/load-balancer-access-logs.html)\n',
    logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws-elb.svg',
    externalUrl: '',
  },
  {
    id: 'cloudwatch-logs-firehose',
    name: 'Logs with Firehose',
    exploreLink: prefixRoute(CloudProvider.AWS, 'configuration/cloudwatch-logs-firehose'),
    description:
      'Logs with Firehose is a solution that allows customers to ship logs to Grafana Cloud Loki, through Firehose.\n\nA [Kinesis Firehose delivery stream](https://docs.aws.amazon.com/firehose/latest/dev/what-is-this-service.html) is configured on the customer cloud, in such a way that it batches and sends ingested records to Grafana Cloud. There’s multiple AWS resources that can be configured to send events to the Firehose delivery stream, such as CloudWatch logs through [subscription filters](https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/SubscriptionFilters.html#FirehoseExample), [VPC flow logs](https://docs.aws.amazon.com/firehose/latest/dev/vpc-splunk-tutorial.html), [AWS CloudTrail logs](https://docs.aws.amazon.com/awscloudtrail/latest/userguide/send-cloudtrail-events-to-cloudwatch-logs.html), etc.\n\n**Links**  \n[Configure Logs with Firehose](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/firehose-logs/config-firehose-logs/) \n[Logs with Firehose - How it works](https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/firehose-logs/) \n[AWS Kinesis Data Firehose](https://docs.aws.amazon.com/firehose/latest/dev/what-is-this-service.html)  \n',
    logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws-firehose.svg',
    externalUrl: '',
  },
  {
    id: 'cloudwatch-logs',
    name: 'Logs with Lambda',
    exploreLink: prefixRoute(CloudProvider.AWS, 'configuration/cloudwatch-logs'),
    description:
      'The Logs with Lambda integration enables you to send logs from different AWS services to Grafana Cloud. The integration will guide you\nthrough the deployment of a Lambda function that forwards logs to Grafana Cloud Loki.\n\n**Links**  \n[Cloudwatch logs docs](https://grafana.com/docs/grafana-cloud/data-configuration/integrations/integration-reference/integration-cloudwatch-logs/)  \n',
    logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws.svg',
    externalUrl: '',
  },
];
