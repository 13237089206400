import React from 'react';
import { SceneComponentProps, SceneObject, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const getStyles = (theme: GrafanaTheme2, align?: 'flex-start' | 'flex-end') => {
  return {
    controls: css({
      display: 'flex',
      gap: theme.spacing(2),
      justifyContent: align ?? 'flex-end',
      width: '100%',
    }),
  };
};

interface SceneControlsNewLineState extends SceneObjectState {
  align?: 'flex-start' | 'flex-end';
  controls: Array<SceneObject<SceneObjectState>>;
}

export class SceneControlsNewLine extends SceneObjectBase<SceneControlsNewLineState> {
  constructor(state: SceneControlsNewLineState) {
    super(state);
  }

  public get Component() {
    // Skipping wrapper component for this scene object so that it renders right away
    return SceneControlsNewLine.Component;
  }

  public static Component = ({ model }: SceneComponentProps<SceneControlsNewLine>) => {
    const { align, controls } = model.useState();
    const styles = useStyles2(getStyles, align);

    return (
      <div className={styles.controls}>
        {controls.map((control) => (
          <control.Component key={control.state.key} model={control} />
        ))}
      </div>
    );
  };
}
