import { RuntimeDataSource } from '@grafana/scenes';
import {
  DataQueryRequest,
  DataQueryResponse,
  dateTimeParse,
  FieldType,
  LoadingState,
  TestDataSourceResponse,
} from '@grafana/data';
import { DataQuery } from '@grafana/schema';
import { Observable } from 'rxjs';
import { getVariableParam } from './utils';
import { LogGroupInfo } from './types';
import { VAR_LOKI_DATASOURCE } from 'scenes/variables';
import { getLogGroupLabels } from 'api/logs/queries';
import { LOGS_DATA_SOURCE_UID } from 'utils/consts';

export class LogGroupsDatasource extends RuntimeDataSource {
  constructor(pluginId: string, uid: string) {
    super(pluginId, uid);
  }

  query(request: DataQueryRequest<DataQuery>): Promise<DataQueryResponse> | Observable<DataQueryResponse> {
    const dataSource = getVariableParam(VAR_LOKI_DATASOURCE) ?? LOGS_DATA_SOURCE_UID;
    const from: number = dateTimeParse(getVariableParam('from') ?? 'now-1h').unix();
    const to: number = dateTimeParse(getVariableParam('to') ?? 'now').unix();
    return getLogGroupLabels(dataSource, from, to).then((values: LogGroupInfo[]) => {
      return {
        state: LoadingState.Done,
        data: [
          {
            fields: [
              {
                name: 'id',
                type: FieldType.string,
                values: (values ?? []).map((val: LogGroupInfo) => val.id),
                config: {},
              },
              {
                name: 'value',
                type: FieldType.string,
                values: (values ?? []).map((val: LogGroupInfo) => val.value),
                config: {},
              },
              {
                name: 'origin',
                type: FieldType.string,
                values: (values ?? []).map((val: LogGroupInfo) => val.origin),
                config: {},
              },
              {
                name: 'method',
                type: FieldType.string,
                values: (values ?? []).map((val: LogGroupInfo) => val.method),
                config: {},
              },
            ],
            length: values.length,
          },
        ],
      };
    });
  }

  testDatasource(): Promise<TestDataSourceResponse> {
    return Promise.resolve({ status: 'success', message: 'Data source is working', title: 'Success' });
  }
}
