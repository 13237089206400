import {
  behaviors,
  EmbeddedScene,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
} from '@grafana/scenes';
import { getGenericQueryRunner, makeTimeRange, onChangeDatasourceBehavior } from 'scenes/misc';
import { allQueries } from './queries';
import { VAR_DATASOURCE } from 'scenes/variables';
import { getVariables } from './getVariables';
import { ExplorablePanel } from 'scenes/common/ExplorablePanel';

export const getRegionsTabScene = (prometheusName: string, from?: string, to?: string) => {
  const datasource = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const queryRunner = getGenericQueryRunner(datasource, allQueries.regionsQueries.countByRegion);

  const { datasources, region, allTags, allTagsValues } = getVariables(prometheusName);

  const sceneTimeRange = makeTimeRange(from, to);

  return new EmbeddedScene({
    $timeRange: sceneTimeRange,
    $variables: new SceneVariableSet({
      variables: [datasources, region, allTags, allTagsValues],
    }),
    controls: [
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneRefreshPicker({
        intervals: ['5s', '1m', '1h'],
        isOnCanvas: true,
      }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      $behaviors: [
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_DATASOURCE,
          onChange: onChangeDatasourceBehavior,
        }),
      ],
      children: [
        new SceneFlexItem({
          minHeight: 300,
          $data: queryRunner,
          body: new ExplorablePanel({
            title: 'Instances count by region',
          }),
        }),
        new SceneFlexLayout({
          direction: 'row',
          height: 200,
          children: [
            new SceneFlexItem({
              height: 200,
              body: new ExplorablePanel({
                $data: getGenericQueryRunner(datasource, allQueries.regionsQueries.countByTags),
                title: 'Instances count by tag',
                pluginId: 'stat',
                fieldConfig: {
                  overrides: [],
                  defaults: {
                    color: {
                      mode: 'fixed',
                      fixedColor: 'green',
                    },
                    unit: 'short',
                  },
                },
              }),
            }),
            new SceneFlexItem({
              height: 200,
              body: new ExplorablePanel({
                $data: getGenericQueryRunner(datasource, allQueries.regionsQueries.countAll),
                title: 'Instances count all regions',
                pluginId: 'stat',
                fieldConfig: {
                  overrides: [],
                  defaults: {
                    color: {
                      mode: 'fixed',
                      fixedColor: 'green',
                    },
                    unit: 'short',
                  },
                },
              }),
            }),
          ],
        }),
      ],
    }),
  });
};
