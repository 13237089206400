import React, { FormEvent, MouseEventHandler, useEffect, useState } from 'react';

import { Button, ButtonProps, Checkbox, Input, Tooltip, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { FormOutput } from 'feature/AWS/components/SaasIntegrations/types';
import { CustomNamespaceApiResponse, ServiceInfoApiResponse, Services } from 'api/hosted-exporters-api/data-models';
import { getConfigureServicesTableStyles } from './ConfigureServicesTable.Styles';
import { getFormServicesFromIds, searchServicesByNameAndMetrics } from '../../CloudWatchUtils';
import { ModalWithFooter } from '../../ModalWithFooter/ModalWithFooter';
import { ConfigureServiceRow } from './ConfigureServiceRow';
import { EditServiceModal } from './EditServiceModal';
import { AddServicesModal } from './AddServicesModal';

type ConfigureServicesTableProps = {
  mode: 'create' | 'edit';
  services: FormOutput[];
  defaultServices: ServiceInfoApiResponse[];
  defaultCustomNamespace: CustomNamespaceApiResponse;
  onServicesChanged: (services: FormOutput[]) => void;
  supportedServices?: Services;
};

const getDeleteModalButtons = (
  confirmText = 'Delete',
  onDelete: MouseEventHandler<HTMLButtonElement>,
  onDismiss: MouseEventHandler<HTMLButtonElement>
): ButtonProps[] => [
  {
    name: 'Cancel',
    variant: 'secondary',
    fill: 'solid',
    onClick: onDismiss,
  },
  {
    name: confirmText,
    onClick: onDelete,
    fill: 'solid',
    variant: 'destructive',
  },
];

type ServiceModal = { isOpen: boolean; serviceId: string };

const DEFAULT_SERVICE_MODAL = { isOpen: false, serviceId: '' };

export const ConfigureServicesTable = ({
  mode = 'edit',
  services,
  defaultServices,
  defaultCustomNamespace,
  onServicesChanged,
  supportedServices,
}: ConfigureServicesTableProps) => {
  const styles = useStyles2(getConfigureServicesTableStyles);
  const [deleteServiceModal, setDeleteServiceModal] = useState<ServiceModal>(DEFAULT_SERVICE_MODAL);
  const [editServiceModal, setEditServiceModal] = useState<ServiceModal>(DEFAULT_SERVICE_MODAL);
  const [deleteServicesModalOpen, setDeleteServicesModalOpen] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filteredServices, setFilteredServices] = useState<FormOutput[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const currentService = filteredServices.find(
    (service) =>
      service.service_id === editServiceModal.serviceId || service.service_id === deleteServiceModal.serviceId
  );

  useEffect(() => {
    const servicesAfterSearch = searchServicesByNameAndMetrics(searchInputValue, services);
    setFilteredServices(servicesAfterSearch);

    const totalServicesSelected = servicesAfterSearch.filter((s) => s.selected).length;
    setSelectedAll(totalServicesSelected === servicesAfterSearch.length);
  }, [searchInputValue, services]);

  function openEditServiceModal(id: string) {
    setEditServiceModal({ ...editServiceModal, isOpen: true, serviceId: id });
  }

  function closeEditServiceModal() {
    setEditServiceModal({ ...editServiceModal, isOpen: false, serviceId: '' });
  }

  function onSaveServiceSettings(serviceSettings: FormOutput | undefined, serviceId: string) {
    if (!!serviceSettings) {
      const serviceToEdit = filteredServices.find((service) => service.service_id === serviceId);
      if (!!serviceToEdit) {
        const updatedServices = services.map((service) => {
          if (serviceToEdit.service_id === service.service_id) {
            serviceSettings.isNew = false;
            return serviceSettings;
          }
          return service;
        });
        onServicesChanged(updatedServices);
      }
    }
    closeEditServiceModal();
  }

  function onAddServices() {
    setShowAddModal(true);
  }

  function onServicesAdded(newServices: string[]) {
    if (newServices?.length > 0) {
      const addedServices = getFormServicesFromIds(
        newServices,
        defaultServices,
        mode === 'edit',
        defaultCustomNamespace
      );

      onServicesChanged([...addedServices, ...services]);
    }
    setShowAddModal(false);
  }

  function openDeleteServiceModal(id: string) {
    setDeleteServiceModal({ ...deleteServiceModal, isOpen: true, serviceId: id });
  }

  function onConfirmDeleteService() {
    const serviceToDelete = filteredServices.find((service) => service.service_id === deleteServiceModal.serviceId);
    if (!!serviceToDelete) {
      const updatedServices = services.filter((service) => service.service_id !== serviceToDelete.service_id);
      onServicesChanged(updatedServices);
    }
    closeDeleteServiceModal();
  }

  function closeDeleteServiceModal() {
    setDeleteServiceModal({ ...deleteServiceModal, isOpen: false, serviceId: '' });
  }

  function onSelectedService(selected: boolean, serviceIndex: number) {
    const serviceToSelect = filteredServices[serviceIndex];
    if (!!serviceToSelect) {
      const updatedServices = services.map((service) => {
        if (serviceToSelect.service_id === service.service_id) {
          return { ...service, selected };
        }
        return service;
      });
      onServicesChanged(updatedServices);
    }
  }

  function onSelectedAllServices(e: FormEvent<HTMLInputElement>) {
    const selected = e.currentTarget.checked;

    const updatedServices = services.map((service) => {
      if (filteredServices.findIndex((filteredService) => filteredService.service_id === service.service_id) >= 0) {
        return { ...service, selected };
      }
      return service;
    });
    onServicesChanged(updatedServices);
  }

  function onRemoveSelectedServices() {
    const servicesToDelete = filteredServices.filter((s) => s.selected);
    if (servicesToDelete.length > 0) {
      const updatedServices = services.filter(
        (service) => servicesToDelete.findIndex((s) => s.service_id === service.service_id) < 0
      );
      onServicesChanged(updatedServices);
    }
  }

  const deleteServicesModalButtons = getDeleteModalButtons(
    'Delete services',
    () => {
      setDeleteServicesModalOpen(false);
      onRemoveSelectedServices();
    },
    () => setDeleteServicesModalOpen(false)
  );

  const deleteModalButtons = getDeleteModalButtons(
    'Delete',
    () => onConfirmDeleteService(),
    () => closeDeleteServiceModal()
  );

  const filteredTotalSelected = filteredServices.filter((s) => s.selected).length;

  return (
    <>
      <div className={styles.searchOrAdd}>
        <div className={styles.search}>
          <Input
            type="text"
            name="search-services"
            placeholder={`Search by service name or metrics`}
            value={searchInputValue}
            autoComplete="off"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSearchInputValue(e.target.value)}
            data-testid={Pages.CloudWatch.ConfigServiceMetrics.searchByServiceOrMetricInput}
          />
        </div>
        {mode === 'edit' && services.length < defaultServices.length && (
          <div className={styles.addButton}>
            <Button
              onClick={onAddServices}
              fill="solid"
              variant="primary"
              data-testid={Pages.CloudWatch.ConfigServiceMetrics.addNewServicesButton}
            >
              Add new services
            </Button>
          </div>
        )}
      </div>
      {filteredServices.length > 0 && (
        <table className={styles.table} data-testid={Pages.CloudWatch.ConfigServiceMetrics.scrapeJobServicesTable}>
          <thead>
            <tr>
              {mode === 'edit' && (
                <th key={'select-all'}>
                  <Tooltip content={`${selectedAll ? 'Deselect' : 'Select'} all`}>
                    <Checkbox
                      value={selectedAll}
                      className={styles.selectAllCheckbox}
                      onChange={onSelectedAllServices}
                      data-testid={Pages.CloudWatch.ConfigServiceMetrics.selectAllJobServices}
                    />
                  </Tooltip>
                </th>
              )}
              <th key={'Services'}>
                <div className={styles.servicesColumn}>
                  <span>Services selected</span>
                  {mode === 'edit' && filteredTotalSelected > 0 && (
                    <div className={styles.servicesSelection}>
                      <span className={styles.selectedServicesMessage}>
                        Amount of selected services: {filteredTotalSelected}
                      </span>
                      <Button
                        aria-label="button"
                        variant={'destructive'}
                        onClick={() => setDeleteServicesModalOpen(true)}
                        data-testid={Pages.CloudWatch.ConfigServiceMetrics.deleteSelectedServices}
                      >
                        Delete
                      </Button>
                      {deleteServicesModalOpen && (
                        <ModalWithFooter
                          isOpen={deleteServicesModalOpen}
                          title={'Delete services'}
                          onDismiss={() => setDeleteServicesModalOpen(false)}
                          buttons={deleteServicesModalButtons}
                        >
                          <p>{`You are about to delete ${filteredTotalSelected} service(s) from this scrape job. This is a permanent action, and it means that metrics will no longer be scraped from these services.`}</p>
                        </ModalWithFooter>
                      )}
                    </div>
                  )}
                </div>
              </th>
              <th key={'Metrics selected'}>Metrics selected</th>
              <th key={'actions'}></th>
            </tr>
          </thead>
          <tbody>
            {filteredServices.map((service, i) => (
              <ConfigureServiceRow
                mode={mode}
                key={service.service_id}
                index={i}
                onEdit={openEditServiceModal}
                onRemove={openDeleteServiceModal}
                onSelected={onSelectedService}
                service={service}
                defaultServices={defaultServices}
              />
            ))}
          </tbody>
        </table>
      )}
      {filteredServices.length === 0 && (
        <div className={styles.emptyServices}>
          <h3>No results matching your query were found.</h3>
        </div>
      )}
      {deleteServiceModal.isOpen && currentService && (
        <ModalWithFooter
          isOpen={deleteServiceModal.isOpen}
          title={`Delete ${currentService.display_name ?? currentService.service_id}`}
          onDismiss={closeDeleteServiceModal}
          buttons={deleteModalButtons}
        >
          <p>{`Are you sure you want to delete this service? All your settings will be lost.`}</p>
        </ModalWithFooter>
      )}

      {editServiceModal.isOpen && currentService && (
        <EditServiceModal
          isOpen={editServiceModal.isOpen}
          index={editServiceModal.serviceId}
          service={currentService}
          serviceConfiguration={filteredServices}
          defaultServices={defaultServices}
          defaultCustomNamespace={defaultCustomNamespace}
          supportedServices={supportedServices}
          onDismiss={closeEditServiceModal}
          onSave={onSaveServiceSettings}
        />
      )}
      {showAddModal && (
        <AddServicesModal
          isOpen={showAddModal}
          servicesSelected={services}
          defaultServices={defaultServices}
          onDismiss={() => setShowAddModal(false)}
          onSave={(services) => onServicesAdded(services)}
        />
      )}
    </>
  );
};
