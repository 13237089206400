import React from 'react';

import { Badge, Button, Checkbox, Icon, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { FormOutput } from 'feature/AWS/components/SaasIntegrations/types';
import { ServiceInfoApiResponse } from 'api/hosted-exporters-api/data-models';
import { AWSIcon } from 'feature/AWS/components/AWSIcon/AWSIcon';
import { getConfigureServiceRowStyles } from './ConfigureServiceRow.styles';
import { WarningText } from 'components/WarningText';

type ConfigureServiceRowProps = {
  mode: 'create' | 'edit';
  index: number;
  service: FormOutput;
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  onSelected: (selected: boolean, index: number) => void;
  defaultServices: ServiceInfoApiResponse[];
};

export const ConfigureServiceRow = ({
  mode = 'edit',
  index,
  service,
  onEdit,
  onRemove,
  onSelected,
  defaultServices,
}: ConfigureServiceRowProps) => {
  const styles = useStyles2(getConfigureServiceRowStyles);

  const serviceInfoApi = defaultServices.find((defaultService) => defaultService.service_id === service.service_id);

  const outputMetricsArray = Object.keys(service.metrics).map((name) => ({ ...service.metrics[name], name: name }));
  const selectedMetricsLength = outputMetricsArray.filter((metric) => metric.isChecked).length;
  const currentServiceMetricsLength = serviceInfoApi?.available_metrics.length ?? 0;
  const isCustomNamespace = service.isCustomNamespace;
  const metricsLength = isCustomNamespace ? outputMetricsArray.length : currentServiceMetricsLength;

  return (
    <tr className={styles.row}>
      {mode === 'edit' && (
        <td className={styles.checkboxColumn}>
          <Checkbox
            className={styles.checkbox}
            value={service.selected}
            onChange={() => onSelected(!service.selected, index)}
            data-testid={Pages.CloudWatch.ConfigServiceMetrics.serviceRowCheckbox(service.service_id)}
          />
        </td>
      )}
      <td className={styles.servicesColumn}>
        <div className={styles.header}>
          {service.isCustomNamespace ? (
            <Icon name="brackets-curly" size="xl" />
          ) : (
            <AWSIcon service={service.display_name ?? service.service_id} />
          )}
          <span>{service.display_name ?? service.service_id}</span>
          {mode === 'edit' && service.isNew && <Badge text={'newly added'} color={'green'} />}
        </div>
      </td>
      <td>
        {`${selectedMetricsLength} out of ${metricsLength}`}{' '}
        {isCustomNamespace && metricsLength === 0 && <WarningText text={'Metrics selection required'} />}
      </td>
      <td className={styles.actionsColumn}>
        <Button
          fill={'outline'}
          variant={'primary'}
          onClick={() => onEdit(service.service_id)}
          data-testid={Pages.CloudWatch.ConfigServiceMetrics.editServiceButton(service.service_id)}
        >
          Edit Metrics
        </Button>
        {mode === 'edit' && (
          <Button
            fill={'outline'}
            variant={'destructive'}
            onClick={() => onRemove(service.service_id)}
            data-testid={Pages.CloudWatch.ConfigServiceMetrics.deleteServiceButton(service.service_id)}
          >
            Delete
          </Button>
        )}
      </td>
    </tr>
  );
};
