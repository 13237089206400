import { css } from '@emotion/css';
import React, { useMemo } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { getArchOptions, showOSSelector, useCollectorSelector, useOsOptions } from '@grafana-cloud/collector';
import { OSSelectionWrapper } from 'feature/common/components/OSSelectionWrapper';
import { useCurrentSourceId } from 'hooks/useCurrentSourceId';

const getStyles = (theme: GrafanaTheme2) => ({
  topMargin: css`
    margin-top: ${theme.spacing(3)};
  `,
});

export const PlatformSelectionSection = () => {
  const styles = useStyles2(getStyles);

  const sourceId = useCurrentSourceId();
  const selectedOs = useCollectorSelector((state) => state.collector.selectedOs);

  const { data: osOptions } = useOsOptions(sourceId);
  const archOptions = useMemo(
    () => selectedOs.osValue && getArchOptions(sourceId, selectedOs.osValue),
    [sourceId, selectedOs.osValue]
  );

  const shouldShowOsSelector = osOptions && showOSSelector(osOptions, archOptions);

  if (!shouldShowOsSelector) {
    return null;
  }

  return (
    <li>
      <h2>Select your platform</h2>
      <div className={styles.topMargin}>
        <OSSelectionWrapper sourceId={sourceId} />
      </div>
    </li>
  );
};
