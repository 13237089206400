import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  cardCentered: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px;

    h2:first-of-type {
      display: none;
    }

    h2 {
      width: 60%;
      margin: 20px 0 40px 0;
    }
    button {
      margin-right: 10px;
    }
  `,
  cardColumn: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  integrationsFlex: (columns = 4) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    position: relative;
    margin-top: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 576px) and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `,
  list: css`
    list-style: none;
  `,
  insightsRow: (columns = 4) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    gap: ${theme.spacing(1)};

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    @media (min-width: 576px) and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
  `,
});
