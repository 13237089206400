import React, { FC, useCallback, useContext, useMemo } from 'react';

import { Button, Checkbox, Dropdown, Icon, useStyles2, Menu } from '@grafana/ui';
import { CloudWatchJob, ScrapeJob } from 'api/hosted-exporters-api/data-models';
import { getJobRowStyles } from './JobRow.styles';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { SaasIntegrationContext, SaasIntegrationState } from 'app/contexts/saasIntegration.context';
import { useCloudwatchConfig } from 'api/hosted-exporters-api/queries';
import { getServicesName } from './CloudWatchUtils';
import { Pages } from 'e2eSelectors/pages';
import { handleKeyDown } from 'utils/aria';
import { EllipsisText } from 'components/EllipsisText';
import { ArnCell } from './ArnCell';
import { ShowMoreList } from 'components/ShowMoreList';
import { JobStatus } from 'feature/AWS/components/JobStatus';
import { ActionType } from 'enums';
import { mapServices } from '../utils';

type JobRowCallbacks = {
  handleJobDelete: (job: ScrapeJob) => void;
  handleJobEdit: (job: ScrapeJob) => void;
};

type JobRowProps = JobRowCallbacks & { job: ScrapeJob };

export const JobRow: FC<JobRowProps> = ({ job: inputJob, handleJobDelete, handleJobEdit }) => {
  const styles = useStyles2(getJobRowStyles);
  const job = inputJob as CloudWatchJob;
  const { isJobSelected, toggleSelectedJobs } = useContext<SaasIntegrationState>(SaasIntegrationContext);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: cloudWatchConfig } = useCloudwatchConfig(pluginId, jsonData.grafana_instance_id);

  const jobSelected = isJobSelected(job.name);
  const allServices = useMemo(
    () => mapServices(cloudWatchConfig?.supported_services ?? {}, ActionType.Edit, job.service_configurations),
    [cloudWatchConfig?.supported_services, job.service_configurations]
  );

  const onChange = useCallback(
    (name: string) => () => {
      toggleSelectedJobs(name);
    },
    [toggleSelectedJobs]
  );

  const menu = (
    <Menu>
      <Menu.Item label="Edit" onClick={() => handleJobEdit(job)} />
      <Menu.Item label="Delete" destructive onClick={() => handleJobDelete(job)} />
    </Menu>
  );

  const services = getServicesName(job.service_configurations, allServices);

  return (
    <tr className={styles.row} data-testid={Pages.CloudWatch.scrapeJobTableRow(job.name)}>
      <td className={styles.checkboxColumn}>
        <Checkbox
          className={styles.checkbox}
          value={jobSelected}
          onChange={onChange(job.name)}
          data-testid={Pages.CloudWatch.scrapeJobRowCheckbox(job.name)}
        />
      </td>
      <td className={styles.nameColumn}>
        <div
          onClick={() => handleJobEdit(job)}
          onKeyDown={handleKeyDown(() => handleJobEdit(job))}
          tabIndex={0}
          role="button"
          className={styles.nameLink}
          data-testid={Pages.CloudWatch.scrapeJobRowLink(job.name)}
        >
          <EllipsisText text={job.name} />
        </div>
      </td>
      <td className={styles.arnColumn}>
        <ArnCell arn={job.role_arn} />
      </td>
      <td>
        <JobStatus enabled={job.enabled} disabled_reason={job.disabled_reason} />
      </td>
      <td className={styles.regionsColumn}>
        <ShowMoreList items={job.regions ?? []} />
      </td>
      <td className={styles.servicesColumn}>
        <ShowMoreList items={services} />
      </td>
      <td className={styles.actionsColumn}>
        <Dropdown overlay={menu}>
          <Button
            className={styles.actionsButton}
            fill={'text'}
            variant={'secondary'}
            data-testid={Pages.CloudWatch.scrapeJobTableActionsButton(job.name)}
          >
            <Icon name={'ellipsis-v'} className={styles.ellipsisIcon} />
          </Button>
        </Dropdown>
      </td>
    </tr>
  );
};
