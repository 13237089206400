import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Icon, useStyles2 } from '@grafana/ui';
import React from 'react';
const getStyles = (theme: GrafanaTheme2) => {
  return {
    link: css`
      color: ${theme.colors.primary.text};
    `,
    errorWrapper: css`
      display: flex;
      flex-direction: column;
      margin-top: ${theme.spacing(5)};
      gap: ${theme.spacing(0.5)};
      justify-content: center;
      align-items: center;
    `,
  };
};
export const QueryError = ({ message, isFullPageError = true }: { message?: string; isFullPageError?: boolean }) => {
  const styles = useStyles2(getStyles);
  const GRAFANA_SUPPORT_URL = 'https://grafana.com/profile/org#support';
  const errorMessage = message ?? 'Error';

  const errorContent = (
    <div className={styles.errorWrapper}>
      <Icon name="exclamation-triangle" size="xxl" />
      <p>{errorMessage}</p>
      <p>
        If it persists, you can{' '}
        <a target="_blank" rel="noreferrer" href={GRAFANA_SUPPORT_URL} className={styles.link}>
          contact us
        </a>
        .
      </p>
    </div>
  );

  return isFullPageError ? <PluginPage>{errorContent}</PluginPage> : errorContent;
};
