import { memo } from 'react';
import { AppPlugin, OrgRole } from '@grafana/data';
import { App } from './components/App';
import { AppConfig } from './components/AppConfig';

import { getFaroConfig } from './faroConfig';
import { initializeFaro } from '@grafana/faro-web-sdk';

// @ts-ignore
import packageJson from '../package.json';
import pluginJson from './plugin.json';
import { getContextUser } from 'utils/user';
import { ContextUser } from 'types/ContextServType';
import { ServiceDashboardExtension } from 'components/Extensions/ServiceDashboardExtension';
import { PLUGIN_BASE_URL } from 'utils/utils.routing';

const { environment, url, name } = getFaroConfig();
const FARO_GLOBAL_OBJECT_KEY = 'aws-app-faro';

const user: ContextUser = getContextUser() ?? {
  id: -1,
  name: '',
  orgId: -1,
  email: '',
  orgName: '',
  orgRole: OrgRole.Viewer,
};

export const faro = Object.prototype.hasOwnProperty.call(window, FARO_GLOBAL_OBJECT_KEY)
  ? (window as any)[FARO_GLOBAL_OBJECT_KEY]
  : initializeFaro({
      url,
      app: {
        name,
        version: packageJson.version,
        environment,
      },
      isolate: true,
      globalObjectKey: FARO_GLOBAL_OBJECT_KEY,
      beforeSend: (event) => {
        const isOnPluginPage = (event.meta.page?.url ?? '').includes(PLUGIN_BASE_URL);
        if (!isOnPluginPage) {
          return null;
        }

        return event;
      },
      user: {
        id: String(user.id),
        username: user.name,
        attributes: {
          orgId: String(user.orgId),
        },
      },
    });

const SERVICE_DASHBOARD_EXTENSION_V1 = `${pluginJson.id}/service-dashboard-extension/v1`;

const plugin = new AppPlugin<{}>().setRootPage(App).addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  body: AppConfig,
  id: 'configuration',
});

if (plugin.exposeComponent) {
  plugin.exposeComponent({
    id: SERVICE_DASHBOARD_EXTENSION_V1,
    title: 'Service Overview dashboard',
    description: 'Service Overview dashboard extension',
    component: memo(ServiceDashboardExtension),
  });
}

export { plugin };
