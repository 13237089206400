import { SceneAppPage, SceneAppPageLike } from '@grafana/scenes';
import { RDS_URL } from 'scenes/AWS/routes';
import { getOverviewScene } from './getOverviewScene';
import { getOverviewDrilldowns } from './getOverviewDrilldowns';
import { VAR_DATASOURCE } from 'scenes/variables';
import React from 'react';
import { ScenesCustomParams } from 'scenes/types';
import { ServiceAlert } from 'scenes/common/ServiceAlert';
import { NECESSARY_RDS_METRICS } from './queries';

const PRESERVE_URL_KEYS = ['from', 'to', `var-${VAR_DATASOURCE}`];

export const getRDSScenePage = (
  { prometheusName, from, to }: ScenesCustomParams,
  parent: SceneAppPageLike
): SceneAppPage => {
  return new SceneAppPage({
    title: 'AWS/RDS',
    titleImg: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws/AWS-RDS.svg',
    hideFromBreadcrumbs: false,
    preserveUrlKeys: PRESERVE_URL_KEYS,
    subTitle: <ServiceAlert necessaryMetrics={NECESSARY_RDS_METRICS} service="RDS" />,
    url: RDS_URL,
    getScene: () => getOverviewScene(prometheusName, from, to),
    drilldowns: getOverviewDrilldowns(prometheusName, from, to),
    getParentPage: () => parent,
  });
};
