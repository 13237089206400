import { css } from '@emotion/css';
import { isEqual } from 'lodash';
import React, { FC, useContext, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';

import { JobStatusWidget } from '../../JobManager/JobStatusWidget';
import { AccountFormOutput, FormOutput, ServicesSummaryChanges } from '../../types';
import {
  CloudWatchJob,
  CustomNamespaceApiResponse,
  ServiceConfigurationApi,
  ServiceInfoApiResponse,
  Services,
} from 'api/hosted-exporters-api/data-models';
import {
  useCheckCloudWatchConnectionStatus,
  useCreateOrUpdateScrapeJobState,
  useGetScrapeJobs,
} from 'api/hosted-exporters-api/queries';
import {
  areServicesChanged,
  getFormErrorMessage,
  getFormServiceFromId,
  getServicesSummaryChanges,
  sortServices,
} from '../CloudWatchUtils';
import { transformServicesForApi } from '../../utils';
import { RudderStackEvents } from 'enums';
import { ConfigureServicesEmpty } from './ConfigureServices/ConfigureServicesEmpty';
import { ConfigureServicesTable } from '../services/ConfigureServicesTable/ConfigureServicesTable';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

type JobFormProps = {
  account: AccountFormOutput;
  defaultServices: ServiceInfoApiResponse[];
  defaultCustomNamespace: CustomNamespaceApiResponse;
  selectedServices?: string[];
  customNamespaces?: string[];
  supportedServices: Services;
  onCreateJob: (job: CloudWatchJob) => void;
  onServicesChanged: (services: string[]) => void;
  onNamespacesChanged: (services: string[]) => void;
};

const getStyles = (theme: GrafanaTheme2) => ({
  jobForm: css`
    position: relative;

    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.components.input.borderColor};
    padding: 16px;
    margin-bottom: 16px;
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  dropdown: css`
    font-size: ${theme.typography.body.fontSize};
  `,
  control: css`
    display: block;
  `,
  actions: css`
    display: flex;
    flex-direction: column;

    button {
      width: fit-content;
    }
  `,
});

export const CreateJobForm: FC<JobFormProps> = ({
  account,
  defaultServices,
  defaultCustomNamespace,
  selectedServices = [],
  customNamespaces = [],
  supportedServices,
  onServicesChanged,
  onNamespacesChanged,
  onCreateJob,
}) => {
  const styles = useStyles2(getStyles);
  const sourceId = 'cloudwatch';
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const connectionStatus = useCheckCloudWatchConnectionStatus();
  const { status: createJobStatus } = useCreateOrUpdateScrapeJobState();

  const { trackRudderStackEvent } = useRudderStack();
  const [servicesForm, setServicesForm] = useState<FormOutput[]>([]);
  const [customNamespacesForm, setCustomNamespacesForm] = useState<FormOutput[]>([]);
  const [hasServicesChanged, setServicesChanged] = useState<boolean>(false);
  const { data: jobs } = useGetScrapeJobs(sourceId, pluginId, jsonData.grafana_instance_id);

  const isCustomNamespacesFormIncomplete = customNamespacesForm.some((el) => Object.keys(el.metrics).length === 0);

  const servicesWithNamespaces = servicesForm.concat(customNamespacesForm);

  useEffect(() => {
    const newServicesForm = selectedServices.map((serviceId) => {
      const serviceAlreadyExist = servicesForm.find((serviceForm) => serviceForm.service_id === serviceId);
      if (!!serviceAlreadyExist) {
        return serviceAlreadyExist;
      } else {
        return getFormServiceFromId(serviceId, defaultServices);
      }
    });
    if (!isEqual(newServicesForm, servicesForm)) {
      setServicesForm(newServicesForm);
      setServicesChanged(true);
    }
  }, [selectedServices, defaultServices, servicesForm]);

  useEffect(() => {
    const newCustomNamespaces = customNamespaces.map((namespaceId) => {
      const namespaceAlreadyExists = customNamespacesForm.find((namespace) => namespace.display_name === namespaceId);
      if (!!namespaceAlreadyExists) {
        return namespaceAlreadyExists;
      } else {
        return getFormServiceFromId(namespaceId, defaultServices, false, defaultCustomNamespace);
      }
    });
    if (!isEqual(newCustomNamespaces, customNamespacesForm)) {
      setCustomNamespacesForm(newCustomNamespaces);
    }
  }, [customNamespacesForm, customNamespaces, defaultServices, defaultCustomNamespace]);

  function onServicesConfigurationChanged(services: FormOutput[]) {
    const updatedSelectedServices = sortServices(services);
    const updatedFilteredSelectedServices = updatedSelectedServices.filter((el) => !el.isCustomNamespace);
    const updatedSelectedCustomNamespaces = updatedSelectedServices.filter((el) => el.isCustomNamespace);

    setServicesForm(updatedFilteredSelectedServices);
    setCustomNamespacesForm(updatedSelectedCustomNamespaces);

    const job = (jobs ?? []).find((job) => job.name === account.name) as CloudWatchJob;
    setServicesChanged(areServicesChanged(job, updatedSelectedServices));

    onServicesChanged(updatedFilteredSelectedServices.map((s) => s.service_id));
    onNamespacesChanged(updatedSelectedCustomNamespaces.map((s) => s.service_id));
  }

  const handleCreate = () => {
    const transformedServices: ServiceConfigurationApi[] = transformServicesForApi(servicesWithNamespaces);
    const newJob = {
      name: account.name,
      role_arn: account.arn,
      aws_account_id: '',
      service_configurations: transformedServices,
      export_tags: account.export_tags,
      regions: account.regions,
      enabled: true,
    };

    onCreateJob(newJob);

    const services_changes: ServicesSummaryChanges = getServicesSummaryChanges(undefined, transformedServices);

    trackRudderStackEvent(RudderStackEvents.CreateScrapeJobInitiated, {
      integration_slug: 'cloudwatch',
      services_changes,
    });
    setServicesChanged(false);
  };

  const createButton = (
    <Button
      data-testid={Pages.CloudWatch.CreateJob.createScrapeJobButton}
      aria-label="Create scrape job"
      tooltip={getFormErrorMessage(account, hasServicesChanged, servicesWithNamespaces.length)}
      disabled={
        connectionStatus !== 'success' ||
        createJobStatus === 'pending' ||
        (servicesForm.length === 0 && customNamespacesForm.length === 0) ||
        isCustomNamespacesFormIncomplete ||
        (!account?.isDirty && !hasServicesChanged) ||
        !account?.isValid ||
        createJobStatus === 'success'
      }
      onClick={handleCreate}
    >
      {createJobStatus === 'pending' && <Spinner className={styles.spinner} />}
      Create scrape job
    </Button>
  );

  return (
    <div className={styles.jobForm}>
      {selectedServices?.length === 0 && customNamespaces?.length === 0 && <ConfigureServicesEmpty />}
      {(selectedServices?.length > 0 || customNamespaces?.length > 0) && (
        <>
          <ConfigureServicesTable
            mode={'create'}
            services={servicesWithNamespaces}
            defaultServices={defaultServices}
            defaultCustomNamespace={defaultCustomNamespace}
            supportedServices={supportedServices}
            onServicesChanged={onServicesConfigurationChanged}
          />
          <div className={styles.actions}>
            {createButton}
            <JobStatusWidget />
          </div>
        </>
      )}
    </div>
  );
};
