import { LogsMethod } from 'scenes/types';

export const getVariableParam = (varName: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(varName !== 'from' && varName !== 'to' ? `var-${varName}` : varName);
};

export const PASS_PHRASE = 'salt';

export const crypt = (salt: any, text: any) => {
  const textToChars = (text: any) => text.split('').map((c: any) => c.charCodeAt(0));
  const byteHex = (n: any) => ('0' + Number(n).toString(16)).slice(-2);
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a: any, b: any) => a ^ b, code);

  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
};

export const decrypt = (salt: any, encoded: any) => {
  const textToChars = (text: any) => text.split('').map((c: any) => c.charCodeAt(0));
  const applySaltToChar = (code: any) => textToChars(salt).reduce((a: any, b: any) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex: any) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode: any) => String.fromCharCode(charCode))
    .join('');
};

export const getAccountLabelValuesByMethod = (method: LogsMethod) => {
  if (method) {
    switch (method) {
      case LogsMethod.FIREHOSE:
        return 'account_id';

      case LogsMethod.LAMBDA:
        return '__aws_cloudwatch_owner';

      case LogsMethod.ALB:
        return '__aws_s3_lb_owner';

      default:
        return '';
    }
  }
  return '';
};
