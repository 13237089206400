import { lastValueFrom } from 'rxjs';

import { BackendSrvRequest, config, getBackendSrv } from '@grafana/runtime';
import { SuccessfulFetchResponse } from 'api/common/data-model';
import { DataSourceInstanceSettings } from '@grafana/data';

export function requestIntegrations<D = any>(
  pluginId: string,
  path: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    method: 'GET',
    url: `api/plugin-proxy/${pluginId}${path}`,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

function fetchApi<D>(
  url: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const options: BackendSrvRequest = {
    headers: {},
    url: url,
    showErrorAlert: false,
    ...optionsOverride,
  };
  return lastValueFrom(getBackendSrv().fetch<D>(options));
}

export function post<D>(url: string, data: any, options: Partial<BackendSrvRequest> = {}): Promise<D> {
  return getBackendSrv().post(url, data, options);
}

export async function apiGet<D>(url: string, optionsOverride: Partial<BackendSrvRequest> = {}): Promise<D> {
  const response = await fetchApi<D>(url, { ...optionsOverride, method: 'GET' });
  return response?.data;
}

export async function del(url: string, options: Partial<BackendSrvRequest> = {}): Promise<void> {
  await lastValueFrom(
    getBackendSrv().fetch({
      method: 'DELETE',
      url,
      ...options,
    })
  );
}

export class NoDataSourceFoundError extends Error {}

function getAllDataSources(): DataSourceInstanceSettings[] {
  return Object.values(config.datasources);
}

export function requestDatasource<D = any>(
  dataSourceName: string,
  path: string,
  optionsOverride: Partial<BackendSrvRequest> = {}
): Promise<SuccessfulFetchResponse<D>> {
  const datasource: DataSourceInstanceSettings | undefined = getAllDataSources().find(
    (el) => el?.name === dataSourceName || el?.uid === dataSourceName
  );
  if (!datasource) {
    throw new NoDataSourceFoundError(`No datasource called ${dataSourceName} found.`);
  }

  const options: BackendSrvRequest = {
    headers: {},
    method: 'GET',
    url: datasource.url + path,
    ...optionsOverride,
  };

  if (datasource.basicAuth || datasource.withCredentials) {
    options.credentials = 'same-origin';
  }

  if (datasource.basicAuth && options.headers) {
    options.headers.Authorization = datasource.basicAuth;
  }

  return lastValueFrom(getBackendSrv().fetch<D>(options));
}
