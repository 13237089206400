import { config } from '@grafana/runtime';

enum FARO_ENV {
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

function getFaroEnv() {
  const appUrl = new URL(config.appUrl).hostname;
  switch (true) {
    case appUrl.endsWith('grafana-ops.net'):
      return FARO_ENV.STAGING;
    case appUrl.endsWith('grafana.net'):
      return FARO_ENV.PROD;
    case appUrl.endsWith('grafana-dev.net'):
    case appUrl.endsWith('localhost'):
    default:
      return FARO_ENV.DEV;
  }
}

// The three apps were created at https://appo11y.grafana.net/a/grafana-kowalski-app/apps
// and the collector URLs were generated there.
export function getFaroConfig() {
  const environment = getFaroEnv();
  switch (environment) {
    case FARO_ENV.DEV:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/195fe2cb5e48d0644017fbba405f9e6b',
        name: 'grafana-csp-app-dev',
        environment,
      };
    case FARO_ENV.STAGING:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/112d9fa8ade8e8146811742e3b280682',
        name: 'grafana-csp-app-ops',
        environment,
      };
    case FARO_ENV.PROD:
    default:
      return {
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/e514893c233b0a83adf4474413fa8311',
        name: 'grafana-csp-app-prod',
        environment,
      };
  }
}
