import { css, cx } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2, GrafanaThemeType } from '@grafana/data';
import { Icon, useStyles2, useTheme2 } from '@grafana/ui';
import { LabelTypes } from 'enums';
import { labelColors } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2, localColors: { borderColor: string; textColor: string }) => ({
  labelWrapper: css`
    padding: 4px 6px;
    border: 1px solid ${localColors.borderColor};
    box-sizing: border-box;
    border-radius: 2px;
    color: ${localColors.textColor};
    font-size: ${theme.typography.bodySmall.fontSize};
    display: flex;
    align-items: center;
  `,
  icon: css`
    width: 13px;
    margin-right: 5px;
    display: flex;

    path {
      fill: ${localColors.textColor};
      fill-opacity: 1;
    }

    img {
      max-width: 100%;
    }
  `,
});

const getLabelType = (themeType: GrafanaThemeType) => ({
  [LabelTypes.Installed]: {
    icon: <Icon name="check" color="inherit" />,
    label: 'Installed',
    style: {
      borderColor: `${labelColors[themeType].labelBorderColor02}`,
      textColor: `${labelColors[themeType].labelTextColor02}`,
    },
  },
  [LabelTypes.Enabled]: {
    icon: <Icon name="check" color="inherit" />,
    label: 'Enabled',
    style: {
      borderColor: `${labelColors[themeType].labelBorderColor02}`,
      textColor: `${labelColors[themeType].labelTextColor02}`,
    },
  },
  [LabelTypes.Disabled]: {
    icon: <Icon name="times-circle" color="inherit" />,
    label: 'Disabled',
    style: {
      borderColor: `${labelColors[themeType].labelBorderColor04}`,
      textColor: `${labelColors[themeType].labelTextColor04}`,
    },
  },
});

type LabelType = `${LabelTypes}`;

export const Label = ({ type = LabelTypes.Installed, customStyle }: { type: LabelType; customStyle?: string }) => {
  const theme = useTheme2();
  const selectedType = getLabelType(theme.colors.mode === 'dark' ? GrafanaThemeType.Dark : GrafanaThemeType.Light)[type];
  const styles = useStyles2((theme) => getStyles(theme, selectedType.style));

  return (
    <span className={cx(styles.labelWrapper, customStyle)}>
      {selectedType.icon && <span className={styles.icon}>{selectedType.icon}</span>}
      <span>{selectedType.label}</span>
    </span>
  );
};
