import { Button, useStyles2 } from '@grafana/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getStyles } from './Homepage.styles';
import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';
import { AzureIcon } from 'img';
import { isUserAdmin } from 'utils/user';
import { Pages } from 'e2eSelectors/pages';
import FeatureList from 'feature/common/components/FeatureList/FeatureList';
import { getDashboardsTabs } from 'feature/common/utils/utils';
import { AZURE_DASHBOARDS_TABS } from 'feature/common/consts';

export const Homepage = () => {
  const styles = useStyles2(getStyles);
  const { push } = useHistory();

  function configureAlloy() {
    push(prefixRoute(CloudProvider.AZURE, `configuration`));
  }

  return (
    <div className={styles.container}>
      <div className={styles.cardCentered}>
        <div className={styles.logo}>
          <img src={AzureIcon} alt="Azure icon" className={styles.img} />
        </div>
        <h1>Instant observability for your core Azure services.</h1>
        <p>Powered by Grafana Cloud and Alloy.</p>
        {isUserAdmin() && (
          <div>
            <Button
              onClick={configureAlloy}
              data-testid={Pages.Azure.OverviewButtonToConfiguration}
              className={styles.configureButton}
            >
              Configure Alloy to send your Azure data
            </Button>
          </div>
        )}
      </div>

      <hr />
      <h3 className={styles.title}>Out-of-the-box dashboards</h3>
      <FeatureList tabDirection="horizontal" tabs={getDashboardsTabs(AZURE_DASHBOARDS_TABS)} />
    </div>
  );
};
