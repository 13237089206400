import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';
import { ROUTES } from 'utils/consts';

export const GCP_URL = prefixRoute(CloudProvider.GCP);
export const GCP_DASHBOARDS_URL = prefixRoute(CloudProvider.GCP, ROUTES.Dashboards);
export const GCP_SERVICES_URL = prefixRoute(CloudProvider.GCP, ROUTES.SERVICES);
export const GCP_OVERVIEW_URL = prefixRoute(CloudProvider.GCP, `${ROUTES.OVERVIEW}`);
export const GCP_LOGS_URL = prefixRoute(CloudProvider.GCP, `${ROUTES.Logs}`);
export const GCP_CONFIGURATION_URL = prefixRoute(CloudProvider.GCP, `${ROUTES.Configuration}`);
