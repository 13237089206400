import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Tooltip, useStyles2 } from '@grafana/ui';

import { EllipsisText } from 'components/EllipsisText';

const getStyles = (theme: GrafanaTheme2) => ({
  moreButton: css`
    color: ${theme.colors.text.secondary};
    font-weight: ${theme.typography.fontWeightRegular};
    padding: 0;
    margin-left: ${theme.spacing(0.5)};
    cursor: default;
    vertical-align: baseline;

    &:hover {
      background: transparent;
    }
  `,
  contentWithMore: css`
    text-align: left;
    display: inline;
    vertical-align: middle;
    white-space: nowrap;
  `,
});

type ShowMoreListProps = {
  items: string[];
  width?: number;
};

const getTextWidth = (ctx: any, items: string[], result: string[]): number => {
  const totalMore = items.length - result.length;
  const moreText = totalMore > 0 ? ` + ${totalMore} more` : '';
  const newText = `${result.join(', ')}${moreText}`;
  return ctx.measureText(newText).width;
};

const getItemsThatFitWidth = (items: string[] = [], maxWidth: number): string[] => {
  if (items.length > 1) {
    const result = [...items];
    const canvas = document.createElement('canvas');
    if (!!canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.font = 'bold 1rem arial';
        let textWidth = getTextWidth(ctx, items, result);

        while (result.length > 1 && textWidth > maxWidth) {
          result.pop();
          textWidth = getTextWidth(ctx, items, result);
        }
        canvas.parentNode?.removeChild(canvas);
      }
    }
    return result;
  }
  return items;
};

export const ShowMoreList: FC<ShowMoreListProps> = ({ items = [], width }) => {
  const styles = useStyles2(getStyles);
  const contentRef = useRef<HTMLDivElement>(null);
  const [fitItems, setFitItems] = useState(items);
  const [enableEllipsis, setEnableEllipsis] = useState(true);

  useEffect(() => {
    if (items.length > 0) {
      setEnableEllipsis(true);
    }
  }, [items]);

  useEffect(() => {
    if (!!contentRef?.current && items.length > 0 && enableEllipsis) {
      const newItems = getItemsThatFitWidth(items, width ?? contentRef.current.clientWidth);
      setFitItems(newItems);
      setEnableEllipsis(false);
    }
  }, [items, enableEllipsis, width]);

  const handleResize = useCallback(() => setEnableEllipsis(true), []);

  useEffect(() => {
    if (!!contentRef?.current) {
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const totalMore = items.length - fitItems.length;

  const moreLink =
    totalMore > 0 ? (
      <Tooltip content={items.join(', ')}>
        <Button className={styles.moreButton} fill={'text'}>
          +{totalMore} more
        </Button>
      </Tooltip>
    ) : (
      ''
    );

  if (enableEllipsis) {
    return (
      <div ref={contentRef}>
        <EllipsisText text={items.join(', ')} width={width} />
      </div>
    );
  } else {
    return (
      <div ref={contentRef} className={styles.contentWithMore}>
        {fitItems.join(', ')}
        {moreLink}
      </div>
    );
  }
};
