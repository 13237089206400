import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getJobStatusWidgetStyles = (theme: GrafanaTheme2) => ({
  jobForm: css`
    position: relative;

    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.components.input.borderColor};
    padding: 16px;
    margin-bottom: 16px;
  `,
  removeButton: css`
    position: absolute;
    top: 16px;
    right: 16px;
  `,
  createScrapeJobStatus: css`
    margin-top: 16px;
    font-size: ${theme.typography.body.fontSize};
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
});
