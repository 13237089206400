import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { InputWithOptions } from './InputWithOptions/InputWithOptions';
import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    margin-top: ${theme.spacing(2)};
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    border-radius: 2px;
  `,
  width50: css`
    width: 50%;
  `,
});

export const AddCustomNamespaces = ({
  customNamespaces,
  onChange,
  defaultServices,
}: {
  customNamespaces: string[];
  onChange: (serviceIds: string[]) => void;
  defaultServices?: string[];
}) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container}>
      <h4>Add custom namespaces</h4>
      <p>
        If you would like Grafana to scrape metrics from your{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/cloudwatch_concepts.html#Namespace"
        >
          Amazon custom namespaces
        </a>
        , add the namespaces here and configure them in the next step of the form.
      </p>
      <div className={styles.width50}>
        <InputWithOptions
          id={Pages.CloudWatch.customNamespaceNameInput}
          values={customNamespaces}
          onChange={onChange}
          label="Namespace name"
          defaultServices={defaultServices}
        />
      </div>
    </div>
  );
};
