import { PlanType } from 'enums';

export interface HostedDataDetails {
  orgSlug: string;
  orgId: number;
  slug: string;
  id: number;
  trial: number;
  trialExpiresAt: string;
  plan: PlanType;
  hmInstancePromId: number;
  hlInstanceId: number;
  hmInstanceGraphiteId: number;
  htInstanceId: number;
  hpInstanceId: number;
  hlInstanceUrl: string;
  hmInstanceGraphiteUrl: string;
  hmInstancePromUrl: string;
  hmInstanceGraphiteType: string;
  htInstanceUrl: string;
  hpInstanceUrl: string;
  [val: string]: string | number;
  regionSlug: string;
  url: string;
}

export const DEV_HOSTED_DATA_DETAILS: HostedDataDetails = {
  orgSlug: 'raintank',
  orgId: 101,
  slug: 'k3d',
  url: 'http://grafana.k3d.localhost:9999',
  id: 1,
  trial: 102,
  trialExpiresAt: 'tomorrow',
  hmInstancePromId: 103,
  hlInstanceId: 104,
  hmInstanceGraphiteId: 105,
  htInstanceId: 106,
  hpInstanceId: 107,
  hlInstanceUrl: 'hlInstanceUrl',
  hmInstanceGraphiteUrl: 'hmInstanceGraphiteUrl',
  hmInstancePromUrl: 'hmInstancePromUrl',
  htInstanceUrl: 'htInstanceUrl',
  hpInstanceUrl: 'hpInstanceUrl',
  hmInstanceGraphiteType: 'hmInstanceGraphiteType',
  plan: PlanType.Free,
  regionSlug: 'dev-us',
};
