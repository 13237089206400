import React, { useMemo } from 'react';

import { SceneComponentProps, sceneGraph, SceneObjectBase, SceneObjectState } from '@grafana/scenes';
import { LinkButton } from '@grafana/ui';

interface OpenInAwsButtonState extends SceneObjectState {
  href: string;
}

export default class OpenInAwsButton extends SceneObjectBase<OpenInAwsButtonState> {
  static Component = OpenInAwsButtonRenderer;

  constructor(state: OpenInAwsButtonState) {
    super(state);
  }
}

function OpenInAwsButtonRenderer({ model }: SceneComponentProps<OpenInAwsButton>) {
  const { href } = model.useState();

  const { from, to } = sceneGraph.getTimeRange(model).useState();

  const processedHref = useMemo(() => {
    const url = new URL(href, window.location.origin);
    url.searchParams.set('from', from);
    url.searchParams.set('to', to);
    return url.toString();
  }, [href, from, to]);

  return (
    <LinkButton
      icon="external-link-alt"
      data-cy="OpenInAwsButton"
      variant="secondary"
      href={processedHref}
      target="_blank"
    >
      Open in AWS
    </LinkButton>
  );
}
