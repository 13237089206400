import { AppPluginMeta } from '@grafana/data';
import { PluginMeta } from 'models/pluginMeta';

export function transformPluginMeta(meta: AppPluginMeta): PluginMeta {
  return {
    pluginId: meta.id,
    pluginVersion: meta.info.version,
    jsonData: {
      ...meta.jsonData!,
      ...(meta.jsonData?.grafana_instance_id && { grafana_instance_id: String(meta.jsonData.grafana_instance_id) }),
      ...(meta.jsonData?.datasources && { datasources: meta.jsonData?.datasources }),
    },
  };
}
