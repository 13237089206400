import { useIntegrationsByCatalogQuery } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { useContext, useMemo } from 'react';
import { CloudProvider } from 'types/CloudProvider';

export const useGetIntegrationsToUninstall = (provider: CloudProvider) => {
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data } = useIntegrationsByCatalogQuery(pluginId, jsonData.grafana_instance_id, provider);
  const integrationsToUninstall = useMemo(
    () =>
      data
        ?.filter((integration) => integration.installation !== undefined)
        .map((integration) => integration.id as CloudProvider),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return integrationsToUninstall;
};
