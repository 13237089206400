import { css, cx } from '@emotion/css';
import React, { useContext } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Markdown } from 'components/Markdown';
import { colors } from 'utils/consts';
import { useGetIntegration, useGetIntegrationChangelog } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { CloudProvider } from 'types/CloudProvider';

const getStyles = (theme: GrafanaTheme2) => ({
  previousVersion: css`
    margin-top: 30px;
  `,
  currentVersion: css`
    background: ${theme.colors.background.secondary};
  `,
  table: css`
    width: 100%;
    margin-top: 12px;
    margin-left: -10px;
    li > ul {
      padding-left: 15px;
    }
    td {
      padding: 10px;
    }
    td:first-child {
      width: 220px;
      vertical-align: top;
    }
    td:last-child {
      color: ${colors.yellow01};
      width: 150px;
    }
  `,
  hr: css`
    margin-bottom: ${theme.spacing(5)};
  `,
});

export const VersionHistory = ({ provider }: { provider: CloudProvider }) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data: source } = useGetIntegration(provider, pluginId, jsonData.grafana_instance_id);
  const { data: versionHistory } = useGetIntegrationChangelog(provider, pluginId, jsonData.grafana_instance_id);

  const installedVersion = source?.installation?.version;

  return (
    <>
      {versionHistory && (
        <>
          <hr className={styles.hr} />
          <h3>Version history</h3>
          <h4>Latest version</h4>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>
                  {versionHistory[0].Version} - {versionHistory[0].Date}
                </td>
                <td>
                  <Markdown markdownSnippet={versionHistory[0].Text} />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>

          {versionHistory.length > 1 && (
            <>
              <h4 className={styles.previousVersion}>Previous versions</h4>
              <table className={styles.table}>
                <tbody>
                  {versionHistory.map(
                    (el, i) =>
                      i !== 0 && (
                        <tr key={i} className={cx({ [styles.currentVersion]: el.Version === installedVersion })}>
                          <td>
                            {el.Version} - {el.Date}
                          </td>
                          <td>
                            <Markdown markdownSnippet={el.Text} />
                          </td>
                          <td>{el.Version === installedVersion ? 'Currently running' : ''}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </>
  );
};
