import { css } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Button, Alert, AlertVariant } from '@grafana/ui';

import { ScrapeJobActions } from './ScrapeJobActions';
import { ScrapeJob } from 'api/hosted-exporters-api/data-models';
import { SaasIntegrationWithJobType, ScrapeJobActionType } from '../types';
import { Pages } from 'e2eSelectors/pages';

type JobTableCallbacks = {
  handleAddScrapeJob: () => void;
  handleJobDelete: (job: ScrapeJob) => void;
  handleJobEdit: (job: ScrapeJob) => void;
};

export type JobTableProps = JobTableCallbacks & { jobs: ScrapeJob[] };

const getStyles = (theme: GrafanaTheme2) => ({
  scrapeJobsTop: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};
  `,
  step: css`
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
  rowButtons: css`
    display: flex;
    align-items: flex-end;
    gap: ${theme.spacing(1)};
  `,
});

type JobTableViewProps = JobTableCallbacks & {
  jobs: ScrapeJob[];
  handleAddScrapeJob: () => void;
  JobTable: FC<JobTableProps>;
  saasIntegrationId: SaasIntegrationWithJobType;
};

const getJobUpdateMessage = (action: ScrapeJobActionType, selectedJobs: number) => {
  const label = selectedJobs === 1 ? 'job' : 'jobs';
  const updateMessage = {
    [ScrapeJobActionType.Enable]: `${selectedJobs} ${label} successfully enabled`,
    [ScrapeJobActionType.Disable]: `${selectedJobs} ${label} successfully disabled`,
    [ScrapeJobActionType.Delete]: `${selectedJobs} ${label} successfully deleted`,
  }[action];

  return updateMessage;
};

export const JobTableView = ({
  handleJobDelete,
  handleJobEdit,
  handleAddScrapeJob,
  jobs,
  JobTable,
  saasIntegrationId,
}: JobTableViewProps) => {
  const styles = useStyles2(getStyles);
  const [jobUpdateMessage, setJobUpdateMessage] = useState<{
    message: string;
    severity: AlertVariant;
    reset: Function;
  } | null>(null);

  function onActionSuccess(action: ScrapeJobActionType, totalJobs: number, reset: Function) {
    setJobUpdateMessage({
      message: getJobUpdateMessage(action, totalJobs),
      severity: 'success',
      reset,
    });
  }

  function hideAlert() {
    if (!!jobUpdateMessage) {
      setJobUpdateMessage(null);
    }
  }

  // Clear the message from the screen
  useEffect(() => {
    if (jobUpdateMessage) {
      jobUpdateMessage.reset();

      // Remove message
      const timer = setTimeout(() => {
        hideAlert();
        clearTimeout(timer);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobUpdateMessage]);

  return (
    <>
      <div className={styles.scrapeJobsTop}>
        <h2>Your scrape jobs</h2>
        {jobs.length > 0 && (
          <div className={styles.rowButtons}>
            <Button
              variant="primary"
              onClick={handleAddScrapeJob}
              data-testid={Pages.SaaSIntegration.addScrapeJobButton}
            >
              Add new scrape job
            </Button>
          </div>
        )}
      </div>

      {!!jobUpdateMessage && (
        <Alert title={jobUpdateMessage.message} severity={jobUpdateMessage.severity} onRemove={hideAlert} />
      )}

      <ScrapeJobActions saasIntegrationId={saasIntegrationId} onSuccess={onActionSuccess} />

      <JobTable
        key={`${saasIntegrationId}-jobs`}
        jobs={jobs}
        handleAddScrapeJob={handleAddScrapeJob}
        handleJobDelete={handleJobDelete}
        handleJobEdit={handleJobEdit}
      />
    </>
  );
};
