import { useQuery } from '@tanstack/react-query';
import { DEV_HOSTED_DATA_DETAILS, HostedDataDetails } from './data-models';
import { requestIntegrations } from 'api/baseApi';
import { HOSTED_DATA_DETAILS_FALLBACK } from 'utils/consts';
import { isUserAdmin, isUserEditor } from 'utils/user';

export const useGetHostedDataDetails = (pluginId: string) =>
  useQuery({
    queryKey: ['hostedDataDetails'],
    queryFn: async () => {
      if (process.env.NODE_ENV === 'development' || (window as any).e2eRunning) {
        return DEV_HOSTED_DATA_DETAILS;
      }
      const response = await requestIntegrations(pluginId, `/grafanacom-api/instances`, {
        showErrorAlert: false,
        showSuccessAlert: false,
      });
      return response.data as HostedDataDetails;
    },
    enabled: isUserAdmin() || isUserEditor(),
  });

export const useHostedDataDetailsWithFallback = (pluginId: string) => {
  /*
    Using `useQueryState()` instead of `useGetHostedDataDetailsQuery()` results in
    not subscribing and not trying to fetch the data from the API.
    Less subscribers means less performance overhead, see
    https://github.com/reduxjs/redux-toolkit/discussions/2357
    We can do this, because we're sure we actually fetch the data once during
    the App's lifetime, and this data doesn't change.
  */
  const { data } = useGetHostedDataDetails(pluginId);

  return data ?? HOSTED_DATA_DETAILS_FALLBACK;
};
