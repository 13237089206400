import useRudderStack from 'hooks/useRudderstack';
import React, { useEffect } from 'react';
import { RudderStackEvents } from 'enums';
import { GetStarted } from './GetStarted';

export const Homepage = () => {
  const { trackRudderStackEvent } = useRudderStack();

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.AwsConfigurationViewPage, { page: 'homepage' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GetStarted />;
};
