import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing(2)};
    width: 100%;
  `,
  alert: css`
    position: fixed;
    top: 50px;
    right: 50px;
    width: 400px;
  `,
  modalWrapper: css`
    max-width: 600px;
  `,
  buttonWrapper: css`
    margin-top: ${theme.spacing(4)};
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  cancelButton: css`
    margin-right: ${theme.spacing(1)};
  `,
  selectedJobs: css`
    display: flex;
    align-items: center;

    span[class$='-Label'] {
      font-size: ${theme.typography.body.fontSize};
      font-weight: ${theme.typography.fontWeightRegular};
    }

    button {
      margin-right: ${theme.spacing(1)};
    }
  `,
  selectedJobsMessage: css`
    margin-left: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightRegular};
  `,
});
