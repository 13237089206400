// include local sources here to show them in the catalog on the AWS page
import { Source, SourceType } from 'api/int-api/data-models';

const AWS_FIREHOSE_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSc7jsX5xz_UzKvDyhUdg50qDYpVOk-7lELtyDAZK--Fg6GOfg/viewform?usp=sf_link';

const awsLogsFirehoseDescription = `Deliver large volumes of AWS logs or events to Grafana Cloud with the [Kinesis Data Firehose](https://aws.amazon.com/kinesis/data-firehose/) delivery stream instead of AWS Lambda.


[Participate in the beta program](${AWS_FIREHOSE_FORM_LINK}).`;

const cloudwatchLogsFirehose: Source = {
  id: 'cloudwatch-logs-firehose',
  slug: 'cloudwatch-logs-firehose',
  name: 'Logs with Firehose',
  logo_url: 'https://storage.googleapis.com/grafanalabs-integration-logos/aws-firehose.svg',
  overview: awsLogsFirehoseDescription,
  type: SourceType.Alpha,

  // External Google Forms form to collect email addresses of interested Grafana Cloud users
  //
  // Responses being recorded here:
  // https://docs.google.com/spreadsheets/d/1ltnqcBnM-Ea6LnW9nAOoEdjpmKxKogknkU2Af4VDLTU/edit?resourcekey#gid=1484845004
  //
  externalUrl: AWS_FIREHOSE_FORM_LINK,
};

const awsLocalSources: Source[] = [cloudwatchLogsFirehose];

// aims to include all local sources that we have built instructions for locally
export const sourcesWithLocalInstructions = awsLocalSources;
