import { EmbeddedScene, SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { AWS_OVERVIEW_URL } from 'scenes/AWS/routes';
import { Homepage as AwsHomePage } from 'feature/AWS/pages/Homepage/Homepage';

const getAwsOverviewScene = () => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: AwsHomePage,
    }),
  });
};

export const getOverviewSceneTab = () =>
  new SceneAppPage({
    title: 'Overview',
    url: AWS_OVERVIEW_URL,
    getScene: () => getAwsOverviewScene(),
    preserveUrlKeys: [],
  });
