import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { EllipsisText } from 'components/EllipsisText';
import { Pages } from 'e2eSelectors/pages';
import { RudderStackEvents } from 'enums';
import { getScrapeJobLink } from 'feature/AWS/utils/utils';
import { HomepageTableRow } from 'feature/common/types/types';
import useRudderStack from 'hooks/useRudderstack';
import React from 'react';
const getStyles = (theme: GrafanaTheme2) => ({
  anchor: css`
    color: ${theme.colors.primary.text};
    text-decoration: underline;
  `,
});

export const ScrapeJobLink = ({ service }: { service: HomepageTableRow }) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  return (
    <a
      href={getScrapeJobLink(service.source)}
      className={styles.anchor}
      data-testid={Pages.ServiceOverview.scrapeJobAnchor(service?.source)}
      onClick={() => trackRudderStackEvent(RudderStackEvents.EditScrapeJob, { page: 'homepage', job: service.source })}
    >
      <EllipsisText text={service?.source} />
    </a>
  );
};
