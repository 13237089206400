import React, { useContext, useState } from 'react';
import { CloudProvider } from 'types/CloudProvider';
import { useCspIntegration, useGetIntegration } from 'api/int-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { Button, LinkButton, useStyles2 } from '@grafana/ui';
import useRudderStack from 'hooks/useRudderstack';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useGetIntegrationsToUninstall } from 'feature/common/hooks/useGetIntegrationsToUninstall';
import { getCloudProviderName } from 'feature/common/utils/utils';
import { Spinner } from '@grafana-cloud/ui';
import { QueryError } from 'components/QueryError/QueryError';
import { Install } from 'feature/common/components/Install';
import { UninstallModal } from 'feature/common/components/UninstallModal/UninstallModal';
import { Update } from 'feature/common/components/Update';
import { RudderStackEvents } from 'enums';
import { Pages } from 'e2eSelectors/pages';
import { getServicesUrlByProvider } from 'scenes/misc';

const getStyles = (theme: GrafanaTheme2) => ({
  hr: css`
    margin-bottom: ${theme.spacing(5)};
  `,
  buttons: css`
    display: flex;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
});

export const InstallBackend = ({
  cloudProvider,
  showServices,
}: {
  cloudProvider: CloudProvider;
  showServices: boolean;
}) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { trackRudderStackEvent } = useRudderStack();
  const {
    data,
    isLoading: isIntegrationLoading,
    isError: isIntegrationError,
  } = useGetIntegration(cloudProvider, pluginId, jsonData.grafana_instance_id);
  const {
    data: currentIntegration,
    isLoading: isCspLoading,
    isError: isCspError,
  } = useCspIntegration(cloudProvider, pluginId, jsonData.grafana_instance_id);
  const [showUninstallModal, setShowUninstallModal] = useState(false);
  const [displayUpdateModal, setDisplayUpdateModal] = useState(false);
  const integrationsToUninstall = useGetIntegrationsToUninstall(cloudProvider);
  const cloudProviderName = getCloudProviderName(cloudProvider);

  const closeUninstallModal = () => {
    setDisplayUpdateModal(false);
  };

  if (isIntegrationLoading || isCspLoading) {
    return <Spinner />;
  }
  if (isIntegrationError || isCspError) {
    return <QueryError message={`Error while loading the ${cloudProviderName} details`} isFullPageError={false} />;
  }
  return (
    <>
      <hr className={styles.hr} />
      <h3>Dashboards Installation</h3>
      <h4>Install pre-made dashboards to this Grafana instance.</h4>
      {integrationsToUninstall && integrationsToUninstall?.length > 0 && data?.installation === undefined && (
        <p>
          Installing the dashboards for {cloudProviderName} will also remove the {integrationsToUninstall?.join(', ')}{' '}
          deprecated dashboards.
        </p>
      )}
      <Install integrationId={cloudProvider} />

      <div className={styles.buttons}>
        {data?.installation && data.has_update && (
          <Button onClick={() => setDisplayUpdateModal(true)} variant="secondary" fill="outline">
            Update
          </Button>
        )}
        {data?.installation && (
          <Button
            onClick={() => {
              setShowUninstallModal(true);
              trackRudderStackEvent(RudderStackEvents.UninstallButton, { integration_slug: currentIntegration?.id });
            }}
            data-testid={Pages.Source.uninstallIntegrationButton}
            variant="secondary"
            fill="outline"
          >
            Uninstall
          </Button>
        )}
        {showServices && data?.installation && (
          <LinkButton
            fill={'outline'}
            href={getServicesUrlByProvider(cloudProvider)}
            onClick={() => {
              trackRudderStackEvent(RudderStackEvents.ViewYourDashboardsButton, { provider: cloudProvider });
            }}
          >
            View your dashboards for your configured services
          </LinkButton>
        )}

        {currentIntegration !== undefined && (
          <>
            <UninstallModal
              isOpen={showUninstallModal}
              selectedIntegration={currentIntegration}
              onClose={() => setShowUninstallModal(false)}
            />
            <Update
              selectedIntegration={currentIntegration}
              displayModal={displayUpdateModal}
              closeUninstallModal={closeUninstallModal}
            />
          </>
        )}
      </div>
    </>
  );
};
