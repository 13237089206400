import { SceneAppDrilldownView, SceneAppPage } from '@grafana/scenes';
import { EC2_URL } from 'scenes/AWS/routes';
import { getOverviewDrilldownSubtitle } from './getOverviewDrilldownSubtitle';
import { getOverviewDrilldownScene } from 'scenes/AWS/Dashboards/EC2/getOverviewDrilldownScene';

export const getOverviewDrilldown = (prometheusName: string, from?: string, to?: string) => {
  const drilldowns: SceneAppDrilldownView[] = [
    {
      routePath: EC2_URL + '/overview/:dimension_InstanceId',
      getPage: (routeMatch, parent) => {
        const dimension_InstanceId: string = routeMatch.params.dimension_InstanceId;
        const urlParams = new URLSearchParams(window.location.search);
        const region = urlParams.get('var-region');

        return new SceneAppPage({
          title: dimension_InstanceId,
          subTitle: getOverviewDrilldownSubtitle(dimension_InstanceId, region),
          url: EC2_URL + `/overview/${dimension_InstanceId}`,
          getParentPage: () => parent,
          getScene: () => getOverviewDrilldownScene(dimension_InstanceId, prometheusName, from, to),
        });
      },
    },
  ];

  return drilldowns;
};
