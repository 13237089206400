import React from 'react';
import { css } from '@emotion/css';
import { Icon, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { Pages } from 'e2eSelectors/pages';
import { CustomMarkdown } from 'feature/common/components/Card/CardSource';
import { GetStartedCardInfo } from './GetStartedCardInfo';
import { RudderStackEvents } from 'enums';
import useRudderStack from 'hooks/useRudderstack';
import { isUserAdmin } from 'utils/user';

type GetStartedCardProps = {
  card: GetStartedCardInfo;
};

export const GetStartedCard = ({ card }: GetStartedCardProps) => {
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();

  return (
    <div key={card.name} className={styles.integrationCard} data-testid={Pages.GetStarted.getCard(card.id)}>
      <div className={styles.header}>
        {card.logo_url && <img src={card.logo_url} className={styles.icon} alt={`icon of ${card.name}`} />}
        {card.name}
      </div>
      <CustomMarkdown
        className={styles.description}
        renderers={{
          // eslint-disable-next-line
          link: ({ node, ...props }) => <a {...props} onClick={(e) => e.stopPropagation()} />,
          img: () => null,
        }}
        markdownSnippet={card.description ?? ''}
      />
      {isUserAdmin() && (
        <div className={styles.footer}>
          <a
            className={styles.anchor}
            href={card.exploreLink}
            target={card.externalUrl ? '_blank' : undefined}
            rel={card.externalUrl ? 'noreferrer' : undefined}
            data-testid={Pages.GetStarted.getExploreLink(card.id)}
            onClick={() =>
              trackRudderStackEvent(RudderStackEvents.GoToExplore, { page: 'homepage', integration_slug: card.id })
            }
          >
            Explore <Icon name="arrow-right" />
          </a>
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  integrationCard: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background.secondary};
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  `,
  header: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  icon: css`
    width: 24px;
    height: auto;
    margin-right: 12px;
  `,
  description: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    margin-bottom: 0;
    line-height: 16px;
    color: ${theme.colors.text.secondary};

    a {
      color: ${theme.colors.text.link};
    }
  `,
  anchor: css`
    color: ${theme.colors.primary.text};
  `,
  footer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
  `,
});
