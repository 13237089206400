import { css } from '@emotion/css';
import React, { FC, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { CopyToClipboard } from 'components/Clipboard/CopyToClipboard';
import { EllipsisText } from 'components/EllipsisText';

const getStyles = (theme: GrafanaTheme2) => ({
  arnContent: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  arnCursor: css`
    cursor: default;
  `,
  clipboard: css`
    color: ${theme.colors.text.primary};
    padding: 0;

    &:hover {
      background-color: transparent;
      opacity: 0.85;
    }
  `,
  checkIcon: css`
    fill: ${theme.colors.success.text};
  `,
});

type ArnCellProps = { arn: string };

export const ArnCell: FC<ArnCellProps> = ({ arn }) => {
  const styles = useStyles2(getStyles);
  const [isCopySuccessful, setIsCopySuccessful] = useState(false);

  return (
    <div className={styles.arnContent}>
      <EllipsisText text={arn} width={200} className={styles.arnCursor} />
      <CopyToClipboard
        className={styles.clipboard}
        onClipboardCopy={() => setIsCopySuccessful(true)}
        onClipboardError={() => setIsCopySuccessful(false)}
        clipboardText={arn}
        fill="text"
      >
        {!isCopySuccessful ? <Icon name="copy" /> : <Icon name="check" className={styles.checkIcon} />}
      </CopyToClipboard>
    </div>
  );
};
