import { css } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ButtonProps, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => ({
  footer: css`
    display: flex;
    flex-direction: column;

    flex-shrink: 0;
    margin-top: ${theme.spacing(2)};
    border-top: 1px solid ${theme.colors.border.medium};
    padding-top: ${theme.spacing(2)};
  `,
  buttonRow: css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(2)};
    align-items: flex-end;
  `,
});

type ModalFooterProps = {
  buttons: ButtonProps[];
};

export const ModalFooter: FC<ModalFooterProps> = ({ buttons }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.footer} data-testid={Pages.SaaSIntegration.modalFooter}>
      <div className={styles.buttonRow}>
        {buttons.map((btnProps) => (
          <Button key={btnProps.name} {...btnProps}>
            {btnProps.name}
          </Button>
        ))}
      </div>
    </div>
  );
};
