import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2, width?: number) => ({
  ellipsis: css`
    width: ${width}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    text-align: left;
  `,
  ellipsisNoWidth: css`
    max-height: 1.5rem;
    overflow: hidden;
    position: relative;

    &::after {
      content: attr(title);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,
});

type EllipsisTextProps = {
  text: string;
  width?: number;
  className?: string;
};

export const EllipsisText: FC<EllipsisTextProps> = ({ text, width, className }) => {
  const styles = useStyles2((theme) => getStyles(theme, width));

  return (
    <div
      title={text}
      className={cx({ [styles.ellipsisNoWidth]: !width }, { [styles.ellipsis]: width !== undefined }, className)}
    >
      {text}
    </div>
  );
};
