import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { ResourceContainer } from './ResourceContainer';
import { ResourceSnippet } from './ResourceSnippet';

const getStyles = (theme: GrafanaTheme2) => ({
  content: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
  `,
  instanceContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(0.5)};
  `,
  metricsColumn: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
  instancesRow: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
  `,
  instanceHeader: css`
    color: ${theme.colors.text.primary}
    font-size: ${theme.typography.body.fontSize};
    font-weight: 700;
    line-height: 18px; /* 150% */
    letter-spacing: 0.018px;
    text-align: left;
    width: 100%;
  `,
  instanceBox: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    border: 1px solid ${theme.colors.border.weak};
    padding: ${theme.spacing(1)};
    height: 100%;
  `,
  snippets: css`
    padding: ${theme.spacing(0.5)};
    border: 1px solid ${theme.colors.border.weak};
    background: ${theme.colors.background.canvas};
    text-align: center;
    border-radius: 3px;
  `,
});

export const ResourceTagsFilterFlow = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.content}>
      <div className={styles.instanceContainer}>
        <div className={styles.instanceHeader}>EC2 Instances</div>
        <div className={styles.instancesRow}>
          <ResourceContainer title={'Instance A'} tagName={'env'} tagValue={'dev'} colorIndex={2} />
          <ResourceContainer title={'Instance B'} tagName={'env'} tagValue={'prod'} colorIndex={6} />
        </div>
      </div>
      <div className={styles.metricsColumn}>
        <ResourceSnippet
          title={'Default CPU Utilization metrics gathered'}
          snippet={
            'aws_ec2_cpu_utilization\n{name=instance_a, env=dev}\naws_ec2_cpu_utilization\n{name=instance_b, env=prod}'
          }
        />
        <ResourceSnippet
          title={'Apply tag filter env=prod'}
          snippet={'aws_ec2_cpu_utilization\n{name=instance_b, env=prod}'}
        />
      </div>
    </div>
  );
};
