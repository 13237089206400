import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Card, useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  cardColumn: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  link: css`
    color: ${theme.colors.text.link};
  `,
  list: css`
    margin-left: ${theme.spacing(3)};
  `,
});

export const GetStartedLearnMoreCard = () => {
  const styles = useStyles2(getStyles);

  return (
    <Card className={styles.cardColumn}>
      <h5>Learn more</h5>
      <ul className={styles.list}>
        <li>
          Connect and pull{' '}
          <a
            className={styles.link}
            rel="noreferrer"
            href={'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/cloudwatch-metrics/'}
            target={'_blank'}
          >
            AWS CloudWatch metrics
          </a>{' '}
          into Grafana Cloud without deploying or installing any local agents or configurations.
        </li>
        <li>
          See which{' '}
          <a
            className={styles.link}
            rel="noreferrer"
            href={'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/cloudwatch-metrics/services/'}
            target={'_blank'}
          >
            services
          </a>{' '}
          are available with CloudWatch metrics as well as the available metrics and statistics.
        </li>
        <li>
          Connect and{' '}
          <a
            className={styles.link}
            rel="noreferrer"
            href={'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/cloudwatch-logs/'}
            target={'_blank'}
          >
            send your logs
          </a>{' '}
          to Grafana Cloud by deploying a Lambda-compatible agent for logs (lambda-promtail) into your AWS
          infrastructure.
        </li>
        <li>
          Learn how to{' '}
          <a
            className={styles.link}
            rel="noreferrer"
            href={'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/monitor-svcs/amazon-ec2/'}
            target={'_blank'}
          >
            monitor your EC2 instances
          </a>{' '}
          without managing and configuring local agents or building dashboards.
        </li>
      </ul>
    </Card>
  );
};
