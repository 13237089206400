import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { Icon, Toggletip, useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  header: css`
    display: flex;
    text-align: left;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: ${theme.typography.bodySmall.fontSize};
  `,
  iconLink: css`
    &:hover {
      cursor: pointer;
      color: ${theme.colors.text.link};
    }
  `,
  toggletipWrapper: css`
    max-width: 600px;
  `,
});

type Props = {
  title: string;
  description: string;
  info: React.ReactNode;
};

export const TagInfo = ({ title, description, info }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <Toggletip
      title={<span className={styles.header}>{title}</span>}
      content={
        <div className={styles.content}>
          <span>{description}</span>
          {info}
        </div>
      }
    >
      <Icon className={styles.iconLink} name={'info-circle'} />
    </Toggletip>
  );
};
