import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: block;
    width: 100%;
  `,
  cardCentered: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 40px;

    h2 {
      width: 60%;
      margin: 20px 0 40px 0;
    }

    button {
      margin-right: 10px;
    }

    p {
      color: ${theme.colors.text.secondary};
    }
  `,
  title: css`
    text-align: center;
    margin-top: ${theme.spacing(7)};
    margin-bottom: ${theme.spacing(5)};
  `,
  configureButton: css`
    margin-top: ${theme.spacing(1)};
  `,
  logo: css`
    width: 70px;
    margin-bottom: ${theme.spacing(3)};
  `,
  img: css`
    width: 100%;
  `,
});
