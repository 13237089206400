import {
  EmbeddedScene,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneTimeRange,
  SceneVariableSet,
  TextBoxVariable,
  VizPanel,
} from '@grafana/scenes';
import { VAR_LOG_TERM_SEARCH, getLokiDatasourceVar } from 'scenes/variables';
import { VariableHide } from '@grafana/schema';
import { LogsQueryBehavior, LogsQueryState, LogsVolumeQueryBehavior } from 'scenes/Logs/behaviours';
import { LogsSearchTextScene } from 'scenes/Logs/LogsSearchTextScene';
import { CloudProvider } from 'types/CloudProvider';
import { getLogsVolumePanel } from 'scenes/Logs/getLogsVolumePanel';
import { getLogsControls } from 'scenes/Logs/getLogsControls';

export const getLogsScene = <T>(lokiUID: string, platform: CloudProvider) => {
  const datasource = { type: 'loki', uid: '$lokiDatasource' };
  const logsState: LogsQueryState<T> = {
    platform,
    params: { term: '' } as T,
  };

  return new EmbeddedScene({
    $data: new SceneQueryRunner({
      datasource,
      queries: [],
      $behaviors: [new LogsQueryBehavior<T>(logsState)],
    }),
    $timeRange: new SceneTimeRange({ from: 'now-1h', to: 'now' }),
    $variables: new SceneVariableSet({
      variables: [
        getLokiDatasourceVar(lokiUID),
        new TextBoxVariable({
          name: VAR_LOG_TERM_SEARCH,
          hide: VariableHide.hideVariable,
        }),
      ],
    }),
    controls: getLogsControls(),
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        new SceneFlexItem({
          key: 'search-text',
          body: new LogsSearchTextScene<T>(logsState),
        }),
        new SceneFlexItem({
          key: 'logs-volume',
          $data: new SceneQueryRunner({
            datasource,
            queries: [],
            $behaviors: [new LogsVolumeQueryBehavior<T>(logsState)],
          }),
          body: getLogsVolumePanel(),
          minHeight: 200,
        }),
        new VizPanel({ key: 'logs', title: 'Logs', pluginId: 'logs' }),
      ],
    }),
  });
};
