import React, { useState, useEffect, useContext } from 'react';

import { Modal, Button, useStyles2, ButtonVariant, Tooltip } from '@grafana/ui';

import { JobStatusFilter } from './JobStatusFilter';
import { getStyles } from './ScrapeJobActions.styles';
import { useQueryClient } from '@tanstack/react-query';
import { SaasIntegrationWithJobType, ScrapeJobActionType } from '../../types';
import { SaasIntegrationContext, SaasIntegrationState } from 'app/contexts/saasIntegration.context';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import {
  useDeleteScrapeJobsMutation,
  useDisableScrapeJobsMutation,
  useEnableScrapeJobsMutation,
} from 'api/hosted-exporters-api/queries';
import { Pages } from 'e2eSelectors/pages';

type ActionButtonProps = {
  id: string;
  open: boolean;
  onConfirm?: () => void;
  closeModal?: () => void;
  onClick?: () => void;
  mainButtonText: string;
  isMainButtonDisabled: boolean;
  confirmText: string;
  description: string;
  variant: ButtonVariant;
  confirmButtonVariant: ButtonVariant;
  confirmButtonDisabled: boolean;
};

export const ActionButton = ({
  id,
  open,
  onClick,
  onConfirm,
  closeModal,
  mainButtonText,
  isMainButtonDisabled,
  confirmText,
  confirmButtonVariant,
  description,
  variant,
  confirmButtonDisabled = false,
}: ActionButtonProps) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <Button
        aria-label="button"
        variant={variant}
        onClick={onClick}
        data-testid={Pages.SaaSIntegration.Actions.getButton(id)}
        disabled={isMainButtonDisabled}
      >
        {mainButtonText}
      </Button>
      <Modal className={styles.modalWrapper} isOpen={open} title={confirmText} onDismiss={closeModal}>
        <div>
          {description}
          <div className={styles.buttonWrapper}>
            <Button aria-label="button" className={styles.cancelButton} onClick={closeModal} variant="secondary">
              Cancel
            </Button>
            <Button
              data-testid={Pages.SaaSIntegration.Actions.getModalButton(id)}
              aria-label="button"
              variant={confirmButtonVariant}
              onClick={onConfirm}
              disabled={confirmButtonDisabled}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

type ScrapeJobActionsProps = {
  saasIntegrationId: SaasIntegrationWithJobType;
  onSuccess: (action: ScrapeJobActionType, totalJobs: number, reset: Function) => void;
};

const getStatusFromAction = (
  action: ScrapeJobActionType,
  enableStatus: string,
  disableStatus: string,
  deleteStatus: string
) => {
  switch (action) {
    case ScrapeJobActionType.Delete:
      return deleteStatus;

    case ScrapeJobActionType.Enable:
      return enableStatus;

    case ScrapeJobActionType.Disable:
      return disableStatus;
  }
  return 'idle';
};

export const ScrapeJobActions = ({ saasIntegrationId, onSuccess }: ScrapeJobActionsProps) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { selectedJobs, resetSelectedJobs } = useContext<SaasIntegrationState>(SaasIntegrationContext);
  const [activeButtonAction, setActiveButtonAction] = useState<ScrapeJobActionType | null>(null);
  const queryClient = useQueryClient();

  const {
    mutate: enableScrapeJobs,
    status: enableStatus,
    reset: resetEnable,
  } = useEnableScrapeJobsMutation(saasIntegrationId, pluginId, jsonData.grafana_instance_id);
  const {
    mutate: disableScrapeJobs,
    status: disableStatus,
    reset: resetDisable,
  } = useDisableScrapeJobsMutation(saasIntegrationId, pluginId, jsonData.grafana_instance_id);
  const {
    mutate: deleteScrapeJobs,
    status: deleteStatus,
    reset: resetDelete,
  } = useDeleteScrapeJobsMutation(saasIntegrationId, pluginId, jsonData.grafana_instance_id);

  const jobQueryState = getStatusFromAction(
    activeButtonAction as ScrapeJobActionType,
    enableStatus,
    disableStatus,
    deleteStatus
  );

  useEffect(() => {
    const action = activeButtonAction as ScrapeJobActionType;
    if (jobQueryState === 'success') {
      setActiveButtonAction(null);
      resetSelectedJobs();

      let reset;
      switch (action) {
        case ScrapeJobActionType.Delete:
          reset = resetDelete;
          break;

        case ScrapeJobActionType.Disable:
          reset = resetDisable;
          break;

        case ScrapeJobActionType.Enable:
          reset = resetEnable;
          break;
      }
      onSuccess(action, selectedJobs.length, reset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobQueryState]);

  const getCommonProps = (actionType: ScrapeJobActionType) => ({
    onClick: () => setActiveButtonAction(actionType),
    closeModal: () => setActiveButtonAction(null),
    open: activeButtonAction === actionType,
    key: actionType,
  });

  const actions = [
    {
      id: 'enable',
      ...getCommonProps(ScrapeJobActionType.Enable),
      mainButtonText: 'Enable',
      description: `You are about to enable ${selectedJobs.length} scrape job(s). This means that metrics will start to be scraped from these services, which will result in additional active series in Grafana Cloud.`,
      confirmText: jobQueryState === 'pending' ? 'Enabling...' : 'Enable scrape jobs',
      variant: 'primary' as ButtonVariant,
      confirmButtonVariant: 'primary' as ButtonVariant,
      onConfirm: () => {
        enableScrapeJobs(selectedJobs);
      },
    },
    {
      id: 'disable',
      ...getCommonProps(ScrapeJobActionType.Disable),
      mainButtonText: 'Disable',
      description: `You are about to disable ${selectedJobs.length} scrape job(s). This means that metrics will no longer be scraped from these services until you enable the scrape job again.`,
      confirmText: jobQueryState === 'pending' ? 'Disabling...' : 'Disable scrape jobs',
      variant: 'secondary' as ButtonVariant,
      confirmButtonVariant: 'primary' as ButtonVariant,
      onConfirm: () => {
        disableScrapeJobs(selectedJobs);
      },
    },
    {
      id: 'delete',
      ...getCommonProps(ScrapeJobActionType.Delete),
      mainButtonText: 'Delete',
      description: `You are about to delete ${selectedJobs.length} scrape job(s). This is a permanent action, and it means that metrics will no longer be scraped from these services.`,
      confirmText: jobQueryState === 'pending' ? 'Deleting...' : 'Delete scrape jobs',
      variant: 'destructive' as ButtonVariant,
      confirmButtonVariant: 'destructive' as ButtonVariant,
      onConfirm: () => {
        deleteScrapeJobs(selectedJobs);
      },
    },
  ];

  function onFilterChanged() {
    queryClient.invalidateQueries({ queryKey: ['getScrapeJobs', saasIntegrationId] });
  }

  const actionButtons = (
    <div>
      {actions.map((action) => (
        <ActionButton
          {...action}
          key={action.key}
          isMainButtonDisabled={selectedJobs.length === 0}
          confirmButtonDisabled={jobQueryState === 'pending'}
        />
      ))}
    </div>
  );

  return (
    <>
      <div className={styles.wrapper}>
        <JobStatusFilter onFilterChanged={onFilterChanged} />
        <div className={styles.selectedJobs}>
          {selectedJobs.length > 0 && (
            <span className={styles.selectedJobsMessage}>
              {' '}
              {selectedJobs.length} scrape {selectedJobs.length === 1 ? 'job' : 'jobs'} selected
            </span>
          )}
          {selectedJobs.length === 0 ? (
            <Tooltip content={'Select scrape job(s) to enable these actions'}>{actionButtons}</Tooltip>
          ) : (
            actionButtons
          )}
        </div>
      </div>
    </>
  );
};
