import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getInstallStyles = (theme: GrafanaTheme2) => ({
  uninstallBtn: css`
    margin: ${theme.spacing(3)} 0;
  `,
  overrideModal: css`
    max-width: 800px;
    padding: 6px;
  `,
  modalContent: css`
    text-align: left;
    h3 {
      margin-bottom: ${theme.spacing(4)};
      padding-top: ${theme.spacing(2)};
      font-size: 18px;
    }
    button,
    a {
      margin-right: ${theme.spacing(1)};
    }
  `,
  text: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #ccccdc;
    padding-left: 30px;
    margin-top: 15px;
  `,
  buttons: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  finishButtons: css`
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: ${theme.spacing(4)};
    display: flex;
    align-items: baseline;

    p {
      font-size: ${theme.typography.body.fontSize};
    }
  `,
  alert: css`
    margin-top: 16px;
  `,
  marginBottom: css`
    margin-bottom: 16px;
  `,
});
