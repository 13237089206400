import React, { FC, useContext } from 'react';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { AlloyIntegrationContext, AlloyIntegrationState } from 'app/contexts/alloyIntegration.context';
import { useGetAgentDetails } from 'api/int-api/queries';
import { Markdown } from 'components/Markdown';
import { CollectorMode } from 'enums';
import { getAgentDetailsContent } from 'feature/common/components/utils';

export const PreInstructions: FC<{ sourceId: string }> = ({ sourceId }) => {
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const {
    configuredParameters: { setupMode },
  } = useContext<AlloyIntegrationState>(AlloyIntegrationContext);
  const { data: agentDetails } = useGetAgentDetails(
    sourceId,
    CollectorMode.Alloy,
    pluginId,
    jsonData.grafana_instance_id
  );

  const content = agentDetails ? getAgentDetailsContent(agentDetails, setupMode) : undefined;

  const preInstructions = content?.preInstructions;

  return <>{preInstructions && <Markdown markdownSnippet={preInstructions} />}</>;
};
