import { SceneAppPage } from '@grafana/scenes';
import GcpIcon from 'img/gcp-logo.svg';
import { GCP_URL } from './routes';
import { getOverviewSceneTab } from './Overview/getOverviewSceneTab';
import { getLogsSceneTab } from './Logs/getLogsSceneTab';
import { getConfigurationSceneTab } from './Configuration/getConfigurationSceneTab';
import { isUserAdmin } from 'utils/user';
import { ScenesCustomParams } from 'scenes/types';
import { CloudProvider } from 'types/CloudProvider';
import { getServicesSceneTab } from 'scenes/common/Services/getServicesSceneTab';
import { getDashboardDrilldown } from 'scenes/common/Dashboards/getDashboardDrilldown';

export const getGcpScenePage = (params: ScenesCustomParams): SceneAppPage => {
  const tabs = [];
  if (params.showServices?.gcp) {
    tabs.push(getServicesSceneTab(CloudProvider.GCP));
  } else {
    tabs.push(getOverviewSceneTab());
  }
  tabs.push(getLogsSceneTab(params));
  if (isUserAdmin()) {
    tabs.push(getConfigurationSceneTab(params.showServices?.gcp));
  }
  return new SceneAppPage({
    title: 'GCP',
    titleImg: GcpIcon,
    hideFromBreadcrumbs: false,
    url: GCP_URL,
    tabs,
    preserveUrlKeys: [],
    drilldowns: getDashboardDrilldown(params, CloudProvider.GCP),
  });
};
