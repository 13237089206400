import { EmbeddedScene, SceneAppPage, SceneAppPageLike, SceneAppPageState, SceneReactObject } from '@grafana/scenes';
import { GCP_CONFIGURATION_URL } from 'scenes/GCP/routes';
import { Configuration as GcpConfiguration } from 'feature/GCP/pages/Configuration/Configuration';

const getGcpConfigurationScene = (showServices: boolean) => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: GcpConfiguration,
      props: { showServices },
    }),
  });
};

export const getConfigurationSceneTab = (showServices = false, parent?: SceneAppPageLike) => {
  const state: SceneAppPageState = {
    title: 'Configuration',
    url: GCP_CONFIGURATION_URL,
    preserveUrlKeys: [],
    getScene: () => getGcpConfigurationScene(showServices),
  };
  if (parent) {
    state.getParentPage = () => parent;
  }
  return new SceneAppPage(state);
};
