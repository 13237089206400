import React from 'react';
import { AlloyStatus } from '@grafana-cloud/collector';
import { AgentSendResult } from 'api/int-api/data-models';
import {
  IntegrationConnectionErrorCause,
  IntegrationConnectionStatus,
  IntegrationConnectionWithoutErrorStatus,
  SetupMode,
} from 'enums';

export type ConfigureParameters = {
  isFullMetricsSelected?: boolean;
  hostName?: string;
  setupMode: SetupMode;
};

export type AlloyIntegrationState = {
  alloyStatus: AlloyStatus;
  configuredParameters: ConfigureParameters;
  showLoadingBar: boolean;
  alloyCheckInstalled: boolean;
  removeLoadingBar: () => void;
  toggleMetricsSet: () => void;
  setHostName: (hostName: string) => void;
  openAlloyModal: () => void;
  installedAlloyCheck: () => void;
  resetConfiguredParameters: () => void;
  setAlloyStatus: (status: AlloyStatus) => void;
  copyToClipboard: () => void;
  integrationConnectionResult: AgentSendResult;
  setIntegrationConnectionError: (error: IntegrationConnectionErrorCause) => void;
  setIntegrationConnectionStatus: (status: IntegrationConnectionWithoutErrorStatus) => void;
  selectSetupMode: (setupMode: SetupMode) => void;
};

export const AlloyIntegrationInitialState: AlloyIntegrationState = {
  alloyStatus: AlloyStatus.None,
  configuredParameters: { setupMode: SetupMode.Simple },
  showLoadingBar: false,
  alloyCheckInstalled: false,
  removeLoadingBar: () => {},
  toggleMetricsSet: () => {},
  setHostName: (hostName: string) => {},
  openAlloyModal: () => {},
  installedAlloyCheck: () => {},
  resetConfiguredParameters: () => {},
  setAlloyStatus: (status: AlloyStatus) => {},
  copyToClipboard: () => {},
  integrationConnectionResult: { status: IntegrationConnectionStatus.None, value: true },
  setIntegrationConnectionError: (error: IntegrationConnectionErrorCause) => {},
  setIntegrationConnectionStatus: (status: IntegrationConnectionWithoutErrorStatus) => {},
  selectSetupMode: (setupMode: SetupMode) => {},
};

export const AlloyIntegrationContext = React.createContext<AlloyIntegrationState>(AlloyIntegrationInitialState);
