import { LogsMethod } from 'scenes/types';
import { LogGroupInfo, LogsOrigin } from 'scenes/AWS/Logs/types';
import { apiGet } from 'api/baseApi';
import { crypt, PASS_PHRASE } from 'scenes/AWS/Logs/utils';

const parseError = (e: any): Error => {
  let error = e as Error;
  if (e.data?.message) {
    try {
      const message = JSON.parse(e.data.message);
      if (message.error) {
        error = new Error(message.error);
      }
    } catch (e1: any) {
      error = new Error(e.data.message);
    }
  }
  return error;
};

export const getLogGroupLabels = async (dataSourceUID: string, from: number, to: number): Promise<LogGroupInfo[]> => {
  try {
    const { data: firehoseLogGroups } = await apiGet<{ data: string[] }>(
      `api/datasources/uid/${dataSourceUID}/resources/label/aws_log_group/values?start=${from}&end=${to}`
    );
    const { data: lambdaLogGroups } = await apiGet<{ data: string[] }>(
      `api/datasources/uid/${dataSourceUID}/resources/label/__aws_cloudwatch_log_group/values?start=${from}&end=${to}`
    );

    const { data: albLogGroups } = await apiGet<{ data: string[] }>(
      `api/datasources/uid/${dataSourceUID}/resources/label/__aws_s3_lb/values?start=${from}&end=${to}`
    );

    const generateId = (group: string, method: LogsMethod) => {
      return crypt(PASS_PHRASE, `${group} - ${method}`);
    };

    return (firehoseLogGroups ?? [])
      .map((group: string) => ({
        id: generateId(group, LogsMethod.FIREHOSE),
        value: group,
        origin: LogsOrigin.CLOUDWATCH,
        method: LogsMethod.FIREHOSE,
      }))
      .concat(
        (lambdaLogGroups ?? []).map((group: string) => ({
          id: generateId(group, LogsMethod.LAMBDA),
          value: group,
          origin: LogsOrigin.CLOUDWATCH,
          method: LogsMethod.LAMBDA,
        }))
      )
      .concat(
        (albLogGroups ?? []).map((group: string) => ({
          id: generateId(group, LogsMethod.ALB),
          value: group,
          origin: LogsOrigin.S3_BUCKET,
          method: LogsMethod.ALB,
        }))
      );
  } catch (e: any) {
    throw parseError(e);
  }
};
