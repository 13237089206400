import { css } from '@emotion/css';
import React, { FC, useContext, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { Clipboard } from 'components/Clipboard';

import { CardElement } from '../../../CardElement';

import { GRAFANA_EXAMPLE_API } from 'utils/misc';
import { SelectGroup } from 'components/SelectGroup';
import { colors } from 'utils/consts';
import { useGetHostedDataDetails } from 'api/grafana-com/queries';
import { GenerateAPI } from 'components/GenerateAPI/GenerateAPI';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';

const getStyles = (theme: GrafanaTheme2) => ({
  title: css`
    margin-top: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(3)};
  `,
  description: css`
    margin-top: ${theme.spacing(3)};
  `,
  link: css`
    * {
      color: white;
    }
  `,
  instructions: css`
    font-size: 20px;
    font-weight: ${theme.typography.fontWeightMedium};
    max-width: 780px;
    margin-bottom: 40px;
    margin-left: ${theme.spacing(3)};

    li {
      margin-bottom: 32px;
      font-size: 19px;

      h2 {
        margin: ${theme.spacing(2)} 0;
        line-height: 26px;
        font-size: 19px;
        color: ${theme.isLight ? colors.blue04 : theme.colors.text.maxContrast};
      }

      p {
        font-size: ${theme.typography.body.fontSize};
        font-weight: ${theme.typography.fontWeightRegular};
      }
    }

    a {
      color: ${theme.isLight ? colors.blue07 : colors.blue03};
      &:hover {
        text-decoration: underline;
      }
    }
  `,
});

enum Methods {
  CloudFormation = 'cf',
  Terraform = 'tf',
}

const CloudFormationComponent = () => {
  const styles = useStyles2(getStyles);
  const { pluginId } = useContext(PluginMetaContext);
  const { data: hostedDataDetails } = useGetHostedDataDetails(pluginId);

  const templateUrl =
    'https://grafanalabs-cf-templates.s3.us-east-2.amazonaws.com/cloudwatch-logs/lambda-promtail.yaml';
  const templateName = 'GrafanaLabs-CloudWatch-Logs';
  const paramWriteAddress = `${hostedDataDetails?.hlInstanceUrl}/loki/api/v1/push`;
  const paramUsername = hostedDataDetails?.hlInstanceId;
  const cloudformationUrl = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${templateUrl}&stackName=${templateName}&param_WriteAddress=${paramWriteAddress}&param_Username=${paramUsername}`;

  const apiKeySuccessMessage =
    'Your API key has been generated below. Copy and paste this key into the "Password" field of the CloudFormation stack.';

  const s3UploadCmd =
    'aws s3api copy-object --copy-source grafanalabs-cf-templates/lambda-promtail/lambda-promtail.zip --bucket YOUR-BUCKET-NAME --region YOUR-REGION-NAME --key lambda-promtail.zip';

  return (
    <>
      <p className={styles.description}>AWS CloudFormation guides you through setting up the necessary resources.</p>

      <p className={styles.description}>
        Follow these steps to create a Lambda function that forwards CloudWatch logs to Grafana Cloud Logs.
      </p>

      <ol className={styles.instructions}>
        <li>
          <h2>Upload the lambda-promtail compressed file to the S3 bucket.</h2>

          <p>
            lambda-promtail is a Golang application that can run as a Lambda function which forwards CloudWatch logs to
            Grafana Cloud.
          </p>

          <Clipboard
            title="Run this command to upload the lambda-promtail compressed binary to an S3 bucket in the region of your choice."
            multipleLines
            code={s3UploadCmd}
          />

          <p>
            The bucket where you upload this file must be in the same AWS region where the Lambda function will run.
          </p>
        </li>

        <li>
          <h2>Create a Grafana.com API key</h2>

          <p>
            Generate a Grafana.com API key with the MetricsPublisher role. This API key will be used as
            &quot;Password&quot; in the CloudFormation stack at the next step.
          </p>

          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={hostedDataDetails?.hlInstanceId ?? 1}
            successMessage={apiKeySuccessMessage}
          />
        </li>

        <li>
          <h2>Launch CloudFormation stack</h2>

          <p>
            Run the CloudFormation setup, and specify a <code>SubscriptionFilter</code> for the LogGroup you want to
            forward to Grafana Cloud.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            target="_blank"
            icon="external-link-alt"
            href={cloudformationUrl}
          >
            Launch CloudFormation stack
          </LinkButton>
        </li>

        <li>
          <h2>Explore logs</h2>

          <p>
            Head over to the Explore page and select a log stream based on <code>__aws_cloudwatch_log_group</code>.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            type="button"
            href={`/explore?left={"datasource":"grafanacloud-${hostedDataDetails?.slug}-logs"}`}
            target={'_blank'}
          >
            Go to Explore
          </LinkButton>
        </li>
      </ol>
    </>
  );
};

const TerraformComponent = () => {
  const styles = useStyles2(getStyles);
  const { pluginId } = useContext(PluginMetaContext);
  const { data: hostedDataDetails } = useGetHostedDataDetails(pluginId);

  const writeAddress = `${hostedDataDetails?.hlInstanceUrl}/loki/api/v1/push`;
  const username = hostedDataDetails?.hlInstanceId;

  const terraformDocsUrl =
    'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/aws/cloudwatch-logs/config-cw-logs-lambda/#configure-with-terraform';

  const apiKeySuccessMessage = 'Your API key has been generated below. Use it in the Terraform setup.';

  return (
    <>
      <p className={styles.description}>The Terraform configuration will create the necessary resources.</p>

      <p className={styles.description}>
        Follow these steps to create a Lambda function that forwards CloudWatch logs to Grafana Cloud Logs.
      </p>

      <ol className={styles.instructions}>
        <li>
          <h2>Create a Grafana.com API key</h2>

          <p>
            Click below to generate a Grafana.com API key with the MetricsPublisher role. This API key will be used in
            the Terraform setup at the next step.
          </p>

          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={hostedDataDetails?.hlInstanceId ?? 1}
            successMessage={apiKeySuccessMessage}
          />
        </li>

        <li>
          <h2>Terraform setup</h2>

          <p>You’ll need to use the following parameters as input variables in the Terraform file.</p>

          <Clipboard title="Grafana Cloud Loki write address:" multipleLines code={writeAddress} />

          <Clipboard title="Grafana Cloud Loki username:" multipleLines code={username?.toString() ?? ''} />

          <p>
            See our documentation and learn how to use Terraform to set up the necessary resources in your AWS account.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            target="_blank"
            icon="external-link-alt"
            href={terraformDocsUrl}
          >
            See Terraform docs
          </LinkButton>
        </li>

        <li>
          <h2>Explore logs</h2>

          <p>
            Head over to the Explore page and select a log stream based on <code>__aws_cloudwatch_log_group</code>.
          </p>

          <LinkButton
            className={styles.link}
            variant="primary"
            type="button"
            href={`/explore?left={"datasource":"grafanacloud-${hostedDataDetails?.slug}-logs"}`}
            target={'_blank'}
          >
            Go to Explore
          </LinkButton>
        </li>
      </ol>
    </>
  );
};

const methodOptions = [
  {
    label: 'Use CloudFormation',
    value: Methods.CloudFormation,
    component: CloudFormationComponent,
  },
  {
    label: 'Use Terraform',
    value: Methods.Terraform,
    component: TerraformComponent,
  },
];

export const Automatic: FC = ({}) => {
  const styles = useStyles2(getStyles);
  const [method, setMethod] = useState(Methods.CloudFormation);
  const selectedOption = methodOptions.find((option) => option.value === method);
  const Component = selectedOption?.component;

  return (
    <>
      <h2 className={styles.title}>Choose a method for creating AWS resources</h2>
      <div>
        <SelectGroup options={methodOptions} value={method} onChange={setMethod} CardElementOverride={CardElement} />
        {selectedOption && Component && <Component />}
      </div>
    </>
  );
};
