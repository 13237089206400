import {
  behaviors,
  EmbeddedScene,
  SceneDataTransformer,
  SceneFlexItem,
  SceneFlexLayout,
  SceneQueryRunner,
  SceneRefreshPicker,
  SceneTimePicker,
  SceneVariableSet,
  VariableValueSelectors,
  VizPanel,
} from '@grafana/scenes';
import { TableCellBackgroundDisplayMode } from '@grafana/schema';
import { TableCellDisplayMode } from '@grafana/ui';
import { RDS_URL } from 'scenes/AWS/routes';
import {
  formatMetricName,
  getGenericQueryRunner,
  makeTimeRange,
  onChangeDatasourceBehavior,
  ServiceWithOotbViews,
} from 'scenes/misc';
import { NECESSARY_RDS_METRICS, rdsQueries } from './queries';
import { VAR_ACCOUNT, VAR_DATASOURCE, VAR_REGION, VAR_SCRAPE_JOB } from 'scenes/variables';
import { getVariables } from './getVariables';
import { ThresholdsMode } from '@grafana/data';
import { ExplorablePanel } from 'scenes/common/ExplorablePanel';
import { SceneControlsNewLine } from 'scenes/components/SceneControlsNewLine';

const metricsOverrides: Array<{
  name: string;
  otherProperties?: any[];
}> = [
  {
    name: NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_MAXIMUM,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.Gauge,
        },
      },
      {
        id: 'thresholds',
        value: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: '#5794F2',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
      },
      {
        id: 'unit',
        value: 'percent',
      },
      {
        id: 'decimals',
        value: 2,
      },
    ],
  },
  {
    name: NECESSARY_RDS_METRICS.METRIC_DATABASE_CONNECTIONS_SUM,
    otherProperties: [
      {
        id: 'custom.cellOptions',
        value: {
          mode: TableCellBackgroundDisplayMode.Basic,
          type: TableCellDisplayMode.ColorText,
        },
      },
    ],
  },
  {
    name: NECESSARY_RDS_METRICS.METRIC_REPLICA_LAG_AVERAGE,
    otherProperties: [
      {
        id: 'unit',
        value: 's',
      },
    ],
  },
  {
    name: NECESSARY_RDS_METRICS.METRIC_SWAP_USAGE_AVERAGE,
    otherProperties: [
      {
        id: 'unit',
        value: 'bytes',
      },
    ],
  },
  ...[
    NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_MAXIMUM,
    NECESSARY_RDS_METRICS.METRIC_WRITE_LATENCY_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_WRITE_LATENCY_MAXIMUM,
  ].map((el) => {
    return {
      name: el,
      otherProperties: [
        {
          id: 'custom.cellOptions',
          value: {
            mode: TableCellBackgroundDisplayMode.Basic,
            type: TableCellDisplayMode.Gauge,
          },
        },
        {
          id: 'unit',
          value: 's',
        },
        {
          id: 'thresholds',
          value: {
            mode: ThresholdsMode.Absolute,
            steps: [
              {
                color: '#5794F2',
                value: 0,
              },
              {
                color: 'red',
                value: 0.01,
              },
            ],
          },
        },
      ],
    };
  }),
];

export const getOverviewScene = (prometheusName: string, from?: string, to?: string) => {
  const datasource = {
    type: 'prometheus',
    uid: '$datasource',
  };

  const { datasources, region, account, scrapeJob } = getVariables(prometheusName);

  const metricsDefaults = [
    NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_MAXIMUM,
    NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_READ_LATENCY_MAXIMUM,
    NECESSARY_RDS_METRICS.METRIC_WRITE_LATENCY_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_WRITE_LATENCY_MAXIMUM,
    NECESSARY_RDS_METRICS.METRIC_READ_IOPS_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_WRITE_IOPS_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_REPLICA_LAG_AVERAGE,
    NECESSARY_RDS_METRICS.METRIC_SWAP_USAGE_AVERAGE,
  ];

  const queryRunner = new SceneQueryRunner({
    datasource: datasource,
    queries: metricsDefaults.map((m) => {
      return {
        refId: m,
        expr:
          'sum by(account_id, region, dimension_InstanceId, dimension_DBInstanceIdentifier, dimension_DatabaseClass, scrape_job, tag_Name)' +
          `(${m}{name!='global', region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}"} + on(name, scrape_job) group_left(tag_Name) aws_rds_info)`,
        format: 'table',
        instant: true,
        interval: '',
        legendFormat: '',
        excludeByName: {
          __name__: true,
        },
      };
    }),
  });

  const transformed = new SceneDataTransformer({
    $data: queryRunner,
    transformations: [
      {
        id: 'merge',
        options: {},
      },
      {
        id: 'organize',
        options: {
          indexByName: {
            account_id: 0,
            dimension_DBInstanceIdentifier: 1,
            region: 2,
            scrape_job: 3,
            tag_Name: 4,
            tag_aws_autoscaling_groupName: 5,
          },
        },
      },
    ],
  });

  const sceneTimeRange = makeTimeRange(from, to);

  return new EmbeddedScene({
    $variables: new SceneVariableSet({
      variables: [datasources, region, account, scrapeJob],
    }),
    $timeRange: sceneTimeRange,
    controls: [
      new SceneControlsNewLine({
        align: 'flex-start',
        controls: [new VariableValueSelectors({})],
      }),
      new SceneControlsNewLine({
        controls: [
          new SceneTimePicker({ isOnCanvas: true }),
          new SceneRefreshPicker({
            intervals: ['5s', '1m', '1h'],
            isOnCanvas: true,
          }),
        ],
      }),
    ],
    body: new SceneFlexLayout({
      direction: 'column',
      $behaviors: [
        new behaviors.ActWhenVariableChanged({
          variableName: VAR_DATASOURCE,
          onChange: onChangeDatasourceBehavior,
        }),
      ],
      children: [
        new SceneFlexLayout({
          direction: 'row',
          height: 100,
          children: [
            new SceneFlexItem({
              height: 100,
              body: new VizPanel({
                title: 'Database instances requiring attention',
                pluginId: 'text',
                displayMode: 'transparent',
                options: {
                  mode: 'markdown',
                  content:
                    'The following database instances may be causing issues. If you see no data, no items are exceeding specific thresholds',
                },
              }),
            }),
          ],
        }),
        new SceneFlexLayout({
          direction: 'row',
          height: 200,
          children: [
            new SceneFlexItem({
              minHeight: 200,
              body: new VizPanel({
                $data: new SceneDataTransformer({
                  $data: new SceneQueryRunner({
                    datasource: datasource,
                    queries: [
                      {
                        refId: NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_AVERAGE,
                        expr:
                          'sum by(account_id, dimension_InstanceId, dimension_DBInstanceIdentifier)' +
                          `(${NECESSARY_RDS_METRICS.METRIC_CPUUTILIZATION_AVERAGE}{name!='global', region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}"} + on(name,scrape_job) group_left(tag_Name) aws_rds_info)`,
                        format: 'table',
                        instant: true,
                        interval: '',
                        legendFormat: '',
                        excludeByName: {
                          __name__: true,
                        },
                      },
                    ],
                  }),
                  transformations: [
                    {
                      id: 'merge',
                      options: {},
                    },
                    {
                      id: 'organize',
                      options: {
                        indexByName: {
                          account_id: 0,
                          dimension_DBInstanceIdentifier: 1,
                        },
                      },
                    },
                  ],
                }),
                title: 'CPU <60% instances',
                // description: 'Used to determine underutilized instances',
                pluginId: 'table',
                fieldConfig: {
                  defaults: {
                    custom: {
                      align: 'auto',
                      cellOptions: {
                        type: 'auto',
                      },
                      width: 200,
                      inspect: false,
                      filterable: true,
                    },
                    mappings: [],
                  },
                  overrides: [
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Time',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'account_id',
                      },
                      properties: [
                        {
                          id: 'custom.width',
                          value: 110,
                        },
                        {
                          id: 'displayName',
                          value: 'Account ID',
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Value',
                      },
                      properties: [
                        {
                          id: 'unit',
                          value: 'percent',
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'dimension_DBInstanceIdentifier',
                      },
                      properties: [
                        {
                          id: 'displayName',
                          value: 'Instance ID',
                        },
                        {
                          id: 'links',
                          value: [
                            {
                              title: 'Go to instance',
                              url:
                                RDS_URL +
                                '/instance/${__data.fields.dimension_DBInstanceIdentifier}?var-region=${__data.fields.region}&${__url.params}&var-datasource=$datasource',
                            },
                          ],
                        },
                      ],
                    },
                  ].flat(),
                },
              }),
            }),
            new SceneFlexItem({
              minHeight: 200,
              body: new VizPanel({
                $data: new SceneDataTransformer({
                  $data: new SceneQueryRunner({
                    datasource: datasource,
                    queries: [
                      {
                        refId: NECESSARY_RDS_METRICS.METRIC_READ_IOPS_AVERAGE,
                        expr:
                          'sum by(account_id, dimension_InstanceId, dimension_DBInstanceIdentifier)' +
                          `(${NECESSARY_RDS_METRICS.METRIC_READ_IOPS_AVERAGE}{name!='global', region=~"$${VAR_REGION}", account_id=~"$${VAR_ACCOUNT}", scrape_job=~"$${VAR_SCRAPE_JOB}"} + on(name,scrape_job) group_left(tag_Name) aws_rds_info)`,
                        format: 'table',
                        instant: true,
                        interval: '',
                        legendFormat: '',
                        excludeByName: {
                          __name__: true,
                        },
                      },
                    ],
                  }),
                  transformations: [
                    {
                      id: 'merge',
                      options: {},
                    },
                    {
                      id: 'organize',
                      options: {
                        indexByName: {
                          account_id: 0,
                          dimension_DBInstanceIdentifier: 1,
                        },
                      },
                    },
                  ],
                }),
                title: 'ReadlOPS <100 instances',
                // description: 'Used to determine underutilized instances'
                pluginId: 'table',
                fieldConfig: {
                  defaults: {
                    custom: {
                      align: 'auto',
                      cellOptions: {
                        type: 'auto',
                      },
                      width: 200,
                      inspect: false,
                      filterable: true,
                    },
                    mappings: [],
                  },
                  overrides: [
                    {
                      matcher: {
                        id: 'byName',
                        options: 'Time',
                      },
                      properties: [
                        {
                          id: 'custom.hidden',
                          value: true,
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'account_id',
                      },
                      properties: [
                        {
                          id: 'custom.width',
                          value: 110,
                        },
                        {
                          id: 'displayName',
                          value: 'Account ID',
                        },
                      ],
                    },
                    {
                      matcher: {
                        id: 'byName',
                        options: 'dimension_DBInstanceIdentifier',
                      },
                      properties: [
                        {
                          id: 'displayName',
                          value: 'Instance ID',
                        },
                        {
                          id: 'links',
                          value: [
                            {
                              title: 'Go to instance',
                              url:
                                RDS_URL +
                                '/instance/${__data.fields.dimension_DBInstanceIdentifier}?var-region=${__data.fields.region}&${__url.params}&var-datasource=$datasource',
                            },
                          ],
                        },
                      ],
                    },
                  ].flat(),
                },
              }),
            }),
          ],
        }),
        new SceneFlexLayout({
          direction: 'row',
          height: 100,
          children: [
            new SceneFlexItem({
              height: 100,
              $data: getGenericQueryRunner(datasource, rdsQueries.dbInstancesCount),
              body: new ExplorablePanel({
                title: 'DB instances count',
                pluginId: 'stat',
                fieldConfig: {
                  overrides: [],
                  defaults: {
                    color: {
                      mode: 'fixed',
                      fixedColor: 'green',
                    },
                    unit: 'short',
                  },
                },
              }),
            }),
            new SceneFlexItem({
              height: 100,
              $data: getGenericQueryRunner(datasource, rdsQueries.avgBurstBalance),
              body: new ExplorablePanel({
                title: 'Average Burst Balance',
                pluginId: 'stat',
                fieldConfig: {
                  overrides: [],
                  defaults: {
                    color: {
                      mode: 'fixed',
                      fixedColor: 'green',
                    },
                    unit: 'short',
                    links: [
                      {
                        title: 'Burst Balance - All Instances',
                        url: RDS_URL + '/filtered-instances/burstBalance',
                      },
                    ],
                  },
                },
              }),
            }),
          ],
        }),
        new SceneFlexLayout({
          direction: 'row',
          height: 100,
          children: [
            new SceneFlexItem({
              height: 100,
              body: new VizPanel({
                title: 'DB fleet overview',
                pluginId: 'text',
                displayMode: 'transparent',
                options: {
                  mode: 'markdown',
                  content:
                    'Here is an overview of all your DB instances, across all regions and AWS accounts. Use the column filters to pare down your list, or click on the metric names to sort the table by a particular metric.',
                },
              }),
            }),
          ],
        }),
        new SceneFlexItem({
          minHeight: 400,
          body: new VizPanel({
            $data: transformed,
            title: '',
            pluginId: 'table',
            fieldConfig: {
              defaults: {
                custom: {
                  align: 'auto',
                  cellOptions: {
                    type: 'auto',
                  },
                  width: 200,
                  inspect: false,
                  filterable: true,
                },
                mappings: [],
              },
              overrides: [
                {
                  matcher: {
                    id: 'byName',
                    options: 'Time',
                  },
                  properties: [
                    {
                      id: 'custom.hidden',
                      value: true,
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'account_id',
                  },
                  properties: [
                    {
                      id: 'custom.width',
                      value: 110,
                    },
                    {
                      id: 'displayName',
                      value: 'Account ID',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'region',
                  },
                  properties: [
                    {
                      id: 'custom.width',
                      value: 110,
                    },
                    {
                      id: 'displayName',
                      value: 'Region',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'scrape_job',
                  },
                  properties: [
                    {
                      id: 'custom.width',
                      value: 110,
                    },
                    {
                      id: 'displayName',
                      value: 'Scrape job',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'tag_Name',
                  },
                  properties: [
                    {
                      id: 'displayName',
                      value: 'Tag name',
                    },
                  ],
                },
                {
                  matcher: {
                    id: 'byName',
                    options: 'dimension_DBInstanceIdentifier',
                  },
                  properties: [
                    {
                      id: 'displayName',
                      value: 'Instance ID',
                    },
                    {
                      id: 'links',
                      value: [
                        {
                          title: 'Go to instance',
                          url:
                            RDS_URL +
                            '/instance/${__data.fields.dimension_DBInstanceIdentifier}?var-region=${__data.fields.region}&${__url.params}&var-datasource=$datasource',
                        },
                      ],
                    },
                  ],
                },
                ...(metricsDefaults.length > 1
                  ? [
                      ...metricsDefaults.map((metricName: string) => {
                        return {
                          matcher: {
                            id: 'byName',
                            options: `Value #${metricName}`,
                          },
                          properties: [
                            {
                              id: 'displayName',
                              value: formatMetricName(metricName, ServiceWithOotbViews.Rds),
                            },
                            [...(metricsOverrides.find((metric) => metric.name === metricName)?.otherProperties ?? [])],
                          ].flat(),
                        };
                      }),
                    ]
                  : []),
              ].flat(),
            },
          }),
        }),
      ],
    }),
  });
};
