import { css } from '@emotion/css';
import React, { useContext, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Collapse, useStyles2 } from '@grafana/ui';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { AlloyIntegrationContext, AlloyIntegrationState } from 'app/contexts/alloyIntegration.context';
import { useGetAgentDetails } from 'api/int-api/queries';
import { areFilteredBlocksPresent } from 'feature/common/components/utils';
import { FilteredMetricsToggle } from './FilteredMetricsToggle';
import { CollectorMode } from 'enums';
import { SetupModeSelector } from 'feature/common/components/SetupModeSelector';

const getStyles = (theme: GrafanaTheme2) => ({
  p: css({
    marginTop: '10px',
  }),
  step: css({
    backgroundColor: theme.colors.background.secondary,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '> button:first-child': {
      padding: 0,
      '> div:first-child': {
        paddingTop: theme.spacing(0.25),
        '> svg': {
          margin: theme.spacing(0.25, 1, 0, 0),
        },
      },
    },
    '> div': {
      padding: 0,
    },
  }),
  topMargin: css({
    marginTop: theme.spacing(3),
  }),
  noMargin: css({
    margin: 0,
  }),
  container: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  }),
});

export const MakeConfigurationSelectionsSection = ({ sourceId }: { sourceId: string }) => {
  const styles = useStyles2(getStyles);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const {
    configuredParameters: { setupMode },
  } = useContext<AlloyIntegrationState>(AlloyIntegrationContext);
  const { data: agentDetails } = useGetAgentDetails(
    sourceId,
    CollectorMode.Alloy,
    pluginId,
    jsonData.grafana_instance_id
  );

  const shouldShowFilteredMetricsToggle = agentDetails ? areFilteredBlocksPresent(agentDetails, setupMode) : false;
  const shouldShowSetupModeSelector = agentDetails?.contentType === 'alloy-both-modes';
  const shouldShowMakeOptionalSelectionsBox = shouldShowFilteredMetricsToggle || shouldShowSetupModeSelector;

  const [isCollapseOpen, setIsCollapseOpen] = useState(true);

  if (!shouldShowMakeOptionalSelectionsBox) {
    return null;
  }

  return (
    <li>
      <h2>Make configuration selections</h2>
      <p className={styles.p}>
        To help us generate configuration snippets that are customized for you, make integration selections below.
      </p>
      <Collapse
        label={<h3 className={styles.noMargin}>Make optional selections</h3>}
        className={styles.step}
        isOpen={isCollapseOpen}
        onToggle={() => setIsCollapseOpen((v) => !v)}
        collapsible
      >
        <div className={styles.container}>
          {shouldShowFilteredMetricsToggle && <FilteredMetricsToggle sourceId={sourceId} />}
          {shouldShowSetupModeSelector && <SetupModeSelector sourceId={sourceId} />}
        </div>
      </Collapse>
    </li>
  );
};
