import { prefixRoute } from 'utils/utils.routing';
import { CloudProvider } from 'types/CloudProvider';
import { ROUTES } from 'utils/consts';

export const AWS_URL = prefixRoute(CloudProvider.AWS);
export const AWS_DASHBOARDS_URL = prefixRoute(CloudProvider.AWS, ROUTES.Dashboards);
export const EC2_URL = prefixRoute(CloudProvider.AWS, `${ROUTES.Dashboards}/ec2`);
export const RDS_URL = prefixRoute(CloudProvider.AWS, `${ROUTES.Dashboards}/rds`);
export const AWS_LOGS_URL = prefixRoute(CloudProvider.AWS, `${ROUTES.Logs}`);
export const AWS_CONFIGURATION_URL = prefixRoute(CloudProvider.AWS, `${ROUTES.Configuration}`);
export const AWS_OVERVIEW_URL = prefixRoute(CloudProvider.AWS, `${ROUTES.OVERVIEW}`);
export const AWS_SERVICES_URL = prefixRoute(CloudProvider.AWS, `${ROUTES.SERVICES}`);
