import { SceneAppDrilldownView, SceneAppPageLike, SceneRouteMatch } from '@grafana/scenes';
import { ScenesCustomParams } from 'scenes/types';
import { CloudProvider } from 'types/CloudProvider';
import { getDashboardsUrlByProvider } from 'scenes/misc';
import { getDashboardEmbeddedScenePage } from './getDashboardEmbeddedScenePage';
import { EC2_URL, RDS_URL } from 'scenes/AWS/routes';
import { getEC2ScenePage } from 'scenes/AWS/Dashboards/EC2/getEC2ScenePage';
import { getRDSScenePage } from 'scenes/AWS/Dashboards/RDS/getRDSScenePage';

export const getDashboardDrilldown = (params: ScenesCustomParams, provider: CloudProvider) => {
  let routes: SceneAppDrilldownView[] = [];
  if (provider === CloudProvider.AWS) {
    routes = routes.concat([
      {
        routePath: EC2_URL,
        getPage: (routeMatch: SceneRouteMatch<any>, parent: SceneAppPageLike) => getEC2ScenePage(params, parent),
      },
      {
        routePath: RDS_URL,
        getPage: (routeMatch: SceneRouteMatch<any>, parent: SceneAppPageLike) => getRDSScenePage(params, parent),
      },
    ]);
  }
  routes = routes.concat([
    {
      routePath: getDashboardsUrlByProvider(provider) + '/:integrationFolder/:dashboardId',
      getPage: (routeMatch: SceneRouteMatch<any>, parent: SceneAppPageLike) =>
        getDashboardEmbeddedScenePage(
          provider,
          routeMatch.params.integrationFolder,
          routeMatch.params.dashboardId,
          params.dashboard,
          parent
        ),
    },
  ]);

  return routes;
};
