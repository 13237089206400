import { isEqual } from 'lodash';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, Controller, FormProvider, FieldValues } from 'react-hook-form';

import { SelectableValue } from '@grafana/data';
import { Field, Input, Button, MultiSelect, Spinner, useStyles2, Alert, ButtonVariant } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';

import { EditJobFormProps } from '../../JobManager/JobManager';
import { JobStatusWidget } from '../../JobManager/JobStatusWidget';

import { TagsCheckbox } from './TagsCheckbox';
import { FormOutput, ScrapeJobActionType, ServicesSummaryChanges } from 'feature/AWS/components/SaasIntegrations/types';
import { CloudWatchConfig, CloudWatchJob, ServiceConfigurationApi } from 'api/hosted-exporters-api/data-models';
import { getEditJobStyles } from './EditJob.styles';
import { ActionType, RudderStackEvents } from 'enums';
import { mapServices, transformServicesForApi } from 'feature/AWS/components/SaasIntegrations/utils';
import {
  areServicesChanged,
  getFormErrorMessage,
  getFormServicesFromInitialConfiguration,
  getServicesSummaryChanges,
  sortServices,
} from '../CloudWatchUtils';
import { ConfigureServicesTable } from '../services/ConfigureServicesTable/ConfigureServicesTable';
import {
  useCloudwatchConfig,
  useCreateOrUpdateScrapeJobState,
  useDeleteScrapeJobsMutation,
  useDisableScrapeJobsMutation,
  useEnableScrapeJobsMutation,
} from 'api/hosted-exporters-api/queries';
import { PluginMetaContext } from 'app/contexts/pluginMeta.context';
import { useCloudwatchParams } from 'hooks/useCloudwatchParams';
import { ActionButton } from '../../JobManager/ScrapeJobActions';
import { JobStatus } from 'feature/AWS/components/JobStatus';

export interface EditReactHookFormProps extends FieldValues {
  arn: string;
  regions: Array<SelectableValue<string>>;
  services: FormOutput[];
  simpleServices: Array<SelectableValue<string>>;
  export_tags: boolean;
  name: string;
}

const defaultCloudwatchJob = {
  name: '',
  enabled: true,
  aws_account_id: '',
  role_arn: '',
  regions: [],
  services: [],
  service_configurations: [],
  export_tags: true,
  disabled_reason: undefined,
};

export const EditJob: FC<EditJobFormProps<CloudWatchJob>> = ({ onSaveJob, onCancel, job = defaultCloudwatchJob }) => {
  const styles = useStyles2(getEditJobStyles);
  const [servicesForm, setServicesForm] = useState<FormOutput[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { pluginId, jsonData } = useContext(PluginMetaContext);
  const { data } = useCloudwatchConfig(pluginId, jsonData.grafana_instance_id);
  const [hasServicesChanged, setServicesChanged] = useState<boolean>(false);
  const { status: editJobStatus } = useCreateOrUpdateScrapeJobState();
  const { id } = useCloudwatchParams();
  const {
    mutate: deleteScrapeJobs,
    isSuccess,
    status: deleteJobStatus,
  } = useDeleteScrapeJobsMutation(id, pluginId, jsonData.grafana_instance_id);

  const cloudWatchConfig = data ?? ({} as CloudWatchConfig);

  let allServices = useMemo(
    () =>
      !!cloudWatchConfig?.supported_services
        ? mapServices(cloudWatchConfig.supported_services, ActionType.Edit, job.service_configurations)
        : [],
    [cloudWatchConfig?.supported_services, job.service_configurations]
  );

  const defaultCustomNamespace = cloudWatchConfig?.custom_namespace;
  const isCustomNamespacesFormIncomplete = servicesForm.some(
    (el) => Object.keys(el.metrics).length === 0 && el.isCustomNamespace
  );

  const formMethods = useForm<EditReactHookFormProps>({
    mode: 'onChange',
    defaultValues: {
      arn: job?.role_arn,
      regions: (job?.regions || []).map((r) => ({ label: r, value: r })),
      export_tags: job.export_tags,
    },
  });

  const {
    control,
    handleSubmit,
    register,
    getValues,
    formState: { isDirty, isValid, errors },
  } = formMethods;

  const { trackRudderStackEvent } = useRudderStack();

  const handleUpdateJob = handleSubmit((newJobProps) => {
    if (!job) {
      return;
    }
    const transformedServices: ServiceConfigurationApi[] = transformServicesForApi(servicesForm);

    const newJob = {
      name: job?.name,
      role_arn: newJobProps.arn,
      aws_account_id: '',
      regions: newJobProps.regions.map((r) => r.value!),
      service_configurations: transformedServices,
      enabled: job?.enabled ?? true,
      export_tags: newJobProps.export_tags,
    };

    if (!isEqual(job, newJob)) {
      onSaveJob(newJob);

      const services_changes: ServicesSummaryChanges = getServicesSummaryChanges(job, transformedServices);

      trackRudderStackEvent(RudderStackEvents.UpdateScrapeJobInitiated, {
        integration_slug: 'cloudwatch',
        services_changes,
      });

      setServicesChanged(false);
    }
  });

  useEffect(
    () => setServicesForm(getFormServicesFromInitialConfiguration(job?.service_configurations, allServices)),
    [job?.service_configurations, allServices]
  );

  useEffect(() => {
    if (isSuccess) {
      onCancel();
    }
  }, [isSuccess, onCancel]);

  function onServicesChanged(services: FormOutput[]) {
    const updatedServices = sortServices(services);
    setServicesForm(updatedServices);
    setServicesChanged(areServicesChanged(job, updatedServices));
  }

  function getAccountForm() {
    const name = getValues('name') ?? job.name;
    const arn = getValues('arn') ?? job.role_arn;
    const regions = getValues('regions')?.map((r) => r.value!) ?? (job?.regions || []);
    const export_tags = getValues('export_tags') ?? job.export_tags;

    return { name, arn, regions, export_tags, isValid, isDirty };
  }

  const confirmDeleteJob = () => {
    trackRudderStackEvent(RudderStackEvents.DeleteScrapeJob, { integration_slug: id, job: job.name });

    deleteScrapeJobs([job.name]);
  };

  const { mutate: enableScrapeJobs, status: enableStatus } = useEnableScrapeJobsMutation(
    'cloudwatch',
    pluginId,
    jsonData.grafana_instance_id
  );
  const { mutate: disableScrapeJobs, status: disableStatus } = useDisableScrapeJobsMutation(
    'cloudwatch',
    pluginId,
    jsonData.grafana_instance_id
  );
  const [activeButtonAction, setActiveButtonAction] = useState<ScrapeJobActionType | null>(null);

  const getCommonProps = (actionType: ScrapeJobActionType) => ({
    onClick: () => setActiveButtonAction(actionType),
    closeModal: () => setActiveButtonAction(null),
    open: activeButtonAction === actionType,
    key: actionType,
  });

  return (
    <FormProvider {...formMethods}>
      <form className={styles.jobForm} onSubmit={handleUpdateJob}>
        <div className={styles.container}>
          <h4>{job?.name}</h4>
          <JobStatus enabled={job.enabled} disabled_reason={job.disabled_reason} />
          <span className={styles.jobFieldName}>ARN</span>
          <Field invalid={errors.arn !== undefined} error={errors.arn ? 'The arn is required' : ''}>
            <Input
              {...register('arn', { required: true })}
              type="text"
              aria-label="AWS ARN"
              id="arn"
              className={styles.arnInput}
            />
          </Field>

          <span className={styles.jobFieldName}>AWS Regions</span>
          <div>
            <Field
              invalid={errors.regions !== undefined}
              error={errors.regions ? 'At least one region is required' : ''}
              htmlFor="regions-selector"
            >
              <Controller
                control={control}
                name="regions"
                rules={{
                  validate: (s: Array<SelectableValue<string>>) => s.length > 0,
                }}
                render={({ field, fieldState }) => (
                  <MultiSelect<string>
                    options={(cloudWatchConfig.regions ?? []).map((region: string) => ({
                      label: region,
                      value: region,
                    }))}
                    placeholder="Select"
                    inputId="regions-selector"
                    closeMenuOnSelect={false}
                    noOptionsMessage="Unable to load regions."
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Field>
          </div>

          <span className={styles.jobFieldName}>Services to scrape</span>
          <ConfigureServicesTable
            mode={'edit'}
            services={servicesForm}
            defaultServices={allServices}
            defaultCustomNamespace={defaultCustomNamespace}
            supportedServices={cloudWatchConfig.supported_services}
            onServicesChanged={onServicesChanged}
          />
          {servicesForm.length === 0 && (
            <Alert title="" severity="error" className={styles.alert}>
              You should select at least one service
            </Alert>
          )}
          <TagsCheckbox register={register} />
          <Alert title="" severity="info" className={styles.alert}>
            Tags will appear as labels on the exported metric with a <code>tag_</code> prefix. Choosing to include tags
            will increase the total number of active series which can have an impact on your Grafana Cloud Costs.
            Additionally, please ensure your tags adhere to AWS best practices in that they do not contain personally
            identifiable information (PII) or other confidential or sensitive information.
          </Alert>
        </div>
        <div className={styles.controlGroup}>
          <Button
            data-testid={Pages.CloudWatch.EditJob.cancelScrapeJobButton}
            onClick={onCancel}
            variant="secondary"
            type="button"
            aria-label="Back to Job List"
          >
            {editJobStatus === 'success' ? 'Back to Job List' : 'Cancel'}
          </Button>
          <Button
            data-testid={Pages.CloudWatch.EditJob.saveScrapeJobButton}
            type="submit"
            className={styles.control}
            aria-label="Update scrape job"
            tooltip={getFormErrorMessage(getAccountForm(), hasServicesChanged, servicesForm.length)}
            disabled={
              editJobStatus === 'pending' ||
              servicesForm.length === 0 ||
              isCustomNamespacesFormIncomplete ||
              (!isDirty && !hasServicesChanged) ||
              !isValid
            }
          >
            {editJobStatus === 'pending' && <Spinner className={styles.spinner} />}
            Save scrape job
          </Button>
          <ActionButton
            {...(job.enabled
              ? {
                  id: 'disable',
                  ...getCommonProps(ScrapeJobActionType.Disable),
                  mainButtonText: 'Disable scrape job',
                  description: `You are about to disable this scrape job. This means that metrics will no longer be scraped from these services until you enable the scrape job again.`,
                  confirmText: disableStatus === 'pending' ? 'Disabling...' : 'Disable scrape jobs',
                  variant: 'secondary' as ButtonVariant,
                  confirmButtonVariant: 'primary' as ButtonVariant,
                  onConfirm: () => {
                    disableScrapeJobs([job.name]);
                  },
                }
              : {
                  id: 'enable',
                  ...getCommonProps(ScrapeJobActionType.Enable),
                  mainButtonText: 'Enable scrape job',
                  description: `You are about to enable this scrape job. This means that metrics will start to be scraped from these services, which will result in additional active series in Grafana Cloud.`,
                  confirmText: enableStatus === 'pending' ? 'Enabling...' : 'Enable scrape jobs',
                  variant: 'secondary' as ButtonVariant,
                  confirmButtonVariant: 'primary' as ButtonVariant,
                  onConfirm: () => {
                    enableScrapeJobs([job.name]);
                  },
                })}
            key={0}
            isMainButtonDisabled={false}
            confirmButtonDisabled={enableStatus === 'pending' || disableStatus === 'pending'}
          />
          <ActionButton
            {...{
              id: 'delete',
              title: `Delete job '${job.name}'`,
              mainButtonText: 'Delete scrape job',
              description: `Are you sure you want to delete this job?`,
              confirmText: deleteJobStatus === 'pending' ? 'Deleting...' : 'Delete',
              variant: 'destructive' as ButtonVariant,
              confirmButtonVariant: 'destructive' as ButtonVariant,
              onClick: () => setOpenDeleteModal(true),
              closeModal: () => setOpenDeleteModal(false),
              open: openDeleteModal,
              key: 'delete',
              onConfirm: () => {
                confirmDeleteJob();
              },
            }}
            key={1}
            isMainButtonDisabled={false}
            confirmButtonDisabled={deleteJobStatus === 'pending'}
          />
        </div>
        <JobStatusWidget />
      </form>
    </FormProvider>
  );
};
