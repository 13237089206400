import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Label, Select, Tooltip, useStyles2 } from '@grafana/ui';
import { FormOutput } from 'feature/AWS/components/SaasIntegrations/types';
import { ScrapeInterval } from 'api/hosted-exporters-api/data-models';
import { transformSecondsToMinutesFormat } from 'utils/misc';

const getScrapeIntervalStyles = (theme: GrafanaTheme2) => ({
  field: css`
    margin-bottom: ${theme.spacing(3)};
    width: 50%;

    @media (min-width: 768px) and (max-width: 1390px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  `,
  scrapeIntervalSelect: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: 60%;
  `,
  label: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: unset;
  `,
});

export const ScrapeIntervalSelect = ({
  index,
  serviceForm,
  statistics,
  onScrapeIntervalChanged,
}: {
  index: string;
  serviceForm: FormOutput;
  statistics: ScrapeInterval[];
  onScrapeIntervalChanged: (value: number) => void;
}) => {
  const styles = useStyles2(getScrapeIntervalStyles);

  const scrapeIntervalDisabled = statistics.length === 1;
  const scrapeIntervalLabel = (
    <Label
      className={styles.label}
      description="We recommend a higher scrape interval to decrease requests and associated costs."
    >
      Scrape interval
    </Label>
  );
  const scrapeIntervalSelect = (
    <Select<number>
      options={(statistics ?? []).map((scrape_interval) => {
        const scrapeIntervalMinutes = transformSecondsToMinutesFormat(scrape_interval.interval_seconds);
        return {
          label: `Every ${scrapeIntervalMinutes.toString()} ${scrapeIntervalMinutes > 1 ? 'minutes' : 'minute'}`,
          value: scrape_interval.interval_seconds,
        };
      })}
      placeholder="Choose"
      noOptionsMessage="Unable to load scrape intervals."
      className={styles.scrapeIntervalSelect}
      value={serviceForm.scrape_interval}
      inputId={`scrape-selector-${index}`}
      onChange={(option) => onScrapeIntervalChanged(option.value ?? 0)}
      disabled={scrapeIntervalDisabled}
    />
  );

  return (
    <Field
      label={scrapeIntervalLabel}
      invalid={serviceForm.scrape_interval === undefined}
      error={serviceForm.scrape_interval === undefined ? 'This field is required' : ''}
      data-testid="scrape-interval-select"
      htmlFor={`scrape-selector-${index}`}
      className={styles.field}
      required={true}
    >
      {scrapeIntervalDisabled ? (
        <Tooltip
          content={`We currently support only ${transformSecondsToMinutesFormat(statistics[0].interval_seconds)} minutes intervals. If you want to change this, please contact us.`}
        >
          <div>{scrapeIntervalSelect}</div>
        </Tooltip>
      ) : (
        scrapeIntervalSelect
      )}
    </Field>
  );
};
