import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Field, IconButton, Input, Label, useStyles2 } from '@grafana/ui';

import { getError } from './utils';
import { removeDuplicatesFromArray } from 'feature/AWS/utils/utils';

const getStyles = (theme: GrafanaTheme2) => ({
  field: css`
    margin: ${theme.spacing(0.5)} 0 ${theme.spacing(1)} ${theme.spacing(0.5)};
  `,
  label: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: unset;
  `,
  button: css`
    margin-top: ${theme.spacing(2)};
  `,
  list: css`
    list-style: none;
    margin-top: ${theme.spacing(1)};
  `,
  namespaceText: css`
    width: 300px;
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.fontWeightLight};
  `,
  addButtonStyle: css`
    margin: 0 -${theme.spacing(1)};
  `,
  table: css`
    margin-left: ${theme.spacing(0.5)};
    border-collapse: collapse;
    width: 100%;
    margin-top: 12px;
    tbody tr:nth-child(even) {
      background: transparent;
    }
    tbody tr:nth-child(odd) {
      background: ${theme.colors.background.primary};
    }
    td {
      font-weight: 400;
      font-size: 12px;

      &:nth-child(2) {
        width: 50px;
        text-align: right;
      }
    }
    td,
    th {
      &:not(:last-child) {
        width: 200px;
      }
      padding: 10px 8px;
    }
  `,
});

export const InputWithOptions = ({
  id,
  values,
  onChange,
  label,
  listElement,
  defaultServices,
}: {
  id: string;
  values: string[];
  onChange: (serviceIds: string[]) => void;
  label?: string;
  listElement?: React.ReactNode;
  defaultServices?: string[];
}) => {
  const styles = useStyles2(getStyles);
  const [inputValue, setInputValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const isNotExistingValue = (value: string) =>
    !!defaultServices
      ? defaultServices?.every((existingService) => existingService.toLowerCase() !== value.trim().toLowerCase())
      : true;

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (values.includes(value.trim().toLowerCase()) || !isNotExistingValue(value) || value.includes(' ')) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }

    setInputValue(value);
  };

  const onAddValue = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.preventDefault();
    if (!values.includes(inputValue) && inputValue.trim() !== '' && !isInvalid) {
      if (inputValue.includes(',')) {
        const separatedValues = inputValue
          .trim()
          .split(',')
          .filter((el) => el !== '' && isNotExistingValue(el) && !values.includes(el));
        const tagsWithoutDuplicates = removeDuplicatesFromArray(separatedValues);
        onChange(values.concat(tagsWithoutDuplicates));
      } else {
        onChange(values.concat(inputValue.trim()));
      }
    }
    setInputValue('');
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isInvalid) {
      onAddValue(event);
    }
  };

  const onRemove = (nameToRemove: string) => {
    onChange(values.filter((name) => name !== nameToRemove));
  };

  return (
    <>
      <Field
        invalid={isInvalid}
        error={getError(isInvalid, inputValue)}
        className={styles.field}
        label={
          label && (
            <Label
              className={styles.label}
              description="Manually type or paste the exact string(s)(separated by a comma)."
            >
              {label}
            </Label>
          )
        }
      >
        <Input
          value={inputValue}
          onChange={onInputChange}
          name="customNamespaces"
          placeholder="Paste or type the exact name here"
          onKeyDown={onKeyDown}
          data-testid={id}
          suffix={
            <Button
              fill="text"
              className={styles.addButtonStyle}
              onClick={onAddValue}
              size="md"
              disabled={inputValue.length <= 0 || isInvalid}
            >
              Add
            </Button>
          }
        />
      </Field>
      {values.length > 0 &&
        (!!listElement ? (
          listElement
        ) : (
          <table className={styles.table}>
            <tbody>
              {values.map((namespace, index) => (
                <tr key={index}>
                  <td className={styles.namespaceText}>{namespace} </td>
                  <td>
                    <IconButton name="trash-alt" onClick={() => onRemove(namespace)} aria-label="delete" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
    </>
  );
};
