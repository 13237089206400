import React, { useContext } from 'react';
import {
  AgentDetails,
  AgentDetailsAlloyAdvanced,
  AgentDetailsAlloySimple,
  SourceDetails,
} from 'api/int-api/data-models';
import { AlloyIntegrationContext, AlloyIntegrationState } from 'app/contexts/alloyIntegration.context';
import { Pages } from 'e2eSelectors/pages';
import { Markdown, IntegrationSnippetMarkdown } from 'components/Markdown';
import { useAdvancedAlloyBlocks, useAlloyPlatformsInstructions, useSimpleAlloyBlock } from './useSnippets';
import { ConfigFileHelp } from './ConfigFileHelp';

interface InstructionsAlloySimpleProps {
  selectedIntegration: SourceDetails;
  agentDetails: AgentDetailsAlloySimple;
}

const InstructionsAlloySimple = ({ selectedIntegration, agentDetails }: InstructionsAlloySimpleProps) => {
  const { configuredParameters, showLoadingBar, copyToClipboard } =
    useContext<AlloyIntegrationState>(AlloyIntegrationContext);

  const block = useSimpleAlloyBlock(configuredParameters, agentDetails);

  const trackOnCopy = () => copyToClipboard();

  return (
    <>
      <h3>Set up Grafana Alloy to use {selectedIntegration.name}</h3>
      <ConfigFileHelp />
      <Markdown
        markdownSnippet={agentDetails.simple.generalGuidance}
        data-testid={Pages.Source.AlloyConfig.alloyGeneralGuidance}
      />

      <IntegrationSnippetMarkdown
        markdownSnippet={block}
        showLoadingBar={showLoadingBar}
        onCopy={trackOnCopy}
        data-testid={Pages.Source.AlloyConfig.alloySimpleBlock}
      />
    </>
  );
};

interface InstructionsAlloyAdvancedProps {
  selectedIntegration: SourceDetails;
  agentDetails: AgentDetailsAlloyAdvanced;
}

const InstructionsAlloyAdvanced = ({ selectedIntegration, agentDetails }: InstructionsAlloyAdvancedProps) => {
  const { configuredParameters, showLoadingBar, copyToClipboard } =
    useContext<AlloyIntegrationState>(AlloyIntegrationContext);

  const { integrationBlock, metricsBlock, logsBlock } = useAdvancedAlloyBlocks(configuredParameters, agentDetails);
  const { integrationInstructions, metricsInstructions, logsInstructions } =
    useAlloyPlatformsInstructions(agentDetails);

  const trackOnCopy = () => copyToClipboard();

  return (
    <>
      <h3>Set up Grafana Alloy to use {selectedIntegration?.name}</h3>
      <ConfigFileHelp />
      <Markdown
        markdownSnippet={agentDetails.advanced.generalGuidance}
        data-testid={Pages.Source.AlloyConfig.alloyGeneralGuidance}
      />

      {integrationBlock && (
        <>
          <h5 data-testid={Pages.Source.AlloyConfig.integrationInstructionsHeader}>Integration</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={integrationBlock}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={undefined}
            data-testid={Pages.Source.AlloyConfig.alloyAdvancedIntegrationsBlock}
          />
          <Markdown
            markdownSnippet={integrationInstructions}
            data-testid={Pages.Source.AlloyConfig.alloyAdvancedIntegrationsInstructions}
          />
        </>
      )}

      {logsBlock && (
        <>
          <h5 data-testid={Pages.Source.AlloyConfig.logsInstructionsHeader}>Logs</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={logsBlock}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            data-testid={Pages.Source.AlloyConfig.alloyAdvancedLogsBlock}
          />
          <Markdown
            markdownSnippet={logsInstructions}
            data-testid={Pages.Source.AlloyConfig.alloyAdvancedLogsInstructions}
          />
        </>
      )}

      {metricsBlock && (
        <>
          <h5 data-testid={Pages.Source.AlloyConfig.metricsInstructionsHeader}>Metrics</h5>
          <IntegrationSnippetMarkdown
            markdownSnippet={metricsBlock}
            showLoadingBar={showLoadingBar}
            onCopy={trackOnCopy}
            highlightLines={undefined}
            data-testid={Pages.Source.AlloyConfig.alloyAdvancedMetricsBlock}
          />
          <Markdown
            markdownSnippet={metricsInstructions}
            data-testid={Pages.Source.AlloyConfig.alloyAdvancedMetricsInstructions}
          />
        </>
      )}
    </>
  );
};

interface InstructionsAlloyProps {
  selectedIntegration: SourceDetails;
  agentDetails: AgentDetails;
}

export const InstructionsAlloy = ({ selectedIntegration, agentDetails }: InstructionsAlloyProps) => {
  if (agentDetails.contentType === 'alloy-simple') {
    return (
      <InstructionsAlloySimple
        selectedIntegration={selectedIntegration}
        agentDetails={agentDetails as AgentDetailsAlloySimple}
      />
    );
  } else {
    return (
      <InstructionsAlloyAdvanced
        selectedIntegration={selectedIntegration}
        agentDetails={agentDetails as AgentDetailsAlloyAdvanced}
      />
    );
  }
};
