import { config } from '@grafana/runtime';
import React from 'react';
import { AWSIcon, AWSLightIcon } from 'img';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { colors } from 'utils/consts';

const getStyles = (theme: GrafanaTheme2) => ({
  appWrapper: css`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: ${theme.colors.background.canvas};
    z-index: 1;
  `,
  appContainer: css`
    padding: 30px 0;
  `,
  homeHeader: css`
    border-bottom: 1px solid ${colors.blue04};
    padding-bottom: ${theme.spacing(4)};
    margin-bottom: 42px;
  `,
  homeHeaderContent: css`
    align-items: center;
    display: flex;
    h1 {
      margin: 0 0 0 5px;
      font-size: ${theme.typography.h2.fontSize};
      color: ${theme.isDark ? theme.colors.text.maxContrast : colors.dark03};
    }
    img {
      width: 36px;
    }
  `,
});

export const UpgradeGrafanaPageDisclaimer = () => {
  const styles = useStyles2(getStyles);

  const awsIcon = config.theme2.isLight ? AWSLightIcon : AWSIcon;

  return (
    <div className={styles.appWrapper}>
      <div className={styles.appContainer}>
        <div className={styles.homeHeader}>
          <div className={cx(styles.homeHeaderContent, 'page-container')}>
            <img src={awsIcon} alt="logo of aws" />
            <h1>AWS Observability</h1>
          </div>
        </div>
        <div className={'page-container'}>
          <p>Upgrade to Grafana 10 to get access to the newest Grafana applications!</p>
        </div>
      </div>
    </div>
  );
};
