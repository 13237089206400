import { css } from '@emotion/css';
import React, { FC, useEffect } from 'react';

import { GrafanaTheme2, textUtil } from '@grafana/data';
import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { Alert } from 'components/Alert';
import { Pages } from 'e2eSelectors/pages';
import { OnboardingPluginError } from 'api/common/data-model';
import { MutationStatus } from '@tanstack/react-query';

const getStyles = (theme: GrafanaTheme2) => ({
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  connectionStatus: css`
    margin-top: 16px;
    margin-bottom: 0;
    font-size: ${theme.typography.bodySmall.fontSize};
  `,
});

type ConnectionStatusProps = {
  status: MutationStatus;
  error?: OnboardingPluginError;
  successMessage?: string;
};

const ConnectionStatusComponent = ({ status, error, successMessage }: ConnectionStatusProps) => {
  const styles = useStyles2(getStyles);

  switch (status) {
    case 'error':
      return (
        <Alert className={styles.connectionStatus} status="error">
          <div
            role="alert"
            aria-label="Connection error"
            dangerouslySetInnerHTML={{
              __html: textUtil.sanitize(error?.message || error?.errorObject?.data?.message || ''),
            }}
            data-testid={Pages.SaaSIntegration.alertParagraph('connection-error')}
          />
        </Alert>
      );
    case 'success':
      return (
        <Alert className={styles.connectionStatus} status="success">
          <div
            role="alert"
            aria-label="Connection success"
            data-testid={Pages.SaaSIntegration.alertParagraph('connection-successful')}
          >
            {successMessage ? successMessage : 'All good! The account is working properly.'}
          </div>
        </Alert>
      );
    case 'pending':
      return (
        <p className={styles.connectionStatus} role="alert" aria-label="Checking connection">
          Checking connection. It might take up to a minute...
        </p>
      );
    default:
      return null;
  }
};

type ConnectionCheckButtonProps = {
  connectionStatus: MutationStatus;
  connectionError?: OnboardingPluginError;
  onSuccess?: () => void;
  onError?: (error: OnboardingPluginError | undefined) => void;
  disabled?: boolean;
  onCheckConnection: () => void;
  successMessage?: string;
};

export const ConnectionCheckButton: FC<ConnectionCheckButtonProps> = ({
  connectionStatus,
  connectionError,
  onSuccess,
  onError,
  disabled,
  onCheckConnection,
  successMessage,
}) => {
  const styles = useStyles2(getStyles);

  useEffect(() => {
    switch (connectionStatus) {
      case 'success': {
        onSuccess?.();
        break;
      }
      case 'error': {
        onError?.(connectionError);
        break;
      }
    }
  }, [connectionStatus, connectionError, onError, onSuccess]);

  return (
    <>
      <Button
        data-testid={Pages.SaaSIntegration.testConnectionButton}
        onClick={onCheckConnection}
        aria-label="Configure AWS account"
        disabled={connectionStatus === 'pending' || disabled}
        type="button"
      >
        {connectionStatus === 'pending' && <Spinner className={styles.spinner} />}
        Configure AWS account
      </Button>
      <ConnectionStatusComponent status={connectionStatus} error={connectionError} successMessage={successMessage} />
    </>
  );
};
