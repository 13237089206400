import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing(8.75)};
    color: ${theme.colors.text.secondary};

    h2 {
      color: ${theme.colors.text.secondary};
    }
  `,
});

export const ConfigureServicesEmpty = () => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.container} data-testid={Pages.CloudWatch.ConfigServiceMetrics.configureServicesEmpty}>
      <h2>No services selected</h2>
      <p>Select services above to begin configuring metrics, statistics, and scrape settings.</p>
    </div>
  );
};
