import React from 'react';
import { css } from '@emotion/css';
import { Card, useStyles2 } from '@grafana/ui';
import FeatureList from 'feature/common/components/FeatureList/FeatureList';
import { GetStartedCardInfo } from './GetStartedCardInfo';
import { AWS_DASHBOARDS_TABS } from 'feature/common/consts';
import { getDashboardsTabs } from 'feature/common/utils/utils';

const getStyles = () => ({
  featureColumn: (columns = 4) => css`
    grid-column: span ${columns - 1} / ${columns};

    @media (max-width: 992px) {
      grid-column: 1;
    }
  `,
  cardColumn: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
});

export const GetStartedInsightsCard = ({ cards = [] }: { cards: GetStartedCardInfo[] }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.featureColumn(cards?.length)}>
      <Card className={styles.cardColumn}>
        <h5>Out-of-the-box dashboards</h5>
        <FeatureList tabs={getDashboardsTabs(AWS_DASHBOARDS_TABS)} />
      </Card>
    </div>
  );
};
