import { css, cx } from '@emotion/css';
import React, { useCallback, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, useTheme2, Button, Input, Label, Tooltip, Icon } from '@grafana/ui';
import { WarningText } from 'components/WarningText';
import { TagsAddIcon, TagsRemoveIcon } from 'img';
import { getTagsError, removeStartOrEndSpaceFromArray, areTagsInvalid } from './utils';

interface Props {
  tags: string[];
  placeholder?: string;
  onChange: (tags: string) => void;
  width?: number;
  id?: string;
  className?: string;
  /** Toggle disabled state */
  disabled?: boolean;
  /** Enable adding new tags when input loses focus */
  addOnBlur?: boolean;
  enableAddTags?: boolean;
  onAddTags?: () => void;
  onClearTags?: () => void;
}

export const TagsInputOptions = ({
  placeholder = 'New tag (enter key to add)',
  onChange,
  width,
  className,
  disabled,
  addOnBlur,
  id = 'edit-tag',
  tags = [],
  enableAddTags,
  onAddTags,
  onClearTags,
}: Props) => {
  const [newTagName, setNewTagName] = useState('');
  const styles = useStyles2(getStyles);
  const theme = useTheme2();
  const addTitle = 'Add tags from other services';
  const clearTitle = 'Clear all tags';

  const onNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(event.target.value);
  }, []);

  const onAdd = (event?: React.MouseEvent | React.KeyboardEvent) => {
    event?.preventDefault();
    onChange(removeStartOrEndSpaceFromArray(newTagName));
    setNewTagName('');
  };

  const onBlur = () => {
    if (addOnBlur && newTagName) {
      onAdd();
    }
  };

  const onKeyboardAdd = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && newTagName !== '' && !isInvalidInput) {
      onAdd(event);
    }
  };

  const isInvalidInput = areTagsInvalid(newTagName, tags);

  return (
    <div className={cx(styles.wrapper, className, width ? css({ width: theme.spacing(width) }) : '')}>
      <Label
        className={styles.label}
        description="Manually type or paste the exact tag key string(s)(separated by a comma) that you have applied to the resources of this AWS service."
      >
        Tags
      </Label>
      <div className={styles.inputOptions}>
        <Input
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onNameChange}
          value={newTagName}
          onKeyDown={onKeyboardAdd}
          onBlur={onBlur}
          suffix={
            <Button
              fill="text"
              className={styles.addButtonStyle}
              onClick={onAdd}
              size="md"
              disabled={newTagName.length <= 0 || isInvalidInput}
            >
              Add
            </Button>
          }
          invalid={isInvalidInput}
        />
        <Tooltip content={'Make sure you add tags with the AWS format, and not the Prometheus format.'}>
          <Icon name="question-circle" size="xl" />
        </Tooltip>
        {tags && tags?.length > 0 && (
          <Button
            fill="solid"
            variant="secondary"
            onClick={() => onClearTags?.()}
            className={styles.buttonOption}
            tooltip={clearTitle}
          >
            <div>{clearTitle}</div>
            <img src={TagsRemoveIcon} alt={clearTitle} />
          </Button>
        )}
        {!enableAddTags && (
          <Button
            fill="solid"
            variant="secondary"
            onClick={() => onAddTags?.()}
            className={styles.buttonOption}
            tooltip={addTitle}
          >
            <div>{addTitle}</div>
            <img src={TagsAddIcon} alt={addTitle} />
          </Button>
        )}
      </div>

      {isInvalidInput && <WarningText text={getTagsError(isInvalidInput, newTagName)} />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css({
    minHeight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  }),
  addButtonStyle: css({
    margin: `0 -${theme.spacing(1)}`,
  }),
  label: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: unset;
  `,
  inputOptions: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: center;

    @media (max-width: 868px) {
      gap: ${theme.spacing(1)};
    }
  `,
  buttonOption: css`
    @media (max-width: 868px) {
      padding-left: ${theme.spacing(0.5)};
      padding-right: ${theme.spacing(0.5)};
      background-color: transparent;
      border: 0;

      img {
        display: block;
        height: 24px;
      }

      div {
        display: none;
      }
    }
    @media (min-width: 869px) {
      img {
        display: none;
      }

      div {
        display: flex;
      }
    }
  `,
});
