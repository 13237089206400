import { Card, useStyles2 } from '@grafana/ui';
import React from 'react';
import { Pages } from 'e2eSelectors/pages';
import { getStartedCards } from './getStartedCards';
import { getStyles } from '../GetStarted.styles';
import { GetStartedCard } from '../GetStartedCard';
import { GetStartedInsightsCard } from '../GetStartedInsightsCard';
import { GetStartedLearnMoreCard } from '../GetStartedLearnMoreCard';

export const GetStarted = () => {
  const styles = useStyles2(getStyles);

  const cards = getStartedCards;

  return (
    <>
      <div>
        <Card className={styles.cardCentered}>
          <h2>
            Easy set-up, granular configuration, and out-of-the-box monitoring for your services across all your AWS
            accounts.
          </h2>
        </Card>
        <div className={styles.integrationsFlex(cards?.length)} data-testid={Pages.GetStarted.cardsRow}>
          {cards.map((card) => (
            <GetStartedCard key={card.id} card={card} />
          ))}
        </div>
      </div>
      <div className={styles.insightsRow(cards?.length)}>
        <GetStartedInsightsCard cards={cards} />
        <GetStartedLearnMoreCard />
      </div>
    </>
  );
};
