import { VAR_LOKI_DATASOURCE } from 'scenes/variables';
import {
  sceneGraph,
  SceneObjectBase,
  SceneObjectState,
  SceneQueryRunner,
  SceneVariable,
  VariableDependencyConfig,
} from '@grafana/scenes';
import { onChangeLokiDatasourceBehavior } from 'scenes/Logs/behaviours';

export class LogGroupsBehaviour extends SceneObjectBase<SceneObjectState> {
  constructor(state: Partial<SceneObjectState>) {
    super(state);
    this.addActivationHandler(this._onActivate);
  }

  protected _variableDependency = new VariableDependencyConfig(this, {
    variableNames: [VAR_LOKI_DATASOURCE],
    onReferencedVariableValueChanged: this.onVariableValueChanged.bind(this),
  });

  public onVariableValueChanged(variable: SceneVariable) {
    if (variable.state.name === VAR_LOKI_DATASOURCE) {
      onChangeLokiDatasourceBehavior(variable);
    }
    const queryRunner = sceneGraph.getAncestor(this, SceneQueryRunner);
    if (queryRunner) {
      queryRunner.runQueries();
    }
  }

  private _onActivate = () => {
    const parent = this.parent;

    if (!parent) {
      throw new Error('LogGroupsBehaviour must be attached to a parent object');
    }

    // re-run the query to update log groups view in case datasource change before scene activation (via url sync)
    (parent as SceneQueryRunner).runQueries();
  };
}
