import { SceneAppPage, SceneAppPageLike } from '@grafana/scenes';
import { getDashboardEmbeddedScene } from './getDashboardEmbeddedScene';
import { DashboardDb } from 'api/dashboards/data-model';
import { getDashboardsUrlByProvider } from 'scenes/misc';
import { CloudProvider } from 'types/CloudProvider';
import { DASHBOARD_ICON } from 'feature/AWS/utils/utils';

const getDashboardId = (dashboard: DashboardDb | undefined) => dashboard?.uri.replace('db/', '') ?? '';

export const getDashboardEmbeddedScenePage = (
  provider: CloudProvider,
  integrationFolder: string,
  id: number,
  dashboard: DashboardDb | undefined,
  parent: SceneAppPageLike
): SceneAppPage => {
  const dashboardId = getDashboardId(dashboard);
  return new SceneAppPage({
    title: dashboard ? dashboard.title : '',
    titleImg:
      provider === CloudProvider.AWS
        ? !!dashboardId && !!(DASHBOARD_ICON as any)[dashboardId]
          ? `https://storage.googleapis.com/grafanalabs-integration-logos/aws/${(DASHBOARD_ICON as any)[dashboardId]}`
          : 'https://storage.googleapis.com/grafanalabs-integration-logos/aws/AWS-CloudWatch.svg'
        : undefined,
    titleIcon: dashboardId === undefined && provider !== CloudProvider.AWS ? 'dashboard' : undefined,
    hideFromBreadcrumbs: false,
    preserveUrlKeys: [],
    url: `${getDashboardsUrlByProvider(provider)}/${integrationFolder}/${id}`,
    getScene: () => getDashboardEmbeddedScene(dashboard),
    getParentPage: () => parent,
  });
};
