import { VAR_ACCOUNT, VAR_ALL_TAGS_VALUES, VAR_REGION, VAR_SCRAPE_JOB } from 'scenes/variables';

export const NECESSARY_EC2_METRICS = {
  METRIC_STATUS_CHECK_FAILED_SUM: `aws_ec2_status_check_failed_sum`,
  METRIC_STATUS_CHECK_FAILED_SYSTEM_SUM: `aws_ec2_status_check_failed_system_sum`,
  METRIC_STATUS_CHECK_FAILED_INSTANCE_SUM: `aws_ec2_status_check_failed_instance_sum`,
  METRIC_CPUUTILIZATION_MAXIMUM: `aws_ec2_cpuutilization_maximum`,
  METRIC_CPUUTILIZATION_AVERAGE: `aws_ec2_cpuutilization_average`,
  METRIC_EBSIOBALANCE_PERCENT_AVERAGE: `aws_ec2_ebsiobalance_percent_average`,
  METRIC_EBSBYTE_BALANCE_PERCENT_AVERAGE: `aws_ec2_ebsbyte_balance_percent_average`,
  METRIC_NETWORK_IN_AVERAGE: `aws_ec2_network_in_average`,
  METRIC_NETWORK_OUT_AVERAGE: `aws_ec2_network_out_average`,
  METRIC_DISK_WRITE_OPS_SUM: `aws_ec2_disk_write_ops_sum`,
  METRIC_DISK_READ_OPS_SUM: `aws_ec2_disk_read_ops_sum`,
  METRIC_EBSREAD_BYTES_SUM: `aws_ec2_ebsread_bytes_sum`,
  METRIC_EBSWRITE_BYTES_SUM: `aws_ec2_ebswrite_bytes_sum`,
  METRIC_EBSREAD_OPS_SUM: `aws_ec2_ebsread_ops_sum`,
  METRIC_EBSWRITE_OPS_SUM: `aws_ec2_ebswrite_ops_sum`,
  METRIC_DISK_WRITE_BYTES_SUM: `aws_ec2_disk_write_bytes_sum`,
  METRIC_DISK_READ_BYTES_SUM: `aws_ec2_disk_read_bytes_sum`,
};

export const allQueries = {
  statusCheckQueries: {
    statusCheckFailedSum: `${NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_SUM}`,
  },
  regionsQueries: {
    countByRegion: `count(label_replace(
      label_replace(
          aws_ec2_info{name=~"arn:aws:ec2:$${VAR_REGION}:.*"},
          "region", "$1", "name", "arn:aws:ec2:(.*?):.*"
      ), "account", "$1", "name", "arn:aws:ec2:.*:(.*):.*"
    ))`,
    countByTags: `count(label_replace(
      label_replace(
          aws_ec2_info{$allTags="$${VAR_ALL_TAGS_VALUES}"},
          "region", "$1", "name", "arn:aws:ec2:(.*?):.*"
      ), "account", "$1", "name", "arn:aws:ec2:.*:(.*):.*"
    ))`,
    countAll: `count(aws_ec2_info{name=~"arn:aws:ec2:.*", region=~'$${VAR_REGION}', account_id=~'$${VAR_ACCOUNT}', scrape_job=~'$${VAR_SCRAPE_JOB}'})`,
  },
  byInstance: {
    cpuUtilizationAvg: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_AVERAGE}{dimension_InstanceId=\"${id}\"}`,
    cpuUtilizationMax: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_MAXIMUM}{dimension_InstanceId=\"${id}\"}`,
    networkInAvg: (id: string) => `${NECESSARY_EC2_METRICS.METRIC_NETWORK_IN_AVERAGE}{dimension_InstanceId=\"${id}\"}`,
    networkOutAvg: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_NETWORK_OUT_AVERAGE}{dimension_InstanceId=\"${id}\"}`,
    diskWriteSum: (id: string) => `${NECESSARY_EC2_METRICS.METRIC_DISK_WRITE_OPS_SUM}{dimension_InstanceId=\"${id}\"}`,
    diskReadSum: (id: string) => `${NECESSARY_EC2_METRICS.METRIC_DISK_READ_OPS_SUM}{dimension_InstanceId=\"${id}\"}`,
    statusCheckFailedSum: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_INSTANCE_SUM}{dimension_InstanceId=\"${id}\"}`,
    statusCheckFailedSystemSum: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_STATUS_CHECK_FAILED_SYSTEM_SUM}{dimension_InstanceId=\"${id}\"}`,
    ebsReadBytesSum: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_EBSREAD_BYTES_SUM}{dimension_InstanceId=\"${id}\"}`,
    ebsWriteBytesSum: (id: string) =>
      `${NECESSARY_EC2_METRICS.METRIC_EBSWRITE_BYTES_SUM}{dimension_InstanceId=\"${id}\"}`,
    ebsReadOpsSum: (id: string) => `${NECESSARY_EC2_METRICS.METRIC_EBSREAD_OPS_SUM}{dimension_InstanceId=\"${id}\"}`,
    ebsWriteOpsSum: (id: string) => `${NECESSARY_EC2_METRICS.METRIC_EBSWRITE_OPS_SUM}{dimension_InstanceId=\"${id}\"}`,
  },
  topInstances: {
    withDiskWriteBytesSum: `sum by(account_id, region, dimension_InstanceId, scrape_job, tag_aws_autoscaling_groupName, tag_Name) ($topOrBottom($topInstances, ${NECESSARY_EC2_METRICS.METRIC_DISK_WRITE_BYTES_SUM}{name!='global'} + on(name, scrape_job) group_left(tag_Name, tag_aws_autoscaling_groupName) aws_ec2_info))`,
    withDiskReadBytesSum: `sum by(account_id, region, dimension_InstanceId, scrape_job, tag_aws_autoscaling_groupName, tag_Name) ($topOrBottom($topInstances, ${NECESSARY_EC2_METRICS.METRIC_DISK_READ_BYTES_SUM}{name!='global'} + on(name, scrape_job) group_left(tag_Name, tag_aws_autoscaling_groupName) aws_ec2_info))`,
    withCpuUtilizationAvg: `sum by(account_id, region, dimension_InstanceId, scrape_job, tag_aws_autoscaling_groupName, tag_Name) ($topOrBottom($topInstances, ${NECESSARY_EC2_METRICS.METRIC_CPUUTILIZATION_AVERAGE}{name!='global'} + on(name, scrape_job) group_left(tag_Name, tag_aws_autoscaling_groupName) aws_ec2_info))`,
  },
  allTagsForAnInstance: (id: string) => `aws_ec2_info{name=~".*${id}"}`,
};
