import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';
import { AWS_CONFIGURATION_URL } from 'scenes/AWS/routes';
import { isUserAdmin } from 'utils/user';

const getStyles = (theme: GrafanaTheme2) => ({
  emptyList: css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.spacing(3)};

    p {
      color: ${theme.colors.text.secondary};
    }
  `,
  clearFiltersButton: css`
    margin-bottom: ${theme.spacing(2)};
  `,
});

export const ServiceOverviewEmptyList = ({
  areScrapeJobsDisabled,
  onClearFilters,
}: {
  areScrapeJobsDisabled: boolean;
  onClearFilters: () => void;
}) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.emptyList} data-testid={Pages.ServiceOverview.emptyContainer}>
      <h2>No data found</h2>
      {areScrapeJobsDisabled ? (
        <p>
          No services were found.{' '}
          {isUserAdmin() ? (
            <>
              Check your <a href={`${AWS_CONFIGURATION_URL}/cloudwatch`}>configuration</a> to ensure that the
              appropriate scrape job is enabled.
            </>
          ) : (
            `Ask your admin to check the configuration if the appropriate scrape job is enabled.`
          )}
        </p>
      ) : (
        <>
          <p>No services were found with the current filter criteria.</p>
          <Button variant="secondary" onClick={() => onClearFilters()} className={styles.clearFiltersButton}>
            Clear filters
          </Button>
        </>
      )}
    </div>
  );
};
