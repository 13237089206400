import { css } from '@emotion/css';
import React, { useState } from 'react';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Field, Button, MultiSelect, useStyles2 } from '@grafana/ui';

import { Pages } from 'e2eSelectors/pages';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    display: flex;
    align-items: center;
  `,
  searchInput: css`
    margin-top: ${theme.spacing(2)};
    box-sizing: border-box;
    align-items: center;
    font-size: ${theme.typography.body.fontSize};
    flex-flow: nowrap;
  `,
  statisticLabel: css`
    margin-right: ${theme.spacing(1)};
    font-size: ${theme.typography.body.fontSize};
  `,
});

export const StatisticsSelect = ({
  statistics,
  outputMetricsArray,
  index,
  searchInputValue,
  onApplyToAll,
}: {
  statistics: string[];
  outputMetricsArray: Array<{ name: string; isChecked: boolean; statistics: string[] }>;
  index: string;
  searchInputValue?: string;
  onApplyToAll: (metrics: string[], statisticBatchSelectValue: string[]) => void;
}) => {
  const styles = useStyles2(getStyles);

  const checkedSearchResultLength = outputMetricsArray.filter(
    (metr) => metr.name.includes(searchInputValue ?? '') && metr.isChecked
  ).length;
  const [statisticBatchSelectValue, setStatisticBatchSelectValue] = useState<string[]>([]);

  const handleApplyToAllSelectedClick = () => {
    const metrics: string[] = [];
    outputMetricsArray.map((metr) => {
      if (metr.isChecked && metr.name.includes(searchInputValue ?? '')) {
        metrics.push(metr.name);
      }
    });
    onApplyToAll(metrics, statisticBatchSelectValue);
  };

  return (
    <div className={styles.container}>
      <Field
        className={styles.searchInput}
        label={<span className={styles.statisticLabel}>Statistics</span>}
        horizontal={true}
        data-testid={Pages.CloudWatch.ConfigServiceMetrics.statisticsSelectField}
        disabled={checkedSearchResultLength === 0}
      >
        <MultiSelect<string>
          options={statistics.map((statistic) => ({
            label: statistic,
            value: statistic,
          }))}
          width={32}
          inputId={`statistic-select-field-${index}`}
          value={statisticBatchSelectValue}
          onChange={(options: Array<SelectableValue<string>>) => {
            setStatisticBatchSelectValue(options.map((option) => option.value ?? ''));
          }}
          closeMenuOnSelect={false}
        />
      </Field>
      <div>
        <Button
          data-testid={Pages.CloudWatch.ConfigServiceMetrics.statisticsApplyButton}
          variant="primary"
          fill="text"
          onClick={handleApplyToAllSelectedClick}
          disabled={checkedSearchResultLength === 0}
        >
          Apply to {checkedSearchResultLength} {checkedSearchResultLength === 1 ? 'metric' : 'metrics'} selected
        </Button>
      </div>
    </div>
  );
};
