import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getConfigureServiceRowStyles = (theme: GrafanaTheme2) => ({
  row: css`
    font-weight: ${theme.typography.fontWeightRegular};
  `,
  header: css`
    color: ${theme.colors.text.primary};
    display: flex;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
  checkbox: css`
    margin-right: ${theme.spacing(1.25)};
    position: relative;
    top: ${theme.spacing(0.25)};

    > span {
      font-size: 19px;
      font-weight: ${theme.typography.fontWeightRegular};
    }
  `,
  checkboxColumn: css`
    width: 20px;
  `,
  servicesColumn: css`
    min-width: 100px;
    width: 50%;
  `,
  actionsColumn: css`
    display: flex;
    gap: ${theme.spacing(1)};
    justify-content: flex-end;
  `,
  actionsButton: css`
    padding: ${theme.spacing(1)};
  `,
  ellipsisIcon: css`
    fill: ${theme.colors.secondary.text};
  `,
  warningText: css`
    color: ${theme.colors.warning.text};
  `,
  marginRight: css`
    margin-right: ${theme.spacing(0.5)};
  `,
});
