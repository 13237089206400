import { css, keyframes } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

const enterKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40%)
  }

  to {
    transform: translateY(0);
    opacity: 1
  }
`;

export const getEditJobStyles = (theme: GrafanaTheme2) => ({
  container: css`
    margin-bottom: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid ${theme.colors.border.medium};
    padding: ${theme.spacing(2)};
    animation: ${enterKeyframes} 200ms ease-in-out;
  `,
  jobForm: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: ${theme.spacing(1)};
    margin-top: ${theme.spacing.gridSize};
  `,
  jobFieldName: css`
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};
    font-size: ${theme.typography.body.fontSize};
  `,
  controlGroup: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    gap: ${theme.spacing(1)};

    a {
      margin-right: ${theme.spacing(2)};
    }
  `,
  control: css`
    margin-right: ${theme.spacing(1)};
  `,
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  alert: css`
    background-color: ${theme.colors.background.primary};
    margin: ${theme.spacing(2)} 0;
    font-size: ${theme.typography.body.fontSize};
  `,
  arnInput: css`
    max-width: 60%;
  `,
  warning: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    color: ${theme.colors.warning.text};
    margin-top: ${theme.spacing(3)};
    align-items: center;
  `,
  hide: css`
    display: none;
  `,
});
