import { getAccountVar, getPrometheusDatasourceVar, getRegionVar, getScrapeJobVar } from 'scenes/variables';

export function getVariables(prometheusName: string, disableDataSourceFilter = false) {
  return {
    datasources: getPrometheusDatasourceVar(prometheusName, disableDataSourceFilter),
    region: getRegionVar(),
    account: getAccountVar(),
    scrapeJob: getScrapeJobVar(),
  };
}
