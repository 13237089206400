import { SaasIntegrationWithJobType } from 'feature/AWS/components/SaasIntegrations/types';
import { useParams } from 'react-router-dom';

type CloudwatchParams = {
  id: SaasIntegrationWithJobType;
  action: string;
  job?: string;
};

export const useCloudwatchParams = (): CloudwatchParams => {
  return useParams<CloudwatchParams>();
};
