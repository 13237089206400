import { ServiceConfigurationApi } from 'api/hosted-exporters-api/data-models';

const SAAS_INTEGRATIONS_WITH_JOB = ['cloudwatch'] as const;

export type SaasIntegrationWithJobType = (typeof SAAS_INTEGRATIONS_WITH_JOB)[number];

export type OutputMetrics = { name: string; isChecked: boolean; statistics: string[] };

type FormMetrics = {
  [key: string]: {
    isChecked: boolean;
    statistics: string[];
  };
};

export interface AccountFormOutput {
  name: string;
  arn: string;
  regions: string[];
  export_tags: boolean;
  isValid: boolean;
  isDirty: boolean;
}

export interface TagsFormOutput {
  name: string;
  value?: string;
  addToMetrics: boolean;
  useAsFilter: boolean;
  invalid?: boolean;
  errorMsg?: string;
}

export interface FormOutput {
  display_name?: string;
  service_id: string;
  metrics: FormMetrics;
  scrape_interval: number;
  selected?: boolean;
  isNew?: boolean;
  tags: TagsFormOutput[];
  isCustomNamespace: boolean;
}

export type ServicesSummaryChanges = {
  added: ServiceConfigurationApi[];
  deleted: ServiceConfigurationApi[];
  updated: ServiceConfigurationApi[];
};

export enum ScrapeJobActionType {
  Disable,
  Enable,
  Delete,
}
