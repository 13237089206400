import { OpenInExploreButton } from 'scenes/Logs/OpenInExploreButton';
import { SceneControlsSpacer, SceneRefreshPicker, SceneTimePicker, VariableValueSelectors } from '@grafana/scenes';

export const getLogsControls = () => [
  new OpenInExploreButton({}),
  new SceneControlsSpacer(),
  new VariableValueSelectors({}),
  new SceneTimePicker({ isOnCanvas: true }),
  new SceneRefreshPicker({ isOnCanvas: true }),
];
