import { EmbeddedScene, SceneAppPage, SceneReactObject } from '@grafana/scenes';
import { AWS_CONFIGURATION_URL } from 'scenes/AWS/routes';
import { Configuration as AwsConfiguration } from 'feature/AWS/pages/Configuration/Configuration';

const getAwsConfigurationScene = (showServices: boolean) => {
  return new EmbeddedScene({
    body: new SceneReactObject({
      component: AwsConfiguration,
      props: { showServices },
    }),
  });
};

export const getConfigurationSceneTab = (showServices = false) =>
  new SceneAppPage({
    title: 'Configuration',
    url: AWS_CONFIGURATION_URL,
    getScene: () => getAwsConfigurationScene(showServices),
    preserveUrlKeys: [],
  });
