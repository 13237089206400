import { FormOutput, TagsFormOutput } from 'feature/AWS/components/SaasIntegrations/types';
import { Checkbox, Icon, IconButton, Input, Tooltip, useStyles2, Tag, getTagColorsFromName } from '@grafana/ui';
import { Pages } from 'e2eSelectors/pages';
import React, { useEffect } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { TagsInputOptions } from './TagsInputOptions';
import { TagInfo } from './TagInfo';
import { TagsToAddToMetricsFlow } from './TagsToAddToMetricsFlow';
import { ResourceTagsFilterFlow } from './ResourceTagsFilterFlow';
import { WarningText } from 'components/WarningText';
import { INVALID_TAGS_MESSAGE } from '../../../CloudWatchUtils';

const getStyles = (theme: GrafanaTheme2, noOptionsAdded?: boolean) => ({
  field: css`
    margin-bottom: 0;
  `,
  label: css`
    font-size: ${theme.typography.body.fontSize};
    max-width: unset;
  `,
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `,
  tagsInputContainer: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(2)};
  `,
  tagsInputOptions: css`
    display: flex;
    gap: ${theme.spacing(2)};
    align-items: flex-start;
    margin-top: ${theme.spacing(5)};
  `,
  applyAllButton: css`
    ${noOptionsAdded ? 'align-self: end;' : ''}
  `,
  helpingTooltipIcon: css`
    top: ${theme.spacing(0.5)};
    position: relative;
  `,
  tagsContainer: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(2)};
  `,
  tagsValue: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
  tagList: css`
    flex-direction: column;
    gap: 0;
    margin-top: ${theme.spacing(5)};
    justify-content: flex-start;

    li {
      height: 48px;
      align-items: center;
      display: flex;
    }
  `,
  tagsTable: css`
    th,
    td {
      padding: ${theme.spacing(1)};
      text-align: center;
    }
  `,
  removeBtn: css`
    margin-top: ${theme.spacing(0.75)};
  `,
});

type TagsSystemProps = {
  serviceForm: FormOutput;
  onTagsChanged: (values: TagsFormOutput[]) => void;
  onClearAllTags: () => void;
  onApplyAllTags: () => void;
  serviceConfiguration: FormOutput[];
};

export const TagsManager = ({
  serviceForm,
  onTagsChanged,
  onClearAllTags,
  onApplyAllTags,
  serviceConfiguration,
}: TagsSystemProps) => {
  const areTagsEmpty = serviceForm.tags === undefined || serviceForm.tags?.length === 0;
  const styles = useStyles2((theme) => getStyles(theme, areTagsEmpty));

  const allSavedTags = Array.from<string>(
    new Set(serviceConfiguration.map((service) => (service.tags ?? []).map((tag) => tag.name) ?? []).flat())
  );
  const serviceTags = (serviceForm.tags ?? []).map((tag) => tag.name);
  let areAllExistingTagsSet = true;
  if (serviceTags.length > 0 && allSavedTags.length > 0) {
    areAllExistingTagsSet = allSavedTags.every((tag) => serviceTags.includes(tag));
  } else if (allSavedTags.length > 0) {
    areAllExistingTagsSet = false;
  }

  const onTagsAdded = (values: string) => {
    let result = serviceForm.tags ? [...serviceForm.tags] : [];
    // remove duplicated values
    const tags: string[] = Array.from(new Set([...result.map((t) => t.name).concat(values.split(','))]));
    tags.forEach((tagName) => {
      if (serviceForm.tags.findIndex((t) => t.name === tagName) < 0) {
        result.push({
          name: tagName,
          value: '',
          addToMetrics: true,
          useAsFilter: false,
        } as TagsFormOutput);
      }
    });
    result = result.sort((a, b) => a.name.localeCompare(b.name));
    onTagsChanged(result);
  };

  // -- Fix tags if they contain spaces at the start or end of the values (previously permitted) --
  const fixExistingTags = () => {
    let result = serviceForm.tags ? [...serviceForm.tags] : [];
    result = result.map((tag) => ({ ...tag, name: tag.name.trim() }));
    onTagsChanged(result);
  };

  useEffect(() => {
    fixExistingTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // ----

  function onAddToMetric(tag: TagsFormOutput, value: boolean) {
    const result = serviceForm.tags.map((t) => {
      if (t.name === tag.name) {
        return {
          ...t,
          addToMetrics: value,
          invalid: !value && !t.useAsFilter,
          errorMsg: !value && !t.useAsFilter ? INVALID_TAGS_MESSAGE : '',
        };
      }
      return t;
    });

    onTagsChanged(result);
  }

  function onAddTagToFilter(tag: TagsFormOutput, useAsFilter: boolean) {
    const result = serviceForm.tags.map((t) => {
      if (t.name === tag.name) {
        return {
          ...t,
          useAsFilter,
          value: '',
          disabled: !useAsFilter,
          invalid: !t.addToMetrics && !useAsFilter,
          errorMsg: !t.addToMetrics && !useAsFilter ? INVALID_TAGS_MESSAGE : '',
        };
      }
      return t;
    });
    onTagsChanged(result);
  }

  function onAddTagFilterValue(tag: TagsFormOutput, value: string) {
    const result = serviceForm.tags.map((t) => {
      if (t.name === tag.name) {
        return { ...t, value };
      }
      return t;
    });
    onTagsChanged(result);
  }

  function onRemoveTag(tag: TagsFormOutput) {
    const result = serviceForm.tags.filter((t) => t.name !== tag.name);
    onTagsChanged(result);
  }

  return (
    <div
      className={styles.flexContainer}
      data-testid={Pages.CloudWatch.ConfigServiceMetrics.editTag(serviceForm.service_id)}
    >
      <TagsInputOptions
        id={'tags-input'}
        tags={serviceTags}
        onClearTags={onClearAllTags}
        onAddTags={onApplyAllTags}
        enableAddTags={areAllExistingTagsSet}
        onChange={onTagsAdded}
      />
      {serviceForm.tags && serviceForm.tags.length > 0 && (
        <div
          className={styles.tagsContainer}
          data-testid={Pages.CloudWatch.ConfigServiceMetrics.tagsContainer(serviceForm.service_id)}
        >
          <table className={styles.tagsTable}>
            <thead>
              <tr>
                <th></th>
                <th>
                  Add tag to metrics{' '}
                  <TagInfo
                    title={'Adding tag to metrics'}
                    description={
                      'Add the tag that you have applied within AWS to the metrics. This enriches the metrics data with that tag.'
                    }
                    info={<TagsToAddToMetricsFlow />}
                  />
                </th>
                <th>
                  Filter by tag value{' '}
                  <TagInfo
                    title={'Filter by tag value'}
                    description={'Filter the metrics by a specific value associated with the tag'}
                    info={<ResourceTagsFilterFlow />}
                  />
                </th>
                <th>
                  <div className={styles.tagsValue}>
                    <span>Tag value filter</span>
                    <Tooltip
                      content={
                        'You can enter a golang compatible regex (ex. "a.*") or leave it blank to filter out all resources with this tag'
                      }
                    >
                      <Icon name="info-circle" />
                    </Tooltip>
                  </div>
                </th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {serviceForm.tags.map((tag) => (
                <tr key={tag.name}>
                  <td>
                    <Tag name={tag.name} color={getTagColorsFromName(tag.name).color} />
                  </td>
                  <td>
                    <Checkbox
                      id={`addTagToMetrics-${tag.name}`}
                      data-testid={Pages.CloudWatch.ConfigServiceMetrics.checkAddTagToMetrics(
                        serviceForm.service_id,
                        tag.name
                      )}
                      value={tag.addToMetrics}
                      onChange={(e) => onAddToMetric(tag, e.currentTarget.checked)}
                    />
                  </td>
                  <td>
                    <Checkbox
                      id={`addTagFilter-${tag.name}`}
                      data-testid={Pages.CloudWatch.ConfigServiceMetrics.checkTagFilter(
                        serviceForm.service_id,
                        tag.name
                      )}
                      value={tag.useAsFilter}
                      onChange={(e) => onAddTagToFilter(tag, e.currentTarget.checked)}
                    />
                  </td>
                  <td>
                    <Input
                      id={`tagFilterValue-${tag.name}`}
                      data-testid={Pages.CloudWatch.ConfigServiceMetrics.setTagFilterValue(
                        serviceForm.service_id,
                        tag.name
                      )}
                      value={tag.value}
                      placeholder={'Enter optional tag value'}
                      disabled={!tag.useAsFilter}
                      onChange={(e) => onAddTagFilterValue(tag, e.currentTarget.value)}
                    />
                  </td>
                  <td>
                    <IconButton
                      name="trash-alt"
                      onClick={() => onRemoveTag(tag)}
                      aria-label="delete"
                      className={styles.removeBtn}
                    />
                  </td>
                  <td>{tag.errorMsg && <WarningText text={tag.errorMsg} />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
